import { useCallback, useEffect, useRef } from "react";

import { useCTGInfoMutation, useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { useCTGEntryDataValue } from "@stores/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useEnterAttributionIdInEntryData } from "./useEnterAttributionIdInEntryData";
import {
  DEFAULT_TABLE_CREATOR_HISTORY_POSITION,
  useTableCreatorCurrentHistory,
} from "../../../shared/context/TableCreatorHistoryContext";

export const useEntryFillInTablePageEffect = () => {
  const { mutate: updateCTGInfo } = useCTGInfoMutation();
  const { data: articleInfo } = useCTGInfoQuery();
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();

  const entryData = useCTGEntryDataValue(getPressIdFromURL());

  useEnterAttributionIdInEntryData();

  const statusRef = useRef(articleInfo?.draftArticleStatus);
  const initialEntryDataRef = useRef(entryData);

  const handleEntryPage = useCallback(() => {
    const status = statusRef.current;

    if (status === DRAFT_STATUS_CODE.aiEntryDone) {
      updateCTGInfo({
        draftArticleStatus: DRAFT_STATUS_CODE.reviewInProgress,
      });
    }
  }, [updateCTGInfo]);

  const settingInitialHistory = useCallback(() => {
    changeCurrentHistory({
      entryData: initialEntryDataRef.current,
      changedDataInfo: {
        target: "all",
        action: "initial",
        position: {
          ...DEFAULT_TABLE_CREATOR_HISTORY_POSITION,
        },
      },
    });
  }, [changeCurrentHistory]);

  useEffect(() => {
    settingInitialHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleEntryPage();
  }, [handleEntryPage]);
};
