import Modal from "@atoms/Modal/Modal";

import * as S from "./CtgListPageEntryGuide.style";

interface Props {
  closeModal: () => void;
  updateCtgList: () => void;
}

const CtgListPageEntryGuide = ({ closeModal, updateCtgList }: Props) => {
  const handleRefreshEvent = () => {
    updateCtgList();
    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <S.PressListEntryModalWrapper>
        <S.ModalTitle>Another editor is currently working</S.ModalTitle>
        <S.ModalText>
          Access is not available because there is another editor who currently
          working on the press you tried to access. Please try again after the
          editor has completed the task.
        </S.ModalText>
        <S.ModalButtonWrapper>
          <S.CancelButton type="button" onClick={closeModal}>
            Cancel
          </S.CancelButton>
          <S.RefreshButton type="button" onClick={handleRefreshEvent}>
            Refresh the page
          </S.RefreshButton>
        </S.ModalButtonWrapper>
      </S.PressListEntryModalWrapper>
    </Modal>
  );
};

export default CtgListPageEntryGuide;
