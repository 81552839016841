import Modal from "@atoms/Modal/Modal";
import SelectBox from "@atoms/SelectBox/SelectBox";
import useModal from "@hooks/useModal";
import IconFilterStatus from "@images/pressList/icon-filter-status-white.svg";
import { useCtgListFilterState } from "@stores/ctgList";

import CtgListSearch from "./components/CtgListSearch/CtgListSearch";
import CtgListSearchResult from "./components/PressListSearchResult/CtgListSearchResult";
import StatusFilter from "./components/StatusFilter/StatusFilter";
import * as S from "./CtgListTableFilter.style";

const LIMIT_NUMBER_LIST = [20, 40, 60, 80, 100];

const CtgListTableFilter = () => {
  const [ctgListFilter, setCtgListFilter] = useCtgListFilterState();

  const { pageLimit, status } = ctgListFilter;

  const {
    isModal: isStatusFilterModalOpen,
    openModal: openStatusFilterModal,
    closeModal: closeStatusFilterModal,
  } = useModal();

  const selectedStatusNumber = status.length;

  const handleSelectLimitNumber = (value: string) => {
    setCtgListFilter({
      ...ctgListFilter,
      pageLimit: Number(value),
      page: 1,
    });
  };

  return (
    <>
      <S.PressListTableFilterWrapper>
        <S.FilterContainer>
          <S.LeftControls>
            <CtgListSearch />
            <S.FilterButton
              buttonSize="xSmall"
              buttonStyle="solidBlueGreen"
              onClick={openStatusFilterModal}
            >
              <img src={IconFilterStatus} alt="status filter" />
              Status Filter
              {selectedStatusNumber > 0 && `(${selectedStatusNumber})`}
            </S.FilterButton>
          </S.LeftControls>
          <S.RightControls>
            <SelectBox
              optionList={LIMIT_NUMBER_LIST}
              defaultText=" accounts per page"
              selectValue={pageLimit}
              onChangeOption={handleSelectLimitNumber}
            />
          </S.RightControls>
        </S.FilterContainer>
        {isStatusFilterModalOpen && (
          <Modal closeModal={closeStatusFilterModal}>
            <StatusFilter closeModal={closeStatusFilterModal} />
          </Modal>
        )}
      </S.PressListTableFilterWrapper>
      <CtgListSearchResult />
    </>
  );
};

export default CtgListTableFilter;
