import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";

export const CHECK_TABLE_KEY_BY_GROUP_TABLE_KEY: {
  [k in string]: {
    [key in string]: string | null;
  };
} = {
  [CTG_SECTION]: {
    "Drug Profile": "Drug",
  },
};
