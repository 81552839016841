import { DrugProfileSection } from "@/types/drugProfile.types";

interface NavigationType<T> {
  title: string;
  sectionKey: T;
}

export const CTG_NAVIGATION_LIST: NavigationType<DrugProfileSection>[] = [
  {
    sectionKey: "Drug Profile",
    title: "Drug Profile",
  },
];
