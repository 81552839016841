import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";
import { useCTGEntryDataState } from "@stores/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useGenericBiosimilarCheckboxEvent = () => {
  const [entryData, setEntryData] = useCTGEntryDataState(getPressIdFromURL());
  const articleSection = CTG_SECTION;

  const checkGenericBiosimilar = (isChecked: boolean, tableIndex: number) => {
    const newGenericBiosimilarValue =
      isChecked === false ? ["true"] : ["false"];

    const changedEntryData = {
      [articleSection]: {
        ...entryData[articleSection],
        "Drug Profile": entryData[articleSection]?.["Drug Profile"]?.map(
          (tableItems, tableItemsIndex) => {
            if (tableItemsIndex === tableIndex) {
              return {
                ...tableItems,
                "Generic / Biosimilar": {
                  ...tableItems["Generic / Biosimilar"],
                  value: newGenericBiosimilarValue,
                },
              };
            }
            return tableItems;
          },
        ),
      },
    };

    setEntryData(changedEntryData);
  };
  return { checkGenericBiosimilar };
};
