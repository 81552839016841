import styled from "styled-components";

export const EditorInfoSearchWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`;

export const EditorInfoSearchTitle = styled.div`
  display: flex;
  flex: 1;
  width: 28rem;
  max-width: 32rem;
  align-items: flex-start;
`;
