/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import { useEffect } from "react";

// ❗️ 헤더 내의 버튼 클릭 시에도 돌아야 함.
export const useSafeLeavePage = (allowLeave = false) => {
  const status =
    new URLSearchParams(window.location.search).get("status") || "";

  const onBeforeUnload = (event: BeforeUnloadEvent) => {
    if (import.meta.env.VITE_ENV === "local") return;
    if (status === "admin") return;
    event.preventDefault();
    event.returnValue = "";
  };

  useEffect(() => {
    window.onbeforeunload = allowLeave ? null : onBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, [allowLeave]);
};
