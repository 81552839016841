import { AUTHORITY_TYPE } from "../constants/authorityType.constants";
import { EDITOR_ROLES } from "../constants/editorRoles.constants";

import type { AuthorityType, EditorRoleCode } from "../types/roles.types";

interface Args {
  editorRoles: EditorRoleCode[];
  authorityType: AuthorityType;
}

export class CTGFillInTableRoleChecker {
  private editorRoles: EditorRoleCode[];

  private authorityType: AuthorityType;

  constructor({ editorRoles, authorityType }: Args) {
    this.editorRoles = editorRoles;
    this.authorityType = authorityType;
  }

  private get hasValidRoles(): boolean {
    return this.editorRoles.some((role) => role === EDITOR_ROLES.EDTK0903);
  }

  private get isSuperAdmin(): boolean {
    return this.authorityType === AUTHORITY_TYPE.superAdmin;
  }

  public get canAccess(): boolean {
    return this.isSuperAdmin || this.hasValidRoles;
  }
}
