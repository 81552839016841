const REGEX = {
  US: /NCT\d{8}/,
  GB: /ISRCTN\d{8}/,
  AU: /ACTRN\d{14}/,
  CN__1: /^ChiCTR\d{9,10}/,
  CN__2: /^CTR\d{8}/,
  EU: /\d{4}-\d{6}-\d{2}(-\d{2})?$/,
  DE: /DRKS\d{8}/,
  JP: /jRCT\d{10}/,
  CH: /SNCTP\d{9}/,
  KR: /KCT\d{7}/,
};

export const checkClinicalTrialsIdentifierEUExternalLink = (value: string) => {
  const withExtraCodeRegex = /\d{4}-\d{6}-\d{2}-\d{2}$/;
  if (withExtraCodeRegex.test(value)) {
    return `https://euclinicaltrials.eu/search-for-clinical-trials/?lang=en&EUCT=${value}`;
  }

  return `https://www.clinicaltrialsregister.eu/ctr-search/search?query=${value}`;
};

type CountryCode =
  | "US"
  | "EU"
  | "DE"
  | "GB"
  | "JP"
  | "CH"
  | "AU"
  | "CN__1"
  | "CN__2"
  | "KR"
  | "empty";

export const checkClinicalTrialsIdentifierCode = (value: string) => {
  if (REGEX.US.test(value)) {
    return "US";
  } else if (REGEX.EU.test(value)) {
    return "EU";
  } else if (REGEX.DE.test(value)) {
    return "DE";
  } else if (REGEX.GB.test(value)) {
    return "GB";
  } else if (REGEX.JP.test(value)) {
    return "JP";
  } else if (REGEX.CH.test(value)) {
    return "CH";
  } else if (REGEX.AU.test(value)) {
    return "AU";
  } else if (REGEX.CN__1.test(value)) {
    return "CN__1";
  } else if (REGEX.CN__2.test(value)) {
    return "CN__2";
  } else if (REGEX.KR.test(value)) {
    return "KR";
  } else {
    return "empty";
  }
};

export const checkClinicalTrialsIdentifierExternalLink = (value: string) => {
  const countryCode: CountryCode = checkClinicalTrialsIdentifierCode(value);

  switch (countryCode) {
    case "US":
      return `https://clinicaltrials.gov/study/${value}`;
    case "GB":
      return `https://www.isrctn.com/${value}`;
    case "AU":
      return `https://anzctr.org.au/Trial/Registration/TrialReview.aspx?ACTRN=${value}`;
    case "EU":
      return checkClinicalTrialsIdentifierEUExternalLink(value);
    case "DE":
      return `https://drks.de/search/en/trial/${value}`;
    case "JP":
      return `https://jrct.niph.go.jp/en-latest-detail/${value}`;
    case "CH":
      return null;
    case "CN__1":
      return `https://www.chictr.org.cn/historyversionpub.html?regno=${value}`;
    case "CN__2":
      return null;
    case "KR":
      return null;
    case "empty":
      return null;
    default:
      return null;
  }
};
