/*
RefCode를 고유하게 식별하는 코드입니다. 중복되지 않도록 주의해주세요.
RefCode는 6자리의 숫자로 구성되며, 앞 3자리는 해당 코드가 REF임을, 뒤 3자리는 RefCode의 순번을 나타냅니다.
예를 들어, REF001은 REF임을 나타내고, 001은 1번째 RefCode임을 나타냅니다.
생성할 때는, 앞 3자리는 REF로 고정하고, 뒤 3자리는 순번을 증가시키면서 생성합니다.
*/

export const REF_CODE_DEAL_TYPE = "REF001";
export const REF_CODE_DEAL_STATUS = "REF002";
export const REF_CODE_LICENSOR_NAME = "REF003";
export const REF_CODE_LICENSEE_NAME = "REF004";
export const REF_CODE_LICENSOR_RESPONSIBILITY = "REF005";
export const REF_CODE_LICENSEE_RESPONSIBILITY = "REF006";
export const REF_CODE_LICENSEE_RIGHTS = "REF007";
export const REF_CODE_LICENSEE_DRUG = "REF008";
export const REF_CODE_LICENSEE_INDICATION = "REF009";
export const REF_CODE_LICENSEE_EXCLUSIVITY = "REF010";
export const REF_CODE_LICENSEE_TERRITORY = "REF011";
export const REF_CODE_UPFRONT = "REF012";
export const REF_CODE_MILESTONE = "REF013";
export const REF_CODE_TOTAL_PRICE_UP_TO = "REF014";
export const REF_CODE_ROYALTY = "REF015";
export const REF_CODE_OTHER_FINANCIAL_TERMS = "REF016";
export const REF_CODE_TOPIC = "REF017";
export const REF_CODE_TITLE = "REF018";
export const REF_CODE_ROA = "REF019";
export const REF_CODE_MODALITY = "REF020";
export const REF_CODE_MOA = "REF021";
export const REF_CODE_CODE_NAME = "REF022";
export const REF_CODE_INN_NAME = "REF023";
export const REF_CODE_BRAND_NAME = "REF024";
export const REF_CODE_COMPANY_NAME = "REF025";
export const REF_CODE_TARGET = "REF026";
export const REF_CODE_MEDICAL_SPECIALTY = "REF027";
export const REF_CODE_OTHER_CHARACTERISTICS = "REF028";
export const REF_CODE_THERAPEUTIC_AREA = "REF029";
export const REF_CODE_TARGET_INDICATION = "REF030";
export const REF_CODE_DEVELOPMENT_STAGE = "REF031";
export const REF_CODE_COMPETITIVE_LANDSCAPE_COMPANY_NAME = "REF032";
export const REF_CODE_COMPETITIVE_LANDSCAPE_ROA = "REF033";
export const REF_CODE_COMPETITIVE_LANDSCAPE_MODALITY = "REF034";
export const REF_CODE_COMPETITIVE_LANDSCAPE_TARGET = "REF035";
export const REF_CODE_COMPETITIVE_LANDSCAPE_MOA = "REF036";
export const REF_CODE_COMPETITIVE_LANDSCAPE_TARGET_INDICATION = "REF037";
export const REF_CODE_COMPETITIVE_LANDSCAPE_DEVELOPMENT_STAGE = "REF038";
export const REF_CODE_COMPETITIVE_LANDSCAPE_DRUG_NAME = "REF039";
export const REF_CODE_DEEP_INSIGHTS = "REF040";
export const REF_CODE_SPOKESPERSON = "REF041";
export const REF_CODE_QUOTE = "REF042";
export const REF_CODE_ADDITIONAL_INFORMATION = "REF043";
export const REF_CODE_ACQUIRED_COMPANY_NAME = "REF044";
export const REF_CODE_ACQUIRED_COMPANY_RESPONSIBILITY = "REF045";
export const REF_CODE_ACQUIRING_COMPANY_NAME = "REF046";
export const REF_CODE_ACQUIRING_COMPANY_RESPONSIBILITY = "REF047";
export const REF_CODE_KEY_ASSETS = "REF048";
export const REF_CODE_TOTAL_PRICE = "REF049";
export const REF_CODE_PRICE_PER_SHARE = "REF050";
export const REF_CODE_NUMBER_OF_ACQUIRING_SHARES = "REF051";
export const REF_CODE_ORIGINATOR_NAME = "REF052";
export const REF_CODE_ORIGINATOR_RESPONSIBILITY = "REF053";
export const REF_CODE_ORIGINATOR_RIGHTS = "REF054";
export const REF_CODE_PARTNER_NAME = "REF055";
export const REF_CODE_PARTNER_RESPONSIBILITY = "REF056";
export const REF_CODE_FINANCIAL_TERMS = "REF057";
export const REF_CODE_RIGHTS_SCOPE = "REF058";
export const REF_CODE_DRUG_PROFILE_DRUG_NAME = "REF059";
export const REF_CODE_DRUG_PROFILE_COMPANY_NAME = "REF060";
export const REF_CODE_DRUG_PROFILE_MODALITY = "REF061";
export const REF_CODE_DRUG_PROFILE_ROA = "REF062";
export const REF_CODE_DRUG_PROFILE_TARGET = "REF063";
export const REF_CODE_DRUG_PROFILE_INDICATION = "REF064";
export const REF_CODE_DRUG_PROFILE_DEVELOPMENT_STAGE = "REF065";
export const REF_CODE_ENDPOINTS_TABLE_TITLE = "REF066";
export const REF_CODE_ENDPOINTS_TABLE_NOTES = "REF067";
export const REF_CODE_ENDPOINTS_TABLE_GROUP_NAME = "REF068";
export const REF_CODE_ENDPOINTS_TABLE_MEASUREMENT_TIMING = "REF069";
export const REF_CODE_ENDPOINTS_TABLE_GROUP_DATA = "REF070";
export const REF_CODE_ENDPOINT_DESCRIPTION = "REF071";
export const REF_CODE_RESULTS_CLASSIFICATION = "REF072";
export const REF_CODE_LICENSOR_RIGHTS = "REF073";
export const REF_CODE_ACQUIRED_COMPANY_RIGHTS = "REF074";

export const REF_CODE_APPROVAL_PROCESS_UPDATE_DATE = "REF075";
export const REF_CODE_APPROVAL_PROCESS_MARKETING_STATUS = "REF076";
export const REF_CODE_APPROVAL_PROCESS_REGULATORY_AGENCY = "REF077";
export const REF_CODE_APPROVAL_PROCESS_APPLICATION_TYPE = "REF078";
export const REF_CODE_APPROVAL_PROCESS_AUTHORIZED_COMPANY = "REF079";
export const REF_CODE_APPROVAL_PROCESS_INDICATION_ON_LABEL = "REF080";
export const REF_CODE_APPROVAL_PROCESS_DOSAGE_AND_ADMINISTRATION_ON_LABEL =
  "REF081";
export const REF_CODE_APPROVAL_PROCESS_ADDITIONAL_INFORMATION_ON_APPROVAL_PROCESS =
  "REF082";
export const REF_CODE_EXPEDITED_PROGRAM_UPDATE_DATE = "REF083";
export const REF_CODE_EXPEDITED_PROGRAM_DESIGNATED_COMPANY_NAME = "REF084";
export const REF_CODE_EXPEDITED_PROGRAM_REGULATORY_AGENCY = "REF085";
export const REF_CODE_EXPEDITED_PROGRAM_EXPEDITED_PROGRAM = "REF086";
export const REF_CODE_EXPEDITED_PROGRAM_DESIGNATED_INDICATION = "REF087";
export const REF_CODE_EXPEDITED_PROGRAM_ADDITIONAL_INFORMATION_ON_EXPEDITED_PROGRAM =
  "REF088";
export const REF_CODE_DEAL_PURPOSE = "REF089";
export const REF_CODE_DEAL_NAME = "REF090";

export const REF_CODE_DEAL_OTHER_PAYMENT_SC_LA = "REF091";
export const REF_CODE_DEAL_OTHER_PAYMENT_DETAIL_SC_LA = "REF092";
export const REF_CODE_DEAL_UPFRONT_PAYMENT_MA = "REF093";
export const REF_CODE_DEAL_UPFRONT_PRICE_PER_SHARE_MA = "REF094";
export const REF_CODE_DEAL_CONTINGENT_PAYMENT_MA = "REF095";
export const REF_CODE_DEAL_CONTINGENT_PRICE_PER_SHARE_MA = "REF096";
export const REF_CODE_DEAL_CONTINGENT_MILESTONE_MA = "REF097";
export const REF_CODE_DEAL_MERGER_FINANCIAL_TERMS_MA = "REF098";
export const REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP = "REF099";

export const REF_CODE_GENERIC_BIO_SIMILAR = "REF100";
export const REF_CODE_REF_PRODUCT_NAME = "REF101";

export const REF_CODE_VENTURE_CAPITAL_ROUND_NAME = "REF102";
export const REF_CODE_VENTURE_CAPITAL_ROUND_TYPE = "REF103";

export const REF_CODE_VENTURE_CAPITAL_FINANCING_INFORMATION_DETAIL = "REF105";
export const REF_CODE_VENTURE_CAPITAL_FINANCING_PURPOSE = "REF106";
export const REF_CODE_VENTURE_CAPITAL_AMOUNT = "REF107";
export const REF_CODE_VENTURE_CAPITAL_COMPANY_DESCRIPTION = "REF108";
export const REF_CODE_VENTURE_CAPITAL_COMPANY_NAME = "REF109";
export const REF_CODE_VENTURE_CAPITAL_INVESTOR_NAME = "REF110";

export const REF_CODE_DEAL_VF_INITIAL_INVESTMENT = "REF111";
export const REF_CODE_DEAL_VF_CONTINGENT_INVESTMENT = "REF112";
export const REF_CODE_DEAL_VF_CONTINGENT_INVESTMENT_DETAILS = "REF113";
export const REF_CODE_DEAL_VF_TOTAL_INVESTMENT = "REF114";
export const REF_CODE_DEAL_VF_OWNERSHIP_STRUCTURE_DETAILS = "REF115";
export const REF_CODE_DEAL_VF_OTHER_FINANCIAL_TERMS = "REF116";

// 기타 REF_CODE 관련 상수
export const REF_CODE_SEPARATOR = "||";
export const REF_CODE_EMPTY = "";
