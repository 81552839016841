import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconCheck = ({ width = 24, height = 24, color = "black" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.77536 3.05806C10.0194 3.30214 10.0194 3.69786 9.77536 3.94194L5.19202 8.52527C4.94795 8.76935 4.55222 8.76935 4.30814 8.52527L2.22481 6.44194C1.98073 6.19786 1.98073 5.80214 2.22481 5.55806C2.46888 5.31398 2.86461 5.31398 3.10869 5.55806L4.75008 7.19945L8.89147 3.05806C9.13555 2.81398 9.53128 2.81398 9.77536 3.05806Z"
        fill={color}
      />
    </svg>
  );
};

IconCheck.displayName = "Check";
export default IconCheck;
