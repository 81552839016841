"use client";

import styled from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
`;

export const ArrowButton = styled.button`
  padding: 0;
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--Border-Radius-circle, 99999.9rem);

  > svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const NumberWrapper = styled.span`
  display: flex;
  flex-direction: row;
`;

export const Skeleton = styled.div`
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  width: 3rem;
  height: 2.8rem;
  border-radius: 50%;
  margin: 0;
`;
