import { CtgListOrderingKey } from "@/components/business/CtgListContainer/ctgList.types";
import { invalidateCtgListFilterQuery } from "@queries/hooks/useCtgListFilter";

import * as S from "./CtgListTableHead.style";
import { useHandleCtgListTableOrdering } from "./hooks/useHandleCtgListTableOrdering";
import { Ordering } from "../../constants/ctgListTable.constants";

const CtgListTableHead = () => {
  const { tableHeaderList, checkIsOrdering, getOrderingIcon, handleOrdering } =
    useHandleCtgListTableOrdering();
  const updateCtgList = invalidateCtgListFilterQuery;

  const handleClickOrdering = (
    orderingTitle: CtgListOrderingKey,
    ordering: Ordering,
  ) => {
    handleOrdering(orderingTitle, ordering);
    updateCtgList();
  };

  return (
    <S.PressListTableHead>
      <S.TableRow>
        {tableHeaderList.map((item) => (
          <S.TableHeadShell
            key={`thead-${item.title}`}
            onClick={() =>
              handleClickOrdering(item.orderingTitle, item.ordering)
            }
          >
            <S.TableHeadShellContents
              isOrdering={checkIsOrdering(item.ordering)}
            >
              <S.TableHeadShellTitle
                isOrdering={checkIsOrdering(item.ordering)}
              >
                {item.title.split("\n").map((word, wordIndex) => (
                  <S.TableHeadShellTitleWord key={word + String(wordIndex)}>
                    {word}
                  </S.TableHeadShellTitleWord>
                ))}
              </S.TableHeadShellTitle>

              <S.OrderingIconWrapper>
                {getOrderingIcon(item.ordering)}
              </S.OrderingIconWrapper>
            </S.TableHeadShellContents>
          </S.TableHeadShell>
        ))}
      </S.TableRow>
    </S.PressListTableHead>
  );
};

export default CtgListTableHead;
