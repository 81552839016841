/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY,
} from "@frontend/common";

import { CTGEntryData } from "@/types/ctgEntryData.types";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";

import { CTG_SECTION } from "./articleSection.constants";

import type {
  GroupTableKeyType,
  SelectedGroupTableKeyType,
} from "../types/tableCreator.types";

export const TABLE_CREATOR_GROUP_TABLE = {
  [CTG_SECTION]: [
    {
      key: "Drug Profile",
      title: "Drug Profile",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
  ],
};

export const GROUP_TABLE_PATH_TO_COUNT_TABLE: Record<
  string,
  {
    [key in GroupTableKeyType]?: (data: CTGEntryData) => number;
  }
> = {
  [CTG_SECTION]: {
    "Drug Profile": (data: CTGEntryData) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.length || 1,
  },
};

type EnableGroupTableKeyList = {
  [CTG_SECTION]: {
    [key in string]: string[];
  };
};

export const ENABLE_GROUP_TABLE_KEY_LIST: EnableGroupTableKeyList = {
  [CTG_SECTION]: {
    "Drug Profile": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
  },
};
