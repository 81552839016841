import styled, { css } from "styled-components";

import { hideScrollbarY } from "@styles/mixin";

export const TextFieldInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
`;

export const TextareaWrapper = styled.div`
  width: 100%;
`;

export const TextFieldTextarea = styled.div<{
  isModified: boolean;
  isMultipleLastValue: boolean;
  isFocusedShell: boolean;
  disabled: boolean;
  placeholder: string;
  $isNotEditable: boolean;
}>`
  width: 100%;
  min-height: 2.4rem;
  height: fit-content;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  background-color: transparent;
  outline: none;
  resize: none;
  padding: 0.4rem 0;
  cursor: text;
  overflow-wrap: anywhere;

  ${hideScrollbarY}

  ${({ isModified }) =>
    !isModified &&
    css`
      color: ${({ theme }) => theme.color.status.todo};
      opacity: 1;
      -webkit-text-fill-color: ${({ theme }) => theme.color.status.todo};
    `}

  &[contenteditable] {
    -webkit-user-modify: read-write-plaintext-only;
  }

  &[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    color: var(--Color-Common-Text-Tertiary, #7c899c);
    -webkit-text-fill-color: var(--Color-Common-Text-Tertiary, #7c899c);
  }

  ${({ isMultipleLastValue }) =>
    isMultipleLastValue &&
    css`
      &::placeholder {
        color: var(--Color-Common-Text-Disabled, #96a1b0);
        -webkit-text-fill-color: var(--Color-Common-Text-Disabled, #96a1b0);
      }
    `}

  ${({ isFocusedShell }) =>
    isFocusedShell &&
    css`
      color: var(--grayscale-spectrum-neural-blue-gray-95, #11161e);
      -webkit-text-fill-color: var(
        --grayscale-spectrum-neural-blue-gray-95,
        #11161e
      );
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  ${({ $isNotEditable }) =>
    $isNotEditable &&
    css`
      cursor: not-allowed;
      background: var(--Color-Common-Bg-Disabled, #cbd0d8);

      color: var(--Color-Common-Text-Disabled, #96a1b0);
      -webkit-text-fill-color: var(--Color-Common-Text-Disabled, #96a1b0);
    `}
`;
