import { CtgListOrderingKey } from "../../../ctgList.types";

export const EMPTY_SHELL_VALUE = "-";

export type Ordering = "none" | "asc" | "desc";

export const ORDERING_TABLE = {
  asc: "desc",
  desc: "none",
  none: "asc",
} as const;

export interface TableHeaderList {
  title: string;
  orderingTitle: CtgListOrderingKey;
  ordering: Ordering;
}

export const TABLE_HEADER_LIST: TableHeaderList[] = [
  {
    title: "NCT No.",
    // 체크
    orderingTitle: "nctId",
    ordering: "none",
  },
  {
    title: "CTG Date",
    orderingTitle: "ctgDate",
    ordering: "none",
  },
  {
    title: "Sponsor Name",
    orderingTitle: "sponsorName",
    ordering: "none",
  },
  {
    title: "Collaborator Name",
    orderingTitle: "collaboratorName",
    ordering: "none",
  },
  {
    title: "Unsuitable",
    orderingTitle: "articleRejectReason",
    ordering: "none",
  },
  {
    title: "CTG Title",
    orderingTitle: "ctgTitle",
    ordering: "none",
  },

  {
    title: "Status",
    orderingTitle: "draftArticleStatusHighest",
    ordering: "none",
  },
  {
    title: "Start Date",
    orderingTitle: "writeStartDt",
    ordering: "none",
  },
  {
    title: "Complete\nDate",
    orderingTitle: "writeEndDt",
    ordering: "none",
  },
  {
    title: "MC\nEditor",
    orderingTitle: "mcEditorName",
    ordering: "none",
  },
  {
    title: "FT\nEditor",
    orderingTitle: "ftEditorName",
    ordering: "none",
  },
];
