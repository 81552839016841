import { useRef, useEffect, useState } from "react";

import { TooltipBase } from "@frontend/common";

import { CtgListData } from "@/components/business/CtgListContainer/ctgList.types";
import { labelingIdInTitle } from "@/components/shared/utils/labelingIdInTitle/labelingIdInTitle";
import { ProcessStatusCode } from "@/types/code.types";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { ctgListApis } from "@services/ctgList.service";
import { toastFail } from "@utils/toast";

import * as S from "./CtgListTableShellForCtgTitle.style";
import { useMoveCtgDetailPage } from "./hooks/useMoveCtgDetailPage";

interface Props {
  ctgData: CtgListData;
  openCheckStatusModal: () => void;
  openAccessDeniedModal: () => void;
  openConcurrentAccessDeniedModal: () => void;
  handleTargetCtgId: () => void;
  handleCurrentStatus: (status: ProcessStatusCode | "") => void;
}

const CtgListTableShellForCtgTitle = ({
  ctgData,
  openCheckStatusModal,
  openAccessDeniedModal,
  openConcurrentAccessDeniedModal,
  handleTargetCtgId,
  handleCurrentStatus,
}: Props) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkTruncation = () => {
      if (titleRef.current) {
        const { scrollHeight, clientHeight } = titleRef.current;
        setIsTruncated(scrollHeight > clientHeight + 1);
      }
    };

    setTimeout(checkTruncation, 0);
    window.addEventListener("resize", checkTruncation);

    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, [ctgData?.ctgTitle]);

  const { moveCtgDetailPage } = useMoveCtgDetailPage({
    draftArticleId: ctgData?.ctgId?.toString(),
    nctNumber: ctgData?.nctId?.toString(),
    ftEditorName: ctgData?.ftEditor2Name,
    onAccessDenied: openAccessDeniedModal,
    onConcurrentAccessDenied: openConcurrentAccessDeniedModal,
  });

  const saveScrollPositionToSessionStorage = () => {
    sessionStorage.setItem("scrollPosition", window.scrollY.toString());
  };

  const checkCurrentStatus = async () => {
    try {
      const { data: currentStatus } = await ctgListApis.checkPressStatus(
        ctgData?.ctgId?.toString(),
      );

      // currentStatus가 ai done, draftArticleStatusHighest가 not for ai인 경우 Alert이 표출되지 않도록 설정
      if (
        currentStatus === DRAFT_STATUS_CODE.aiEntryDone &&
        ctgData.draftArticleStatusHighest === DRAFT_STATUS_CODE.notForAiEntry
      ) {
        saveScrollPositionToSessionStorage();
        await moveCtgDetailPage();

        return;
      }
      if (currentStatus !== ctgData.draftArticleStatusHighest) {
        handleCurrentStatus(currentStatus as ProcessStatusCode | "");
        handleTargetCtgId();
        openCheckStatusModal();
      } else {
        saveScrollPositionToSessionStorage();
        await moveCtgDetailPage();
      }
    } catch (error) {
      toastFail("Could not check the current status of the article.");
    }
  };

  const title = ctgData?.ctgTitle || "-";

  const clickToMovePressDetailPage = async () => {
    await checkCurrentStatus();
  };

  return (
    <S.PressTitleTableShell onClick={clickToMovePressDetailPage}>
      <S.TitleWrapper>
        <div ref={titleRef}>{labelingIdInTitle(title)}</div>
      </S.TitleWrapper>
      {isTruncated && (
        <S.TooltipWrapper className="tooltip-container">
          <TooltipBase className="title-tooltip">
            {labelingIdInTitle(title)}
          </TooltipBase>
        </S.TooltipWrapper>
      )}
    </S.PressTitleTableShell>
  );
};

export default CtgListTableShellForCtgTitle;
