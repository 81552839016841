import { useMemo } from "react";

import { SECTION_CODE_ACCORDING_TO_CLASSIFICATION } from "@frontend/common";

import { useMainLabelOptionList } from "@/components/shared/business/components/ArticleMainLabelContainer/hooks/useMainLabelOptionList";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { useGetImageKeywordQuery } from "@api/imageKeyword/imageKeyword";
import { useGetTargetIndicationTherapeuticAreaQuery } from "@api/targetIndicationTherapeuticArea/targetIndicationTherapeuticArea";

import {
  KEYWORD_ENTITY_BY_KEYS,
  KEYWORD_RANK_BY_KEYS,
  KEYWORD_TITLE_BY_KEYS,
} from "../constants/filterKeyword.constants";
import { InsertImageFilterKeys } from "../types/filterKeyword.types";

const sortKeywordData = (
  targetData: Record<InsertImageFilterKeys, string[]>,
) => {
  return Object.fromEntries(
    Object.entries(targetData).sort(([a], [b]) => {
      const rankA =
        KEYWORD_RANK_BY_KEYS[a as InsertImageFilterKeys] || Infinity;
      const rankB =
        KEYWORD_RANK_BY_KEYS[b as InsertImageFilterKeys] || Infinity;
      return rankA - rankB;
    }),
  );
};

export const useImageFilterKeyword = () => {
  const { data: articleInfo } = useArticleInfoQuery();
  const targetClassificationCode = articleInfo?.classificationManual;
  const sectionCode =
    SECTION_CODE_ACCORDING_TO_CLASSIFICATION[targetClassificationCode];

  const { data } = useGetImageKeywordQuery(sectionCode);
  const keywordData = data?.data?.data;

  const keywordDataForImageList = sortKeywordData(keywordData.filteredKeywords);

  const keywordDataForUploadLabel = sortKeywordData(keywordData.allKeywords);
  const targetIndicationList = useMainLabelOptionList("Target Indication");
  const therapeuticAreaData = useGetTargetIndicationTherapeuticAreaQuery(
    targetIndicationList ?? [],
  );

  const keywordDataWithTA = {
    ...keywordDataForUploadLabel,
    "Therapeutic Area": therapeuticAreaData.data?.data || [],
  };

  const keywordDataForImageListWithTA = {
    ...keywordDataForImageList,
    "Therapeutic Area": therapeuticAreaData.data?.data || [],
  };

  const keywordLabelNameList = Object.keys(keywordDataWithTA)
    .map((key) => KEYWORD_TITLE_BY_KEYS[key as InsertImageFilterKeys] || "")
    .filter((key) => !!key);

  const keywordInfoListForUploadLabel = Object.entries(
    keywordDataWithTA,
  ).flatMap(([key, keywords]: [string, string[]]) => {
    const typedKey = key as InsertImageFilterKeys;
    return (
      keywords?.map((keyword) => ({
        value: keyword,
        entity: KEYWORD_ENTITY_BY_KEYS[typedKey] || null,
        labelName: KEYWORD_TITLE_BY_KEYS[typedKey] || "",
      })) || []
    );
  });

  const keywordInfoListForImageList = Object.entries(
    keywordDataForImageListWithTA,
  ).flatMap(([key, keywords]: [string, string[]]) => {
    const typedKey = key as InsertImageFilterKeys;
    return (
      keywords?.map((keyword) => ({
        value: keyword,
        entity: KEYWORD_ENTITY_BY_KEYS[typedKey] || null,
        labelName: KEYWORD_TITLE_BY_KEYS[typedKey] || "",
      })) || []
    );
  });
  const keywordList = useMemo(
    () => {
      const baseKeywords = Object.values(keywordDataForImageListWithTA)
        .flatMap((keywords) => keywords)
        .filter((keyword) => !!keyword);

      const therapeuticAreas = (therapeuticAreaData.data?.data || []).filter(
        (keyword: string) => !!keyword,
      );

      return [...new Set([...therapeuticAreas, ...baseKeywords])];
    },
    // NOTE : data 값에 따라 keywordList값 설정
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, therapeuticAreaData.data],
  );

  return {
    keywordList,
    keywordInfoListForImageList,
    keywordLabelNameList,
    keywordInfoListForUploadLabel,
  };
};
