"use client";

import styled from "styled-components";

export const TypeListItem = styled.div`
  position: relative;
  width: 100%;
  padding-left: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1.1rem;
  text-transform: capitalize;

  &::before {
    position: absolute;
    left: 0.2rem;
    display: block;
    content: "•";
    color: #2a2f37;
    font-size: 1rem;
  }
`;
