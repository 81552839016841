import { useMutation, useSuspenseQuery } from "@tanstack/react-query";

import { queryClient } from "@api/queryClient";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastFail } from "@utils/toast";

import { PutCTGInfoArg } from "./ctgInfo.interface";
import { ctgInfoApis } from "./ctgInfo.service";
import { CTG_QUERY_KEYS } from "../queryKey";

import type { GetArticleInfoResponse } from "@interface/articleInfo.interface";

export const useCTGInfoQuery = (
  draftArticleId = Number(getPressIdFromURL()),
) => {
  const query = useSuspenseQuery({
    queryKey: [CTG_QUERY_KEYS.ctgInfo, draftArticleId],
    queryFn: () => ctgInfoApis.get(draftArticleId),
    select: ({ data }) => data,
    staleTime: 500,
  });

  return query;
};

export const useCTGInfoMutation = (
  draftArticleId = Number(getPressIdFromURL()),
) => {
  const mutation = useMutation({
    mutationFn: (args: Omit<PutCTGInfoArg, "draftArticleId">) =>
      ctgInfoApis.put({
        draftArticleId,
        ...args,
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [CTG_QUERY_KEYS.ctgInfo, draftArticleId],
      });
    },
    onError: () => {
      toastFail("Failed to update article info");
    },
  });

  return mutation;
};

export const getCTGInfoData = (draftArticleId = Number(getPressIdFromURL())) =>
  queryClient.getQueryData<GetArticleInfoResponse>([
    CTG_QUERY_KEYS.ctgInfo,
    draftArticleId,
  ]);
