import { useState, useEffect } from "react";

import { CtgListData } from "@/components/business/CtgListContainer/ctgList.types";
import { UNSUITABLE_REASON_KEYS } from "@/components/shared/business/UnsuitableReason/constants/unsuitableReason.constants";
import { useHandleUnsuitablePress } from "@/components/shared/business/UnsuitableReason/hooks/useHandleUnsuitablePress";
import { ProcessStatusCode } from "@/types/code.types";
import { useUnsuitablePressCancelMutation } from "@api/unsuitablePress/unsuitablePress";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import {
  checkSuperAdmin,
  useAuthorityType,
} from "@libs/roles/hooks/useAuthorityType";

import UnsuitableCheckboxIcon from "./components/UnsuitableCheckboxIcon/UnsuitableCheckboxIcon";

interface CtgListUnsuitableContainerProps extends CtgListData {
  onModalStateChange?: (isOpen: boolean) => void;
}

const CtgListUnsuitableContainer = ({
  onModalStateChange,
  ...data
}: CtgListUnsuitableContainerProps) => {
  const [targetUnsuitablePressId, setTargetUnsuitablePressId] =
    useState<number>(0);
  const authorityType = useAuthorityType();
  const isSuperAdmin = checkSuperAdmin(authorityType);
  const { mutateAsync: turnOffUnsuitable } = useUnsuitablePressCancelMutation();

  const {
    openModalUnsuitablePress,
    closeModalUnsuitablePress,
    isOpenModalUnsuitablePress,
  } = useHandleUnsuitablePress({ type: UNSUITABLE_REASON_KEYS.ctgList });

  useEffect(() => {
    const isModalOpen =
      isOpenModalUnsuitablePress ||
      (targetUnsuitablePressId === data?.ctgId && !data?.articleRejectReason);

    if (onModalStateChange) {
      onModalStateChange(isModalOpen);
    }
  }, [
    isOpenModalUnsuitablePress,
    targetUnsuitablePressId,
    data?.ctgId,
    data?.articleRejectReason,
    onModalStateChange,
  ]);

  const checkDisabledUnsuitablePressByRoles = () => {
    if (isSuperAdmin) return false;
    return true;
  };

  const checkDisabledUnsuitablePressByStatus = (status: ProcessStatusCode) => {
    if (status !== DRAFT_STATUS_CODE.published) return false;
    return true;
  };

  const clickUnsuitableCheckbox = (
    pressId: number,
    status: ProcessStatusCode,
  ) => {
    if (status === DRAFT_STATUS_CODE.unsuitable) {
      turnOffUnsuitable(pressId);
    }
    if (status !== DRAFT_STATUS_CODE.unsuitable) {
      setTargetUnsuitablePressId(pressId);
      openModalUnsuitablePress();
    }
  };

  const handleCloseModal = () => {
    setTargetUnsuitablePressId(0);
    closeModalUnsuitablePress();
  };

  useEffect(() => {
    return () => {
      if (isOpenModalUnsuitablePress) {
        closeModalUnsuitablePress();
      }
    };
  }, [closeModalUnsuitablePress, isOpenModalUnsuitablePress]);

  return (
    <>
      {data?.articleRejectReason ? <>{data?.articleRejectReason}</> : "-"}
      <UnsuitableCheckboxIcon
        isChecked={data?.draftArticleStatus === "S930" || false}
        onClick={() =>
          clickUnsuitableCheckbox(data?.ctgId || 0, data?.draftArticleStatus)
        }
        disabled={
          checkDisabledUnsuitablePressByRoles() === true ||
          checkDisabledUnsuitablePressByStatus(
            data?.draftArticleStatusHighest,
          ) === true
        }
        draftArticleId={data?.ctgId}
        isOpenModalUnsuitablePress={
          targetUnsuitablePressId === data?.ctgId && !data?.articleRejectReason
        }
        articleRejectReason={data?.articleRejectReason}
        closeModalUnsuitablePress={handleCloseModal}
        mcEditorName={data?.mcEditorName}
        aiDone={data?.aiDone || false}
        openModalUnsuitablePress={openModalUnsuitablePress}
      />
    </>
  );
};

export default CtgListUnsuitableContainer;
