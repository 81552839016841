import { useLocation } from "react-router-dom";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import { ROUTE_PATHS } from "@routes/routePath";

const ArticleInfo = (draftArticleId: number) => {
  return useArticleInfoQuery(draftArticleId);
};

const CTGInfo = (draftArticleId: number) => {
  return useCTGInfoQuery(draftArticleId);
};

export const useContentInfo = (draftArticleId: number) => {
  const location = useLocation();
  const isPress = location.pathname === ROUTE_PATHS.pressList;

  if (isPress) return ArticleInfo(draftArticleId);
  return CTGInfo(draftArticleId);
};
