import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import { ROUTE_PATHS } from "@routes/routePath";
import { toastFail, toastSuccess } from "@utils/toast";

import { createEntryDataApis } from "./createEntryData.service";

export const useCreateEntryDataMutation = () => {
  const location = useLocation();
  const listPagePathNameList: string[] = [
    ROUTE_PATHS.pressList,
    ROUTE_PATHS.ctgList,
  ];
  const toastPosition = listPagePathNameList.includes(location.pathname)
    ? "bottomLeft"
    : "topCenter";
  const mutation = useMutation({
    mutationFn: (pressId: number) => createEntryDataApis.post({ pressId }),
    onSuccess: () => {
      toastSuccess("Successfully created entry data", "green", toastPosition);
    },
    onError: () => {
      toastFail(
        "Generating entry data failed due to an ungenerable classification, please check your classification.",
        toastPosition,
      );
    },
  });

  return mutation;
};
