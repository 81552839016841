import React, { useState } from "react";

import { CtgSearchFilterType } from "@/components/business/CtgListContainer/ctgList.types";

import { CTG_LIST_EDITOR_INFO_FILTER } from "./components/CtgListEditorInfoSearch/constants/editorInfoFilter.constants";
import CtgListEditorInfoSearch from "./components/CtgListEditorInfoSearch/CtgListEditorInfoSearch";
import { CTG_LIST_INFO_FILTER } from "./components/CtgListPressInfoSearch/constants/pressInfoFilter.constants";
import CtgListPressInfoSearch from "./components/CtgListPressInfoSearch/CtgListPressInfoSearch";
import * as S from "./CtgListSearch.style";

const CtgListSearch = () => {
  const [currentCtgFilter, setCurrentCtgFilter] = useState<CtgSearchFilterType>(
    CTG_LIST_INFO_FILTER[0],
  );
  const [currentEditorFilter, setCurrentEditorFilter] =
    useState<CtgSearchFilterType>(CTG_LIST_EDITOR_INFO_FILTER[0]);

  return (
    <S.SearchWrapper>
      <CtgListPressInfoSearch
        currentPressFilter={currentCtgFilter}
        setCurrentFilter={setCurrentCtgFilter}
      />
      <CtgListEditorInfoSearch
        currentEditorFilter={currentEditorFilter}
        setCurrentFilter={setCurrentEditorFilter}
      />
    </S.SearchWrapper>
  );
};

export default CtgListSearch;
