import React, { useEffect, useState } from "react";

import { IconRefreshCw } from "@frontend/common";

import BorderBox from "@atoms/BorderBox/BorderBox";
import IconCheck from "@images/common/icon-filter-check-white.svg";
import IconXMarkRed from "@images/common/icon-x-mark-red.svg";
import XMarkIcon from "@images/pressList/icon-x-mark-red.svg";
import { useCtgListFilterState } from "@stores/ctgList";

import StatusFilterSection from "./components/StatusFilterSection/StatusFilterSection";
import {
  STATUS_FILTER_LIST,
  type StatusFilterList,
} from "./statusFilter.constants";
import * as S from "./StatusFilter.style";

interface Props {
  closeModal: () => void;
}

const StatusFilter = ({ closeModal }: Props) => {
  const [ctgListFilter, setCtgListFilter] = useCtgListFilterState();
  const { status } = ctgListFilter;

  const [statusFilterList, setStatusFilterList] =
    useState<StatusFilterList>(STATUS_FILTER_LIST);

  const handleReset = () => {
    setStatusFilterList(STATUS_FILTER_LIST);
  };

  const handleApplyStatus = () => {
    const updatedStatusFilterList = Object.entries(statusFilterList).flatMap(
      ([, value]) => value.filter((item) => item.isChecked),
    );
    const newStatusFilter = updatedStatusFilterList.map((item) => item.code);
    setCtgListFilter({
      ...ctgListFilter,
      status: newStatusFilter,
      page: 1,
    });
    closeModal();
  };

  useEffect(() => {
    const initStatusFilterList: StatusFilterList = Object.entries(
      STATUS_FILTER_LIST,
    ).reduce((result, [key, value]) => {
      const updatedList = value.map((item) => {
        if (status.includes(item.code)) {
          return { ...item, isChecked: true };
        }
        return item;
      });
      return { ...result, [key]: updatedList };
    }, {} as StatusFilterList);
    setStatusFilterList(initStatusFilterList);
  }, [status]);

  const totalCheckedStatus = Object.entries(statusFilterList)
    .map(([, value]) => value.filter((item) => item.isChecked).length)
    .reduce((acc, cur) => acc + cur, 0);

  const confirmButtonText =
    totalCheckedStatus > 1 ? "Apply Status Filters" : "Apply Status Filter";

  return (
    <S.StatusFilterWrapper>
      <BorderBox padding="16">
        <S.StatusFilterContentWrapper>
          <S.StatusFilterRow>
            <S.FilterResetButton
              onClick={handleReset}
              disabled={totalCheckedStatus < 1}
            >
              {totalCheckedStatus < 1 ? (
                <IconRefreshCw width={20} height={20} color="#a5a5a5" />
              ) : (
                <IconRefreshCw width={20} height={20} color="#515C6C" />
              )}
              <S.FilterResetButtonText>Reset</S.FilterResetButtonText>
            </S.FilterResetButton>
            <S.ClassificationCloseButton
              type="button"
              onClick={() => closeModal()}
            >
              <S.ClassificationXMark>
                <img src={XMarkIcon} alt="close filter" />
              </S.ClassificationXMark>
              <S.ClassificationCloseText>Close</S.ClassificationCloseText>
            </S.ClassificationCloseButton>
          </S.StatusFilterRow>
          <>
            {Object.entries(statusFilterList).map(([key, value]) => (
              <S.StatusFilterSectionWrapper key={`status-section-${key}`}>
                <StatusFilterSection
                  statusSectionList={value}
                  title={key}
                  statusFilterList={statusFilterList}
                  setStatusFilterList={setStatusFilterList}
                />
              </S.StatusFilterSectionWrapper>
            ))}
          </>
          <S.ConfirmButtonWrapper>
            <S.ConfirmButton onClick={handleApplyStatus}>
              <img src={IconCheck} alt="filter check icon" />
              <S.ConfirmButtonText>{confirmButtonText}</S.ConfirmButtonText>
            </S.ConfirmButton>
          </S.ConfirmButtonWrapper>
        </S.StatusFilterContentWrapper>
      </BorderBox>
      <S.CloseButton type="button" onClick={() => closeModal()}>
        <img src={IconXMarkRed} alt="close filter" />
      </S.CloseButton>
    </S.StatusFilterWrapper>
  );
};

export default StatusFilter;
