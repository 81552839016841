import React from "react";

import { useNavigate } from "react-router-dom";

import ErrorPageView from "@/components/shared/ui/ErrorPageView/ErrorPageView";
import { getPressIdFromURL } from "@utils/getStateFromURL";

const NotCorrectDataFormat = ({ message }: { message: string }) => {
  const navigate = useNavigate();
  const articleId = getPressIdFromURL();

  return (
    <ErrorPageView
      title="Data Format Error"
      message={`${
        articleId ? `(#${articleId}) \n` : ""
      }Data format doesn't match.\nPlease contact the administrator for assistance.`}
      details={message}
      buttonLabel="Back to CTG List"
      handleButtonClick={() => navigate("/ctg-list")}
    />
  );
};

export default NotCorrectDataFormat;
