import React from "react";

import {
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS,
  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import {
  BASIC_DATA_FOR_ADDING_SUB_GROUP_SHELLS,
  SUB_GROUP_SHELL_PATH_TO_ADD_SUB_GROUP_SHELL,
} from "@FillInTableContainer/shared/constants/tableCreatorSubGroupShell.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { useEntryDataState } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  clickAddContingentSubGroupShellButton: (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
      subGroupShellIndexParameter: number;
      subGroupShellKeyParameter: string | null;
    },
  ) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    subGroupShellIndex: number;
    subGroupShellKey: string | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useAddVentureFormationSubGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const articleSection = "Deals";
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const initEntryData = useInitEntryDataValue(getPressIdFromURL());
  const updateEntryData = useUpdateEntryData();
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeInfo } = useTableCreatorPositionInfo();
  const { changeValidatedInfo } = useValidatedInTableCreator();

  const handleAddSubGroupShell = (groupShellInfo: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    groupShellKeyParameter: string | null;
    subGroupShellIndexParameter: number;
    subGroupShellKeyParameter: string | null;
  }) => {
    const {
      tableIndexParameter,
      groupShellIndexParameter,
      subGroupShellIndexParameter,
      subGroupShellKeyParameter,
    } = groupShellInfo;

    if (subGroupShellKeyParameter === null) return;

    const targetShell = SUB_GROUP_SHELL_PATH_TO_ADD_SUB_GROUP_SHELL[
      subGroupShellKeyParameter
    ](entryData, tableIndexParameter, groupShellIndexParameter);

    if (targetShell === undefined) return;

    const newArrayLength = targetShell.length + 1 || 1;
    const emptyArray = new Array(newArrayLength).fill({});

    const addSubGroupShellData = emptyArray.map((_, index) => {
      const subGroupShellData =
        BASIC_DATA_FOR_ADDING_SUB_GROUP_SHELLS[articleSection][
          subGroupShellKeyParameter
        ](initEntryData);

      if (index === subGroupShellIndexParameter) {
        return subGroupShellData;
      }

      if (index > subGroupShellIndexParameter) {
        return targetShell[index - 1];
      }

      if (index < subGroupShellIndexParameter) {
        return targetShell[index];
      }

      return targetShell[index];
    });

    const changeSubGroupShellData: Record<string, ArticleEntryData> = {
      [VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.contingentInvestment]: {
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableData, tableDataIndex) => {
            if (tableDataIndex === tableIndexParameter) {
              return {
                ...tableData,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms]: {
                  ...tableData[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                  ],
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentAndDetailVf]:
                    addSubGroupShellData,
                },
              };
            }

            return tableData;
          }),
        },
      } as ArticleEntryData,
    };

    setEntryData(changeSubGroupShellData[subGroupShellKeyParameter]);
    updateEntryData(changeSubGroupShellData[subGroupShellKeyParameter]);
    changeCurrentHistory({
      entryData: changeSubGroupShellData[subGroupShellKeyParameter],
      changedDataInfo: {
        target: "subGroupShell",
        action: "add",
        position: {
          groupShellKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupTableKey,
          groupShellIndex,
          subGroupShellIndex,
          subGroupShellKey,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const clickAddContingentSubGroupShellButton = (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
      subGroupShellIndexParameter: number;
      subGroupShellKeyParameter: string | null;
    },
  ) => {
    event.stopPropagation();
    handleAddSubGroupShell(groupShellInfo);
  };

  return {
    clickAddContingentSubGroupShellButton,
  };
};
