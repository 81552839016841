import {
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENTS,
  ENTRY_DATA_KEY_COMMENTS_SHELL_KEYS as KEY_COMMENTS_SHELL_KEYS,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_APPROVAL_PROCESS_CONTENTS as APPROVAL_PROCESS_CONTENTS,
  ENTRY_DATA_APPROVAL_PROCESS_CONTENTS_KEYS as APPROVAL_PROCESS_CONTENTS_KEYS,
  ENTRY_DATA_APPROVAL_PROCESS_KEYS as APPROVAL_PROCESS_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";

const getDrugProfileFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath = entryData?.[SECTION_KEYS.regulatory]?.[DRUG_PROFILE];
  if (!targetPath) return [`${DRUG_PROFILE} data is missing`];
  if (!Array.isArray(targetPath)) return [`${DRUG_PROFILE} data is missing`];
  if (targetPath.length === 0) return [`${DRUG_PROFILE} data is missing`];

  const checkDrugProfileTableValues = targetPath.flatMap(
    (tableItem, tableIndex) => {
      const requiredFields = [
        {
          key: DRUG_PROFILE_SHELL_KEYS.brandName,
          label: DRUG_PROFILE_SHELL_KEYS.brandName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.innName,
          label: DRUG_PROFILE_SHELL_KEYS.innName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.codeName,
          label: DRUG_PROFILE_SHELL_KEYS.codeName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.moa,
          label: DRUG_PROFILE_SHELL_KEYS.moa,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.modality,
          label: DRUG_PROFILE_SHELL_KEYS.modality,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
          label: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.phase,
          label: DRUG_PROFILE_SHELL_KEYS.phase,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.roa,
          label: DRUG_PROFILE_SHELL_KEYS.roa,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.target,
          label: DRUG_PROFILE_SHELL_KEYS.target,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.targetIndication,
          label: DRUG_PROFILE_SHELL_KEYS.targetIndication,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
          label: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
          label: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.refProductName,
          label: DRUG_PROFILE_SHELL_KEYS.refProductName,
        },
      ];

      return requiredFields
        .map(({ key, label }) =>
          !tableItem[key]?.value
            ? `${label} is missing in ${DRUG_PROFILE} table ${tableIndex}`
            : "",
        )
        .filter((message) => !!message);
    },
  );

  return [...checkDrugProfileTableValues];
};

const getApprovalProcessContentsFormatCheckList = (
  entryData: ArticleEntryData,
) => {
  const targetPath =
    entryData?.[SECTION_KEYS.regulatory]?.[APPROVAL_PROCESS_CONTENTS];
  if (!targetPath) return [`${APPROVAL_PROCESS_CONTENTS} data is missing`];

  const approvalProcessPath =
    targetPath?.[APPROVAL_PROCESS_CONTENTS_KEYS.approvalProcess];

  if (!approvalProcessPath)
    return [
      `${APPROVAL_PROCESS_CONTENTS_KEYS.approvalProcess} data is missing`,
    ];
  if (!Array.isArray(approvalProcessPath))
    return [
      `${APPROVAL_PROCESS_CONTENTS_KEYS.approvalProcess} data is missing`,
    ];
  if (approvalProcessPath.length === 0)
    return [
      `${APPROVAL_PROCESS_CONTENTS_KEYS.approvalProcess} data is missing`,
    ];

  const requiredFields = [
    {
      key: APPROVAL_PROCESS_CONTENTS_KEYS.additionalInformationOnTheApprovalProcess,
      label:
        APPROVAL_PROCESS_CONTENTS_KEYS.additionalInformationOnTheApprovalProcess,
    },
  ];

  const singleRequiredFieldsCheck = requiredFields
    .map(({ key, label }) =>
      !targetPath[key]?.value
        ? `${label} is missing in ${APPROVAL_PROCESS_CONTENTS} table`
        : "",
    )
    .filter((message) => !!message);

  const checkApprovalProcessValues = approvalProcessPath.flatMap(
    (groupShellInfo, groupShellInfoIndex) => {
      const requireApprovalProcessFields = [
        {
          key: APPROVAL_PROCESS_KEYS.applicationType,
          label: APPROVAL_PROCESS_KEYS.applicationType,
        },
        {
          key: APPROVAL_PROCESS_KEYS.authorizedCompany,
          label: APPROVAL_PROCESS_KEYS.authorizedCompany,
        },
        {
          key: APPROVAL_PROCESS_KEYS.dosageAndAdministrationOnLabel,
          label: APPROVAL_PROCESS_KEYS.dosageAndAdministrationOnLabel,
        },
        {
          key: APPROVAL_PROCESS_KEYS.indicationOnLabel,
          label: APPROVAL_PROCESS_KEYS.indicationOnLabel,
        },
        {
          key: APPROVAL_PROCESS_KEYS.marketingStatus,
          label: APPROVAL_PROCESS_KEYS.marketingStatus,
        },
        {
          key: APPROVAL_PROCESS_KEYS.regulatoryAgency,
          label: APPROVAL_PROCESS_KEYS.regulatoryAgency,
        },
        {
          key: APPROVAL_PROCESS_KEYS.updateDate,
          label: APPROVAL_PROCESS_KEYS.updateDate,
        },
      ];

      return requireApprovalProcessFields
        .map(({ key, label }) =>
          !groupShellInfo[key]?.value
            ? `${label} is missing in ${APPROVAL_PROCESS_CONTENTS} table ${APPROVAL_PROCESS_CONTENTS_KEYS.approvalProcess} groupShell ${groupShellInfoIndex}`
            : "",
        )
        .filter((message) => !!message);
    },
  );

  return [...singleRequiredFieldsCheck, ...checkApprovalProcessValues];
};

const getKeyCommentFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath = entryData?.[SECTION_KEYS.regulatory]?.[KEY_COMMENTS];
  if (!targetPath) return [`${KEY_COMMENTS} data is missing`];

  const requiredFields = [
    {
      key: KEY_COMMENTS_SHELL_KEYS.quote,
      label: KEY_COMMENTS_SHELL_KEYS.quote,
    },
    {
      key: KEY_COMMENTS_SHELL_KEYS.spokesperson,
      label: KEY_COMMENTS_SHELL_KEYS.spokesperson,
    },
  ];

  return (
    requiredFields
      ?.map(({ key, label }) =>
        !targetPath[key]?.value ? `${label} is missing in ${KEY_COMMENTS}` : "",
      )
      ?.filter((message) => !!message) || []
  );
};

export const getRegulatoryEntryDataFormatCheckList = (
  entryData: ArticleEntryData,
) => [
  ...getDrugProfileFormatCheckList(entryData),
  ...getApprovalProcessContentsFormatCheckList(entryData),
  ...getKeyCommentFormatCheckList(entryData),
];
