import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconRefreshCw = ({
  width = 20,
  height = 20,
  color = "#96A1B0",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4364 5.51734C11.6103 5.25173 12.8324 5.28782 13.9886 5.62225C15.1448 5.95667 16.1974 6.57853 17.0482 7.4298C17.0547 7.43627 17.0612 7.44264 17.0679 7.4489L20.1524 10.3472H17.4449C16.9616 10.3472 16.5699 10.739 16.5699 11.2222C16.5699 11.7055 16.9616 12.0972 17.4449 12.0972H22.7782C23.2614 12.0972 23.6532 11.7055 23.6532 11.2222V5.88889C23.6532 5.40564 23.2614 5.01389 22.7782 5.01389C22.2949 5.01389 21.9032 5.40564 21.9032 5.88889V9.59103L18.276 6.18272C17.2179 5.12748 15.9105 4.35642 14.4748 3.94116C13.0347 3.52459 11.5125 3.47964 10.0502 3.81049C8.58802 4.14133 7.23343 4.8372 6.11288 5.83316C4.99233 6.82911 4.14234 8.0927 3.64221 9.50601C3.481 9.96157 3.71963 10.4616 4.17519 10.6228C4.63076 10.784 5.13076 10.5454 5.29197 10.0898C5.69347 8.95517 6.37586 7.94075 7.27546 7.14117C8.17506 6.3416 9.26254 5.78295 10.4364 5.51734ZM3.22266 13.9028C2.99059 13.9028 2.76803 13.995 2.60394 14.1591C2.43984 14.3231 2.34766 14.5457 2.34766 14.7778V20.1111C2.34766 20.5944 2.73941 20.9861 3.22266 20.9861C3.70591 20.9861 4.09766 20.5944 4.09766 20.1111V16.8007L6.73818 19.2818C7.79625 20.3371 9.10371 21.1081 10.5394 21.5234C11.9795 21.94 13.5017 21.9849 14.9639 21.6541C16.4262 21.3232 17.7808 20.6274 18.9013 19.6314C20.0219 18.6355 20.8718 17.3719 21.372 15.9586C21.5332 15.503 21.2946 15.003 20.839 14.8418C20.3834 14.6806 19.8834 14.9192 19.7222 15.3748C19.3207 16.5094 18.6383 17.5238 17.7387 18.3234C16.8391 19.123 15.7516 19.6816 14.5777 19.9472C13.4038 20.2128 12.1818 20.1767 11.0256 19.8423C9.86943 19.5079 8.81681 18.886 7.96597 18.0348C7.95951 18.0283 7.95294 18.0219 7.94628 18.0157L5.43164 15.6528L8.55599 15.6528C9.03924 15.6528 9.43099 15.261 9.43099 14.7778C9.43099 14.2945 9.03924 13.9028 8.55599 13.9028L3.22266 13.9028Z"
        fill={color}
      />
    </svg>
  );
};

IconRefreshCw.displayName = "RefreshCw";

export default IconRefreshCw;
