import { ProcessStatusCode } from "@/types/code.types";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";

// NOTE: 해당 권한이 접근하지 못하는 상태에 대한 리스트
export const ROLES_REDIRECT_LIST: Record<string, ProcessStatusCode[]> = {
  EDTK0100_STATUS: [
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0201_STATUS: [
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0202_STATUS: [
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0303_STATUS: [
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0401_STATUS: [
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0402_STATUS: [
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0503_STATUS: [
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0603_STATUS: [
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0703_STATUS: [
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0803_STATUS: [
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0903_STATUS: [
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
};
