import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { DEFAULT_DROPDOWN_LIST } from "@/components/business/CTGFillInTableContainer/shared/components/TableCreatorListBox/constants/tableCreatorListBox.constants";
import {
  handleDropdownListWithCategory,
  handleDropdownListWithoutCategory,
} from "@/components/business/CTGFillInTableContainer/shared/components/TableCreatorListBox/utils/handleDropdownList";
import { dropDownList } from "@api/dropDownList/dropDownList.service";

import type { TableCreatorDropdownContextType } from "@/components/business/CTGFillInTableContainer/shared/components/TableCreatorListBox/types/tableCreatorDropdown.types";

const TableCreatorDropdownContext =
  createContext<TableCreatorDropdownContextType>(DEFAULT_DROPDOWN_LIST);

export const TableCreatorDropdownProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const sectionCode = "TY007";
  const [dropdownList, setDropdownList] =
    useState<TableCreatorDropdownContextType>(DEFAULT_DROPDOWN_LIST);

  const getTableCreatorDropdownList = useCallback(async () => {
    const { data } = await dropDownList.get(sectionCode);

    setDropdownList({
      categoryDropdown: handleDropdownListWithCategory(data),
      defaultDropdown: handleDropdownListWithoutCategory(data),
    });
  }, [sectionCode]);

  useEffect(() => {
    if (sectionCode) {
      getTableCreatorDropdownList();
    }
  }, [getTableCreatorDropdownList, sectionCode]);

  return (
    <TableCreatorDropdownContext.Provider value={dropdownList}>
      {children}
    </TableCreatorDropdownContext.Provider>
  );
};

export const useTableCreatorDropdown = () => {
  const dropdown = useContext(TableCreatorDropdownContext);

  return {
    dropdownList: dropdown,
    defaultDropdownList: dropdown.defaultDropdown,
    categoryDropdownList: dropdown.categoryDropdown,
  };
};
