import {
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS,
  ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSOR_TABLE_KEYS,
  ENTRY_DATA_RIGHTS_SCOPE_KEYS,
} from "../../../constants";

export const VENTURE_FORMATION_KEY = "Venture Formation";

export const VENTURE_FORMATION_GROUP_TABLE_KEY = ENTRY_DATA_LICENSEE_TABLE;

export const VENTURE_FORMATION_TABLE_KEYS = {
  newCompany: "New Company",
  existingCompany: "Existing Company",
  assetTransfer: "Asset Transfer",
  investmentTerms: "Investment Terms",
  additionalInformation: "Additional Information",
  dealPurpose: "Deal Purpose",
  otherFinancialTerms: "Other Financial Terms",
  ownershipStructureDetail: "Ownership Structure Detail",
  none: null,
} as const;

export const VENTURE_FORMATION_GROUP_SHELL_KEYS = {
  newCompany: "New Company",
  existingCompany: "Existing Company",
  assetTransfer: "Asset Transfer",
  otherFinancialTerms: "Other Financial Terms",
  rightsScope: "Rights Scope",
  contingentInvestment: "Contingent Investment",
  contingentInvestmentAndDetailVf: "Contingent Investment and Detail (VF)",
  additionalInformation: "Additional Information",
  dealPurpose: "Deal Purpose",
  investmentTerms: "Investment Terms",
  none: null,
} as const;

export const VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS = {
  mergerFinancialTerms: "Merger Financial terms",
  otherFinancialTerms: "Other Financial Terms",
  disclosedPaymentsSum: "Disclosed Payments Sum",
  initialInvestment: "Initial Investment",
  contingentInvestment: "Contingent Investment",
  disclosedPaymentsSumVf: "Disclosed Payments Sum",
  licenseeResponsibilityAndRightsVf: "Licensee Responsibility and Rights",
  totalInvestmentVf: "Total Investment",
  ownershipStructureDetails: "Ownership Structure Details",

  none: null,
};

export const VENTURE_FORMATION_SHELL_KEYS = {
  licensorName: ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName,
  licensorResponsibilityRights:
    ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights,
  licenseeName: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.licenseeName,
  exclusivity: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.exclusivity,
  territory: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.territory,
  drug: ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug,
  indication: ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication,
  initialInvestment:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.initialInvestment,
  contingentInvestment:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentInvestmentVf,
  contingentDetail:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentDetailVf,
  disclosedPaymentsSumVf:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumVf,
  licenseeResponsibilityAndRightsVf:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.licenseeResponsibilityAndRightsVf,
  totalInvestmentVf:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.totalInvestmentVf,
  ownershipStructureDetails:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.ownershipStructureDetails,
  otherFinancialTermsVf:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherFinancialTermsVf,
  additionalInformation:
    ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.additionalInformation,
  purposeOfTheDeal:
    ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal,
} as const;
