import React from "react";

import { formatNumberWithCommas } from "@/components/shared/utils/formatNumberWithCommas/formatNumberWithCommas";
import ArrowNextDouble from "@images/pagination/arrow-next-double-pagination.svg";
import ArrowNextSingle from "@images/pagination/arrow-next-single-pagination.svg";
import ArrowPrevDouble from "@images/pagination/arrow-prev-double-pagination.svg";
import ArrowPrevSingle from "@images/pagination/arrow-prev-single-pagination.svg";
import { useCtgListFilterState } from "@stores/ctgList";

import * as S from "./Pagination.style";
import { createPageList } from "./Pagination.utils";

export const PAGINATION_LENGTH = 10;

const Pagination = () => {
  const [ctgListFilter, setCtgListFilter] = useCtgListFilterState();

  const { page, maxPage } = ctgListFilter;

  const pageList = createPageList(page, maxPage);

  const handlePrevDouble = () => {
    setCtgListFilter({
      ...ctgListFilter,
      page: 1,
    });
  };

  const handlePrevSingle = () => {
    if (page === 1) return;

    const targetPage =
      page - PAGINATION_LENGTH < 1 ? 1 : page - PAGINATION_LENGTH;
    setCtgListFilter({
      ...ctgListFilter,
      page: targetPage,
    });
  };

  const handleNextDouble = () => {
    setCtgListFilter({
      ...ctgListFilter,
      page: maxPage,
    });
  };

  const handleNextSingle = () => {
    if (page === maxPage) return;

    const targetPage =
      page + PAGINATION_LENGTH > maxPage ? maxPage : page + PAGINATION_LENGTH;
    setCtgListFilter({
      ...ctgListFilter,
      page: targetPage,
    });
  };

  const handleSpecificPage = (targetPage: number) => {
    setCtgListFilter({
      ...ctgListFilter,
      page: targetPage,
    });
  };

  const handleGoToPage = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (Number(event.currentTarget.value) > maxPage) {
        return false;
      }

      setCtgListFilter({
        ...ctgListFilter,
        page: Number(event.currentTarget.value),
      });
    }
  };

  return (
    <S.PaginationWrapper>
      <button onClick={handlePrevDouble} type="button">
        <img src={ArrowPrevDouble} alt="prev arrow" />
      </button>
      <button onClick={handlePrevSingle} type="button">
        <img src={ArrowPrevSingle} alt="prev arrow" />
      </button>

      <S.NumberWrapper>
        {pageList.map((value) => (
          <S.PageNumber
            key={value}
            isTarget={value === page}
            onClick={() => handleSpecificPage(value)}
          >
            {value}
          </S.PageNumber>
        ))}
      </S.NumberWrapper>

      <button onClick={handleNextSingle} type="button">
        <img src={ArrowNextSingle} alt="next arrow" />
      </button>
      <button onClick={handleNextDouble} type="button">
        <img src={ArrowNextDouble} alt="next arrow" />
      </button>
      <S.SearchPageNumber>
        <input type="number" min="1" max={maxPage} onKeyDown={handleGoToPage} />
        <span>of {formatNumberWithCommas(maxPage)} Pages</span>
      </S.SearchPageNumber>
    </S.PaginationWrapper>
  );
};

export default Pagination;
