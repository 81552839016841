import CtgListContainer from "@/components/business/CtgListContainer/CtgListContainer";
import { useChangeBackgroundColor } from "@hooks/useChangeBackgroundColor";
import { theme } from "@styles/theme";
import { removeDealTypeDisplayName } from "@utils/returnDealTypeDisplayName/returnDealTypeDisplayName";

const CtgListPage = () => {
  useChangeBackgroundColor(theme.color.neural.gray00);

  removeDealTypeDisplayName();

  return <CtgListContainer />;
};

export default CtgListPage;
