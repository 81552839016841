import { useEffect } from "react";

import {
  VENTURE_FORMATION_SHELL_KEYS,
  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import { useSellerAndBuyerByDealTypeQuery } from "@api/sellerAndBuyerByDealType/sellerAndBuyerByDealType";
import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  DefaultShellTitle,
  TableCreatorTextFieldContainer,
} from "@FillInTableContainer/shared";
import { useCurrentDealTypeValue } from "@FillInTableContainer/shared/context/CurrentDealTypeContext";
import useCheckPressedShell from "@FillInTableContainer/shared/hooks/useCheckPressedShell";
import { useGetInfoOfShell } from "@FillInTableContainer/shared/hooks/useGetInfoOfShell";
import {
  getShellPlaceholder,
  isRequiredShell,
} from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { padStart } from "@utils/padStart/padStart";

import * as S from "./DealTermsMergeShell.style";
import { useHandleDealTermsMergeTableCreatorShell } from "./hooks/useHandleDealTermsMergeTableCreatorShell";
import { useGetNumberOfSupGroupShell } from "../../../../../../../../../DealTermsLicenseAgreementGroupTable/components/DealTermsLicenseAgreementTable/components/DealTermsLicenseAgreementGroupShellSection/components/DealTermsLicenseAgreementGroupShell/hooks/useGetNumberOfSubGroupShell";

interface Props {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  subGroupShellIndex: number;
  subGroupShellKey: string | null;
  tableKey: string | null;
  tableIndex: number;
  shellKey: string;
  shellIndex: number;
  isFocusedGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isClearGroupShell: boolean;
  isAlertGroupShell: boolean;
  isClearSubGroupShell: boolean;
  isDeleteSubGroupShell: boolean;
  isClearGroupShellSection?: boolean;
}

interface PositionInfoProps {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  subGroupShellIndex: number;
  subGroupShellKey: string | null;
  tableKey: string | null;
  tableIndex: number;
  shellKey: string;
  shellIndex: number;
}

const DealTermsMergeShell = ({
  groupShellIndex,
  groupShellKey,
  subGroupShellIndex,
  subGroupShellKey,
  groupTableIndex,
  groupTableKey,
  tableIndex,
  tableKey,
  shellKey,
  shellIndex,
  isAlertGroupShell,
  isDeleteGroupShell,
  isClearGroupShell,
  isClearSubGroupShell,
  isDeleteSubGroupShell,
  isClearGroupShellSection,
}: Props) => {
  const positionPropsInfo: PositionInfoProps = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
  };

  const {
    isFocused,
    isClearShell,
    isEnableEdit,
    isAlertShell,
    focusStatus,
    columnSize,
    handleFocusTextFieldWrapper,
    handleClickToDeleteShell,
    handleFocusOutShell,
  } = useHandleDealTermsMergeTableCreatorShell(positionPropsInfo);
  const { isPressed, handleChangePressedStatus } = useCheckPressedShell();
  const { subGroupShellCount } = useGetNumberOfSupGroupShell();

  const articleSection = useArticleSection();
  const { shellValue } = useGetInfoOfShell();
  const { register } = useScroll();

  const { currentDealTypeList } = useCurrentDealTypeValue();
  const { data: sellerBuyerInfo } =
    useSellerAndBuyerByDealTypeQuery(currentDealTypeList);

  const hasMultipleContingentPaymentSubGroupShell = () => {
    const currentContingentPaymentLength = subGroupShellCount(
      subGroupShellKey,
      tableIndex,
      groupShellIndex,
    );

    if (currentContingentPaymentLength > 1) return true;

    return false;
  };

  const getMergeSellTitle = () => {
    if (
      subGroupShellKey ===
        VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.contingentInvestment &&
      hasMultipleContingentPaymentSubGroupShell()
    )
      return `${getShellTitle(articleSection, shellKey)} ${padStart(
        subGroupShellIndex + 1,
      )}`;

    if (shellKey === VENTURE_FORMATION_SHELL_KEYS.licensorName)
      return sellerBuyerInfo.data.seller;

    if (shellKey === VENTURE_FORMATION_SHELL_KEYS.licenseeName)
      return sellerBuyerInfo.data.buyer;

    // if (shellKey === "Drug") return "Drug Assets";

    return getShellTitle(articleSection, shellKey);
  };

  useEffect(() => {
    const handleCheckClickOutsideShell = () => {
      if (isFocused) handleFocusOutShell();
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleFocusOutShell, isFocused]);

  return (
    <S.ShellWrapper
      column={columnSize}
      {...register(
        `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${shellKey}__${shellIndex}`,
      )}
    >
      {isClearShell && isEnableEdit && (
        <S.DeleteTableButtonWrapper>
          <S.DeleteTableButton type="button" onClick={handleClickToDeleteShell}>
            <img src={IconXMark} alt="button for clear" />
            {focusStatus.type === "clear" ? "Clear" : null}
          </S.DeleteTableButton>
        </S.DeleteTableButtonWrapper>
      )}

      <S.ShellBorderWrapper
        isFocused={isFocused && focusStatus.type !== "dragging"}
        isClearStatus={
          isClearShell ||
          isClearGroupShell ||
          isClearSubGroupShell ||
          !!isClearGroupShellSection
        }
        isDeleteStatus={isDeleteGroupShell || isDeleteSubGroupShell}
        isAlertStatus={isAlertShell || isAlertGroupShell}
        isDisableEdit={!isEnableEdit}
        $isPressed={isPressed}
        $isDragging={isFocused && focusStatus.type === "dragging"}
        onClick={handleFocusTextFieldWrapper}
        onMouseDown={(e) => handleChangePressedStatus(e, true)}
        onMouseUp={(e) => handleChangePressedStatus(e, false)}
      >
        <DefaultShellTitle
          isFocused={isFocused}
          isClearShell={isClearShell}
          isAlertShell={isAlertShell}
          isRequiredShell={isRequiredShell(articleSection, shellKey)}
          shellTitle={getMergeSellTitle()}
          columnSize={columnSize}
        />

        <TableCreatorTextFieldContainer
          placeholder={
            getShellPlaceholder(articleSection, shellKey) ||
            getShellTitle(articleSection, shellKey)
          }
          shellValue={shellValue(
            shellKey,
            tableIndex,
            groupShellIndex,
            shellIndex,
            subGroupShellIndex,
          )}
          shellKey={shellKey}
          shellIndex={shellIndex}
          groupTableKey={groupTableKey}
          groupTableIndex={groupTableIndex}
          groupShellKey={groupShellKey}
          groupShellIndex={groupShellIndex}
          subGroupShellIndex={subGroupShellIndex}
          subGroupShellKey={subGroupShellKey}
          tableKey={tableKey}
          tableIndex={tableIndex}
        />
      </S.ShellBorderWrapper>
    </S.ShellWrapper>
  );
};

export default DealTermsMergeShell;
