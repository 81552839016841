import { apiClient } from "@api/apiClient";

import type {
  CtgFilteringData,
  PostCtgListFilterResponse,
} from "@interface/ctgList.interface";

const API_ENDPOINT = {
  draftArticleList: "/des/ctg/list",
  checkPressStatus: "/des/draft-article/status",
};

export const ctgListApis = {
  post: async (filteringData: CtgFilteringData) => {
    const { data } = await apiClient.post<PostCtgListFilterResponse>(
      API_ENDPOINT.draftArticleList,
      filteringData,
    );
    return data;
  },
  checkPressStatus: async (draftArticleId: string) => {
    const { data } = await apiClient.get(
      `${API_ENDPOINT.checkPressStatus}/${draftArticleId}`,
    );
    return data;
  },
};
