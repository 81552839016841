import type { TableCreatorLayout } from "../types/tableCreator.types";

export const TABLE_CREATOR_LAYOUT: TableCreatorLayout = {
  "Clinical Trials": [
    {
      groupTableKey: "Drug Profile",
      tableList: [
        {
          tableKey: "Drug",
          groupShellList: [
            {
              groupShellKey: "Drug Name",
              shellList: [
                {
                  shellKey: "Brand Name",
                },
                {
                  shellKey: "INN Name",
                },
                {
                  shellKey: "Code Name",
                },
              ],
            },
            {
              groupShellKey: null,
              shellList: [
                {
                  shellKey: "ROA",
                },
                {
                  shellKey: "Modality",
                },
                {
                  shellKey: "Target",
                },
                {
                  shellKey: "MOA",
                },
                {
                  shellKey: "Target Indication",
                },
                {
                  shellKey: "Other Characteristics",
                },
                {
                  shellKey: "Phase",
                },
                {
                  shellKey: "Generic / Biosimilar",
                },
                {
                  shellKey: "Ref.Product Name",
                },
                {
                  shellKey: "Originator/Developer",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      groupTableKey: "Clinical Trial Design-1",
      tableList: [
        {
          tableKey: "Clinical Design",
          groupShellList: [
            {
              groupShellKey: null,
              shellList: [
                {
                  shellKey: "Clinical Design Phase",
                },
                {
                  shellKey: "Inclusion Criteria - Diagnostic",
                },
                {
                  shellKey: "Inclusion Criteria - Detail",
                },
                {
                  shellKey: "Total Patients Number",
                },
                {
                  shellKey: "Trial Name",
                },
                {
                  shellKey: "NCT Name",
                },
                {
                  shellKey: "Trial Intervention",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      groupTableKey: "Clinical Trial Design-2",
      tableList: [
        {
          tableKey: null,
          groupShellList: [
            {
              groupShellKey: "Arm",
              shellList: [
                {
                  shellKey: "Arm Name",
                },
                {
                  shellKey: "Patients Number",
                },
                {
                  shellKey: "Design Group Information",

                  subShellList: [
                    {
                      subShellKey: "Drug Name",
                    },
                    {
                      subShellKey: "Dose",
                    },
                    {
                      subShellKey: "Duration",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    {
      groupTableKey: "Clinical Results",
      tableList: [
        {
          tableKey: null,
          groupShellList: [
            {
              groupShellKey: "Clinical Results",
              shellList: [
                {
                  shellKey: "Results Classification",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      groupTableKey: "Clinical Endpoints (Table)",
      tableList: [
        {
          tableKey: "Data",
          groupShellList: [
            {
              groupShellKey: "Endpoints Table Information",
              shellList: [
                {
                  shellKey: "Endpoint Classification",
                },
                {
                  shellKey: "Endpoint Name including Measurement Timing",
                },
                {
                  shellKey: "Endpoint Group Information",
                  subShellList: [
                    {
                      subShellKey: "Group Data",
                    },
                    // {
                    //   subShellKey: "Group Name",
                    // },
                    {
                      subShellKey: "P-Value",
                    },
                  ],
                },
              ],
            },
            {
              groupShellKey: "Table Notes",
              shellList: [
                {
                  shellKey: "Endpoints Table Notes",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      groupTableKey: "Clinical Endpoints (Description)",
      tableList: [
        {
          tableKey: null,
          groupShellList: [
            {
              groupShellKey: "Endpoint Description",
              shellList: [
                {
                  shellKey: "Endpoint Description",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      groupTableKey: "Key Comment",
      tableList: [
        {
          tableKey: null,
          groupShellList: [
            {
              groupShellKey: null,
              shellList: [
                {
                  shellKey: "Quote",
                },
                {
                  shellKey: "Spokesperson",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  "Clinical Trial Status": [
    {
      groupTableKey: "Drug Profile",
      tableList: [
        {
          tableKey: "Drug",
          groupShellList: [
            {
              groupShellKey: "Drug Name",
              shellList: [
                {
                  shellKey: "Brand Name",
                },
                {
                  shellKey: "INN Name",
                },
                {
                  shellKey: "Code Name",
                },
              ],
            },
            {
              groupShellKey: null,
              shellList: [
                {
                  shellKey: "ROA",
                },
                {
                  shellKey: "Modality",
                },
                {
                  shellKey: "Target",
                },
                {
                  shellKey: "MOA",
                },
                {
                  shellKey: "Target Indication",
                },
                {
                  shellKey: "Other Characteristics",
                },
                {
                  shellKey: "Phase",
                },
                {
                  shellKey: "Generic / Biosimilar",
                },
                {
                  shellKey: "Ref.Product Name",
                },
                {
                  shellKey: "Originator/Developer",
                },
              ],
            },
          ],
        },
      ],
    },
    // {
    //   groupTableKey: "Clinical Trial Design-1",
    //   tableList: [
    //     {
    //       tableKey: "Clinical Design",
    //       groupShellList: [
    //         {
    //           groupShellKey: null,
    //           shellList: [
    //             {
    //               shellKey: "Clinical Design Phase",
    //             },
    //             {
    //               shellKey: "Inclusion Criteria - Diagnostic",
    //             },
    //             {
    //               shellKey: "Inclusion Criteria - Detail",
    //             },
    //             {
    //               shellKey: "Total Patients Number",
    //             },
    //             {
    //               shellKey: "Trial Name",
    //             },
    //             {
    //               shellKey: "NCT Name",
    //             },
    //             {
    //               shellKey: "Trial Intervention",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },
  ],
  Regulatory: [
    {
      groupTableKey: "Drug Profile",
      tableList: [
        {
          tableKey: "Drug",
          groupShellList: [
            {
              groupShellKey: "Drug Name",
              shellList: [
                {
                  shellKey: "Brand Name",
                },
                {
                  shellKey: "INN Name",
                },
                {
                  shellKey: "Code Name",
                },
              ],
            },
            {
              groupShellKey: null,
              shellList: [
                {
                  shellKey: "ROA",
                },
                {
                  shellKey: "Modality",
                },
                {
                  shellKey: "Target",
                },
                {
                  shellKey: "MOA",
                },
                {
                  shellKey: "Target Indication",
                },
                {
                  shellKey: "Other Characteristics",
                },
                {
                  shellKey: "Phase",
                },
                {
                  shellKey: "Generic / Biosimilar",
                },
                {
                  shellKey: "Ref.Product Name",
                },
                {
                  shellKey: "Originator/Developer",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      groupTableKey: "Approval Process Contents",
      tableList: [
        {
          tableKey: "Approval Process",
          groupShellList: [
            {
              groupShellKey: "Approval Process",
              shellList: [
                {
                  shellKey: "Update Date",
                },
                {
                  shellKey: "Marketing Status",
                },
                {
                  shellKey: "Regulatory Agency",
                },
                {
                  shellKey: "Application Type",
                },
                {
                  shellKey: "Authorized Company",
                },
                {
                  shellKey: "Indication on Label",
                },
                {
                  shellKey: "Dosage and Administration on Label",
                },
              ],
            },
            {
              groupShellKey: "Additional",
              shellList: [
                {
                  shellKey: "Additional Information on the Approval Process",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      groupTableKey: "Key Comment",
      tableList: [
        {
          tableKey: null,
          groupShellList: [
            {
              groupShellKey: null,
              shellList: [
                {
                  shellKey: "Quote",
                },
                {
                  shellKey: "Spokesperson",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
