import React from "react";

import ReactCountryFlag from "react-country-flag";

import * as S from "./CountryFlag.style";
import IconDefault from "./images/IconDefault/IconDefault";

type Props = {
  countryCode: string;
  isTopMargin?: boolean;
  wrapperClassName?: string;
  defaultIconWidth?: number;
  defaultIconHeight?: number;
} & React.ComponentProps<typeof ReactCountryFlag>;

const CountryFlag = ({
  countryCode,
  isTopMargin = false,
  wrapperClassName,
  defaultIconWidth,
  defaultIconHeight,
  ...rest
}: Props) => {
  if (countryCode === "empty")
    return (
      <S.FlagWrapper $isTopMargin={isTopMargin} className={wrapperClassName}>
        <IconDefault width={defaultIconWidth} height={defaultIconHeight} />
      </S.FlagWrapper>
    );

  return (
    <S.FlagWrapper $isTopMargin={isTopMargin} className={wrapperClassName}>
      <ReactCountryFlag countryCode={countryCode} svg {...rest} />
    </S.FlagWrapper>
  );
};

export default CountryFlag;
