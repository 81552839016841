import { ClassificationCode, ProcessStatusCode } from "@/types/code.types";
import { getCookie } from "@utils/cookie";

import { EDITOR_ROLES } from "../constants/editorRoles.constants";
import { HALF_BUTTON_ROLES_LIST } from "../constants/fillInTableRolesList.constants";
import { CLASSIFICATION_KEYS_CODE } from "../constants/returnRolesList.constants";
import { EditorRoleCode } from "../types/roles.types";

export const checkHalfButtonRoles = (
  isSuperAdmin: boolean,
  editorRoles: EditorRoleCode[],
  status: ProcessStatusCode,
  classification?: ClassificationCode | "",
  fillInTableEditor1?: string | null,
) => {
  if (!classification) return false;
  if (isSuperAdmin) return true;

  const isEnableEnrollEditor =
    getCookie("accountName") === fillInTableEditor1 ||
    fillInTableEditor1 === "" ||
    fillInTableEditor1 === null;

  const checkEditorRolesAccess = (role: EditorRoleCode) => {
    return (
      editorRoles.includes(role) &&
      HALF_BUTTON_ROLES_LIST[role].includes(status) &&
      isEnableEnrollEditor
    );
  };

  const handleHalfButtonRoles = () => {
    if (classification === CLASSIFICATION_KEYS_CODE.clinicalTrials) {
      if (checkEditorRolesAccess(EDITOR_ROLES.EDTK0201)) {
        return true;
      }
    }

    if (classification === CLASSIFICATION_KEYS_CODE.clinicalTrialStatus) {
      if (checkEditorRolesAccess(EDITOR_ROLES.EDTK0303)) {
        return true;
      }
    }

    if (classification === CLASSIFICATION_KEYS_CODE.posterContents) {
      if (checkEditorRolesAccess(EDITOR_ROLES.EDTK0401)) {
        return true;
      }
    }

    if (classification === CLASSIFICATION_KEYS_CODE.expeditedProgram) {
      if (checkEditorRolesAccess(EDITOR_ROLES.EDTK0603)) {
        return true;
      }
    }
    if (classification === CLASSIFICATION_KEYS_CODE.approvalProcess) {
      if (checkEditorRolesAccess(EDITOR_ROLES.EDTK0703)) {
        return true;
      }
    }

    return false;
  };

  return handleHalfButtonRoles();
};
