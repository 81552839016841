import { CTG_SECTION } from "./articleSection.constants";

export const TABLE_CREATOR_GROUP_SHELL = {
  [CTG_SECTION]: [
    {
      key: "Drug Name",
      title: "Drug Name",
      isRequired: true,
      isReviseGroupShell: false,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
  ],
};
