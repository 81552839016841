import styled, { css } from "styled-components";

export const TopFixInfoItemWrapper = styled.div<{
  $minWidth: string;
  $maxWidth: string;
  $width?: string;
  $isFlex?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-4, 0.4rem);
  width: ${({ $width }) => $width || "100%"};
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};
  padding: var(--Spacing-4, 0.4rem) var(--Spacing-8, 0.8rem);

  border-left: 1px solid #b1b8c4;
  border-top: 1px solid #b1b8c4;
  border-bottom: 1px solid #b1b8c4;
  background: #f0f2f4;

  &:last-child {
    ${({ $isFlex }) =>
      $isFlex &&
      css`
        flex: 1;
      `}
    border-right: 1px solid #b1b8c4;
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }

  &:first-child {
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
  }

  &.press-date-item {
    cursor: pointer;

    &:hover {
      background-color: #fff;

      .press-date-item-title {
        color: #3d4551;

        svg > path {
          fill: #3d4551;
        }
      }
    }
  }
`;

export const TopFixInfoTitle = styled.h6`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  color: #515c6c;
  height: 2rem;
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 130%;
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-0, 0rem);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
`;

export const TopFixInfoContent = styled.div`
  color: #2a2f37;
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 130%;
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-4, 0.4rem);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;

  &.press-date-item-content {
    white-space: nowrap;
  }
`;

export const TranslatedId = styled.span`
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
`;

export const ManualPressDateWrapper = styled.mark`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  text-overflow: ellipsis;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  padding: 0.2rem 0.4rem;
  border: 0.1rem solid var(--Color-Common-Border-Secondary, #b1b8c4);
  border-radius: 0.2rem;
  background-color: transparent;
  margin-left: 0.4rem;
`;
