import useArticleSection from "@/components/business/CTGFillInTableContainer/hooks/useArticleSection";
import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";
import { useTableCreatorFormatErrorPressId } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorFormatErrorPressIdContext";
import { useUpdateCTGEntryData } from "@/components/business/CTGFillInTableContainer/shared/hooks/useUpdateCTGEntryData";
import UndoRedoButton from "@/components/shared/ui/UndoRedoButton/UndoRedoButton";
import { useCTGInfoMutation, useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { useCTGFillInTableRoles } from "@libs/roles/hooks/useCTGFillInTableRoles";
import { useCTGEntryDataState } from "@stores/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastDraftSave } from "@utils/toast";

import { useModalityCategoryUpdate } from "../../hooks/useModalityCategoryUpdate";

interface Props {
  undoRedoEvents: {
    undo: () => void;
    redo: () => void;
    isDisabledUndo: boolean;
    isDisabledRedo: boolean;
  };
}

const FillInTableContentsFabContainer = ({ undoRedoEvents }: Props) => {
  const draftArticleId = getPressIdFromURL();

  // NOTE : DataFormat으로 에러가 발생한 경우, Bottom Button을 비활성화 시키기 위한 로직
  const { errorPressId } = useTableCreatorFormatErrorPressId();
  const isDisabledPress = errorPressId === draftArticleId;

  const [entryData] = useCTGEntryDataState(draftArticleId);
  const articleSection = useArticleSection();

  const { data: articleInfo } = useCTGInfoQuery(Number(draftArticleId));
  const { mutateAsync: updateCTGInfo } = useCTGInfoMutation(
    Number(draftArticleId),
  );
  const { updateModalityCategoryValue } = useModalityCategoryUpdate();

  const { hasEditFullTableRoles } = useCTGFillInTableRoles();

  const { undo, redo, isDisabledUndo, isDisabledRedo } = undoRedoEvents;

  const updateEntryData = useUpdateCTGEntryData();
  const draftArticleStatus = articleInfo?.draftArticleStatus;
  const isNotReviewInProgress =
    draftArticleStatus !== DRAFT_STATUS_CODE.reviewInProgress;

  const isDisabledUndoRedoByRoles = !hasEditFullTableRoles;

  const updateCTGEditor = async () => {
    if (
      draftArticleStatus === DRAFT_STATUS_CODE.aiEntryDone ||
      draftArticleStatus === DRAFT_STATUS_CODE.reviewInProgress
    ) {
      const ftEditor2Name = articleInfo?.ftEditor2Name;
      if (ftEditor2Name === null || ftEditor2Name === "") {
        await updateCTGInfo({
          type: "FT2",
          isCheckStartDate: "true",
        });
      }
    }
  };

  const saveDraft = async () => {
    if (articleSection === CTG_SECTION) updateCTGEditor();

    await updateEntryData({
      ...entryData,
    });

    updateModalityCategoryValue();

    toastDraftSave();
  };

  const isDisabledUndoButton =
    isDisabledUndoRedoByRoles || isDisabledPress || isDisabledUndo;
  const isDisabledDraftButton =
    isNotReviewInProgress || isDisabledUndoRedoByRoles || isDisabledPress;
  const isDisabledRedoButton =
    isDisabledUndoRedoByRoles || isDisabledPress || isDisabledRedo;

  return (
    <UndoRedoButton
      undoIsDisabled={isDisabledUndoButton}
      undoOnClickHandler={undo}
      redoIsDisabled={isDisabledRedoButton}
      redoOnClickHandler={redo}
      saveIsDisabled={isDisabledDraftButton}
      saveOnClickHandler={saveDraft}
    />
  );
};

export default FillInTableContentsFabContainer;
