import { apiClient } from "@api/apiClient";

import type {
  GetArticleInfoResponse,
  PutArticleInfoArg,
  PutArticleInfoResponse,
} from "@interface/articleInfo.interface";

export const API_ENDPOINT = {
  get: "/des/draft-article/info",
  update: "/des/draft-article/update",
};

export const articleInfoApis = {
  get: async (draftArticleId: number) => {
    const { data } = await apiClient.get<GetArticleInfoResponse>(
      `${API_ENDPOINT.get}/${draftArticleId}`,
    );

    return data;
  },
  put: async (articleInfo: PutArticleInfoArg) => {
    const { data } = await apiClient.put<PutArticleInfoResponse>(
      API_ENDPOINT.update,
      articleInfo,
    );

    return data;
  },
};
