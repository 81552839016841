import React, { Dispatch, SetStateAction } from "react";

import FilterTag from "@atoms/FilterTag/FilterTag";

import * as S from "./StatusFilterSection.style";

import type {
  StatusFilterList,
  StatusFilterValue,
} from "../../statusFilter.constants";

interface Props {
  title: string;
  statusSectionList: StatusFilterValue[];
  statusFilterList: StatusFilterList;
  setStatusFilterList: Dispatch<SetStateAction<StatusFilterList>>;
}

const StatusFilterSection = ({
  statusSectionList,
  title,
  statusFilterList,
  setStatusFilterList,
}: Props) => {
  const selectedStatusLength = statusSectionList.filter(
    (item) => item.isChecked,
  ).length;

  const isCheckedAll = statusSectionList.length === selectedStatusLength;
  const handleCheckedStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const updatedStatusFilterList = statusSectionList.map((item) => {
      if (item.code === value) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });

    setStatusFilterList({
      ...statusFilterList,
      [title]: updatedStatusFilterList,
    });
  };

  const handleCheckedAllStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.currentTarget;
    const updatedStatusFilterList = statusSectionList.map((item) => ({
      ...item,
      isChecked: checked,
    }));

    setStatusFilterList({
      ...statusFilterList,
      [title]: updatedStatusFilterList,
    });
  };

  return (
    <>
      <S.StatusFilterRow>
        <S.StatusFilterTitle>
          {title}
          {statusSectionList.length > 1 && (
            <FilterTag
              name="statusType"
              value={`all-${title}`}
              onChange={handleCheckedAllStatus}
              checked={isCheckedAll}
              isAll
            >
              Select All
            </FilterTag>
          )}
        </S.StatusFilterTitle>
      </S.StatusFilterRow>
      <S.StatusFilterRow>
        <S.StatusFilterContent>
          <S.StatusFilterFieldset>
            {statusSectionList.map((item) => (
              <FilterTag
                key={`status-${item.code}`}
                tagType="checkbox"
                onChange={handleCheckedStatus}
                name="status"
                value={item.code}
                checked={item.isChecked}
                code={item.code}
                filterType="status"
              >
                {item.name}
              </FilterTag>
            ))}
          </S.StatusFilterFieldset>
        </S.StatusFilterContent>
      </S.StatusFilterRow>
    </>
  );
};

export default StatusFilterSection;
