import React, { useState } from "react";

import { ArticleSectionType } from "@/components/business/CTGFillInTableContainer/types/fillInTable.types";
import { useCTGEntryDataState } from "@stores/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { ENTRY_DATA_TO_CHANGE_VALUE_ORDER } from "../constants/changeEntryDataOrder.constants";

export type DragInfoType = [number | null, number | null];

type IndexType = {
  tableIndex: number;
  groupShellIndex: number;
  subGroupShellIndex?: number | null;
};

export const useHandleTableCreatorValueDrag = (
  articleSection: ArticleSectionType,
  positionIndex: IndexType,
  shellKey: string,
) => {
  const [entryData, setEntryData] = useCTGEntryDataState(getPressIdFromURL());
  const [valueDraggedIndexInfo, setValueDraggedIndexInfo] =
    useState<DragInfoType>([null, null]);

  const [isDraggingValue, setIsDraggingValue] = useState<boolean>(false);

  const handleValueDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    setValueDraggedIndexInfo([index, null]);
  };

  const handleValueDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    event.preventDefault();
    setValueDraggedIndexInfo([valueDraggedIndexInfo[0], index]);
  };

  const handleValueDragEnd = () => {
    setEntryData(
      ENTRY_DATA_TO_CHANGE_VALUE_ORDER?.[articleSection]?.[shellKey](
        entryData,
        valueDraggedIndexInfo,
        positionIndex,
      ) || {},
    );
    setIsDraggingValue(false);
  };

  const handleValueDragOn = () => {
    setIsDraggingValue(true);
  };

  return {
    handleValueDragStart,
    handleValueDragOver,
    handleValueDragEnd,
    handleValueDragOn,
    valueDraggedIndexInfo,
    isDraggingValue,
  };
};
