import { useState } from "react";

import { CtgListData } from "@/components/business/CtgListContainer/ctgList.types";
import { ProcessStatusCode } from "@/types/code.types";
import Modal from "@atoms/Modal/Modal";
import IconAlert from "@images/pressList/statusModal/icon-alert-red.svg";
import IconClose from "@images/pressList/statusModal/icon-close.svg";
import { DRAFT_STATUS_INFO } from "@PressListContainer/draftStatusInfo.constants";

import * as S from "./CtgListCheckStatusGuide.style";
import { useMoveCtgDetailPage } from "../../hooks/useMoveCtgDetailPage";

interface Props {
  ctgListData: CtgListData[];
  draftArticleId: number;
  closeModal: () => void;
  updateCtgList: () => void;
  openAccessDeniedModal: () => void;
  openConcurrentAccessDeniedModal: () => void;
  currentStatus: ProcessStatusCode | "";
}

const CtgListCheckStatusGuide = ({
  ctgListData,
  closeModal,
  draftArticleId,
  updateCtgList,
  openAccessDeniedModal,
  openConcurrentAccessDeniedModal,
  currentStatus,
}: Props) => {
  const ctgData = ctgListData?.filter(
    (ctg) => ctg.ctgId === draftArticleId,
  )?.[0];

  const [initPressStatus] = useState(ctgData?.draftArticleStatusHighest);

  const { moveCtgDetailPage } = useMoveCtgDetailPage({
    draftArticleId: ctgData?.ctgId?.toString(),
    nctNumber: ctgData?.nctId?.toString() || "",
    ftEditorName: ctgData?.ftEditor2Name || "",
    onAccessDenied: openAccessDeniedModal,
    onConcurrentAccessDenied: openConcurrentAccessDeniedModal,
  });

  const handleCancelEvent = () => {
    updateCtgList();
    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <S.ModalContentsWrapper>
        <S.ModalTitle>
          <img src={IconAlert} alt="" />
          Status Change Notification
        </S.ModalTitle>
        <S.ModalText>
          The status of the selected Press has been changed from ‘
          <strong>
            {initPressStatus && DRAFT_STATUS_INFO[initPressStatus].text}
          </strong>
          ’ to ‘
          <strong>
            {currentStatus && DRAFT_STATUS_INFO[currentStatus].text}
          </strong>
          ’. Would you like to continue?
        </S.ModalText>
        <S.ModalButtonWrapper>
          <S.CancelButton type="button" onClick={handleCancelEvent}>
            Back to Ctg List
          </S.CancelButton>
          <S.RefreshButton type="button" onClick={moveCtgDetailPage}>
            Continue
          </S.RefreshButton>
          <S.CloseButton type="button" onClick={handleCancelEvent}>
            <img src={IconClose} alt="Close" />
          </S.CloseButton>
        </S.ModalButtonWrapper>
      </S.ModalContentsWrapper>
    </Modal>
  );
};

export default CtgListCheckStatusGuide;
