import { useCallback, useMemo } from "react";

import {
  ArticleSection,
  removeParentheses,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
} from "@frontend/common";

import { ClassificationCode } from "@/types/code.types";
import { useGetPressContentBoldKeywordQuery } from "@api/pressContent/pressContent";
import { BoldKeywordItem } from "@api/pressContent/pressContent.interface";
import { ARTICLE_SECTION_CODE } from "@FillInTableContainer/constants/fillInTable.constants";
import { useEntryDataValue } from "@stores/entryData";
import { useCurrencyInfoValue } from "@stores/pressContentCurrencyKeyword";
import { getPressIdFromURL } from "@utils/getStateFromURL";

const PAYMENT_KEYWORDS_BAR = "__PAYMENT_KEYWORDS_BAR__";
const CURRENCY_BAR = "__CURRENCY_BAR__";
const UNIT_BAR = "__UNIT_BAR__";

const generateRegexList = (keywordList: string[]) => {
  return (
    keywordList
      ?.sort((a, b) => b.length - a.length)
      .map((item) => {
        const cleanedName = removeParentheses(item).trim();
        const parts = cleanedName.split(/\s+/);
        const escapedParts = parts.map((part) =>
          part.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
        );
        const pattern = escapedParts.join("[\\s-]*");
        return new RegExp(`(${pattern})`, "gi");
      }) || []
  );
};

export const useCheckBoldKeywords = (
  classificationManual: ClassificationCode,
  additionalBoldKeywords: string[],
) => {
  // NOTE: classification manual 없는 경우에 대한 예외 처리 진행 (CTG)
  const { data: boldKeywords } =
    useGetPressContentBoldKeywordQuery(classificationManual);

  const articleSection = classificationManual
    ? ARTICLE_SECTION_CODE?.[classificationManual]
    : "";

  const entryData = useEntryDataValue(getPressIdFromURL());

  const boldKeywordsData = boldKeywords?.data as unknown as BoldKeywordItem[];

  const { paymentKeyword, currency, unit } = useCurrencyInfoValue();
  const currencyKeywordsBar = paymentKeyword.join("|");
  const currencyUnitBar = currency.join("|");
  const unitBar = unit.join("|");

  const boldKeywordsRegexList = boldKeywordsData?.map(
    (keyword: BoldKeywordItem) => {
      const nameToString = String(keyword.name).trim();
      if (keyword.isPayment === true) {
        const targetName = nameToString
          .replace(PAYMENT_KEYWORDS_BAR, currencyKeywordsBar)
          .replace(CURRENCY_BAR, currencyUnitBar)
          .replace(UNIT_BAR, unitBar)
          .trim();
        const regex = new RegExp(`\\b${targetName}\\b`, "gi");
        return regex;
      }
      if (keyword.isRegular === true) {
        return new RegExp(nameToString, "gi");
      }
      return new RegExp(`\\b(${nameToString})\\b`, "gi");
    },
  );

  const boldKeywordsInEntryData = useCallback(() => {
    if (classificationManual === "" || articleSection === "") return [];

    const enableBoldSectionList = [
      SECTION_KEYS.deals,
      SECTION_KEYS.ventureCapital,
      SECTION_KEYS.expeditedProgram,
      SECTION_KEYS.regulatory,
      SECTION_KEYS.clinicalTrialStatus,
    ] as unknown as ArticleSection[];
    if (!enableBoldSectionList.includes(articleSection)) return [];

    if (
      !(
        [
          SECTION_KEYS.deals,
          SECTION_KEYS.ventureCapital,
        ] as unknown as ArticleSection[]
      ).includes(articleSection) &&
      !entryData[SECTION_KEYS.clinicalTrialStatus] &&
      !entryData[SECTION_KEYS.expeditedProgram] &&
      !entryData[SECTION_KEYS.regulatory]
    ) {
      return generateRegexList([...additionalBoldKeywords]);
    }

    if (
      SECTION_KEYS.clinicalTrialStatus &&
      entryData[SECTION_KEYS.clinicalTrialStatus]
    ) {
      const originatorList =
        entryData[SECTION_KEYS.clinicalTrialStatus]?.["Drug Profile"]?.flatMap(
          (tableData) => {
            return (
              tableData["Originator/Developer"].value
                ?.flatMap((item) => item.text)
                ?.filter((text) => !!text.trim()) || []
            );
          },
        ) || [];

      return generateRegexList([...originatorList, ...additionalBoldKeywords]);
    }

    if (SECTION_KEYS.regulatory && entryData[SECTION_KEYS.regulatory]) {
      const originatorList =
        entryData[SECTION_KEYS.regulatory]?.["Drug Profile"]?.flatMap(
          (tableData) => {
            return (
              tableData["Originator/Developer"].value
                ?.flatMap((item) => item.text)
                ?.filter((text) => !!text.trim()) || []
            );
          },
        ) || [];

      const authorizedCompanyList =
        entryData[SECTION_KEYS.regulatory]?.["Approval Process Contents"]?.[
          "Approval Process"
        ]?.flatMap((tableData) => {
          return (
            tableData["Authorized Company"].value
              ?.flatMap((item) => item.text)
              ?.filter((text) => !!text.trim()) || []
          );
        }) || [];

      return generateRegexList([
        ...originatorList,
        ...authorizedCompanyList,
        ...additionalBoldKeywords,
      ]);
    }

    if (
      SECTION_KEYS.expeditedProgram &&
      entryData[SECTION_KEYS.expeditedProgram]
    ) {
      const originatorList =
        entryData[SECTION_KEYS.expeditedProgram]?.["Drug Profile"]?.flatMap(
          (tableData) => {
            return (
              tableData["Originator/Developer"].value
                ?.flatMap((item) => item.text)
                ?.filter((text) => !!text.trim()) || []
            );
          },
        ) || [];
      const designatedCompanyName =
        entryData[SECTION_KEYS.expeditedProgram]?.[
          "Expedited Program Contents"
        ]?.["Designated Company name"]?.value
          ?.flatMap((item) => item.text)
          ?.filter((text) => !!text.trim()) || [];

      return generateRegexList([
        ...originatorList,
        ...designatedCompanyName,
        ...additionalBoldKeywords,
      ]);
    }

    if (SECTION_KEYS.deals && entryData[SECTION_KEYS.deals]) {
      const dealNameList =
        entryData[SECTION_KEYS.deals]?.["Deal Type & Status"]?.[
          "Deal Name"
        ]?.value
          ?.map((item) => item.text)
          ?.filter((text) => !!text.trim()) || [];
      const licensorNameList =
        entryData[SECTION_KEYS.deals]?.["Licensor Table"]?.[
          "Licensor Name"
        ]?.value
          ?.map((item) => item.text)
          ?.filter((text) => !!text.trim()) || [];
      const licenseeNameList =
        entryData[SECTION_KEYS.deals]?.["Licensee Table"]?.flatMap(
          (tableData) =>
            tableData["Licensee Name"]?.value
              ?.map((item) => item.text)
              ?.filter((text) => !!text.trim()) || [],
        ) || [];

      return generateRegexList([
        ...dealNameList,
        ...licenseeNameList,
        ...licensorNameList,
      ]);
    }

    if (SECTION_KEYS.ventureCapital && entryData[SECTION_KEYS.ventureCapital]) {
      const targetData = entryData[SECTION_KEYS.ventureCapital];
      const roundNameList =
        targetData?.["Round Type & Status"]?.["Round Name"]?.value
          ?.map((item) => item.text)
          ?.filter((text) => !!text.trim()) || [];
      const roundTypeList =
        targetData?.["Round Type & Status"]?.["Round Type"]?.value
          ?.map((item) => item.text)
          ?.filter((text) => !!text.trim()) || [];
      const companyNameList =
        targetData?.["Funded Company"]?.["Company Name"]?.value
          ?.map((item) => item.text)
          ?.filter((text) => !!text.trim()) || [];

      const investorsList =
        targetData?.Investors?.["Investor Name"]?.value
          ?.map((item) => item.text)
          ?.filter((text) => !!text.trim()) || [];

      return generateRegexList([
        ...roundNameList,
        ...roundTypeList,
        ...companyNameList,
        ...investorsList,
      ]);
    }

    return [];
  }, [articleSection, entryData, classificationManual, additionalBoldKeywords]);

  const boldDataListDBAndEntryData = useMemo(
    () => [...boldKeywordsRegexList, ...boldKeywordsInEntryData()],
    [boldKeywordsInEntryData, boldKeywordsRegexList],
  );

  const checkBoldKeyword = useCallback(
    (keyword: string) => {
      if (boldDataListDBAndEntryData.length === 0) return keyword;

      let result = keyword;
      boldDataListDBAndEntryData.forEach((regex) => {
        result = result.replaceAll(regex, (match) => {
          return `<b>${match}</b>`;
        });
      });
      return result;
    },
    [boldDataListDBAndEntryData],
  );

  return {
    checkBoldKeyword,
  };
};
