import React from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconBloodDerivatives = ({
  width = 24,
  height = 24,
  color = "#515C6C",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99999 13.25C9.91886 13.25 11.45 11.7274 11.45 9.8789C11.45 8.32071 10.5208 6.51483 9.48304 5.01356C8.97694 4.28144 8.46941 3.65602 8.0881 3.21355C8.0579 3.17851 8.02851 3.14464 8 3.11198C7.97149 3.14464 7.9421 3.17852 7.91189 3.21358C7.53058 3.65607 7.02304 4.28152 6.51694 5.01367C5.47914 6.51499 4.54999 8.32086 4.54999 9.8789C4.54999 11.7274 6.08112 13.25 7.99999 13.25ZM7.49206 2.55365C6.37428 3.82389 3.79999 7.05578 3.79999 9.8789C3.79999 12.155 5.68039 14 7.99999 14C10.3196 14 12.2 12.155 12.2 9.8789C12.2 7.05555 9.62572 3.82382 8.50793 2.55363C8.19796 2.20139 7.99999 2 7.99999 2C7.99999 2 7.80203 2.2014 7.49206 2.55365Z"
        fill={color}
        className="modality-icon-fill"
      />
    </svg>
  );
};

IconBloodDerivatives.displayName = "BloodDerivatives";

export default IconBloodDerivatives;
