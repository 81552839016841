import { useCallback } from "react";

import {
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL,
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_LICENSOR_TABLE,
  ENTRY_DATA_LICENSOR_TABLE_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS,
  VENTURE_FORMATION_GROUP_SHELL_KEYS,
  VENTURE_FORMATION_SHELL_KEYS,
  VENTURE_FORMATION_TABLE_KEYS,
} from "@frontend/common";

import {
  ContingentInvestmentVfKey,
  DealsSection,
  LicensorTableKey,
  RightsScopeKey,
  VentureFormationTermsKey,
} from "@/types/deals.types";
import { KEY_DEALS_GROUP_SHELL } from "@FillInTableContainer/shared/constants/dealsKey.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorShellReturn {
  handleClickToClearLicenseeTableShell: () => void;
  handleKeyDownToClearLicenseeTableShell: (event: KeyboardEvent) => void;
}

interface PositionInfoProps {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  tableKey: string | null;
  tableIndex: number;
  shellKey: string;
  shellIndex: number;
}

export const useHandleVentureFormationLicenseeTableShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const handleClearShell = useCallback(() => {
    const isLicensor =
      info.shellKey === ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName ||
      info.shellKey ===
        ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights;

    const clearDealPurposeGroupShell = (): ArticleEntryData => {
      const additionalInformationTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...additionalInformationTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal]: {
              ...additionalInformationTableData?.[
                ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const clearLicensorTable = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData?.[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSOR_TABLE]: {
            ...entryData?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
              ENTRY_DATA_LICENSOR_TABLE
            ],
            [info.shellKey as LicensorTableKey]: {
              ...entryData?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
                ENTRY_DATA_LICENSOR_TABLE
              ]?.[info.shellKey as LicensorTableKey],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const clearLicenseeNameShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]: {
                  ...tableInfo[ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName],
                  value: [],
                },
              };
            }
            return tableInfo;
          }),
        },
      };
    };

    const clearAdditionalInformationTable = (): ArticleEntryData => {
      const additionalInformationTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...additionalInformationTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.additionalInformation]:
              {
                ...additionalInformationTableData?.[
                  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS
                    .additionalInformation
                ],
                value: [],
              },
          },
        },
      } as ArticleEntryData;
    };

    const clearMergeShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData.Deals?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                  ],
                  [info.shellKey as VentureFormationTermsKey]: {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                    ]?.[info.shellKey as VentureFormationTermsKey],
                    value: [],
                  },
                },
              };
            }
            return tableInfo;
          }),
        },
      };
    };

    const clearContingentShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData.Deals?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                  ],
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentAndDetailVf]:
                    tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                    ][
                      ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                        .contingentInvestmentAndDetailVf
                    ].map((subGroupShellInfo, subGroupShellInfoIndex) => {
                      if (info.subGroupShellIndex === subGroupShellInfoIndex)
                        return {
                          ...subGroupShellInfo,
                          [info.shellKey as ContingentInvestmentVfKey]: {
                            ...subGroupShellInfo[
                              info.shellKey as ContingentInvestmentVfKey
                            ],
                            value: [],
                          },
                        };

                      return subGroupShellInfo;
                    }),
                },
              };
            }
            return tableInfo;
          }),
        },
      };
    };

    const clearRightScopeShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableInfo[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                ].map((groupShellInfo, groupShellIndex) => {
                  if (groupShellIndex === info.groupShellIndex) {
                    return {
                      ...groupShellInfo,
                      [info.shellKey as RightsScopeKey]: {
                        ...groupShellInfo?.[info.shellKey as RightsScopeKey],
                        value: [],
                      },
                    };
                  }

                  return groupShellInfo;
                }),
              };
            }
            return tableInfo;
          }),
        },
      } as ArticleEntryData;
    };

    if (info.shellKey === VENTURE_FORMATION_SHELL_KEYS.licenseeName) {
      setEntryData(clearLicenseeNameShell());
      changeCurrentHistory({
        entryData: clearLicenseeNameShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    const ventureFormationShellKeyList: string[] = [
      VENTURE_FORMATION_SHELL_KEYS.initialInvestment,
      VENTURE_FORMATION_SHELL_KEYS.totalInvestmentVf,
      VENTURE_FORMATION_SHELL_KEYS.ownershipStructureDetails,
      VENTURE_FORMATION_SHELL_KEYS.disclosedPaymentsSumVf,
      VENTURE_FORMATION_SHELL_KEYS.otherFinancialTermsVf,
      VENTURE_FORMATION_SHELL_KEYS.licenseeResponsibilityAndRightsVf,
    ];

    const isVentureFormationTable = ventureFormationShellKeyList.includes(
      info.shellKey || "",
    );

    if (isVentureFormationTable) {
      setEntryData(clearMergeShell());
      changeCurrentHistory({
        entryData: clearMergeShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    const contingentShellKeyList: string[] = [
      VENTURE_FORMATION_SHELL_KEYS.contingentInvestment,
      VENTURE_FORMATION_SHELL_KEYS.contingentDetail,
    ];

    const isContingentShell = contingentShellKeyList.includes(
      info.shellKey || "",
    );

    if (isContingentShell) {
      setEntryData(clearContingentShell());
      changeCurrentHistory({
        entryData: clearContingentShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    if (
      info.tableKey === VENTURE_FORMATION_TABLE_KEYS.dealPurpose &&
      info.groupShellKey === VENTURE_FORMATION_GROUP_SHELL_KEYS.dealPurpose
    ) {
      setEntryData(clearDealPurposeGroupShell());
      changeCurrentHistory({
        entryData: clearDealPurposeGroupShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (info.tableKey === VENTURE_FORMATION_TABLE_KEYS.additionalInformation) {
      setEntryData(clearAdditionalInformationTable());
      changeCurrentHistory({
        entryData: clearAdditionalInformationTable(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    if (info.groupShellKey === KEY_DEALS_GROUP_SHELL.rightsScope) {
      setEntryData(clearRightScopeShell());
      changeCurrentHistory({
        entryData: clearRightScopeShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    if (isLicensor) {
      setEntryData(clearLicensorTable());
      changeCurrentHistory({
        entryData: clearLicensorTable(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClickToClearLicenseeTableShell = () => {
    if (focusStatus.type === "clear") {
      handleClearShell();
    }
  };

  const handleKeyDownToClearLicenseeTableShell = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearShell();
      }
    },
    [focusStatus.type, handleClearShell],
  );

  return {
    handleClickToClearLicenseeTableShell,
    handleKeyDownToClearLicenseeTableShell,
  };
};
