import { Suspense } from "react";

import Dim from "@/components/shared/ui/Dim/Dim";
import Loader from "@/components/shared/ui/Loader/Loader";
import { setDealTypeDisplayName } from "@utils/returnDealTypeDisplayName/returnDealTypeDisplayName";

import CtgListTable from "./components/CtgListTable/CtgListTable";
import CtgListTableFilter from "./components/CtgListTableFilter/CtgListTableFilter";
import Pagination from "./components/Pagination/Pagination";
import * as S from "./CtgListContainer.style";
import { useSetScrollPosition } from "./hooks/useSetScrollPosition";

const CtgListContainer = () => {
  useSetScrollPosition();
  setDealTypeDisplayName();

  return (
    <S.PressListWrapper>
      <Suspense
        fallback={
          <Dim>
            <Loader />
          </Dim>
        }
      >
        <CtgListTableFilter />
        <CtgListTable />
        <Pagination />
      </Suspense>
    </S.PressListWrapper>
  );
};

export default CtgListContainer;
