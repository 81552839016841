import {
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_SHELL_KEYS as DRUG_PROFILE_GROUP_SHELL_KEYS,
  ENTRY_DATA_DRUG_PROFILE_TABLE_KEYS as DRUG_PROFILE_TABLE_KEYS,
} from "@frontend/common";

import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import { TableCreatorTableList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface VentureCapitalDrugProfileLayout {
  groupTableKey: VentureCapitalGroupTable;
  tableList: TableCreatorTableList;
}

export const VENTURE_CAPITAL_DRUG_PROFILE_GROUP_TABLE_LAYOUT: VentureCapitalDrugProfileLayout =
  {
    groupTableKey: DRUG_PROFILE,
    tableList: [
      {
        tableKey: DRUG_PROFILE_TABLE_KEYS.drug,
        groupShellList: [
          {
            groupShellKey: DRUG_PROFILE_GROUP_SHELL_KEYS.drugName,
            shellList: [
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.brandName,
              },
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.innName,
              },
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.codeName,
              },
            ],
          },
          {
            groupShellKey: DRUG_PROFILE_GROUP_SHELL_KEYS.none,
            shellList: [
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.roa,
              },
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.modality,
              },
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.target,
              },
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.moa,
              },
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.targetIndication,
              },
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
              },
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.phase,
              },
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
              },
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.refProductName,
              },
              {
                shellKey: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
              },
            ],
          },
        ],
      },
    ],
  };
