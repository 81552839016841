import { CTGEntryData } from "@/types/ctgEntryData.types";
import { apiClient } from "@api/apiClient";

import {
  GetEntryDataResponse,
  PutEntryDataResponse,
} from "./ctgEntryData.interface";

const API_ENDPOINT = {
  getEntryData: "/des/article-entry-data/info",
  updateEntryData: "/des/article-entry-data/update",
};

export const ctgEntryDataApis = {
  getEntryData: async (id: string) => {
    const { data } = await apiClient.get<GetEntryDataResponse>(
      `${API_ENDPOINT.getEntryData}/${id}`,
    );

    return data;
  },
  updateEntryData: async (
    id: string,
    entryData: CTGEntryData,
    isDbSaved: boolean,
    sectionCode: string,
  ) => {
    const { data } = await apiClient.put<PutEntryDataResponse>(
      API_ENDPOINT.updateEntryData,
      {
        draftArticleId: id,
        entryData,
        articleSection: sectionCode,
        isDbSaved,
      },
    );

    return data;
  },
};
