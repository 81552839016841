import { Suspense, useEffect, useRef } from "react";

import { useCTGListRoles } from "@libs/roles/hooks/useCTGListRoles";
import { usePressClassificationRoles } from "@libs/roles/hooks/usePressClassificationRoles";
import { usePressIdFromURL } from "@utils/getStateFromURL";

import * as S from "./UnsuitableButton.style";
import { useContentInfo } from "../../hooks/useContentInfo";
import { useHandleUnsuitablePress } from "../../hooks/useHandleUnsuitablePress";
import { UnsuitableReasonUsageType } from "../../types/unsuitableReason.types";
import UnsuitableReasonModalContainer from "../UnsuitableReasonModalContainer/UnsuitableReasonModalContainer";

type Props = {
  type?: UnsuitableReasonUsageType;
};

const TYPE_TO_TEXT: Record<UnsuitableReasonUsageType, string> = {
  PRESS_DETAIL: "The Unsuitable Press",
  CTG_DETAIL: "The Unsuitable CTG",
  PRESS_LIST: "The Unsuitable Press",
  CTG_LIST: "The Unsuitable CTG",
};

const UnsuitableButton = ({ type = "PRESS_DETAIL" }: Props) => {
  const draftArticleId = usePressIdFromURL();
  const { data: articleInfo } = useContentInfo(Number(draftArticleId));
  const unsuitableRef = useRef<HTMLDivElement>(null);

  const { hasUnsuitablePressRoles } = usePressClassificationRoles();
  const { hasUnsuitableCTGRoles } = useCTGListRoles();

  const ROLES_BY_TYPE = {
    PRESS_DETAIL: hasUnsuitablePressRoles,
    CTG_DETAIL: hasUnsuitableCTGRoles,
    PRESS_LIST: hasUnsuitablePressRoles,
    CTG_LIST: hasUnsuitableCTGRoles,
  };

  const duplArticleId = articleInfo?.duplArticleId || null;
  const articleRejectReason = articleInfo?.articleRejectReason || null;
  const isRejectedReason = !!articleRejectReason;

  const targetRejectReason = articleRejectReason
    ? `${articleRejectReason}${duplArticleId ? ` (${duplArticleId})` : ""}`
    : null;

  const {
    openModalUnsuitablePress,
    closeModalUnsuitablePress,
    isOpenModalUnsuitablePress,
  } = useHandleUnsuitablePress({ type });

  const handleUnsuitableButtonActive = async () => {
    if (isOpenModalUnsuitablePress) return closeModalUnsuitablePress();

    openModalUnsuitablePress();
  };

  useEffect(() => {
    const handleCheckClickOutsideUnsuitable = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const clickReasonButton = unsuitableRef.current?.contains(target);
      const deleteReasonButton = target.dataset.button;

      if (clickReasonButton) return;
      if (deleteReasonButton) return;

      closeModalUnsuitablePress();
    };

    window.addEventListener("click", handleCheckClickOutsideUnsuitable);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideUnsuitable);
  }, [closeModalUnsuitablePress]);

  return (
    <>
      <S.UnsuitableButtonWrapper
        ref={unsuitableRef}
        isEnableUnsuitablePress={isRejectedReason}
        disabled={!hasUnsuitablePressRoles}
      >
        <S.UnsuitableButton
          type="button"
          onClick={handleUnsuitableButtonActive}
          isEnableUnsuitablePress={isRejectedReason}
          disabled={!ROLES_BY_TYPE[type]}
        >
          <>{isRejectedReason ? targetRejectReason : TYPE_TO_TEXT[type]}</>
        </S.UnsuitableButton>
        <Suspense fallback={<></>}>
          <UnsuitableReasonModalContainer
            closeModalUnsuitablePress={closeModalUnsuitablePress}
            isOpenModalUnsuitablePress={isOpenModalUnsuitablePress}
            articleRejectReason={articleRejectReason}
            unsuitableReasonUsageType={type}
            draftArticleId={draftArticleId}
          />
        </Suspense>
      </S.UnsuitableButtonWrapper>
    </>
  );
};

export default UnsuitableButton;
