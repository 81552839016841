import { ENTRY_DATA_SECTION_KEYS } from "@frontend/common";

import { ClinicalTrials } from "@/types/clinicalTrials.types";
import { ClinicalTrialStatus } from "@/types/clinicalTrialStatus.types";
import { Deals } from "@/types/deals.types";
import { ExpeditedProgram } from "@/types/expeditedProgram.types";
import { Regulatory } from "@/types/regulatory.types";
import { VentureCapital } from "@/types/ventureCapital.types";
import { useUpdateEntryDataMutate } from "@api/entryData/entryData";
import { EXPEDITED_PROGRAM } from "@FillInTableContainer/shared/constants/expeditedProgramKey.constants";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useSectionCodeToClassification } from "./useSectionCode";

export const useUpdateEntryData = () => {
  const { mutateAsync: updateEntryDataMutate } = useUpdateEntryDataMutate(
    getPressIdFromURL(),
  );

  const sectionCode = useSectionCodeToClassification();

  const updateEntryData = async (
    entryData: Partial<{
      "Clinical Trials": Partial<ClinicalTrials>;
      "Clinical Trial Status": Partial<ClinicalTrialStatus>;
      Regulatory: Partial<Regulatory>;
      Deals: Partial<Deals>;
      [EXPEDITED_PROGRAM]: Partial<ExpeditedProgram>;
      [ENTRY_DATA_SECTION_KEYS.ventureCapital]: Partial<VentureCapital>;
    }>,
    isDbSaved?: boolean,
  ) => {
    await updateEntryDataMutate({
      entryData,
      isDbSaved: isDbSaved || false,
      sectionCode,
    });
  };

  return updateEntryData;
};
