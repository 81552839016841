import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import { CTG_QUERY_KEYS } from "@api/ctg/queryKey";
import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import { ROUTE_PATHS } from "@routes/routePath";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastFail, toastSuccess } from "@utils/toast";

import { unsuitablePressApis } from "./unsuitablePress.service";

export const useUnsuitablePressCancelMutation = () => {
  const location = useLocation();
  const isPressList = location.pathname === ROUTE_PATHS.pressList;
  const toastPosition = isPressList ? "bottomLeft" : "topCenter";
  const currentInfoQueryKey = isPressList
    ? [QUERY_KEYS.articleInfo, Number(getPressIdFromURL())]
    : [CTG_QUERY_KEYS.ctgInfo, Number(getPressIdFromURL())];

  const mutation = useMutation({
    mutationFn: (draftArticleId: number) => {
      return unsuitablePressApis.put(String(draftArticleId));
    },
    onSuccess: async () => {
      if (!isPressList) {
        await queryClient.refetchQueries({
          queryKey: currentInfoQueryKey,
        });
      }
      toastSuccess(
        "Unsuitable press successfully released",
        "green",
        toastPosition,
      );
    },
    onError: () => {
      toastFail("Failed to update unsuitable reason", toastPosition);
    },
  });

  return mutation;
};

export const useOverlappedPressCancelMutation = () => {
  const location = useLocation();
  const isPressList = location.pathname === ROUTE_PATHS.pressList;

  const mutation = useMutation({
    mutationFn: async (draftArticleId: number) => {
      const response = await unsuitablePressApis.put(String(draftArticleId));
      return response;
    },
    onSuccess: async (response) => {
      if (!isPressList) {
        const articleId = getPressIdFromURL();
        await queryClient.refetchQueries({
          queryKey: [QUERY_KEYS.articleInfo, Number(articleId)],
        });
      }
      if (response.code !== 200) {
        toastFail(response.message);
        throw new Error(`${response.message}`);
      }
    },
  });

  return mutation;
};
