import { useCallback, useEffect, useRef } from "react";

import {
  useArticleInfoMutation,
  useArticleInfoQuery,
} from "@api/articleInfo/useArticleInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { FILL_IN_TABLE_KEY } from "@FillInTableContainer/shared/constants/fillInTableKey";
import {
  useTableCreatorCurrentHistory,
  DEFAULT_TABLE_CREATOR_HISTORY_POSITION,
} from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import { ArticleSectionType } from "@FillInTableContainer/types/fillInTable.types";
import { useClassificationState } from "@stores/classification";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useEnterAttributionIdInEntryData } from "./useEnterAttributionIdInEntryData";

const HalfClassification: ArticleSectionType[] = [
  FILL_IN_TABLE_KEY.ClinicalTrials,
  FILL_IN_TABLE_KEY.ClinicalTrialStatus,
  FILL_IN_TABLE_KEY.Regulatory,
  FILL_IN_TABLE_KEY.ExpeditedProgram,
];

const fullClassification: ArticleSectionType[] = [
  FILL_IN_TABLE_KEY.Deals,
  FILL_IN_TABLE_KEY.VentureCapital,
];

export const useEntryFillInTablePageEffect = () => {
  const { mutate: updateArticleInfo } = useArticleInfoMutation();
  const { data: articleInfo } = useArticleInfoQuery();
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();

  const entryData = useEntryDataValue(getPressIdFromURL());

  useEnterAttributionIdInEntryData();

  const statusRef = useRef(articleInfo?.draftArticleStatus);
  const initialEntryDataRef = useRef(entryData);
  const articleSection = useArticleSection();

  const handleEntryPage = useCallback(() => {
    const status = statusRef.current;

    if (
      status === DRAFT_STATUS_CODE.aiEntryDone &&
      HalfClassification.includes(articleSection)
    )
      updateArticleInfo({
        draftArticleStatus: DRAFT_STATUS_CODE.reviewInProgressHalf,
      });

    if (
      status === DRAFT_STATUS_CODE.aiEntryDone &&
      fullClassification.includes(articleSection)
    ) {
      updateArticleInfo({
        draftArticleStatus: DRAFT_STATUS_CODE.reviewInProgress,
      });
    }
  }, [articleSection, updateArticleInfo]);

  const setClassificationState = useClassificationState();

  const savedClassification = useCallback(() => {
    if (articleInfo?.classificationManual) {
      return setClassificationState(articleInfo?.classificationManual);
    }

    return null;
  }, [articleInfo?.classificationManual, setClassificationState]);

  const settingInitialHistory = useCallback(() => {
    changeCurrentHistory({
      entryData: initialEntryDataRef.current,
      changedDataInfo: {
        target: "all",
        action: "initial",
        position: {
          ...DEFAULT_TABLE_CREATOR_HISTORY_POSITION,
        },
      },
    });
  }, [changeCurrentHistory]);

  useEffect(() => {
    settingInitialHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    savedClassification();
  }, [savedClassification]);

  useEffect(() => {
    handleEntryPage();
  }, [handleEntryPage]);
};
