import { useSafeLeavePage } from "@hooks/useSafeLeavePage";
import CTGFillInTableConcurrentAccessGuard from "@libs/concurrentAccess/components/CTGFillInTableConcurrentAccessGuard";
import CTGFillInTableRoleGuard from "@libs/roles/components/CTGFillInTableRoleGuard";

import CTGFillInTable from "./components/CTGFillInTable/CTGFillInTable";
import FillInTableProvider from "./components/FillInTableProvider/FillInTableProvider";

const CTGFillInTableContainer = () => {
  useSafeLeavePage();

  return (
    <CTGFillInTableRoleGuard>
      <CTGFillInTableConcurrentAccessGuard>
        <FillInTableProvider>
          <CTGFillInTable />
        </FillInTableProvider>
      </CTGFillInTableConcurrentAccessGuard>
    </CTGFillInTableRoleGuard>
  );
};

export default CTGFillInTableContainer;
