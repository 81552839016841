import React from "react";

import { useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import AccessDeniedGuide from "@organisms/AccessDeniedGuide/AccessDeniedGuide";

import { useAuthorityType } from "../hooks/useAuthorityType";
import { useEditorRoles } from "../hooks/useEditorRoles";
import { CTGFillInTableRoleChecker } from "../services/ctgFillInTableRoleChecker";

interface Props {
  children: React.ReactNode;
}

const CTGFillInTableRoleGuard = ({ children }: Props) => {
  const authorityType = useAuthorityType();
  const editorRoles = useEditorRoles();
  const { data: articleInfo } = useCTGInfoQuery();

  if (!articleInfo) {
    throw new Error("articleInfo is not defined");
  }

  const roleChecker = new CTGFillInTableRoleChecker({
    authorityType,
    editorRoles,
  });

  if (roleChecker.canAccess) {
    return <>{children}</>;
  } else {
    return <AccessDeniedGuide />;
  }
};

export default CTGFillInTableRoleGuard;
