import { CtgSearchFilterType } from "@/components/business/CtgListContainer/ctgList.types";

export const CTG_LIST_EDITOR_INFO_FILTER: CtgSearchFilterType[] = [
  {
    key: "allEditor",
    name: "All Editor",
    isAllInfo: true,
  },
  {
    key: "mcEditorName",
    name: "MC Editor",
    code: "S112",
    isAllInfo: false,
  },
  {
    key: "ftEditorName",
    name: "FT Editor",
    code: "S911",
    isAllInfo: false,
  },
];

export const CTG_LIST_EDITOR_INFO_FILTER_KEY = [
  "allEditor",
  "mcEditorName",
  "ftEditorName",
] as const;
