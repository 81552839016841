import React, { Dispatch, SetStateAction } from "react";

import IconXMark from "@images/pressClassification/icon-reason-x-mark.svg";

import {
  UNSUITABLE_REASON_TYPE_INFO,
  UnsuitableReasonList,
  UnsuitableReasonType,
} from "./constants/UnsuitableReason.constants";
import { useHandleUnsuitableReasonItem } from "./hooks/useHandleUnsuitableReasonItem";
import * as S from "./UnsuitableReasonCheckItem.style";
import { UnsuitableReasonUsageType } from "../../../../types/unsuitableReason.types";

export interface InfoForSelectedRejectReason
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  reasonId: string;
  reason: string | null;
  explanation?: string;
  reasonType: UnsuitableReasonType;
  placeholder?: string;
  isTarget: boolean;
  duplId?: number | null;
  articleRejectReason: string | null;
  unsuitablePressReasonList: UnsuitableReasonList[];
  setUnsuitablePressReasonList: Dispatch<
    SetStateAction<UnsuitableReasonList[]>
  >;
  draftArticleId: number;
  unsuitableReasonUsageType: UnsuitableReasonUsageType;
  closeModalUnsuitablePress?: () => void;
}

const UnsuitableReasonCheckItem = ({
  reasonId,
  reason,
  explanation,
  reasonType,
  placeholder,
  isTarget,
  duplId,
  articleRejectReason,
  unsuitablePressReasonList,
  setUnsuitablePressReasonList,
  draftArticleId,
  unsuitableReasonUsageType,
  closeModalUnsuitablePress,
}: InfoForSelectedRejectReason) => {
  const infoForSelectedRejectReason = {
    reasonId,
    reason,
    explanation,
    reasonType,
    placeholder,
    isTarget,
    duplId,
    articleRejectReason,
    unsuitablePressReasonList,
    setUnsuitablePressReasonList,
    draftArticleId,
    unsuitableReasonUsageType,
    closeModalUnsuitablePress,
  };
  const {
    handleCheckTargetReason,
    handleDeleteReasonInput,
    handleClickCheckBox,
    handleKeyDownEnterSavedReason,
    handleFocusOutAddIdReason,
    inputRef,
  } = useHandleUnsuitableReasonItem(infoForSelectedRejectReason);

  const handleReturnDefaultValue = () => {
    if (reasonType === "addId") return duplId;
    if (reasonType === "writing") return reason;
  };

  const isNotDefaultType =
    reasonType === UNSUITABLE_REASON_TYPE_INFO.writing ||
    reasonType === UNSUITABLE_REASON_TYPE_INFO.addId;

  const isNotFocusedInput =
    inputRef.current && reasonType !== "default" && !isTarget;

  if (isNotFocusedInput) inputRef.current.value = "";

  const handleCheckNumberInput = () => {
    if (!inputRef.current) return;

    inputRef.current.value = inputRef.current.value.replace(/[^0-9]/g, "");
  };

  return (
    <S.CheckItemLabelWrapper
      onChange={() => handleCheckTargetReason(reasonType)}
    >
      <S.Checkbox
        type="checkbox"
        checked={isTarget}
        onClick={() => handleClickCheckBox(reasonType)}
      />
      <S.CheckItemLabel isTarget={isTarget}>
        {isNotDefaultType ? (
          <>
            {explanation} <br />
            <S.ReasonInputWrapper $isFilled={!!inputRef.current?.value}>
              <S.ReasonInput
                type="text"
                placeholder={placeholder}
                onInput={
                  reasonType === "addId" ? handleCheckNumberInput : undefined
                }
                onKeyDown={handleKeyDownEnterSavedReason}
                onBlur={() => handleFocusOutAddIdReason(reasonType)}
                defaultValue={handleReturnDefaultValue() || ""}
                maxLength={30}
                ref={inputRef}
              />
              {isTarget && (
                <S.ResetButton
                  data-button="delete reason button"
                  onClick={() => handleDeleteReasonInput(reasonType)}
                  type="button"
                >
                  <img
                    data-button="delete reason button"
                    src={IconXMark}
                    alt="delete reason"
                  />
                </S.ResetButton>
              )}
            </S.ReasonInputWrapper>
          </>
        ) : (
          reason
        )}
      </S.CheckItemLabel>
    </S.CheckItemLabelWrapper>
  );
};

export default UnsuitableReasonCheckItem;
