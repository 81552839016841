import React from "react";

import FooterFnb from "./components/FooterFnb/FooterFnb";
import * as S from "./FooterBottom.style";
import { RouterType } from "../../../../../../../types";

const COPY_RIGHTS = `${new Date().getFullYear()} © BioResearchAI.Co.,Ltd. All rights reserved.`;
const COMPANY_INFO_ADDRESS =
  "BioResearchAI.Co.,Ltd.  |  18, Teheran-ro 10-gil, 2F, Gangnam-gu, Seoul, Republic of Korea";
const COMPANY_INFO_BUSINESS_NUMBER =
  "Business Registration Number: 290-88-02458  |  CEO: Jason Lee";

type Props = {
  router: RouterType;
};

const FooterBottom = ({ router }: Props) => {
  return (
    <S.FooterBottomWrapper>
      <S.FooterBottomContentsWrapper>
        <S.CompanyInfoWrapper>
          <S.CopyRights>{COPY_RIGHTS}</S.CopyRights>
          <S.CompanyAddress>{COMPANY_INFO_ADDRESS}</S.CompanyAddress>
          <S.CompanyAddress>{COMPANY_INFO_BUSINESS_NUMBER}</S.CompanyAddress>
        </S.CompanyInfoWrapper>
        <S.FooterFnbWrapper>
          <FooterFnb router={router} />
        </S.FooterFnbWrapper>
      </S.FooterBottomContentsWrapper>
    </S.FooterBottomWrapper>
  );
};

export default FooterBottom;
