import React from "react";

import { useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import { useAuthorityType } from "@libs/roles/hooks/useAuthorityType";
import UrlPageEntryGuide from "@organisms/UrlPageEntryGuide/UrlPageEntryGuide";

import { useHasFillInTableLock } from "../hooks/useHasFillInTableLock";
import { FillInTableConcurrentAccessChecker } from "../services/fillInTableConcurrentAccessChecker";

interface Props {
  children: React.ReactNode;
}

const CTGFillInTableConcurrentAccessGuard = ({ children }: Props) => {
  const { data: articleInfo } = useCTGInfoQuery();
  if (articleInfo === undefined) throw new Error("articleInfo is undefined");

  const { ftEditor2Name } = articleInfo;
  const currentUrl = window.location.href;
  const authorityType = useAuthorityType();

  const hasLock = useHasFillInTableLock({
    url: currentUrl,
    ftEditorName: ftEditor2Name,
  });

  const fillInTableConcurrentAccessChecker =
    new FillInTableConcurrentAccessChecker({
      hasLock,
      ftEditorName: ftEditor2Name,
      authorityType,
    });

  if (fillInTableConcurrentAccessChecker.canAccess) return <>{children}</>;
  else return <UrlPageEntryGuide />;
};

export default CTGFillInTableConcurrentAccessGuard;
