import styled from "styled-components";

type Props = {
  draggedPosition: { x: number; y: number };
  modalSizeHeight: number;
  modalSizeWidth: number;
};

export const FillInTableSimilarDataModalWrapper = styled.div.attrs<Props>(
  ({ draggedPosition, modalSizeHeight, modalSizeWidth }) => ({
    style: {
      minWidth: "89.6rem",
      minHeight: "32rem",
      width: `${modalSizeWidth}px`,
      height: `${modalSizeHeight}px`,
      maxWidth: "120rem",
      maxHeight: "76.8rem",
      backgroundColor: `#f0f2f4`,
      boxShadow: `0px 8px 16px 0px rgba(0, 0, 0, 0.16), 0px 0px 8px 0px rgba(0, 0, 0, 0.12)`,
      position: `fixed`,
      top: `${draggedPosition.y}px`,
      left: `${draggedPosition.x}px`,
      zIndex: 99,
      borderRadius: `0.4rem`,
      resize: `both`,
    },
  }),
)<Props>``;

export const FillInTableSimilarDataModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  height: 100%;
`;

export const FillInTableModalHeadLine = styled.div`
  width: 100%;
  height: 3.6rem;
  padding: 1.2rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.4rem 0.4rem 0 0;
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  position: absolute;
  cursor: grab;
`;

export const DragBox = styled.div`
  display: flex;
  max-height: var(--Size-Btn-Lg, 3rem);
  padding: var(--Spacing-4, 0.25rem) var(--Spacing-6, 0.375rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-Btn-4, 0.25rem);
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  text-align: center;
  font-family: var(--font-pretendard);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
`;

export const ModalTitle = styled.div`
  display: flex;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 auto;
`;

export const CloseBox = styled.button`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  cursor: pointer;

  span {
    color: var(--Color-Common-Text-Interactive-Destructive, #e53e06);
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

export const FillInTableContentContainer = styled.div<{ isResizing: boolean }>`
  width: 100%;
  height: ${({ isResizing }) => (isResizing ? "100%" : "auto")};
  max-height: ${({ isResizing }) => (isResizing ? "none" : "60rem")};
  overflow-y: visible;
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 1.2rem 1.2rem 1.2rem;
  border-radius: 0 0 0.4rem 0.4rem;
`;

export const ResizeBorder = styled.div<{
  isTopBorder?: boolean;
}>`
  position: absolute;
  width: 100%;
  text-align: end;
  bottom: ${({ isTopBorder }) => !isTopBorder && 0};
  cursor: nwse-resize;
`;
