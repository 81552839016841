import React, { ForwardedRef, HTMLAttributes, forwardRef } from "react";

import * as S from "./SelectField.style";
import { IconChevronDown, IconChevronUp, IconXMark } from "../../../Icons";

interface Props extends HTMLAttributes<HTMLInputElement> {
  placeholder: string;
  width?: string;
  height?: string;
  styleType: "line" | "transparent";
  status: "focus" | "error" | "disabled" | "default" | "filled";
  isFilledTextColor?: boolean;
  value?: string;
  svgDataId?: string;
  containerClickHandler?: () => void;
  leftIcon?: React.ReactNode;
  selectCount?: React.ReactNode;
  deleteValue?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SelectField = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder = "Placeholder",
      width = "24rem",
      height = "3.2rem",
      styleType = "line",
      status = "filled",
      isFilledTextColor = false,
      svgDataId,
      value,
      containerClickHandler,
      leftIcon,
      selectCount,
      deleteValue,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <S.SelectFieldWrapper
        $width={width}
        $height={height}
        $status={status}
        $styleType={styleType}
        $isEmpty={!value}
        onClick={containerClickHandler}
      >
        {leftIcon && (
          <S.IconWrapper className="left-icon">{leftIcon}</S.IconWrapper>
        )}
        <S.SelectFieldInput
          ref={ref}
          placeholder={placeholder}
          disabled={status === "disabled"}
          $isFilledTextColor={isFilledTextColor}
          value={value}
          readOnly
          {...rest}
        />
        {selectCount}
        {deleteValue && (
          <S.DeleteButton type="button" onClick={deleteValue}>
            <IconXMark width={24} height={24} color="#E53E06" />
          </S.DeleteButton>
        )}
        {status === "focus" ? (
          <S.IconWrapper>
            <IconChevronUp
              color="#0198E9"
              svgDataId={svgDataId}
              width={16}
              height={16}
            />
          </S.IconWrapper>
        ) : (
          <S.IconWrapper>
            <IconChevronDown
              width={16}
              height={16}
              color={
                status === "error" && styleType === "transparent"
                  ? "#E53E06"
                  : "#515C6C"
              }
              svgDataId={svgDataId}
            />
          </S.IconWrapper>
        )}
      </S.SelectFieldWrapper>
    );
  },
);

export default SelectField;
