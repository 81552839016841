import React from "react";

import { LIST_SECTION_TITLE } from "@/components/business/CTGFillInTableContainer/shared/components/TableCreatorListBox/constants/tableCreatorListBox.constants";
import { TargetListType } from "@/components/business/CTGFillInTableContainer/shared/hooks/useHandleTargetListIndex";

import * as S from "./TableCreatorAiDropdown.style";
import { AIDropdownListFormat } from "../../types/tableCreatorDropdown.types";

interface Props {
  targetItemRef: React.RefObject<HTMLLIElement>;
  aiListSet: AIDropdownListFormat[];
  targetListIndex: TargetListType;
  checkIndexOfValueInDefaultAiList: () => number;
  handleClickAIItem: (item: string) => void;
}

const TableCreatorAiDropdown = ({
  targetItemRef,
  aiListSet,
  targetListIndex,
  checkIndexOfValueInDefaultAiList,
  handleClickAIItem,
}: Props) => {
  const isAiTarget = targetListIndex.key === "AI";

  return (
    <S.ListWrapper>
      <S.ListSectionTitle>{LIST_SECTION_TITLE.ai}</S.ListSectionTitle>
      {aiListSet.map(({ list }) =>
        list.map((aiItem, itemIndex) => (
          <S.AiItem
            key={aiItem}
            data-dropdown="list-box"
            onMouseDown={() => handleClickAIItem(aiItem)}
            $isTarget={isAiTarget && itemIndex === targetListIndex.listIndex}
            ref={
              isAiTarget && itemIndex === targetListIndex.listIndex
                ? targetItemRef
                : null
            }
            $isDefaultAiValue={itemIndex === checkIndexOfValueInDefaultAiList()}
          >
            {aiItem}
          </S.AiItem>
        )),
      )}
    </S.ListWrapper>
  );
};

export default TableCreatorAiDropdown;
