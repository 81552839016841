import { useEffect } from "react";

import { toast } from "react-toastify";

import { showToast } from "@atoms/Toast/Toast";

type Props = {
  message: string;
};

const ToastMessageContainer = ({ message }: Props) => {
  useEffect(() => {
    // 모든 기존 토스트 제거(하나만 표출)
    toast.dismiss();

    showToast({
      icon: "error",
      backgroundColor: "hotPink",
      text: message,
      position: "bottomLeft",
      autoClose: 5000,
    });
  }, [message]);

  return <></>;
};

export default ToastMessageContainer;
