export const changeRAndDUpperCase = (dealType: string) => {
  return dealType.replace("r&d", "R&D");
};

export const changeDealTypeForDisplay = (dealType: string) => {
  if (dealType === "cross license agreement")
    return "license agreement(cross license)";

  return dealType.replace("r&d", "R&D");
};
