import { useCallback } from "react";

import { removeCodeInTitle } from "@/components/shared/utils/removeCodeInTitle/removeCodeInTitle";
import { ParsedJsonContentType } from "@/types/fillInTableContent.types";
import { usePressInfoQuery } from "@queries/hooks/usePressInfo";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { parseJSON } from "@utils/parseJSON";
import { toastFail, toastSuccess } from "@utils/toast";

type props = {
  contentTitle?: string;
};

export const useCopyPress = ({ contentTitle }: props) => {
  const pressId = getPressIdFromURL();
  const pressContentData = usePressInfoQuery(Number(pressId));
  const pressContent: ParsedJsonContentType[] = parseJSON(
    pressContentData?.data.pressContent || "",
  );
  const pressAllContent = pressContent
    .map((item) => {
      if (item.type === "table") return item.data;
      if (item.type === "image") return item.source;
      return item.data;
    })
    .join(`\n`);

  const pressTitle = removeCodeInTitle(contentTitle || "");

  const copyPressTitleAndTextContent = useCallback(
    (title: string, textContent: string) => {
      const textToCopy = `${title}\n\n${textContent}`;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toastSuccess("Press content copied successfully.");
        })
        .catch(() => {
          toastFail("Failed to copy Press content.");
        });
    },
    [],
  );

  return {
    copyPress: () => copyPressTitleAndTextContent(pressTitle, pressAllContent),
  };
};
