import { IconXMark } from "@frontend/common";

import { ProcessStatusCode } from "@/types/code.types";

import * as S from "./SearchFilterTag.style";

interface Props {
  type: "search" | "result";
  code?: ProcessStatusCode;
  isAllInfo: boolean;
  isClassification?: boolean;
  isUserInput?: boolean;
  text: string;
  onClickDeleteButton?: () => void;
  aiSelected?: boolean;
  isEditor?: boolean;
}

const SearchFilterTag = ({
  type = "search",
  isAllInfo,
  code,
  text,
  isClassification,
  isUserInput,
  onClickDeleteButton,
  aiSelected,
  isEditor = false,
}: Props) => {
  return (
    <S.FilterTagWrapper
      type={type}
      code={code}
      isAllInfo={isAllInfo}
      isUserInput={isUserInput}
      isClassification={isClassification}
      aiSelected={aiSelected}
      onClick={onClickDeleteButton}
      isEditor={isEditor}
    >
      <S.FilterTagText
        code={code}
        type={type}
        isAllInfo={isAllInfo}
        isUserInput={isUserInput}
        isEditor={isEditor}
      >
        {text}
      </S.FilterTagText>
      {type === "result" && (
        <S.FilterDeleteButton type="button">
          <IconXMark width={16} height={16} color="#E53E06" />
        </S.FilterDeleteButton>
      )}
    </S.FilterTagWrapper>
  );
};

export default SearchFilterTag;
