import { useCallback, useState } from "react";

import { CopyPressButton } from "@frontend/common";
import { useNavigate } from "react-router-dom";

import BottomButtonContainer from "@/components/shared/ui/BottomButtonContainer/BottomButtonContainer";
import PressTitle from "@/components/shared/ui/PressTitle/PressTitle";
import TopFixInfo from "@/components/shared/ui/TopFixInfo/TopFixInfo";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { useCopyPress } from "@hooks/useCopyPress/useCopyPress";
import IconArrowLeft from "@images/common/icon-arrow-left.svg";
import { usePressClassificationRoles } from "@libs/roles/hooks/usePressClassificationRoles";
import PublishablePressCheckbox from "@organisms/PublishablePressCheckbox/PublishablePressCheckbox";
import { ROUTE_PATHS } from "@routes/routePath";
import { usePressIdFromURL } from "@utils/getStateFromURL";

import MoveFillInTableButton from "./components/MoveFillInTableButton/MoveFillInTableButton";
import PressClassificationPress from "./components/PressClassificationPress/PressClassificationPress";
import PressLabelInfoList from "./components/PressLabelInfoList/PressLabelInfoList";
import * as S from "./PressClassificationContainer.style";
import UnsuitableButton from "../../shared/business/UnsuitableReason/components/UnsuitableButton/UnsuitableButton";

const DISABLED_STATUS_MANUAL_CLASSIFICATION: string[] = [
  DRAFT_STATUS_CODE.aiEntryInProgress,
];

const PressClassificationContainer = () => {
  const navigate = useNavigate();
  const [currentSize, setCurrentSize] = useState({
    width: 0,
    y: 0,
  });

  const draftArticleId = usePressIdFromURL();
  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const pressStatus = articleInfo?.draftArticleStatusHighest;
  const classificationManual = articleInfo?.classificationManual;

  const { hasManualClassificationRoles } = usePressClassificationRoles();

  const { copyPress } = useCopyPress({ contentTitle: articleInfo?.pressTitle });

  const disabledClassificationManual =
    DISABLED_STATUS_MANUAL_CLASSIFICATION.includes(pressStatus || "");

  const movePrev = async () => {
    navigate(ROUTE_PATHS.pressList);
  };

  const handleCurrentPressSize = useCallback((element: HTMLDivElement) => {
    if (!element) return;

    setCurrentSize({
      width: element.getBoundingClientRect().width,
      y: element.getBoundingClientRect().y + 16,
    });
  }, []);

  const additionalBoldKeywords = articleInfo?.companyName
    ? [articleInfo.companyName]
    : [];

  return (
    <S.LabelingPressWrapper>
      <TopFixInfo
        disabledClassificationManual={disabledClassificationManual}
        hasManualClassificationRoles={hasManualClassificationRoles}
      />
      <S.PressInfoContainer>
        <PressLabelInfoList title="Label Info" />
        <PressTitle
          contentTitle={articleInfo?.pressTitle}
          contentUrl={articleInfo?.pressUrl}
        />
        <CopyPressButton className="copy-press-button" onClick={copyPress} />
      </S.PressInfoContainer>
      <S.ContentsWrapper>
        <S.LabelingPressContainer ref={handleCurrentPressSize}>
          <S.PressContentScrollWrapper>
            <PressClassificationPress
              columnLabelViewerSize={currentSize}
              classificationManual={classificationManual}
              additionalBoldKeywords={additionalBoldKeywords}
            />
          </S.PressContentScrollWrapper>
        </S.LabelingPressContainer>
      </S.ContentsWrapper>
      <BottomButtonContainer>
        <S.LeftButtonWrapper>
          <S.PrevButton onClick={movePrev}>
            <img src={IconArrowLeft} alt="go to press list" />
            Press List
          </S.PrevButton>
          <UnsuitableButton />
          <PublishablePressCheckbox />
        </S.LeftButtonWrapper>
        <S.RightButtonToGoNextPageWrapper>
          <MoveFillInTableButton />
        </S.RightButtonToGoNextPageWrapper>
      </BottomButtonContainer>
    </S.LabelingPressWrapper>
  );
};

export default PressClassificationContainer;
