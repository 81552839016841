import { useCallback, useEffect, useRef } from "react";

import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";
import { useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import { useCTGEntryDataState } from "@stores/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useUpdateCTGEntryData } from "../../../shared/hooks/useUpdateCTGEntryData";
import {
  CHECK_ATTRIBUTION_ID_IN_ENTRY_DATA,
  CHECK_ENTRY_DATA_FORMAT,
} from "../constants/entryDataFormat.constants";

export const useEnterAttributionIdInEntryData = () => {
  const { data: articleInfo } = useCTGInfoQuery(Number(getPressIdFromURL()));

  const articleSection = articleInfo ? CTG_SECTION : "none";

  const [entryData, setEntryData] = useCTGEntryDataState(getPressIdFromURL());
  const updateEntryData = useUpdateCTGEntryData();

  const entryDataRef = useRef(entryData);

  const enterAttributionIdInEntryData = useCallback(() => {
    if (articleSection === "none") return;

    const checkAttributionIdInEntryData =
      CHECK_ATTRIBUTION_ID_IN_ENTRY_DATA[articleSection](entryData);

    if (checkAttributionIdInEntryData) return;
    const entryDataWithAttributionId = CHECK_ENTRY_DATA_FORMAT[articleSection](
      entryDataRef.current,
    );

    setEntryData(entryDataWithAttributionId);
    updateEntryData(entryDataWithAttributionId);
  }, [articleSection, entryData, setEntryData, updateEntryData]);

  useEffect(() => {
    enterAttributionIdInEntryData();
    // DESCRIPTIONㄴ: 처음 렌더링 된 경우만 실행
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
