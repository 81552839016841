import { useNavigate } from "react-router-dom";

import { useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import Modal from "@atoms/Modal/Modal";
import { usePreventScrollModalBackground } from "@hooks/usePreventScrollModalBackground";
import { usePressListRedirectRoles } from "@libs/roles/hooks/usePressListRedirectRoles";

import * as S from "./CtgGuideModal.style";

interface Props {
  closeModal: () => void;
  draftArticleId: number;
}

const CtgListGuideModal = ({ closeModal, draftArticleId }: Props) => {
  const navigate = useNavigate();
  const { data: pressInfo } = useCTGInfoQuery(draftArticleId);
  const { checkPressListRedirect } = usePressListRedirectRoles();

  const redirectPath =
    pressInfo && checkPressListRedirect(pressInfo.draftArticleStatus);

  const isEnableRedirect = !!redirectPath;

  const handleRedirectEntryPage = () => {
    navigate(`${redirectPath}?id=${draftArticleId}`);
  };

  usePreventScrollModalBackground();

  return (
    <Modal closeModal={closeModal}>
      <S.PressListEntryModalWrapper>
        <S.ModalTitle>Access is not available</S.ModalTitle>
        {isEnableRedirect ? (
          <S.ModalText isEnableRedirect={isEnableRedirect}>
            Your current permissions do not allow access to the selected press.
            Please contact an administrator to request the necessary
            permissions. You can{" "}
            <S.PressClassificationLink
              type="button"
              onClick={handleRedirectEntryPage}
            >
              view the press
            </S.PressClassificationLink>{" "}
            within your granted access.
          </S.ModalText>
        ) : (
          <S.ModalText isEnableRedirect={isEnableRedirect}>
            Your current permissions don&apos;t allow access to the selected
            press. Contact an administrator for the required permissions.
          </S.ModalText>
        )}
        <S.ModalButtonWrapper>
          <S.RefreshButton type="button" onClick={closeModal}>
            OK
          </S.RefreshButton>
        </S.ModalButtonWrapper>
      </S.PressListEntryModalWrapper>
    </Modal>
  );
};

export default CtgListGuideModal;
