import styled from "styled-components";

export const FilterSelectWrapper = styled.div`
  position: relative;
`;

export const SelectButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    margin-left: 0.6rem;
  }
`;

export const FilterListTitle = styled.p`
  display: flex;
  align-items: center;
  height: 1.6rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 145%;
  color: #2a2f37;
`;

export const DropDown = styled.ul`
  display: flex;
  position: absolute;
  flex-direction: column;
  z-index: 20;
  gap: 0.4rem;
  width: 12rem;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.neural.gray05};
  border-radius: 0.4rem;
  padding: 0.8rem;
  margin-top: 0.4rem;
`;
