import styled from "styled-components";

export const TopFixInfoListWrapper = styled.div<{
  $minWidth: string;
  $maxWidth: string;
}>`
  display: flex;
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};
  height: 100%;
`;
