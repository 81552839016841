import { Suspense } from "react";

import {
  ParagraphSectionLayout,
  ParagraphTitle,
  ARTICLE_DETAIL_DEAL_TERMS_PARAGRAPH_TITLE as PARAGRAPH_TITLE,
  DEAL_TYPE_KEYS,
} from "@frontend/common";

import { useEntryDataQuery } from "@api/entryData/entryData";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";

import CrossLicenseAgreementTable from "./components/CrossLicenseAgreementTable/CrossLicenseAgreementTable";
import LicenseAgreementTable from "./components/LicenseAgreementTable/LicenseAgreementTable";
import MergeAndAcquisitionTable from "./components/MergeAndAcquisitionTable/MergeAndAcquisitionTable";
import VentureFormationTable from "./components/VentureFormationTable/VentureFormationTable";
import * as S from "./ParagraphDealTerms.styles";
import { getDealType } from "./utils/getDealType";

const ParagraphDealTerms = () => {
  const [updateParagraph, loadingIndex, updateKeyMap] = useUpdateParagraph({
    paragraphCode: "TC111",
    sectionCode: "TY002",
  });
  const { data: entryData } = useEntryDataQuery();

  const dealType = getDealType(entryData);

  const components = () => {
    if (dealType === DEAL_TYPE_KEYS.mergeAndAcquisition) {
      return <MergeAndAcquisitionTable />;
    }
    if (dealType === DEAL_TYPE_KEYS.licenseAgreement) {
      return <LicenseAgreementTable />;
    }
    if (dealType === DEAL_TYPE_KEYS.strategicRAndDCollaboration) {
      return <LicenseAgreementTable noBackgroundArrow />;
    }
    if (dealType === DEAL_TYPE_KEYS.crossLicenseAgreement) {
      return <CrossLicenseAgreementTable />;
    }
    if (dealType === DEAL_TYPE_KEYS.ventureFormation) {
      return <VentureFormationTable />;
    }
    return <LicenseAgreementTable />;
  };

  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={PARAGRAPH_TITLE} />
      <S.ParagraphDealTermsWrapper key={updateKeyMap.get(0)}>
        <S.ParagraphDealTermsUpdateButton
          disabled={loadingIndex === 0}
          onClick={() => updateParagraph(0)}
        />
        <Suspense>{components()}</Suspense>
      </S.ParagraphDealTermsWrapper>
    </ParagraphSectionLayout>
  );
};

export default ParagraphDealTerms;
