import { UNSUITABLE_REASON_KEYS } from "../../../../../constants/unsuitableReason.constants";
import { UnsuitableReasonUsageType } from "../../../../../types/unsuitableReason.types";

export type UnsuitableReasonType = "default" | "writing" | "addId";

export interface UnsuitableReasonList {
  id: string;
  reason: string | null;
  placeholder?: string;
  explanation?: string;
  defaultReason?: string;
  duplId?: number | null;
  type: UnsuitableReasonType;
  isTarget: boolean;
}

export const DEFAULT_VALUE_FOR_ADD_ID_TYPE = {
  overlappedPress: "Overlapped press without merging",
  overlappedPressWithMerging: "Overlapped press with merging",
  dealDataIgnore: "Deal data ignore",
};

export const ADD_ID_TYPE_VALUES = Object.values(DEFAULT_VALUE_FOR_ADD_ID_TYPE);

export const UNSUITABLE_REASON_TYPE_INFO: Record<string, UnsuitableReasonType> =
  {
    default: "default",
    writing: "writing",
    addId: "addId",
  };

const PRESS_UNSUITABLE_REASON_LIST: UnsuitableReasonList[] = [
  {
    id: "S930-10",
    reason: "venture formation (spin out, joint venture)",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-16",
    reason: "Opening new round",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-11",
    reason: "three party deal",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-15",
    reason: "comment or celebration on deal",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-12",
    reason: "closing, completion",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-13",
    reason: "update, amendment, termination, term sheet, LOI",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-00",
    reason: "To be entered later",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-01",
    reason: "Missing press content",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-02",
    reason: "Not enough information",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-03",
    reason: "Out of scope company",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-04",
    reason: "Out of scope press",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-05",
    reason: "Second target company",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-06",
    reason: "Second target press",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-07",
    reason: "Non-english",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-08",
    reason: DEFAULT_VALUE_FOR_ADD_ID_TYPE.overlappedPress,
    explanation: DEFAULT_VALUE_FOR_ADD_ID_TYPE.overlappedPress,
    defaultReason: DEFAULT_VALUE_FOR_ADD_ID_TYPE.overlappedPress,
    duplId: null,
    placeholder: "Press ID",
    type: UNSUITABLE_REASON_TYPE_INFO.addId,
    isTarget: false,
  },
  {
    id: "S930-17",
    reason: DEFAULT_VALUE_FOR_ADD_ID_TYPE.overlappedPressWithMerging,
    explanation: DEFAULT_VALUE_FOR_ADD_ID_TYPE.overlappedPressWithMerging,
    defaultReason: DEFAULT_VALUE_FOR_ADD_ID_TYPE.overlappedPressWithMerging,
    duplId: null,
    placeholder: "Press ID",
    type: UNSUITABLE_REASON_TYPE_INFO.addId,
    isTarget: false,
  },
  {
    id: "S930-09",
    reason: null,
    explanation: "If other, please specify",
    placeholder: "max 30 characters",
    type: UNSUITABLE_REASON_TYPE_INFO.writing,
    isTarget: false,
  },
  {
    id: "S930-14",
    reason: DEFAULT_VALUE_FOR_ADD_ID_TYPE.dealDataIgnore,
    explanation: DEFAULT_VALUE_FOR_ADD_ID_TYPE.dealDataIgnore,
    defaultReason: DEFAULT_VALUE_FOR_ADD_ID_TYPE.dealDataIgnore,
    duplId: null,
    placeholder: "Press ID",
    type: UNSUITABLE_REASON_TYPE_INFO.addId,
    isTarget: false,
  },
];

const PRESS_LIST_UNSUITABLE_REASON_LIST: UnsuitableReasonList[] =
  PRESS_UNSUITABLE_REASON_LIST.filter(
    ({ type }) => type === UNSUITABLE_REASON_TYPE_INFO.default,
  );

const CTG_UNSUITABLE_REASON_LIST: UnsuitableReasonList[] = [
  {
    id: "S930-51",
    reason: "Missing CTG Content",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-52",
    reason: "Out of Scope CTG",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-53",
    reason: "Second target CTG",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-50",
    reason: null,
    explanation: "If other, please specify",
    placeholder: "max 30 characters",
    type: UNSUITABLE_REASON_TYPE_INFO.writing,
    isTarget: false,
  },
];

const CTG_LIST_UNSUITABLE_REASON_LIST: UnsuitableReasonList[] =
  CTG_UNSUITABLE_REASON_LIST.filter(
    ({ type }) => type === UNSUITABLE_REASON_TYPE_INFO.default,
  );

const PRESS_DEFAULT_REASON_LIST = PRESS_UNSUITABLE_REASON_LIST.map(
  ({ reason }) => reason,
).filter((item) => item !== null);

const PRESS_LIST_DEFAULT_REASON_LIST = PRESS_LIST_UNSUITABLE_REASON_LIST.map(
  ({ reason }) => reason,
).filter((item) => item !== null);

const CTG_DEFAULT_REASON_LIST = CTG_UNSUITABLE_REASON_LIST.map(
  ({ reason }) => reason,
).filter((item) => item !== null);

const CTG_LIST_DEFAULT_REASON_LIST = CTG_LIST_UNSUITABLE_REASON_LIST.map(
  ({ reason }) => reason,
).filter((item) => item !== null);

const PRESS_DEFAULT_REASON = [...PRESS_DEFAULT_REASON_LIST];
const PRESS_LIST_DEFAULT_REASON = [...PRESS_LIST_DEFAULT_REASON_LIST];
const CTG_DEFAULT_REASON = [...CTG_DEFAULT_REASON_LIST];
const CTG_LIST_DEFAULT_REASON = [...CTG_LIST_DEFAULT_REASON_LIST];

export const UNSUITABLE_REASON_LIST_TITLE_BY_TYPE: Record<
  UnsuitableReasonUsageType,
  string
> = {
  [UNSUITABLE_REASON_KEYS.pressList]:
    "Please select only one reason why the article is not suitable.",
  [UNSUITABLE_REASON_KEYS.pressDetail]:
    "Please select only one reason why the article is not suitable.",
  [UNSUITABLE_REASON_KEYS.ctgList]:
    "Please select only one reason why the CTG is not suitable.",
  [UNSUITABLE_REASON_KEYS.ctgDetail]:
    "Please select only one reason why the CTG is not suitable.",
};

export const UNSUITABLE_REASON_DEFAULT_LIST_BY_TYPE: Record<
  UnsuitableReasonUsageType,
  string[]
> = {
  [UNSUITABLE_REASON_KEYS.pressList]: PRESS_LIST_DEFAULT_REASON,
  [UNSUITABLE_REASON_KEYS.pressDetail]: PRESS_DEFAULT_REASON,
  [UNSUITABLE_REASON_KEYS.ctgList]: CTG_LIST_DEFAULT_REASON,
  [UNSUITABLE_REASON_KEYS.ctgDetail]: CTG_DEFAULT_REASON,
};

export const UNSUITABLE_LIST_BY_TYPE: Record<
  UnsuitableReasonUsageType,
  UnsuitableReasonList[]
> = {
  [UNSUITABLE_REASON_KEYS.pressList]: PRESS_LIST_UNSUITABLE_REASON_LIST,
  [UNSUITABLE_REASON_KEYS.pressDetail]: PRESS_UNSUITABLE_REASON_LIST,
  [UNSUITABLE_REASON_KEYS.ctgList]: CTG_LIST_UNSUITABLE_REASON_LIST,
  [UNSUITABLE_REASON_KEYS.ctgDetail]: CTG_UNSUITABLE_REASON_LIST,
};
