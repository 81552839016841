import { apiClient } from "@api/apiClient";

const API_ENDPOINT =
  "/des/therapeutic-area/target-indication-to-therapeutic-area";

export const getTargetIndicationTherapeuticArea = async (
  targetIndications: string[],
) => {
  const response = await apiClient.post(API_ENDPOINT, targetIndications);
  return response.data;
};
