import React, { useEffect, useRef } from "react";

import {
  TableCreatorTableTitle,
  TableDividerPlusBar,
} from "@/components/business/CTGFillInTableContainer/shared";
import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";
import {
  GroupTableKeyType,
  TableCreatorGroupShellList,
} from "@/components/business/CTGFillInTableContainer/shared/types/tableCreator.types";
import { isAddDeleteTable } from "@/components/business/CTGFillInTableContainer/shared/utils/getOptionsFromData";
import { getTableTitle } from "@/components/business/CTGFillInTableContainer/shared/utils/getTextFromData";
import { DrugProfileSection } from "@/types/drugProfile.types";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import DrugProfileGroupShell from "./components/DrugProfileGroupShell/DrugProfileGroupShell";
import * as S from "./DrugProfileTable.style";
import { useTableCreatorDrugProfileTable } from "./hooks/useTableCreatorDrugProfileTable";

export interface TableInfoProps {
  groupTableKey: DrugProfileSection;
  tableKey: string | null;
  groupShellList: TableCreatorGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}
interface Props extends TableInfoProps {
  handleAddTable: (tableIndex: number) => void;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd: (groupTableKey: GroupTableKeyType) => void;
  handleDragOn: () => void;
  isDraggableTable: boolean;
}

const DrugProfileTable = ({
  groupTableKey,
  tableKey,
  groupTableIndex,
  tableIndex,
  tableCount,
  groupShellList,
  handleAddTable,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
  isDraggableTable,
}: Props) => {
  const articleSection = CTG_SECTION;
  const tablePropsInfo: TableInfoProps = {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    tableCount,
    groupShellList,
  };
  const tableTitleRef = useRef<HTMLDivElement>(null);

  const {
    focusStatus,
    isFocused,
    isLastTable,
    tableWrapperRef,
    isFocusedAddButton,
    handleClickDeleteButton,
    focusEventToChangePositionInfo,
    numberOfGroupShellCount,
    changeFocusStatus,
    changePositionInfo,
    changePositionInfoToDefault,
    handleDefaultFocus,
  } = useTableCreatorDrugProfileTable(tablePropsInfo);

  const isClearTable =
    isFocused &&
    focusStatus.type === "clear" &&
    focusStatus.container === "table";

  const isDeleteTable =
    isFocused &&
    focusStatus.type === "delete" &&
    focusStatus.container === "table";

  const handleFocusTableTitle = () => {
    focusEventToChangePositionInfo({
      type: "clear",
      container: "table",
    });
  };

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;

        const clickSelectButton = tableTitleRef.current?.contains(target);

        if (clickSelectButton) return;
        if (target.dataset.button) return;
        if (target.dataset.title) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isFocused]);

  return (
    <>
      {isAddDeleteTable(articleSection, tableKey) && (
        <TableDividerPlusBar
          tableTitle={getTableTitle(articleSection, tableKey) || ""}
          isLastPosition={false}
          onClick={() => handleAddTable(tableIndex)}
          isVisibility={isFocusedAddButton}
        />
      )}
      <S.TableWrapper
        className="table-wrapper"
        isFocused={isFocused}
        isClearTable={isClearTable}
        isDeleteTable={isDeleteTable}
        isAddTable={isAddDeleteTable(articleSection, tableKey)}
        isDraggingTable={isFocused && focusStatus.type === "dragging"}
      >
        {(isClearTable || isDeleteTable) && (
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton
              data-button="delete-button"
              onClick={handleClickDeleteButton}
            >
              <img src={IconXMark} alt="button for clear table" />
              {focusStatus.type === "clear" ? `Clear` : null}
              {focusStatus.type === "delete" ? `Delete ` : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <div ref={tableWrapperRef}>
          <div ref={tableTitleRef}>
            {getTableTitle(articleSection, tableKey) && (
              <TableCreatorTableTitle
                tableKey={tableKey}
                groupTableKey={groupTableKey}
                groupTableIndex={groupTableIndex}
                tableIndex={tableIndex}
                isDragging={isFocused && focusStatus.type === "dragging"}
                onClickHandler={handleFocusTableTitle}
                handleDragStart={handleDragStart}
                handleDragEnd={handleDragEnd}
                handleDragOn={handleDragOn}
                isDraggableTable={isDraggableTable}
                changeFocusStatusForDragging={() => {
                  changePositionInfo();
                  changeFocusStatus({
                    type: "dragging",
                    container: "table",
                  });
                }}
                changeFocusStatusForDragEnd={() => {
                  changePositionInfoToDefault();
                  changeFocusStatus({
                    type: "default",
                    container: "noFocus",
                  });
                }}
              />
            )}
          </div>
          {groupShellList.map(
            ({ groupShellKey, shellList }, groupShellListIndex) => (
              <S.GroupShellWrapper
                key={`groupShellList-${String(groupShellListIndex)}`}
              >
                {newArrayToCountValue(
                  numberOfGroupShellCount(groupShellKey),
                ).map((_, groupShellIndex) => {
                  return (
                    <>
                      <DrugProfileGroupShell
                        key={`group-shell-${String(groupTableIndex)}-${String(
                          tableIndex,
                        )}-${String(groupShellIndex)}`}
                        shellList={shellList}
                        groupTableKey={groupTableKey}
                        groupTableIndex={groupTableIndex}
                        tableKey={tableKey}
                        tableIndex={tableIndex}
                        groupShellKey={groupShellKey}
                        groupShellIndex={groupShellIndex}
                        groupShellCount={numberOfGroupShellCount(groupShellKey)}
                        isTableScroll={false}
                      />
                    </>
                  );
                })}
              </S.GroupShellWrapper>
            ),
          )}
        </div>
      </S.TableWrapper>
      {isAddDeleteTable(articleSection, tableKey) && (
        <TableDividerPlusBar
          tableTitle={getTableTitle(articleSection, tableKey) || ""}
          onClick={() => handleAddTable(tableIndex + 1)}
          isLastPosition
          isVisibility={isLastTable && isFocusedAddButton}
        />
      )}
    </>
  );
};

export default DrugProfileTable;
