import styled, { css } from "styled-components";

export const PressListTableHead = styled.thead`
  height: 4.4rem;
`;

export const TableRow = styled.tr``;

export const TableHeadShell = styled.th<{ isFirst?: boolean }>`
  padding: 0.5rem 0.8rem;
  border-bottom: 2px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  vertical-align: middle;
  white-space: nowrap;
`;

export const TableHeadShellContents = styled.ul<{ isOrdering: boolean }>`
  display: flex;
  align-items: top;
  gap: 0.6rem;
  width: 100%;
  height: 100%;
  cursor: pointer;

  ${({ isOrdering }) =>
    isOrdering &&
    css`
      color: var(--Color-Common-Text-Info, #004aeb);
    `}
`;

export const TableHeadShellTitle = styled.li<{
  isOrdering: boolean;
}>`
  font-family: var(--font-pretendard);
  height: 100%;
  font-weight: 600;
  font-size: 0.8rem;
  text-align: left;
  line-height: 1.2rem;

  &:hover,
  &:active {
    strong {
      color: var(--Color-Common-Text-Info, #004aeb);
    }
  }

  ${({ isOrdering }) =>
    isOrdering &&
    css`
      strong {
        color: var(--Color-Common-Text-Info, #004aeb);
      }
    `}
`;

export const TableHeadShellTitleWord = styled.strong`
  display: block;
  font-weight: 600;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.color.neural.gray08};
`;

export const OrderingIconWrapper = styled.li``;
