import React, { Component, ReactNode } from "react";

import { NotCorrectDataFormatError } from "@pages/FillInTablePage/errors/classes/notCorrectDataFormat";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import CTGDataFormatErrorMessageContainer from "./components/CTGDataFormatErrorMessageContainer/CTGDataFormatErrorMessageContainer";
import ErrorMessageContainer from "../ComponentErrorBoundary/ErrorMessageContainer/ErrorMessageContainer";

interface Props {
  children?: ReactNode;
  onReset?: () => void;
}

interface State {
  shouldHandleError: boolean;
  error: Error | null;
}

class CTGTableCreatorErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldHandleError: false,
      error: null,
    };
  }

  public static getDerivedStateFromError(error: Error): State {
    return {
      shouldHandleError: true,
      error,
    };
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    const { shouldHandleError } = this.state;
    if (prevState.shouldHandleError && !shouldHandleError) {
      const { onReset } = this.props;
      onReset?.();
    }

    if (prevProps !== this.props) {
      this.setState({
        shouldHandleError: false,
        error: null,
      });
    }
  }

  public render() {
    const { shouldHandleError, error } = this.state;
    const { children } = this.props;

    if (!shouldHandleError) {
      return children;
    }

    if (shouldHandleError) {
      if (error instanceof NotCorrectDataFormatError) {
        const articleId = getPressIdFromURL();

        return (
          <CTGDataFormatErrorMessageContainer
            message={error?.message ?? "Please try again later"}
            title="Data Format Error"
            articleId={articleId}
          />
        );
      }
      return (
        <ErrorMessageContainer
          message={error?.message ?? "Please try again later"}
          clickButton={() => {
            this.setState({
              shouldHandleError: false,
              error: null,
            });
          }}
        />
      );
    }
    return children;
  }
}

export default CTGTableCreatorErrorBoundary;
