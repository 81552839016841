import React from "react";

import CTGFillInTablePage from "@pages/CTGFillInTablePage/CTGFillInTablePage";
import CtgListPage from "@pages/CtgListPage";
import EditArticlePage from "@pages/EditArticlePage";
import FillInTablePage from "@pages/FillInTablePage/FillInTablePage";
import InsertImagePage from "@pages/InsertImagePage";
import LabelingPressPage from "@pages/PressClassificationPage";
import PressListPage from "@pages/PressListPage";
import RedirectPage from "@pages/RedirectPage";
import SignInPage from "@pages/SignInPage";
import { ROUTE_PATHS } from "@routes/routePath";

export type PageAccess =
  | "public"
  | "requireSignIn"
  | "requireSignOut"
  | "requireAdmin";

export type LayoutType = "none" | "center" | "full" | "wide" | "responsive";
interface PageList {
  path: string;
  component: React.FC;
  access: PageAccess;
  header: boolean;
  code?: string;
  layout: LayoutType;
}

export const pageList: Array<Readonly<PageList>> = [
  {
    path: ROUTE_PATHS.signIn,
    component: SignInPage,
    access: "requireSignOut",
    header: false,
    layout: "none",
  },
  {
    path: ROUTE_PATHS.pressList,
    component: PressListPage,
    access: "requireSignIn",
    header: true,
    layout: "wide",
  },
  {
    path: ROUTE_PATHS.ctgList,
    component: CtgListPage,
    access: "requireSignIn",
    header: true,
    layout: "wide",
  },
  {
    path: ROUTE_PATHS.pressClassification,
    component: LabelingPressPage,
    access: "requireSignIn",
    header: true,
    code: "C101",
    layout: "responsive",
  },
  {
    path: ROUTE_PATHS.fillInTable,
    component: FillInTablePage,
    access: "requireSignIn",
    header: true,
    code: "C102",
    layout: "center",
  },
  {
    path: ROUTE_PATHS.ctgFillInTable,
    component: CTGFillInTablePage,
    access: "requireSignIn",
    header: true,
    code: "C102",
    layout: "center",
  },
  {
    path: ROUTE_PATHS.insertImage,
    component: InsertImagePage,
    access: "requireSignIn",
    header: true,
    code: "C104",
    layout: "center",
  },
  {
    path: ROUTE_PATHS.editArticle,
    component: EditArticlePage,
    access: "requireSignIn",
    header: true,
    code: "C103",
    layout: "full",
  },
  {
    path: ROUTE_PATHS.redirect,
    component: RedirectPage,
    access: "public",
    header: false,
    layout: "full",
  },
];
