"use client";

import styled, { css } from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowButton = styled.button`
  padding: 0;
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--Border-Radius-circle, 99999.9rem);

  > svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  &:hover:not(:disabled) {
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }

  &:active:not(:disabled) {
    background: var(--Color-Common-Bg-Interactive-Secondary-Pressed, #e5e7eb);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16),
      0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  }

  &:disabled {
    cursor: not-allowed;

    > svg {
      path {
        fill: #96a1b0;
      }
    }
  }
`;

export const NumberWrapper = styled.span`
  display: flex;
  flex-direction: row;
`;

export const PageNumber = styled.strong<{ $isTarget?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.8rem;
  height: 2.8rem;
  border-radius: var(--Border-Radius-circle, 99999.9rem);
  padding: 0 0.8rem;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 600;

  &:hover {
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }

  &:active {
    background: var(--Color-Common-Bg-Interactive-Secondary-Pressed, #e5e7eb);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16),
      0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  }

  ${({ $isTarget }) =>
    $isTarget
      ? css`
          color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
          background: var(--Color-Common-Bg-Interactive-Selected, #e5f7ff);

          &:hover {
            color: var(
              --Color-Common-Text-Interactive-Primary-Hovered,
              #017abc
            );
            background: var(
              --Color-Common-Bg-Interactive-Selected-Hovered,
              #d1efff
            );
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
              0px 0px 2px 0px rgba(0, 0, 0, 0.12);
          }

          &:active {
            color: var(
              --Color-Common-Text-Interactive-Primary-Pressed,
              #015d8e
            );
            background: var(
              --Color-Common-Bg-Interactive-Selected-Pressed,
              #a4dfff
            );
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16),
              0px 0px 1px 0px rgba(0, 0, 0, 0.12);
          }
        `
      : css`
          color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
        `};
`;
