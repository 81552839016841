import { useMutation, useSuspenseQuery } from "@tanstack/react-query";

import { CTGEntryData } from "@/types/ctgEntryData.types";
import { queryClient } from "@api/queryClient";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastFail } from "@utils/toast";

import { ctgEntryDataApis } from "./ctgEntryData.service";
import { CTG_QUERY_KEYS } from "../queryKey";

export const getCTGEntryData = async (articleId: string) => {
  const { data } = await ctgEntryDataApis.getEntryData(articleId);
  return data;
};

export const useCTGEntryDataQuery = (
  articleId: string = getPressIdFromURL(),
) => {
  return useSuspenseQuery({
    queryKey: [CTG_QUERY_KEYS.ctgEntryData, articleId],
    queryFn: () => ctgEntryDataApis.getEntryData(articleId),
    select: (data) => data.data,
  });
};

interface Props {
  entryData: CTGEntryData;
  sectionCode: string;
  isDbSaved: boolean;
}
export const useUpdateCTGEntryDataMutate = (
  articleId: string = getPressIdFromURL(),
) => {
  return useMutation({
    mutationFn: ({ entryData, sectionCode, isDbSaved }: Props) =>
      ctgEntryDataApis.updateEntryData(
        articleId,
        entryData,
        isDbSaved,
        sectionCode,
      ),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [CTG_QUERY_KEYS.ctgUpdateEntryData, articleId],
      });
    },
    onError: () => {
      toastFail("Failed to update entry data");
    },
  });
};
