import ResetIcon from "@images/pressList/icon-reload.svg";
import SearchFilterTag from "@PressListContainer/shared/components/SearchFilterTag/SearchFilterTag";
import { useCtgListFilterState } from "@stores/ctgList";

import {
  EDITOR_INFO_CODE,
  STATUS_FILTER,
  StatusCode,
} from "./constants/filterCode.constants";
import * as S from "./CtgListSearchResult.style";

interface SearchType {
  [key: string]: string | undefined;
}

const CtgListSearchResult = () => {
  const [ctgListFilter, setCtgListFilter] = useCtgListFilterState();

  const { search, ctgListDataTotal, status } = ctgListFilter;

  const isSearch = (search && !!Object.keys(search).length) || !!status.length;

  const handleDeleteStatus = (currentCode: string) => {
    const deleteStatusFilter = status.filter(
      (filterCode) => filterCode !== currentCode,
    );

    setCtgListFilter({
      ...ctgListFilter,
      status: deleteStatusFilter,
      page: 1,
    });
  };

  const handleDeleteSearch = (currentKey: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [currentKey]: currentSearch, ...otherSearch } =
      search as SearchType;

    setCtgListFilter({
      ...ctgListFilter,
      search: {
        ...otherSearch,
      },
      page: 1,
    });
  };

  const handleResetFilter = () => {
    setCtgListFilter({
      ...ctgListFilter,
      search: {},
      status: [],
    });
  };

  const handleCheckPressIdSearch = (key: string, value: string) => {
    if (key !== "pressId") return value;

    const pressIdValue = value.replace(/#/g, "");

    return `#${pressIdValue}`;
  };

  return (
    <>
      {isSearch && (
        <S.SearchResult>
          <S.SearchResultText>Search Results for</S.SearchResultText>
          {search &&
            Object.entries(search).map(
              ([key, value]) =>
                value && (
                  <SearchFilterTag
                    key={key}
                    type="result"
                    text={handleCheckPressIdSearch(key, value)}
                    isAllInfo={!!(key === "allInfo" || key === "allEditor")}
                    isUserInput
                    code={EDITOR_INFO_CODE[key]}
                    onClickDeleteButton={() => handleDeleteSearch(key)}
                  />
                ),
            )}

          {status &&
            status.map((statusCode) => (
              <SearchFilterTag
                key={statusCode}
                type="result"
                text={STATUS_FILTER[statusCode as StatusCode]}
                isAllInfo={false}
                code={statusCode}
                onClickDeleteButton={() => handleDeleteStatus(statusCode)}
              />
            ))}
          <S.SearchTotalCount>({ctgListDataTotal})</S.SearchTotalCount>
          <S.ResetButton onClick={handleResetFilter}>
            <img src={ResetIcon} width={10} alt="filter reset icon" />
            <S.ResetButtonText>Filter Reset</S.ResetButtonText>
          </S.ResetButton>
        </S.SearchResult>
      )}
    </>
  );
};

export default CtgListSearchResult;
