import { useQuery } from "@tanstack/react-query";

import { getPressIdFromURL } from "@utils/getStateFromURL";

import { ctgInitEntryDataApis } from "./ctgInitEntryData.service";
import { CTG_QUERY_KEYS } from "../queryKey";

export const getCTGInitEntryData = async (articleId: string) => {
  const data = await ctgInitEntryDataApis.get(articleId);
  return data["Init Data"];
};

export const useCTGInitEntryDataQuery = (
  articleId: string = getPressIdFromURL(),
) => {
  return useQuery({
    queryKey: [CTG_QUERY_KEYS.ctgInitEntryData, articleId],
    queryFn: () => ctgInitEntryDataApis.get(articleId),
    select: (data) => data["Init Data"],
  });
};
