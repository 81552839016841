import { useCallback } from "react";

import { getCTGEntryData } from "@api/ctg/ctgEntryData/ctgEntryData";
import { useCTGEntryDataState } from "@stores/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useGetCTGEntryData = () => {
  const [, setEntryData] = useCTGEntryDataState(getPressIdFromURL());

  const getEntryDataAndStored = useCallback(async () => {
    const data = await getCTGEntryData(getPressIdFromURL());
    setEntryData(data);
  }, [setEntryData]);

  return getEntryDataAndStored;
};
