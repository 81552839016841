import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";
import { useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import { useEditorRoles } from "@libs/roles/hooks/useEditorRoles";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { checkSuperAdmin, useAuthorityType } from "./useAuthorityType";
import { checkUnsuitablePressRoles } from "../util/checkUnsuitablePressRoles";

export const useCTGListRoles = () => {
  const { data: articleInfo } = useCTGInfoQuery(Number(getPressIdFromURL()));
  const editorRoles = useEditorRoles();
  const authorityType = useAuthorityType();
  const isSuperAdmin = checkSuperAdmin(authorityType);

  const status = articleInfo?.draftArticleStatusHighest;
  const classification = CTG_SECTION;

  const hasUnsuitableCTGRoles = checkUnsuitablePressRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
  );

  return {
    hasUnsuitableCTGRoles,
  };
};
