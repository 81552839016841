import { useState } from "react";

import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { useCtgListFilterQuery } from "@queries/hooks/useCtgListFilter";
import { formatDate } from "@utils/formatDate";
import { handleCtgError } from "@utils/handleDraftError";

import CtgListCompanyShell from "./components/CtgListCompanyShell/CtgListCompanyShell";
import CtgListStatusContainer from "./components/CtgListStatusContainer/CtgListStatusContainer";
import CtgListUnsuitableContainer from "./components/CtgListUnsuitableContainer/CtgListUnsuitableContainer";
import CtgTitleContainer from "./components/CtgTitleContainer/CtgTitleContainer";
import * as S from "./CtgListTableBody.style";
import { EMPTY_SHELL_VALUE } from "../../constants/ctgListTable.constants";

const EDITOR_LIST = ["mcEditorName", "ftEditor2Name"];

const WRITE_DATE_LIST = ["writeStartDt", "writeEndDt"];

const CtgListTableBody = () => {
  const [activeModalCtgId, setActiveModalCtgId] = useState<number | null>(null);

  const { data: ctgListData, refetch: updateCtgList } = useCtgListFilterQuery();

  return (
    <S.CtgListTableBody>
      {ctgListData?.data.map((ctgListItem) => (
        <S.CtgListTableTr
          key={`row-${ctgListItem.ctgId}`}
          isError={handleCtgError(ctgListItem)[0]}
        >
          <S.CtgListTablePressId>{ctgListItem?.nctId}</S.CtgListTablePressId>
          <S.PressListTableDate>{ctgListItem?.ctgDate}</S.PressListTableDate>
          <S.CtgListTableCompany>
            <CtgListCompanyShell companyName={ctgListItem?.sponsorName || []} />
          </S.CtgListTableCompany>
          <S.CtgListTableCompany>
            <CtgListCompanyShell
              companyName={ctgListItem?.collaboratorName || []}
            />
          </S.CtgListTableCompany>

          <S.PressListTableUnsuitable
            isUnsuitable={
              ctgListItem.draftArticleStatusHighest ===
              DRAFT_STATUS_CODE.unsuitable
            }
            isModalOpen={activeModalCtgId === ctgListItem.ctgId}
          >
            <CtgListUnsuitableContainer
              {...ctgListItem}
              onModalStateChange={(isOpen) => {
                if (isOpen) {
                  setActiveModalCtgId(ctgListItem?.ctgId || null);
                } else if (activeModalCtgId === ctgListItem.ctgId) {
                  setActiveModalCtgId(null);
                }
              }}
            />
          </S.PressListTableUnsuitable>

          <CtgTitleContainer
            ctgListItem={ctgListItem}
            updateCtgList={updateCtgList}
            ctgListData={ctgListData?.data}
          />

          <S.StatusTableData
            key={ctgListItem.ctgId}
            code={ctgListItem.draftArticleStatusHighest}
          >
            <CtgListStatusContainer data={ctgListItem} />
          </S.StatusTableData>

          {WRITE_DATE_LIST.map((dateKey) => (
            <S.PressListTableDate key={dateKey}>
              {ctgListItem[dateKey as keyof typeof ctgListItem]
                ? formatDate(
                    ctgListItem[dateKey as keyof typeof ctgListItem] as string,
                    "YY.MM.DD HH:mm:ss",
                  )
                : EMPTY_SHELL_VALUE}
            </S.PressListTableDate>
          ))}

          {EDITOR_LIST.map((editor) => (
            <S.CtgListTableEditor key={editor}>
              {ctgListItem[editor as keyof typeof ctgListItem] ??
                EMPTY_SHELL_VALUE}
            </S.CtgListTableEditor>
          ))}
        </S.CtgListTableTr>
      ))}
    </S.CtgListTableBody>
  );
};

export default CtgListTableBody;
