import React, { Suspense, useEffect, useState } from "react";

import useArticleSection from "@/components/business/CTGFillInTableContainer/hooks/useArticleSection";
import { GroupTableKeyType } from "@/components/business/CTGFillInTableContainer/shared/types/tableCreator.types";
import { getMaxShellValueLength } from "@/components/business/CTGFillInTableContainer/shared/utils/getOptionsFromData";
import { EntryDataValueInfo } from "@/types/drugProfile.types";

import ClinicalTrialsIdentifierValidationInfo from "./components/ClinicalTrialsIdentifierValidationInfo/ClinicalTrialsIdentifierValidationInfo";
import ModalityCategoryTextFieldContainer from "./components/ModalityCategoryTextFieldContainer/ModalityCategoryTextFieldContainer";
import { IS_NOT_EDITABLE_SHELL_KEYS } from "./components/TableCreatorTextField/constants/notEditableShellKey.constants";
import useCheckDisplayValueDragDivider from "./components/TableCreatorTextField/hooks/useCheckDisplayValueDragDivider";
import TableCreatorTextField from "./components/TableCreatorTextField/TableCreatorTextField";
import * as S from "./TableCreatorTextFieldContainer.style";
import { useHandleTableCreatorValueDrag } from "../../hooks/useHandleTableCreatorValueDrag";
import TableDragDivider from "../TableDragDivider/TableDragDivider";

interface Props {
  groupTableKey: GroupTableKeyType;
  tableKey: string | null;
  groupShellKey: string | null;
  groupTableIndex: number;
  tableIndex: number;
  groupShellIndex: number;
  subGroupShellKey?: string | null;
  subGroupShellIndex?: number | null;
  placeholder: string;
  shellKey: string;
  shellIndex: number;
  shellValue: {
    value: EntryDataValueInfo[] | [];
    options: string[];
  };
}

const TableCreatorTextFieldContainer = ({
  groupTableKey,
  tableKey,
  groupShellKey,
  groupTableIndex,
  tableIndex,
  groupShellIndex,
  subGroupShellIndex = null,
  subGroupShellKey = null,
  placeholder,
  shellKey,
  shellIndex,
  shellValue,
}: Props) => {
  const [isValidationInfo, setIsValidationInfo] = useState(false);
  const articleSection = useArticleSection();
  const valueLength = getMaxShellValueLength(articleSection, shellKey);
  const valueList = shellValue.value || [];

  const isEditableShell = !IS_NOT_EDITABLE_SHELL_KEYS.includes(shellKey);

  const isMultipleValue =
    valueLength > 1 &&
    valueLength > valueList.length &&
    valueList.length !== 0 &&
    isEditableShell;

  const DEFAULT_SHELL_VALUE: EntryDataValueInfo[] =
    valueLength > valueList.length && isEditableShell
      ? [...valueList, { text: "", is_modified: true }]
      : valueList;

  const {
    handleValueDragEnd,
    handleValueDragOn,
    handleValueDragOver,
    handleValueDragStart,
    valueDraggedIndexInfo,
    isDraggingValue,
  } = useHandleTableCreatorValueDrag(
    articleSection,
    { tableIndex, groupShellIndex, subGroupShellIndex },
    shellKey,
  );

  const valueListCount =
    valueLength > valueList.length
      ? DEFAULT_SHELL_VALUE.length - 1
      : valueList.length;

  const { isLastValue, handleCheckDragDividerDisplay } =
    useCheckDisplayValueDragDivider({
      isDraggingValue,
      valueListCount: valueList.length - 1,
    });

  const openValidationInfo = () => {
    setIsValidationInfo(true);
  };

  const closeValidationInfo = () => {
    setIsValidationInfo(false);
  };

  useEffect(() => {
    if (shellKey !== "NCT Name") return;
    const nctNameShellValueLength = shellValue?.value.length;

    if (nctNameShellValueLength === 0) {
      closeValidationInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shellValue]);

  if (shellKey === "Modality") {
    return (
      <>
        {DEFAULT_SHELL_VALUE.map((value, valueIndex) => (
          <S.ModalityShellRow>
            <S.ModalityShellColumn
              className={`is-not-modality-value-in-first-column-${
                valueIndex === 0 && !value.text
              }`}
              $isMultipleValue={valueListCount > 1}
            >
              <S.DraggableValueWrapper
                isTargetDraggingTable={
                  isDraggingValue && valueDraggedIndexInfo[0] !== valueIndex
                }
                onDragOver={(e) => handleValueDragOver(e, valueIndex)}
                className="modality-flex"
              >
                {!(isMultipleValue && valueIndex === valueListCount) && (
                  <TableDragDivider
                    isDraggingEndPosition={handleCheckDragDividerDisplay({
                      moveDragIndex: valueDraggedIndexInfo[1],
                      startDragIndex: valueDraggedIndexInfo[0],
                      valueIndex,
                    })}
                  />
                )}
                <TableCreatorTextField
                  key={`text-field-${shellKey}-${value}-${String(valueIndex)}`}
                  groupTableKey={groupTableKey}
                  tableKey={tableKey}
                  groupShellKey={groupShellKey}
                  groupTableIndex={groupTableIndex}
                  tableIndex={tableIndex}
                  groupShellIndex={groupShellIndex}
                  subGroupShellKey={subGroupShellKey}
                  subGroupShellIndex={subGroupShellIndex}
                  placeholder={placeholder}
                  shellKey={shellKey}
                  shellIndex={shellIndex}
                  shellValue={shellValue}
                  valueIndex={valueIndex}
                  isMultipleLastValue={
                    isMultipleValue && valueIndex === valueListCount
                  }
                  isMultipleValue={valueListCount > 1}
                  openValidationInfo={openValidationInfo}
                  closeValidationInfo={closeValidationInfo}
                  handleDragEnd={handleValueDragEnd}
                  handleDragOn={handleValueDragOn}
                  handleDragStart={handleValueDragStart}
                />
                {isLastValue(valueIndex) && (
                  <TableDragDivider
                    isDraggingEndPosition={handleCheckDragDividerDisplay({
                      moveDragIndex: valueDraggedIndexInfo[1],
                      startDragIndex: valueDraggedIndexInfo[0],
                      valueIndex,
                      lastIndex: valueList.length,
                    })}
                  />
                )}
              </S.DraggableValueWrapper>
            </S.ModalityShellColumn>
            <S.ModalityShellColumn
              className={`is-not-modality-first-value-${
                valueIndex === 0 && !value.text
              }`}
              $isMultipleValue={valueListCount > 1}
            >
              <S.DraggableValueWrapper
                isTargetDraggingTable={
                  isDraggingValue && valueDraggedIndexInfo[0] !== valueIndex
                }
                onDragOver={(e) => handleValueDragOver(e, valueIndex)}
                className="modality-flex"
              >
                {!(isMultipleValue && valueIndex === valueListCount) && (
                  <TableDragDivider
                    isDraggingEndPosition={handleCheckDragDividerDisplay({
                      moveDragIndex: valueDraggedIndexInfo[1],
                      startDragIndex: valueDraggedIndexInfo[0],
                      valueIndex,
                    })}
                  />
                )}
                <Suspense>
                  <ModalityCategoryTextFieldContainer
                    modalityValue={value.text}
                    modalityValueIndex={valueIndex}
                  />
                </Suspense>
                {isLastValue(valueIndex) && (
                  <TableDragDivider
                    isDraggingEndPosition={handleCheckDragDividerDisplay({
                      moveDragIndex: valueDraggedIndexInfo[1],
                      startDragIndex: valueDraggedIndexInfo[0],
                      valueIndex,
                      lastIndex: valueList.length,
                    })}
                  />
                )}
              </S.DraggableValueWrapper>
            </S.ModalityShellColumn>
          </S.ModalityShellRow>
        ))}
      </>
    );
  }

  return (
    <>
      {DEFAULT_SHELL_VALUE.map((value, valueIndex) => (
        <S.DraggableValueWrapper
          isTargetDraggingTable={
            isDraggingValue && valueDraggedIndexInfo[0] !== valueIndex
          }
          onDragOver={(e) => handleValueDragOver(e, valueIndex)}
        >
          {!(isMultipleValue && valueIndex === valueListCount) && (
            <TableDragDivider
              isDraggingEndPosition={handleCheckDragDividerDisplay({
                moveDragIndex: valueDraggedIndexInfo[1],
                startDragIndex: valueDraggedIndexInfo[0],
                valueIndex,
              })}
            />
          )}
          <TableCreatorTextField
            key={`text-field-${shellKey}-${value}-${String(valueIndex)}`}
            groupTableKey={groupTableKey}
            tableKey={tableKey}
            groupShellKey={groupShellKey}
            groupTableIndex={groupTableIndex}
            tableIndex={tableIndex}
            groupShellIndex={groupShellIndex}
            subGroupShellKey={subGroupShellKey}
            subGroupShellIndex={subGroupShellIndex}
            placeholder={placeholder}
            shellKey={shellKey}
            shellIndex={shellIndex}
            shellValue={shellValue}
            valueIndex={valueIndex}
            isMultipleLastValue={
              isMultipleValue && valueIndex === valueListCount
            }
            isMultipleValue={valueListCount > 1}
            openValidationInfo={openValidationInfo}
            closeValidationInfo={closeValidationInfo}
            handleDragEnd={handleValueDragEnd}
            handleDragOn={handleValueDragOn}
            handleDragStart={handleValueDragStart}
          />
          {isLastValue(valueIndex) && isEditableShell && (
            <TableDragDivider
              isDraggingEndPosition={handleCheckDragDividerDisplay({
                moveDragIndex: valueDraggedIndexInfo[1],
                startDragIndex: valueDraggedIndexInfo[0],
                valueIndex,
                lastIndex: valueList.length,
              })}
            />
          )}
        </S.DraggableValueWrapper>
      ))}
      {shellKey === "NCT Name" && isValidationInfo && (
        <ClinicalTrialsIdentifierValidationInfo
          closeValidationInfo={closeValidationInfo}
        />
      )}
    </>
  );
};

export default TableCreatorTextFieldContainer;
