import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { HAS_ENTRY_DATA_CLASSIFICATION } from "@constants/hasEntryDataClassification.constants";
import { useGetEntryData } from "@hooks/useGetEntryData";
import IconArrowRightDisable from "@images/common/icon-arrow-right-gray.svg";
import IconArrowRight from "@images/common/icon-arrow-right.svg";
import { usePressClassificationRoles } from "@libs/roles/hooks/usePressClassificationRoles";
import { ROUTE_PATHS } from "@routes/routePath";
import { usePressIdFromURL } from "@utils/getStateFromURL";

import * as S from "./MoveFillInTableButton.style";
import { useCheckPageEntryInPressClassification } from "../../hooks/useCheckPageEntryInPressClassification";

const DISABLED_STATUS_TO_MOVE_NEXT_PROCESS: string[] = [
  DRAFT_STATUS_CODE.unsuitable,
  DRAFT_STATUS_CODE.aiEntryInProgress,
  DRAFT_STATUS_CODE.notForAiEntry,
];

const MoveFillInTableButton = () => {
  const navigate = useNavigate();
  const draftArticleId = usePressIdFromURL();
  const { data: articleInfo, refetch } = useArticleInfoQuery(
    Number(draftArticleId),
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const shouldPoll =
      articleInfo?.draftArticleStatusHighest ===
        DRAFT_STATUS_CODE.aiEntryInProgress ||
      articleInfo?.draftArticleStatusHighest ===
        DRAFT_STATUS_CODE.notForAiEntry;

    const hasValidClassification = HAS_ENTRY_DATA_CLASSIFICATION.includes(
      articleInfo?.classificationManual || "",
    );

    if (
      shouldPoll &&
      hasValidClassification &&
      articleInfo?.draftArticleId === Number(draftArticleId)
    ) {
      intervalId = setInterval(async () => {
        await refetch();
      }, 3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    articleInfo?.draftArticleStatusHighest,
    articleInfo?.classificationManual,
    articleInfo?.draftArticleId,
    draftArticleId,
    refetch,
  ]);

  const pressStatus = articleInfo?.draftArticleStatusHighest;
  const unsuitableReason = articleInfo?.articleRejectReason;
  const { hasNextPageAccessRoles } = usePressClassificationRoles();
  const { canEntryFillInTablePage } = useCheckPageEntryInPressClassification();
  const classificationManual = articleInfo?.classificationManual;
  const getEntryDataAndStored = useGetEntryData();

  const isDisabledNextProcess =
    DISABLED_STATUS_TO_MOVE_NEXT_PROCESS.includes(pressStatus || "") ||
    !articleInfo?.classificationManual;

  const movePrev = async () => {
    navigate(ROUTE_PATHS.pressList);
  };
  const moveNext = async () => {
    await getEntryDataAndStored();
    navigate(`${ROUTE_PATHS.fillInTable}?id=${draftArticleId}`);
  };

  return hasNextPageAccessRoles ? (
    !canEntryFillInTablePage ? (
      <S.NextButton disabled>
        Another Editor is working...
        <img src={IconArrowRightDisable} alt="Another Editor is working..." />
      </S.NextButton>
    ) : articleInfo?.draftArticleStatusHighest ===
      DRAFT_STATUS_CODE.aiEntryInProgress ? (
      <S.AIInProgressButton>AI Entry In Progress ...</S.AIInProgressButton>
    ) : (
      <S.NextButton disabled={isDisabledNextProcess} onClick={moveNext}>
        Fill in Table
        <img
          src={isDisabledNextProcess ? IconArrowRightDisable : IconArrowRight}
          alt="go to fill in Table"
        />
      </S.NextButton>
    )
  ) : (
    <S.DoneButton
      onClick={movePrev}
      disabled={!classificationManual && !unsuitableReason}
    >
      Done
    </S.DoneButton>
  );
};

export default MoveFillInTableButton;
