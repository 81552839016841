import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorFocusStatusContext";

import { DefaultDataSetType } from "../types/tableCreatorDropdown.types";

interface Props {
  changeSelectedValue: (value: string) => void;
  changeTextFieldMarginBottom: (margin: number) => void;
  handleChangeDataSetValue: (value: DefaultDataSetType) => void;
}

export const useClickDropdownItem = ({
  changeSelectedValue,
  changeTextFieldMarginBottom,
  handleChangeDataSetValue,
}: Props) => {
  const { changeFocusStatus } = useTableCreatorFocusStatus();

  const handleClickAIItem = (item: string) => {
    changeSelectedValue(item);
    changeTextFieldMarginBottom(0);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const handleClickDictionaryItem = (item: string) => {
    changeSelectedValue(item);
  };

  const handleClickDataSetItem = (item: DefaultDataSetType) => {
    handleChangeDataSetValue(item);
  };

  return {
    handleClickAIItem,
    handleClickDictionaryItem,
    handleClickDataSetItem,
  };
};
