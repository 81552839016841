import React from "react";

import {
  PARAGRAPH_CODE,
  DealTermsVentureFormationTableContainer,
} from "@frontend/common";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useCreateCompanyLogoComponentList } from "@EditArticleContainer/shared/hooks/useCreateCompanyLogoComponentList";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import {
  REF_CODE_LICENSEE_NAME,
  REF_CODE_LICENSEE_RESPONSIBILITY,
  REF_CODE_LICENSOR_NAME,
  REF_CODE_LICENSOR_RESPONSIBILITY,
  REF_CODE_DEAL_VF_INITIAL_INVESTMENT,
  REF_CODE_DEAL_VF_CONTINGENT_INVESTMENT,
  REF_CODE_DEAL_VF_TOTAL_INVESTMENT,
  REF_CODE_DEAL_VF_OWNERSHIP_STRUCTURE_DETAILS,
  REF_CODE_DEAL_VF_OTHER_FINANCIAL_TERMS,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";

import {
  licenseeNameText,
  licenseeOtherFinancialTermsText,
  licenseeContingentInvestmentText,
  licenseeResponsibilityText,
  licenseeOwnershipStructureDetailsText,
  licenseeTotalInvestmentText,
  licenseeInitialInvestmentText,
  licensorNameText,
  licensorResponsibilityText,
} from "./utils/getVentureFormationTableShellValue";
import { useCreateVentureFormationRightsTables } from "../../hooks/useCreateVentureFormationRightsTables";
import { useSellerBuyerByDealType } from "../../hooks/useSellerBuyerByDealType";

const VentureFormationTable = () => {
  const { sellerName, buyerName } = useSellerBuyerByDealType();
  const { createCompanyLogoComponentList } =
    useCreateCompanyLogoComponentList();
  const dealTerms = useParagraphEditedData(PARAGRAPH_CODE.TY002.dealTerms);

  const { partnerBRightsScope } = useCreateVentureFormationRightsTables();

  const SHELL_TEXT = {
    licensorNameText: licensorNameText(dealTerms),
    licensorResponsibilityText: licensorResponsibilityText(dealTerms),
    licenseeNameText: licenseeNameText(dealTerms),
    licenseeResponsibilityText: licenseeResponsibilityText(dealTerms),
    licenseeInitialInvestmentText: licenseeInitialInvestmentText(dealTerms),
    licenseeContingentInvestmentText:
      licenseeContingentInvestmentText(dealTerms),
    licenseeTotalInvestmentText: licenseeTotalInvestmentText(dealTerms),
    licenseeOwnershipStructureDetailsText:
      licenseeOwnershipStructureDetailsText(dealTerms),
    licenseeOtherFinancialTermsText: licenseeOtherFinancialTermsText(dealTerms),
  };

  const COMMON_EDITABLE_TEXT_STYLE = {
    padding: "0.8rem",
    borderRadius: "0",
  };

  const licensingInfoData = {
    partnerA: {
      licensingName: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT.licensorNameText,
            labels: [],
          }}
          refKey={REF_CODE_LICENSOR_NAME}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
          imagePosition="left"
          imageNodeList={createCompanyLogoComponentList(
            SHELL_TEXT.licenseeNameText.replaceAll("- ", "").split(`\n`) ?? "",
          )}
          isDisabled
        />
      ),
      responsibilityAndRights: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT.licensorResponsibilityText,
            labels: [],
          }}
          refKey={REF_CODE_LICENSOR_RESPONSIBILITY}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
    },
    partnerB: {
      licensingName: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT.licenseeNameText,
            labels: [],
          }}
          refKey={REF_CODE_LICENSEE_NAME}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
          imagePosition="left"
          imageNodeList={createCompanyLogoComponentList(
            SHELL_TEXT.licenseeNameText.replaceAll("- ", "").split(`\n`) ?? "",
          )}
          isDisabled
        />
      ),
      responsibilityAndRights: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT.licenseeResponsibilityText,
            labels: [],
          }}
          refKey={REF_CODE_LICENSEE_RESPONSIBILITY}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
    },
  };

  const financialTermsData = {
    buyer: {
      initialInvestment: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT.licenseeInitialInvestmentText,
            labels: [],
          }}
          refKey={REF_CODE_DEAL_VF_INITIAL_INVESTMENT}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),

      contingentInvestment: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT.licenseeContingentInvestmentText,
            labels: [],
          }}
          refKey={REF_CODE_DEAL_VF_CONTINGENT_INVESTMENT}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
      totalInvestment: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT.licenseeTotalInvestmentText,
            labels: [],
          }}
          refKey={REF_CODE_DEAL_VF_TOTAL_INVESTMENT}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
      ownershipStructureDetails: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT.licenseeOwnershipStructureDetailsText,
            labels: [],
          }}
          refKey={REF_CODE_DEAL_VF_OWNERSHIP_STRUCTURE_DETAILS}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
    },
  };

  const otherFinancialTermsData = {
    otherFinancialTerms: (
      <EditableTextContainer
        item={{
          text: SHELL_TEXT.licenseeOtherFinancialTermsText,
          labels: [],
        }}
        refKey={REF_CODE_DEAL_VF_OTHER_FINANCIAL_TERMS}
        canTextEdit
        style={COMMON_EDITABLE_TEXT_STYLE}
      />
    ),
  };

  const rightsScopeData = {
    buyer: partnerBRightsScope.map((tableData) => {
      const territoryData = (
        tableData?.find(
          (itemData) =>
            !Array.isArray(itemData) && itemData.title === "Territory",
        ) as {
          title: string;
          value: React.ReactNode;
        }
      )?.value;
      const exclusivityData = (
        tableData?.find(
          (itemData) =>
            !Array.isArray(itemData) && itemData.title === "Exclusivity",
        ) as {
          title: string;
          value: React.ReactNode;
        }
      )?.value;

      const drugInfoData = tableData
        .filter((data) => Array.isArray(data))
        .map((itemData) => {
          const drugData = itemData.find(
            (item) => item.title === "Asset(s)",
          )?.value;
          const mainUsageData = itemData.find(
            (item) => item.title === "Main Usage",
          )?.value;
          return {
            drug: drugData,
            mainUsage: mainUsageData,
          };
        });

      return {
        territory: territoryData,
        exclusivity: exclusivityData,
        drugInfo: drugInfoData,
      };
    }),
  };

  return (
    <DealTermsVentureFormationTableContainer
      sellerName={sellerName || ""}
      buyerName={buyerName || ""}
      licensingInfoData={licensingInfoData}
      otherFinancialTermsData={otherFinancialTermsData}
      financialTermsData={financialTermsData}
      rightScopeData={rightsScopeData}
    />
  );
};

export default VentureFormationTable;
