import React from "react";

import IconLink from "@images/pressClassification/icon-link-skyblue.svg";

import * as S from "./CTGPressTitle.style";
import { removeCodeInTitle } from "../../utils/removeCodeInTitle/removeCodeInTitle";

interface Props {
  isLabel?: boolean;
  imageSrc?: string;
  contentTitle?: string;
  contentUrl?: string;
}

const PRESS_TITLE_LABEL = "Press Title";

const CTGPressTitle = ({
  isLabel = true,
  imageSrc = IconLink,
  contentTitle,
  contentUrl,
}: Props) => {
  const title = contentTitle || "";
  const cleanedTitle = removeCodeInTitle(title);

  return (
    <S.PressTitleWrapper>
      {isLabel && <S.PressTitleLabel>{PRESS_TITLE_LABEL}</S.PressTitleLabel>}
      <S.PressTitle onClick={() => window.open(contentUrl || "", "_blank")}>
        <img src={imageSrc} alt="link" />
        <strong>{cleanedTitle}</strong>
      </S.PressTitle>
    </S.PressTitleWrapper>
  );
};

export default CTGPressTitle;
