import { useCallback, useEffect } from "react";

import { DealsSection } from "@/types/deals.types";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  PositionInfo,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  DEFAULT_VALIDATED_INFO,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useGetNumberOfGroupShell } from "@FillInTableContainer/shared/hooks/useGetNumberOfGroupShell";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import useClearVentureFormationGroupShellSection from "./useClearVentureFormationGroupShellSection";
import { useHandleMergeLicenseeTable } from "./useHandleMergeLicenseeTable";

interface Props {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupTableIndex: number;
  tableIndex: number;
}

interface UseHandleTableCreatorTableReturn {
  info: PositionInfo;
  focusStatus: FocusStatus;
  isFocused: boolean;
  isClearGroupShellSection: (groupShellSectionKey: string | null) => boolean;
  focusEventToChangePositionInfo: (focusEnterType: FocusStatus) => void;
  numberOfGroupShellCount: (groupShellKey: string | null) => number;
  handleClickClearButton: () => void;
  handleChangeDefaultFocus: () => void;
  handleFocusGroupShellSection: (groupShellSectionKey: string | null) => void;
  handleClickClearGroupShellSectionButton: (
    groupShellSectionKey: string | null,
  ) => void;
}

export const useHandleDealTermsMergeTableCreatorTable = (
  tablePropsInfo: Props,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, groupTableIndex, tableKey, tableIndex } =
    tablePropsInfo;

  const articleSection = "Deals";

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { groupShellCount } = useGetNumberOfGroupShell();

  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const {
    handleClickLicenseeTableClearButton,
    handleKeyDownToClearLicenseeTable,
  } = useHandleMergeLicenseeTable(tablePropsInfo);

  const {
    handleClickToClearVentureFormationGroupShellSection,
    handleKeyDownToClearVentureFormationGroupShellSection,
  } = useClearVentureFormationGroupShellSection(tablePropsInfo);

  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST[articleSection][groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    isEnableEdit;

  const numberOfGroupShellCount = (groupShellKey: string | null) =>
    groupShellCount(groupShellKey, tableIndex);

  const handleFocusGroupShellSection = (
    groupShellSectionKey: string | null,
  ) => {
    // ❗️ groupShellSection 포커스 키는 그냥 일단 상수로 ? 연결해주기
    changeInfo({
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellSectionKey,
      groupShellSectionIndex: null,
      groupShellIndex: null,
      groupShellKey: null,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "groupShellSection" });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const isGroupShellSectionFocus = (groupShellSectionKey: string | null) => {
    return (
      info.groupTableKey === groupTableKey &&
      info.groupTableIndex === groupTableIndex &&
      info.tableKey === tableKey &&
      info.tableIndex === tableIndex &&
      info.groupShellSectionKey === groupShellSectionKey &&
      info.groupShellKey === null &&
      info.groupShellIndex === null &&
      info.shellKey === null &&
      info.shellIndex === null &&
      info.valueIndex === null &&
      isEnableEdit
    );
  };
  const isClearGroupShellSection = (groupShellSectionKey: string | null) =>
    isGroupShellSectionFocus(groupShellSectionKey) &&
    focusStatus.container === "groupShellSection" &&
    focusStatus.type === "clear";

  const focusEventToChangePositionInfo = (focusEnterType: FocusStatus) => {
    changeInfo({
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellKey: null,
      groupShellIndex: null,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "table" });

    if (focusEnterType.type === "clear") {
      changeFocusStatus({ type: "clear", container: "table" });
    }
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleChangeDefaultFocus = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const handleClickClearButton = () => {
    handleClickLicenseeTableClearButton();
  };

  const handleClickClearGroupShellSectionButton = (
    groupShellSectionKey: string | null,
  ) => {
    handleClickToClearVentureFormationGroupShellSection(groupShellSectionKey);
  };

  const handleKeyDownToClearTable = useCallback(
    (event: KeyboardEvent) => {
      handleKeyDownToClearLicenseeTable(event);
    },
    [handleKeyDownToClearLicenseeTable],
  );

  const KeyDownToClearVentureFormationGroupShellSection = useCallback(
    (event: KeyboardEvent) => {
      handleKeyDownToClearVentureFormationGroupShellSection(
        event,
        info.groupShellSectionKey || "",
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleKeyDownToClearVentureFormationGroupShellSection],
  );

  useEffect(() => {
    if (focusStatus.container !== "table") return;
    window.addEventListener("keydown", handleKeyDownToClearTable);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToClearTable);
    };
  }, [focusStatus, handleKeyDownToClearTable]);

  useEffect(() => {
    if (focusStatus.container !== "groupShellSection") return;
    window.addEventListener(
      "keydown",
      KeyDownToClearVentureFormationGroupShellSection,
    );

    return () => {
      window.removeEventListener(
        "keydown",
        KeyDownToClearVentureFormationGroupShellSection,
      );
    };
  }, [focusStatus, KeyDownToClearVentureFormationGroupShellSection]);

  return {
    info,
    focusStatus,
    isFocused,
    isClearGroupShellSection,
    handleChangeDefaultFocus,
    handleClickClearButton,
    numberOfGroupShellCount,
    focusEventToChangePositionInfo,
    handleFocusGroupShellSection,
    handleClickClearGroupShellSectionButton,
  };
};
