import { RegulatorySection } from "@/types/regulatory.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  handleClickToDeleteDrugProfileGroupShell: () => void;
  handleKeyDownToDeleteDrugProfileGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: RegulatorySection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useHandleRegulatoryDrugProfileGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const articleSection = "Regulatory";
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const handleClearGroupShell = () => {
    const clearDrugProfileGroupShell = (): ArticleEntryData => {
      if (info.groupShellKey === "Drug Name")
        return {
          ...entryData,
          [articleSection]: {
            ...entryData[articleSection],
            "Drug Profile": entryData[articleSection]?.["Drug Profile"]?.map(
              (tableInfo, tableInfoIndex) => {
                if (tableInfoIndex === info.tableIndex) {
                  return {
                    ...tableInfo,
                    "Brand Name": {
                      ...tableInfo["Brand Name"],
                      value: [],
                    },
                    "Code Name": {
                      ...tableInfo["Code Name"],
                      value: [],
                    },
                    "INN Name": {
                      ...tableInfo["INN Name"],
                      value: [],
                    },
                  };
                }
                return tableInfo;
              },
            ),
          },
        };

      return {
        ...entryData,
        [articleSection]: {
          ...entryData[articleSection],
          "Drug Profile": entryData[articleSection]?.["Drug Profile"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  MOA: {
                    ...tableInfo.MOA,
                    value: [],
                  },
                  Modality: {
                    ...tableInfo.Modality,
                    value: [],
                  },
                  "Other Characteristics": {
                    ...tableInfo["Other Characteristics"],
                    value: [],
                  },
                  ROA: {
                    ...tableInfo.ROA,
                    value: [],
                  },
                  Target: {
                    ...tableInfo.Target,
                    value: [],
                  },
                  "Target Indication": {
                    ...tableInfo["Target Indication"],
                    value: [],
                  },
                  "Therapeutic Area": {
                    ...tableInfo["Therapeutic Area"],
                    value: [],
                  },
                  "Originator/Developer": {
                    ...tableInfo["Originator/Developer"],
                    value: [],
                  },
                  Phase: {
                    ...tableInfo?.Phase,
                    value: [],
                  },
                  "Generic / Biosimilar": {
                    ...tableInfo?.["Generic / Biosimilar"],
                    value: ["false"],
                  },
                  "Ref.Product Name": {
                    ...tableInfo?.["Ref.Product Name"],
                    value: [],
                  },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    setEntryData(clearDrugProfileGroupShell());
    changeCurrentHistory({
      entryData: clearDrugProfileGroupShell(),
      changedDataInfo: {
        target: "groupShell",
        action: "clear",
        position: {
          groupShellKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupTableKey,
          groupShellIndex,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const handleClickToDeleteDrugProfileGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
  };

  const handleKeyDownToDeleteDrugProfileGroupShell = (event: KeyboardEvent) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
  };

  return {
    handleClickToDeleteDrugProfileGroupShell,
    handleKeyDownToDeleteDrugProfileGroupShell,
  };
};
