import { CTGEntryData } from "@/types/ctgEntryData.types";
import { useUpdateCTGEntryDataMutate } from "@api/ctg/ctgEntryData/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useUpdateCTGEntryData = () => {
  const { mutateAsync: updateEntryDataMutate } = useUpdateCTGEntryDataMutate(
    getPressIdFromURL(),
  );

  const sectionCode = "TY007";

  const updateEntryData = async (
    entryData: CTGEntryData,
    isDbSaved?: boolean,
  ) => {
    await updateEntryDataMutate({
      entryData,
      isDbSaved: isDbSaved || false,
      sectionCode,
    });
  };

  return updateEntryData;
};
