"use client";

import styled from "styled-components";

export const ErrorMessage = styled.div`
  padding-top: 0.4rem;

  color: var(--Color-Common-Text-Warning, #e53e06);
  font-family: var(--font-pretendard);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;
