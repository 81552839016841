import { useEffect } from "react";

import { UseQueryOptions, useSuspenseQuery } from "@tanstack/react-query";

import { useGetCtgListFilterData } from "@/components/business/CtgListContainer/hooks/useGetCtgListFilterData";
import { QUERY_KEYS } from "@api/queryKeys";
import { ctgListApis } from "@services/ctgList.service";
import { useCtgListFilterState } from "@stores/ctgList";

import { queryClient } from "../../api/queryClient";

import type {
  CtgFilteringData,
  PostCtgListFilterResponse,
} from "@interface/ctgList.interface";

export const useCtgListFilterQuery = (
  options?: UseQueryOptions<
    PostCtgListFilterResponse,
    unknown,
    PostCtgListFilterResponse,
    [string, CtgFilteringData]
  >,
) => {
  const filteringData = useGetCtgListFilterData();

  const [, setCtgListFilter] = useCtgListFilterState();
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.ctgListFilter, filteringData],
    queryFn: async () => {
      const response = await ctgListApis.post(filteringData);
      return response;
    },
    staleTime: 0,
    refetchInterval: 2000,
    ...options,
  });

  useEffect(() => {
    setCtgListFilter((prev) => ({
      ...prev,
      maxPage: query.data.totalPage,
      ctgListDataTotal: query.data.totalCount,
    }));
  }, [query.data.totalCount, query.data.totalPage, setCtgListFilter]);

  return query;
};

export const invalidateCtgListFilterQuery = () => {
  return queryClient.invalidateQueries({
    queryKey: [QUERY_KEYS.ctgListFilter],
  });
};
