import styled from "styled-components";

import { BottomButton } from "../../PressClassificationContainer.style";

export const NextButton = styled(BottomButton)`
  background-color: #0198e9;
  color: #ffffff;
  border: 0.1rem solid #0198e9;
  padding: 0 1.6rem 0 2.4rem;

  &:disabled {
    background-color: #cbd0d8;
    color: #96a1b0;
    border: 0.1rem solid #cbd0d8;
  }
`;

export const AIInProgressButton = styled(BottomButton)`
  background-color: rgba(255, 153, 0, 0.1);
  color: ${({ theme }) => theme.color.status.progress};
  cursor: not-allowed;
  padding: 0 1.6rem 0 2.4rem;
`;

export const DoneButton = styled(BottomButton)`
  background-color: #0198e9;
  color: #ffffff;
  border: 0.1rem solid #0198e9;

  &:disabled {
    background-color: #cbd0d8;
    color: #96a1b0;
    border: 0.1rem solid #cbd0d8;
  }
`;
