import React from "react";

import { TargetListType } from "@/components/business/CTGFillInTableContainer/shared/hooks/useHandleTargetListIndex";

import * as S from "./TableCreatorDataSetDropdown.style";
import {
  DataSetDropdownList,
  DefaultDataSetType,
} from "../../types/tableCreatorDropdown.types";

interface Props {
  dataSetList: DataSetDropdownList[];
  targetListIndex: TargetListType;
  targetItemRef: React.RefObject<HTMLLIElement>;
  handleClickDataSetItem: (item: DefaultDataSetType) => void;
}

const TableCreatorDataSetDropdown = ({
  dataSetList,
  targetItemRef,
  targetListIndex,
  handleClickDataSetItem,
}: Props) => {
  const isDataSet = targetListIndex.key === "DATASET";

  return (
    <S.ListWrapper>
      <S.ListSectionTitle>Data Set</S.ListSectionTitle>
      {dataSetList.map((dataSet) =>
        dataSet.list.map((listItem, listIndex) => (
          <S.DictionaryItem
            key={`${String(listIndex)}`}
            data-dropdown="list-box"
            onMouseDown={() => handleClickDataSetItem(listItem)}
            $isTarget={isDataSet && listIndex === targetListIndex.listIndex}
            ref={
              isDataSet && listIndex === targetListIndex.listIndex
                ? targetItemRef
                : null
            }
          >
            {Object.entries(listItem).map(([key, value]) => (
              <S.DataSetWrapper key={`${key}-${value}`}>
                <S.DataSetSubTitle>[{key}]</S.DataSetSubTitle>
                <S.DataSetContent>{value}</S.DataSetContent>
              </S.DataSetWrapper>
            ))}
          </S.DictionaryItem>
        )),
      )}
    </S.ListWrapper>
  );
};

export default TableCreatorDataSetDropdown;
