export const QUERY_KEYS = {
  entryData: "entryData",
  initEntryData: "initEntryData",
  updateEntryData: "updateEntryData",
  pressInfo: "pressInfo",
  articleInfo: "articleInfo",
  authorityType: "authorityType",
  pageEntry: "pageEntry",
  pageEntryEditorWriting: "pageEntryEditorWriting",
  pressListFilter: "pressListFilter",
  editorRoles: "editorRoles",
  tableCreatorCommentList: "tableCreatorCommentList",
  tableCreatorCommentMessage: "tableCreatorCommentMessage",
  tableCreatorCommentStatus: "tableCreatorCommentStatus",
  temporaryToken: "temporaryToken",
  articleContentData: "articleContentData",
  allArticleData: "allArticleData",
  mainLabelInfo: "mainLabelInfo",
  mainLabel: "mainLabel",
  updateMainLabel: "updateMainLabel",
  pressCompany: "pressCompany",
  dropdownList: "dropdownList",
  clinicalTrialsIdentifier: "clinicalTrialsIdentifier",
  clinicalTrialsIdentifierAllCheck: "clinicalTrialsIdentifierAllCheck",
  pressContent: "pressContent",
  imageSearch: "imageSearch",
  aiImages: "aiImages",
  aiImagesInfo: "aiImagesInfo",
  displayDealTypeName: "displayDropdownName",
  desDealTypeName: "desDealTypeName",
  companyProfile: "companyProfile",
  companyLogoList: "companyLogoList",
  sellerAndBuyerByDealType: "sellerAndBuyerByDealType",
  getModalityCategory: "getModalityCategory",
  updateModalityCategory: "updateModalityCategory",
  pressContentBoldKeyword: "pressContentBoldKeyword",
  pressContentCurrencyKeyword: "pressContentCurrencyKeyword",
  pressCopy: "pressCopy",
  pressCopiedList: "pressCopiedList",
  imageKeyword: "imageKeyword",
  draftPressInfo: "draftPressInfo",
  similarData: "similarData",
  dealsOverlapped: "dealsOverlapped",
  dealsOverlappedUpdate: "dealsOverlappedUpdate",
  targetIndicationTherapeuticArea: "targetIndicationTherapeuticArea",
  ctgListFilter: "ctgListFilter",
};
