import React, { useEffect, useRef } from "react";

import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  TableDividerPlusBar,
  TableCreatorTableTitle,
} from "@FillInTableContainer/shared";
import {
  GroupTableKeyType,
  TableCreatorSubGroupGroupShellList,
} from "@FillInTableContainer/shared/types/tableCreator.types";
import {
  isAddDeleteTable,
  isGroupShellScroll,
  isTableScroll,
} from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";

import * as S from "./ClinicalTrialDesignTable.style";
import ClinicalTrialDesignGroupShell from "./components/ClinicalTrialDesignGroupShell/ClinicalTrialDesignGroupShell";
import { useHandleClinicalTrialsTableCreatorTable } from "./hooks/useHandleClinicalTrialsTableCreatorTable";

import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";

export interface TableInfoProps {
  groupTableKey: ClinicalTrialsSection;
  tableKey: string | null;
  groupShellList: TableCreatorSubGroupGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}
interface Props extends TableInfoProps {
  handleAddTable: (tableIndex: number) => void;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd: (groupTableKey: GroupTableKeyType) => void;
  handleDragOn: () => void;
  isDraggableTable: boolean;
}

const ClinicalTrialDesignTable = ({
  groupTableKey,
  tableKey,
  groupTableIndex,
  tableIndex,
  tableCount,
  groupShellList,
  handleAddTable,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
  isDraggableTable,
}: Props) => {
  const articleSection = useArticleSection();
  const tablePropsInfo: TableInfoProps = {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    tableCount,
    groupShellList,
  };
  const tableTitleRef = useRef<HTMLDivElement>(null);

  const {
    focusStatus,
    isFocused,
    isLastTable,
    isFocusedAddButton,
    handleClickDeleteButton,
    focusEventToChangePositionInfo,
    changeFocusStatus,
    changePositionInfo,
    changePositionInfoToDefault,
    handleDefaultFocus,
  } = useHandleClinicalTrialsTableCreatorTable(tablePropsInfo);

  const isClearTable =
    isFocused &&
    focusStatus.type === "clear" &&
    focusStatus.container === "table";

  const isDeleteTable =
    isFocused &&
    focusStatus.type === "delete" &&
    focusStatus.container === "table";

  const handleFocusTableTitle = () => {
    focusEventToChangePositionInfo({
      type: "clear",
      container: "table",
    });
  };

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;

        const clickSelectButton = tableTitleRef.current?.contains(target);

        if (clickSelectButton) return;
        if (target.dataset.button) return;
        if (target.dataset.title) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isFocused]);

  return (
    <>
      {isAddDeleteTable(articleSection, tableKey) && (
        <TableDividerPlusBar
          tableTitle={getTableTitle(articleSection, tableKey) || ""}
          isLastPosition={false}
          onClick={() => handleAddTable(tableIndex)}
          isVisibility={isFocusedAddButton}
        />
      )}
      <S.TableWrapper
        className="table-wrapper"
        isFocused={isFocused}
        isClearTable={isClearTable}
        isDeleteTable={isDeleteTable}
        isAddTable={isAddDeleteTable(articleSection, tableKey)}
        isDraggingTable={isFocused && focusStatus.type === "dragging"}
      >
        {(isClearTable || isDeleteTable) && (
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton
              data-button="delete-button"
              onClick={handleClickDeleteButton}
            >
              <img src={IconXMark} alt="button for clear table" />
              {focusStatus.type === "clear" ? `Clear Clinical Design` : null}
              {focusStatus.type === "delete" ? `Delete Clinical Design` : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <S.ScrollContentsWrapper>
          <div ref={tableTitleRef}>
            {getTableTitle(articleSection, tableKey) && (
              <TableCreatorTableTitle
                tableKey={tableKey}
                groupTableKey={groupTableKey}
                groupTableIndex={groupTableIndex}
                tableIndex={tableIndex}
                isDragging={isFocused && focusStatus.type === "dragging"}
                onClickHandler={handleFocusTableTitle}
                handleDragStart={handleDragStart}
                handleDragEnd={handleDragEnd}
                handleDragOn={handleDragOn}
                isDraggableTable={isDraggableTable}
                changeFocusStatusForDragging={() => {
                  changePositionInfo();
                  changeFocusStatus({
                    type: "dragging",
                    container: "table",
                  });
                }}
                changeFocusStatusForDragEnd={() => {
                  changePositionInfoToDefault();
                  changeFocusStatus({
                    type: "default",
                    container: "noFocus",
                  });
                }}
              />
            )}
          </div>
          <S.GroupShellListWrapper>
            {groupShellList.map(
              ({ groupShellKey, subGroupShellList }, groupShellListIndex) => (
                <S.GroupShellWrapper
                  key={`groupShellList-${String(groupShellListIndex)}`}
                >
                  <ClinicalTrialDesignGroupShell
                    key={`group-shell-${String(groupTableIndex)}-${String(
                      tableIndex,
                    )}-${String(groupShellListIndex)}`}
                    subGroupShellList={subGroupShellList}
                    groupTableKey={groupTableKey}
                    groupTableIndex={groupTableIndex}
                    tableKey={tableKey}
                    tableIndex={tableIndex}
                    groupShellKey={groupShellKey}
                    groupShellIndex={groupShellListIndex}
                    isTableScroll={
                      isTableScroll(articleSection, tableKey) ||
                      isGroupShellScroll(articleSection, groupShellKey)
                    }
                  />
                </S.GroupShellWrapper>
              ),
            )}
          </S.GroupShellListWrapper>
        </S.ScrollContentsWrapper>
      </S.TableWrapper>
      {tableCount > 1 && <S.TableDivider />}
      {isAddDeleteTable(articleSection, tableKey) && (
        <TableDividerPlusBar
          tableTitle={getTableTitle(articleSection, tableKey) || ""}
          onClick={() => handleAddTable(tableIndex + 1)}
          isLastPosition
          isVisibility={isLastTable && isFocusedAddButton}
        />
      )}
    </>
  );
};

export default ClinicalTrialDesignTable;
