import { useCallback, useEffect, useRef } from "react";

import {
  VENTURE_FORMATION_GROUP_SHELL_KEYS,
  VENTURE_FORMATION_TABLE_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import { useSellerAndBuyerByDealTypeQuery } from "@api/sellerAndBuyerByDealType/sellerAndBuyerByDealType";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorTableTitle } from "@FillInTableContainer/shared";
import GroupShellTitleDescriptionContainer from "@FillInTableContainer/shared/components/GroupShellTitleDescriptionContainer/GroupShellTitleDescriptionContainer";
import TableDragDivider from "@FillInTableContainer/shared/components/TableDragDivider/TableDragDivider";
import { useCurrentDealTypeValue } from "@FillInTableContainer/shared/context/CurrentDealTypeContext";
import useCheckIsDisplayGroupShellDragDivider from "@FillInTableContainer/shared/hooks/useCheckIsDisplayGroupShellDragDivider";
import { useHandleTableCreatorGroupShellDrag } from "@FillInTableContainer/shared/hooks/useHandleTableCreatorGroupShellDrag";
import { TableCreatorSubGroupGroupShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { getTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import DealTermsVentureFormationGroupShell from "./components/DealTermsVentureFormationGroupShell/DealTermsVentureFormationGroupShell";
import * as S from "./DealTermsVentureFormationTable.style";
import { useHandleDealTermsMergeTableCreatorTable } from "./hooks/useHandleDealTermsMergeTableCreatorTable";

interface Props {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupShellList: TableCreatorSubGroupGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
}

const ARTICLE_SECTION = "Deals";

const isDraggingGroupShellKey = "Rights Scope";

const DealTermsVentureFormationTable = ({
  groupShellList,
  groupTableIndex,
  tableIndex,
  tableKey,
  groupTableKey,
}: Props) => {
  const articleSection = useArticleSection();
  const tableTitleRef = useRef<HTMLDivElement>(null);
  const groupShellTitleRef = useRef<HTMLDivElement[]>([]);
  const clearButtonRef = useRef<HTMLDivElement>(null);

  const tablePropsInfo: Props = {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    groupShellList,
  };

  const {
    focusStatus,
    isFocused,
    isClearGroupShellSection,
    handleClickClearButton,
    numberOfGroupShellCount,
    focusEventToChangePositionInfo,
    handleChangeDefaultFocus,
    handleFocusGroupShellSection,
    handleClickClearGroupShellSectionButton,
  } = useHandleDealTermsMergeTableCreatorTable(tablePropsInfo);

  const {
    handleGroupShellDragEnd,
    handleGroupShellDragOn,
    handleGroupShellDragOver,
    handleGroupShellDragStart,
    groupShellDraggedIndexInfo,
    isDraggingGroupShell,
  } = useHandleTableCreatorGroupShellDrag(ARTICLE_SECTION, tableIndex);

  const { currentDealTypeList } = useCurrentDealTypeValue();

  const { data: sellerBuyerInfo } =
    useSellerAndBuyerByDealTypeQuery(currentDealTypeList);

  const { isLastGroupShell, handleCheckDragDividerDisplay } =
    useCheckIsDisplayGroupShellDragDivider(
      tableIndex,
      isDraggingGroupShell,
      isDraggingGroupShellKey,
    );

  const customTableTitle = () => {
    if (tableKey === VENTURE_FORMATION_TABLE_KEYS.newCompany)
      return sellerBuyerInfo.data.seller;

    if (tableKey === VENTURE_FORMATION_TABLE_KEYS.existingCompany)
      return sellerBuyerInfo.data.buyer;

    return "";
  };

  const isClearTable =
    isFocused &&
    focusStatus.type === "clear" &&
    focusStatus.container === "table";

  const title = getTableTitle(articleSection, tableKey);

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;

        const clickSelectButton = tableTitleRef.current?.contains(target);
        const clickGroupShellSectionButton = groupShellTitleRef.current?.some(
          (ref) => ref?.contains(target),
        );

        if (clickSelectButton) return;
        if (target.dataset.button) return;
        if (clickGroupShellSectionButton) return;
        handleChangeDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleChangeDefaultFocus, isFocused]);

  const handleFocusTableTitle = () => {
    focusEventToChangePositionInfo({
      type: "clear",
      container: "table",
    });
  };

  const handleGetCustomGroupShellTitle = (groupShellKey: string | null) => {
    if (groupShellKey === VENTURE_FORMATION_GROUP_SHELL_KEYS.rightsScope)
      return "Asset Transfer";
    else if (
      groupShellKey === VENTURE_FORMATION_GROUP_SHELL_KEYS.investmentTerms
    )
      return "Investment Terms";
    else if (
      groupShellKey === VENTURE_FORMATION_GROUP_SHELL_KEYS.otherFinancialTerms
    )
      return "Other Financial Terms";
    else return "";
  };

  const groupShellSectionDescription = (groupShellKey: string | null) => {
    if (groupShellKey === VENTURE_FORMATION_GROUP_SHELL_KEYS.rightsScope)
      return "(Existing Company →  New company)";
    else if (
      groupShellKey === VENTURE_FORMATION_GROUP_SHELL_KEYS.investmentTerms
    )
      return "(Existing Company →  New company)";

    return "";
  };

  const addItemRefs = useCallback(
    (element: HTMLDivElement | null, itemIndex: number) => {
      if (!element) return;

      groupShellTitleRef.current[itemIndex] = element;
    },
    [],
  );

  return (
    <S.TableWrapper isFocused={isFocused} isClearTable={isClearTable}>
      {isClearTable && (
        <S.DeleteTableButtonWrapper>
          <S.DeleteTableButton
            data-button="delete-button"
            onClick={handleClickClearButton}
          >
            <img src={IconXMark} alt="button for clear table" />
            {focusStatus.type === "clear" ? `Clear ${title} Table` : null}
            {focusStatus.type === "delete" ? `Delete ${title} Table` : null}
          </S.DeleteTableButton>
        </S.DeleteTableButtonWrapper>
      )}

      <div ref={tableTitleRef}>
        {getTableTitle(articleSection, tableKey) && (
          <TableCreatorTableTitle
            tableKey={tableKey}
            groupTableKey={groupTableKey}
            groupTableIndex={groupTableIndex}
            tableIndex={tableIndex}
            onClickHandler={handleFocusTableTitle}
            tableTitle={customTableTitle()}
          />
        )}
      </div>
      <S.GroupShellSpaceWrapper>
        {groupShellList.map(
          ({ groupShellKey, subGroupShellList }, groupShellListIndex) => (
            <S.GroupShellSectionWrapper
              key={`groupShellList-${String(groupShellListIndex)}`}
              isFocused={isClearGroupShellSection(groupShellKey)}
            >
              {isClearGroupShellSection(groupShellKey) && (
                <S.FixedDeleteButtonWrapper ref={clearButtonRef}>
                  <S.DeleteGroupShellSectionButtonWrapper>
                    <S.DeleteGroupShellSectionButton
                      data-button="delete-button"
                      onClick={() =>
                        handleClickClearGroupShellSectionButton(groupShellKey)
                      }
                    >
                      <img src={IconXMark} alt="button for clear Row" />
                      Clear {handleGetCustomGroupShellTitle(groupShellKey)}
                    </S.DeleteGroupShellSectionButton>
                  </S.DeleteGroupShellSectionButtonWrapper>
                </S.FixedDeleteButtonWrapper>
              )}
              {/* ❗️아예 별개로 추가된 부분, 여기만 GroupShellSection으로 동작하도록 되어야 할 듯 */}
              {handleGetCustomGroupShellTitle(groupShellKey) !== "" && (
                <div ref={(e) => addItemRefs(e, groupShellListIndex)}>
                  <GroupShellTitleDescriptionContainer
                    mainGroupShellTitle={handleGetCustomGroupShellTitle(
                      groupShellKey,
                    )}
                    description={groupShellSectionDescription(groupShellKey)}
                    titleFontSize="1.4rem"
                    onClickHandler={() =>
                      handleFocusGroupShellSection(groupShellKey)
                    }
                  />
                </div>
              )}

              {newArrayToCountValue(numberOfGroupShellCount(groupShellKey)).map(
                (_, groupShellIndex) => (
                  <S.DraggableGroupShellWrapper
                    isTargetDraggingTable={
                      isDraggingGroupShell &&
                      groupShellDraggedIndexInfo[0] !== groupShellIndex
                    }
                    onDragOver={(e) =>
                      handleGroupShellDragOver(e, groupShellIndex)
                    }
                  >
                    <TableDragDivider
                      isDraggingEndPosition={handleCheckDragDividerDisplay({
                        moveDragIndex: groupShellDraggedIndexInfo[1],
                        startDragIndex: groupShellDraggedIndexInfo[0],
                        groupShellIndex,
                        groupShellKey,
                      })}
                    />

                    <DealTermsVentureFormationGroupShell
                      key={`${String(groupShellListIndex)}-${String(
                        groupShellIndex,
                      )}`}
                      subGroupShellList={subGroupShellList}
                      groupTableKey={groupTableKey}
                      groupTableIndex={groupTableIndex}
                      tableKey={tableKey}
                      tableIndex={tableIndex}
                      groupShellKey={groupShellKey}
                      groupShellIndex={groupShellIndex}
                      groupShellCount={numberOfGroupShellCount(groupShellKey)}
                      handleDragEnd={handleGroupShellDragEnd}
                      handleDragOn={handleGroupShellDragOn}
                      handleDragStart={handleGroupShellDragStart}
                      isDraggableGroupShell={isDraggingGroupShell}
                      isFocusGroupShellSection={isClearGroupShellSection(
                        groupShellKey,
                      )}
                    />
                    {isLastGroupShell(groupShellIndex, groupShellKey) && (
                      <TableDragDivider
                        isDraggingEndPosition={handleCheckDragDividerDisplay({
                          moveDragIndex: groupShellDraggedIndexInfo[1],
                          startDragIndex: groupShellDraggedIndexInfo[0],
                          groupShellIndex,
                          groupShellKey,
                          lastIndex: newArrayToCountValue(
                            numberOfGroupShellCount(groupShellKey),
                          ).length,
                        })}
                      />
                    )}
                  </S.DraggableGroupShellWrapper>
                ),
              )}
            </S.GroupShellSectionWrapper>
          ),
        )}
      </S.GroupShellSpaceWrapper>
    </S.TableWrapper>
  );
};

export default DealTermsVentureFormationTable;
