import { ProcessStatusCode } from "@/types/code.types";

export interface StatusFilterValue {
  code: ProcessStatusCode;
  name: string;
  isChecked: boolean;
  isColorful: boolean;
}

export type StatusFilterKey = "Store to DB" | "ETC";

export type StatusFilterList = Record<StatusFilterKey, StatusFilterValue[]>;

export const STATUS_DB_SAVED_LIST: StatusFilterValue[] = [
  {
    code: "S112",
    name: "Not for AI Entry",
    isChecked: false,
    isColorful: true,
  },
  {
    code: "S300",
    name: "AI Entry in Progress",
    isChecked: false,
    isColorful: true,
  },
  {
    code: "S111",
    name: "AI Entry Done",
    isChecked: false,
    isColorful: true,
  },

  {
    code: "S120",
    name: "Review in Progress",
    isChecked: false,
    isColorful: true,
  },
  {
    code: "S910",
    name: "DB Saved",
    isChecked: false,
    isColorful: true,
  },
];

export const STATUS_ETC_LIST: StatusFilterValue[] = [
  {
    code: "S930",
    name: "Unsuitable",
    isChecked: false,
    isColorful: true,
  },
];

export const STATUS_FILTER_LIST: StatusFilterList = {
  "Store to DB": STATUS_DB_SAVED_LIST,
  ETC: STATUS_ETC_LIST,
};
