import React, { useRef } from "react";

import { useContentInfo } from "@/components/shared/business/UnsuitableReason/hooks/useContentInfo";
import { useSetUnsuitableArticleInfoMutation } from "@api/articleInfo/useArticleInfo";
import { useUnsuitablePressCancelMutation } from "@api/unsuitablePress/unsuitablePress";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { trimCharacter } from "@utils/trimCharacter";

import { UNSUITABLE_REASON_KEYS } from "../../../../../constants/unsuitableReason.constants";
import {
  ADD_ID_TYPE_VALUES,
  UNSUITABLE_REASON_TYPE_INFO,
  UnsuitableReasonList,
  UnsuitableReasonType,
} from "../constants/UnsuitableReason.constants";
import { InfoForSelectedRejectReason } from "../UnsuitableReasonCheckItem";

interface UseHandleUnsuitableReasonItemReturn {
  handleCheckTargetReason: (type: UnsuitableReasonType) => void;
  handleDeleteReasonInput: (type: UnsuitableReasonType) => void;
  handleKeyDownEnterSavedReason: (e: React.KeyboardEvent) => void;
  handleFocusOutAddIdReason: (type: UnsuitableReasonType) => void;
  handleClickCheckBox: (type: UnsuitableReasonType) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

export const useHandleUnsuitableReasonItem = (
  infoForSelectedRejectReason: InfoForSelectedRejectReason,
): UseHandleUnsuitableReasonItemReturn => {
  const {
    reasonId,
    unsuitablePressReasonList,
    setUnsuitablePressReasonList,
    draftArticleId,
    unsuitableReasonUsageType,
    closeModalUnsuitablePress,
  } = infoForSelectedRejectReason;

  const { data: articleInfo } = useContentInfo(Number(draftArticleId));
  const { mutateAsync: turnOnUnsuitable } =
    useSetUnsuitableArticleInfoMutation();
  const { mutateAsync: turnOffUnsuitable } = useUnsuitablePressCancelMutation();

  const inputRef = useRef<HTMLInputElement>(null);

  const submitUnsuitableReason = async (
    unsuitableReason: string | null,
    overlappedPressId: string | null,
  ) => {
    const isUnsuitableReasonToAddId = ADD_ID_TYPE_VALUES.includes(
      unsuitableReason || "",
    );

    if (isUnsuitableReasonToAddId && !overlappedPressId) return;

    const mcEditorName = trimCharacter(articleInfo?.mcEditorName);

    const unsuitablePressRequestData =
      mcEditorName === null || mcEditorName === ""
        ? {
            draftArticleId,
            draftArticleStatus: DRAFT_STATUS_CODE.unsuitable,
            articleRejectReason: unsuitableReason ?? "",
            duplArticleId:
              isUnsuitableReasonToAddId && !!overlappedPressId
                ? Number(overlappedPressId.replace(/[^0-9]/g, ""))
                : undefined,
            type: "MC",
            isCheckStartDate: "true",
            publishable: false,
          }
        : {
            draftArticleId,
            draftArticleStatus: DRAFT_STATUS_CODE.unsuitable,
            articleRejectReason: unsuitableReason ?? "",
            duplArticleId:
              isUnsuitableReasonToAddId && !!overlappedPressId
                ? Number(overlappedPressId.replace(/[^0-9]/g, ""))
                : undefined,
            publishable: false,
          };

    if (!!unsuitableReason && unsuitableReason.length > 0) {
      await turnOnUnsuitable({
        ...unsuitablePressRequestData,
      });

      if (
        closeModalUnsuitablePress &&
        (unsuitableReasonUsageType === UNSUITABLE_REASON_KEYS.pressList ||
          unsuitableReasonUsageType === UNSUITABLE_REASON_KEYS.ctgList)
      ) {
        closeModalUnsuitablePress();
      }

      return;
    }

    turnOffUnsuitable(Number(draftArticleId));
  };

  const handleCheckDefaultReason = (reasonItem: UnsuitableReasonList) => {
    const targetChange = {
      ...reasonItem,
      duplId: null,
      isTarget: !reasonItem.isTarget,
    };

    const newTargetUnsuitableReasonList = unsuitablePressReasonList.map(
      (item) => {
        if (item.id === reasonItem.id) {
          return targetChange;
        }

        return {
          ...item,
          isTarget: false,
          duplId: null,
        };
      },
    );

    setUnsuitablePressReasonList(newTargetUnsuitableReasonList);
    submitUnsuitableReason(
      targetChange.isTarget ? targetChange.reason : "",
      null,
    );
  };

  const handleCheckWriteReason = (
    reasonItem: UnsuitableReasonList,
    type: UnsuitableReasonType,
  ) => {
    const targetChange =
      type === "addId"
        ? {
            ...reasonItem,
            duplId: Number(inputRef.current?.value) || null,
            isTarget: !!inputRef.current?.value,
          }
        : {
            ...reasonItem,
            reason: inputRef.current?.value || "",
            duplId: null,
            isTarget: !!inputRef.current?.value,
          };

    const newTargetUnsuitableReasonList = unsuitablePressReasonList.map(
      (item) => {
        if (item.id === reasonItem.id) {
          return targetChange;
        }

        return {
          ...item,
          duplId: null,
          isTarget: false,
        };
      },
    );

    setUnsuitablePressReasonList(newTargetUnsuitableReasonList);
  };

  const handleFocusOutAddIdReason = (type: UnsuitableReasonType) => {
    const [currentTarget] = unsuitablePressReasonList.filter(
      (reasonItem) => reasonItem.id === reasonId,
    );

    const currentOverlapId = inputRef.current?.value || null;
    const currentReason = currentTarget.isTarget ? currentTarget.reason : "";

    submitUnsuitableReason(
      currentReason,
      type === "addId" ? currentOverlapId : null,
    );
  };

  const handleCheckTargetReason = (type: UnsuitableReasonType) => {
    const writingTargetValue = inputRef.current?.value || "";

    const isNotDefaultType =
      type === UNSUITABLE_REASON_TYPE_INFO.writing ||
      type === UNSUITABLE_REASON_TYPE_INFO.addId;

    if (isNotDefaultType && !writingTargetValue) inputRef.current?.focus();

    const [currentTarget] = unsuitablePressReasonList.filter(
      (reasonItem) => reasonItem.id === reasonId,
    );

    if (type === UNSUITABLE_REASON_TYPE_INFO.default) {
      return handleCheckDefaultReason(currentTarget);
    }

    if (isNotDefaultType) {
      return handleCheckWriteReason(currentTarget, type);
    }
  };

  const handleKeyDownEnterSavedReason = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") inputRef.current?.blur();
  };

  const handleDeleteReasonInput = (type: UnsuitableReasonType) => {
    inputRef.current?.blur();

    if (!inputRef.current) return;

    inputRef.current.value = "";

    const [currentTarget] = unsuitablePressReasonList.filter(
      (reasonItem) => reasonItem.id === reasonId,
    );

    handleCheckWriteReason(currentTarget, type);
  };

  const handleClickCheckBox = (type: UnsuitableReasonType) => {
    if (type === "default") return;

    const [currentTarget] = unsuitablePressReasonList.filter(
      (reasonItem) => reasonItem.id === reasonId,
    );

    if (currentTarget.isTarget && inputRef.current) inputRef.current.value = "";
  };

  return {
    handleCheckTargetReason,
    handleDeleteReasonInput,
    handleKeyDownEnterSavedReason,
    handleFocusOutAddIdReason,
    handleClickCheckBox,
    inputRef,
  };
};
