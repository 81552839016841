import { ClassificationCode, ProcessStatusCode } from "@/types/code.types";
import { getCookie } from "@utils/cookie";

import { EDITOR_ROLES } from "../constants/editorRoles.constants";
import { FULL_TABLE_ROLES_LIST } from "../constants/fillInTableRolesList.constants";
import { CLASSIFICATION_KEYS_CODE } from "../constants/returnRolesList.constants";
import { EditorRoleCode } from "../types/roles.types";

export const checkEditFullTableRoles = (
  isSuperAdmin: boolean,
  editorRoles: EditorRoleCode[],
  status: ProcessStatusCode,
  classification?: ClassificationCode | "CTG",
  fillInTableEditor2?: string | null,
) => {
  if (!classification) return false;
  if (isSuperAdmin) return true;

  const isEnableEnrollEditor =
    getCookie("accountName") === fillInTableEditor2 ||
    fillInTableEditor2 === "" ||
    fillInTableEditor2 === null;

  const checkEditorRolesAccess = (role: EditorRoleCode) => {
    return (
      editorRoles.includes(role) &&
      FULL_TABLE_ROLES_LIST[role].includes(status) &&
      isEnableEnrollEditor
    );
  };

  const handleStatusClassificationMatch = () => {
    switch (classification) {
      case CLASSIFICATION_KEYS_CODE.clinicalTrials:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0202);
      case CLASSIFICATION_KEYS_CODE.clinicalTrialStatus:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0303);
      case CLASSIFICATION_KEYS_CODE.posterContents:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0402);
      case CLASSIFICATION_KEYS_CODE.deals:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0503);
      case CLASSIFICATION_KEYS_CODE.expeditedProgram:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0603);
      case CLASSIFICATION_KEYS_CODE.approvalProcess:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0703);
      case CLASSIFICATION_KEYS_CODE.ventureCapital:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0803);
      case "CTG":
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0903);
      default:
        return false;
    }
  };

  return handleStatusClassificationMatch();
};
