"use client";

import styled, { css } from "styled-components";

const borderStyle = (
  $status: "focus" | "error" | "disabled" | "default" | "filled" | "pressed",
) => {
  switch ($status) {
    case "focus":
      return `1px solid var(--Color-Common-Border-Focus-Ring, #017abc)`;
    case "error":
      return `1px solid var(--Color-Common-Border-Warning, #e53e06)`;
    case "disabled":
      return `1px solid var(--Color-Common-Border-Disabled, #b1b8c4)`;
    case "filled":
      return `1px solid var(--Color-Common-Border-Disabled, #b1b8c4)`;
    default:
      return `1px solid var(--Color-Common-Border-Interactive-Secondary, #B1B8C4)`;
  }
};

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectFieldWrapper = styled.div<{
  $width: string;
  $height: string;
  $status: "focus" | "error" | "disabled" | "default" | "filled";
  $styleType: "line" | "transparent";
  $isEmpty: boolean;
}>`
  display: flex;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: var(--Spacing-4, 0.4rem) var(--Spacing-8, 0.8rem);
  align-items: center;
  gap: var(--Spacing-4, 0.4rem);
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Primary, #fff);
  cursor: pointer;

  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      .left-icon {
        opacity: 0.5;
      }
    `}

  ${({ $styleType, $status }) =>
    $styleType === "line" &&
    css`
      border: ${borderStyle($status)};

      input::placeholder {
        opacity: 0.5;
      }

      ${$status !== "disabled" &&
      css`
        &:hover {
          border: 1px solid
            var(--Color-Common-Border-Interactive-Secondary-Hovered, #96a1b0);
        }

        &:focus-within {
          border: 1px solid var(--Color-Common-Border-Focus-Ring, #017abc);

          input {
            color: #515c6c;
          }
        }

        &:focus {
          input {
            color: black;
          }
        }

        &:active {
          border: 1px solid
            var(--Color-Common-Border-Interactive-Secondary-Pressed, #7c899c);
        }
      `}
    `}

  ${({ $styleType, $status }) =>
    $styleType === "transparent" &&
    css`
      border: none;

      ${$status !== "disabled" &&
      css`
        &:hover {
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16),
            0px 0px 4px 0px rgba(0, 0, 0, 0.12);
        }

        &:focus-within {
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
            0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;

          input {
            color: #515c6c;
          }
        }

        &:active {
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
            0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;
        }
      `}
    `}

  ${({ $status }) =>
    $status === "disabled" &&
    css`
      cursor: not-allowed;
      background: var(--Color-Common-Bg-Disabled, #cbd0d8);
    `}
`;

export const SelectFieldInput = styled.input<{
  $isFilledTextColor: boolean;
}>`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 130%;
  width: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;

  /* color: ${({ $isFilledTextColor }) =>
    $isFilledTextColor
      ? "var(--Color-Common-Text-Interactive-Primary, #0198E9)"
      : "var(--Color-Common-Text-Primary, #2a2f37)"}; */
  background-color: transparent;

  &::placeholder {
    color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  }

  &:disabled {
    color: var(--Color-Common-Text-Disabled, #96a1b0);
    cursor: not-allowed;
  }
`;

export const DeleteButton = styled.button`
  display: flex;
  width: 2.4rem;
  height: 2.4rem;
  align-items: center;
`;
