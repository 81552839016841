import styled from "styled-components";

import IconCheckedDefault from "@images/pressList/icon-column-check.svg";

export const CheckboxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 1rem;
  color: ${({ theme }) => theme.color.neural.gray08};
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: -0.012rem;
  text-align: left;

  &:has(input:disabled) {
    cursor: not-allowed;
  }
  &:hover {
    background: transparent;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const CheckboxInput = styled.input`
  all: unset;
  display: inline-block;
  width: 0rem;
  height: 0rem;
  overflow: hidden;
  cursor: pointer;

  &:checked {
    background: var(--Color-Bg-Inverse-Error, #db0f72);
    background-image: url(${IconCheckedDefault});
    background-repeat: no-repeat;
    background-position: center;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const UnsuitablePosition = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;
