import { useCallback, useState } from "react";

import { DataSetDropdownList } from "../components/TableCreatorListBox/types/tableCreatorDropdown.types";

const useGetDataSetDropdown = () => {
  const [dataSetList, setDataSetList] = useState<DataSetDropdownList[]>([]);

  const changeCurrentRecommendDatasetList = useCallback(
    (newDatasetList: DataSetDropdownList[]) => {
      setDataSetList(newDatasetList);
    },
    [],
  );

  return {
    initialDataSetList: [],
    changeCurrentRecommendDatasetList,
    dataSetList,
  };
};

export default useGetDataSetDropdown;
