import {
  checkIsCrossLicenseDealType,
  CROSS_LICENSE_AGREEMENT_GROUP_TABLE_KEY,
} from "@frontend/common";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { useEntryDataValue } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { SHELL_PATH_TO_READ_SHELL_INFO } from "../constants/tableCreatorShell.constants";
import { useCurrentDealTypeValue } from "../context/CurrentDealTypeContext";

import type { EntryDataValueInfo } from "@/types/clinicalTrials.types";

const SHELL_KEYS_OPTIONS_WITH_ENTRY_DATA = [
  "Licensor Name",
  "Licensor Responsibility & Rights",
  "Licensee Name",
  "Licensee Responsibility & Rights (SC & LA)",
  "Licensee Responsibility & Rights (MA)",
  "Licensee Responsibility & Rights (VF)",
];

interface UseGetInfoOfShellReturn {
  shellValue: (
    shellKey: string,
    tableIndex: number,
    groupShellIndex: number,
    shellIndex: number,
    subGroupShellIndex?: number,
  ) => {
    value: EntryDataValueInfo[];
    options: string[];
    attributionId?: string;
  };
}

export const useGetInfoOfShell = (
  groupTableKey?: string,
): UseGetInfoOfShellReturn => {
  const entryData = useEntryDataValue(getPressIdFromURL());
  const initEntryData = useInitEntryDataValue(getPressIdFromURL());
  const articleSection = useArticleSection();
  const { currentDealTypeList } = useCurrentDealTypeValue();

  const isCrossLicenseAgreement =
    checkIsCrossLicenseDealType(currentDealTypeList);

  const shellValue = (
    shellKey: string,
    tableIndex: number,
    groupShellIndex: number,
    shellIndex: number,
    subGroupShellIndex?: number,
  ) => {
    if (!SHELL_PATH_TO_READ_SHELL_INFO[articleSection][shellKey]) {
      return {
        value: [],
        options: [],
      };
    }
    const currentValue = SHELL_PATH_TO_READ_SHELL_INFO[articleSection][
      shellKey
    ](
      entryData,
      tableIndex,
      groupShellIndex,
      shellIndex,
      subGroupShellIndex || 0,
    ).value;

    const isWithEntryDataOptions =
      SHELL_KEYS_OPTIONS_WITH_ENTRY_DATA.includes(shellKey);

    // NOTE: init data에서 Licensee table이 1개인 경우에도 대응되도록 하기 위해 init data가 아닌 entry data를 바라보도록 설정
    // NOTE: 만약 Init data의 licensee table이 1개인 경우에는 TableIndex를 0으로 하고, 2개인 경우에는 받은 tableIndex를 사용할 수 있도록 변경
    const haveTwoLicenseeTable =
      initEntryData?.Deals?.["Licensee Table"]?.length === 2;
    const crossLicenseTableIndex = haveTwoLicenseeTable ? tableIndex : 0;

    const crossLicenseDropdownOption = SHELL_PATH_TO_READ_SHELL_INFO[
      articleSection
    ][shellKey](initEntryData, crossLicenseTableIndex, 0, 0, 0)?.options;

    const initOptions = SHELL_PATH_TO_READ_SHELL_INFO[articleSection][shellKey](
      isWithEntryDataOptions ? entryData : initEntryData,
      0, // NOTE: TableIndex
      0, // NOTE: GroupShellIndex
      0, // NOTE: ShellIndex
      0, // NOTE: SubGroupShellIndex
    )?.options;

    const displayCrossLicenseDropdownOption =
      groupTableKey === CROSS_LICENSE_AGREEMENT_GROUP_TABLE_KEY &&
      isCrossLicenseAgreement;

    const currentDropdown = displayCrossLicenseDropdownOption
      ? crossLicenseDropdownOption
      : initOptions;

    return {
      value: currentValue,
      options: currentDropdown || [],
    };
  };
  return {
    shellValue,
  };
};
