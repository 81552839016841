import React from "react";

import { DealDBSnapshotCategory } from "../../../types";
import ModalityIcon from "../../components/ModalityIcon/ModalityIcon";
import {
  THERAPEUTIC_AREA_MESH_FONT_COLOR_BY_GROUP_NAME,
  THERAPEUTIC_AREA_MESH_ICONS_BY_GROUP_NAME,
  THERAPEUTIC_AREA_MESH_MAIN_COLOR_BY_GROUP_NAME,
} from "../therapeuticArea.constants";

export const DEAL_SNAPSHOT_KEYS = {
  modality: "MODALITY",
  therapeuticArea: "THERAPEUTIC_AREA",
  modalityCategory: "modalityCategory",
  therapeuticsArea: "therapeuticsArea",
  phase: "PHASE",
  territoryBuyer: "TERRITORY_BUYER",
  territorySeller: "TERRITORY_SELLER",
  bigPharma: "BIG_PHARMA",
} as const;

export const MODALITY_GROUP_NAME = {
  antibody: "antibody",
  cellTherapy: "cell therapy",
  exosome: "exosome",
  bloodDerivatives: "blood derivatives",
  biologicallyDerivedExtracts: "biologically derived extracts",
  genetherapy: "gene therapy",
  imagingAgent: "imaging agent",
  microbiome: "microbiome",
  macromolecule: "macro molecule",
  radiopharmaceutical: "radiopharmaceutical",
  smallMolecule: "small molecule",
  therapeuticProtein: "therapeutic protein",
  vaccine: "vaccine",
  undisclosed: "undisclosed",
  functionBased: "function based",
  other: "other",
} as const;

export const THERAPEUTIC_AREA_GROUP_NAME = {
  oncology: "oncology",
  immunology: "immunology",
  neurology: "neurology",
  undisclosed: "undisclosed",
  infectiousDisease: "infectious disease",
  metabolicEndocrine: "metabolic/endocrine",
  eye: "eye",
  psychiatry: "psychiatry",
  musculoskeletal: "musculoskeletal",
  dermatology: "dermatology",
  cardiovascular: "cardiovascular",
  genitourinary: "genitourinary",
  etc: "etc",
  gastrointestinal: "gastrointestinal",
  blood: "blood",
  pulmonary: "pulmonary",
  other: "other",
} as const;

export const PHASE_GROUP_NAME = {
  preclinical: "preclinical",
  phase1: "phase 1",
  phase2: "phase 2",
  phase3: "phase 3",
  phase4: "phase 4",
  approved: "approved",
  discontinued: "discontinued",
  other: "other",
  ndaBla: "nda/bla",
  undisclosed: "undisclosed",
  clinical: "clinical",
  screeningUndisclosedUndefined: "screening/undisclosed/undefined",
  screening: "screening",
  pivot: "pivot",
} as const;

export const TERRITORY_GROUP_NAME = {
  northAmerica: "north america",
  centralAmerica: "central america",
  caribbean: "caribbean",
  southAmerica: "south america",
  europe: "europe",
  europeRussiaCIS: "europe/russia/cis",
  asia: "asia",
  oceaniaAfrica: "oceania/africa",
  oceania: "oceania",
  africa: "africa",
  other: "other",
  undisclosed: "undisclosed",
};

export const BIG_PHARMA_GROUP_NAME = {
  lilly: "lilly",
  msd: "msd",
  abbvie: "abbvie",
  roche: "roche",
  sanofi: "sanofi",
  novartis: "novartis",
  takeda: "takeda",
  gsk: "gsk",
  jAndJ: "j&j",
  biogen: "biogen",
  boehringerIngelheim: "boehringer ingelheim",
  otsuka: "otsuka",
  astraZeneca: "astrazeneca",
  vertex: "vertex",
  novoNordisk: "novo nordisk",
  merckKgaa: "merck kgaa",
  bayer: "bayer",
  pfizer: "pfizer",
  bms: "bms",
  chugai: "chugai",
  regeneron: "regeneron",
  amgen: "amgen",
  gilead: "gilead",
  daiichiSankyo: "daiichi sankyo",
} as const;

const COMMON_ICON_PROPS = {
  width: 16,
  height: 16,
  backgroundType: "transparent",
  backgroundColor: "transparent",
  iconColor: "#fff",
} as const;

export const DEAL_SNAPSHOT_ICON_BY_CATEGORY: Record<
  DealDBSnapshotCategory,
  Record<string, React.ReactNode>
> = {
  [DEAL_SNAPSHOT_KEYS.modality]: {
    [MODALITY_GROUP_NAME.smallMolecule]: (
      <ModalityIcon modalityType="smallMolecule" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.antibody]: (
      <ModalityIcon modalityType="antibody" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.undisclosed]: (
      <ModalityIcon modalityType="empty" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.cellTherapy]: (
      <ModalityIcon modalityType="cellTherapy" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.therapeuticProtein]: (
      <ModalityIcon modalityType="therapeuticProtein" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.genetherapy]: (
      <ModalityIcon modalityType="geneTherapy" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.vaccine]: (
      <ModalityIcon modalityType="vaccine" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.radiopharmaceutical]: (
      <ModalityIcon modalityType="radiopharmaceutical" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.imagingAgent]: (
      <ModalityIcon modalityType="imagingAgent" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.microbiome]: (
      <ModalityIcon modalityType="microBiome" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.macromolecule]: (
      <ModalityIcon modalityType="macromolecule" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.bloodDerivatives]: (
      <ModalityIcon modalityType="bloodDerivatives" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.biologicallyDerivedExtracts]: (
      <ModalityIcon
        modalityType="biologicallyDerivedExtracts"
        {...COMMON_ICON_PROPS}
      />
    ),
    [MODALITY_GROUP_NAME.functionBased]: (
      <ModalityIcon modalityType="functionBased" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.exosome]: (
      <ModalityIcon modalityType="exosome" {...COMMON_ICON_PROPS} />
    ),
    [MODALITY_GROUP_NAME.other]: (
      <ModalityIcon modalityType="empty" {...COMMON_ICON_PROPS} />
    ),
  },
  [DEAL_SNAPSHOT_KEYS.therapeuticArea]: {
    [THERAPEUTIC_AREA_GROUP_NAME.oncology]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.immunology]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.neurology]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.undisclosed]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.infectiousDisease]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.metabolicEndocrine]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.eye]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.psychiatry]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.musculoskeletal]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.dermatology]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.cardiovascular]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.genitourinary]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.etc]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.gastrointestinal]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.blood]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.pulmonary]: <></>,
    [THERAPEUTIC_AREA_GROUP_NAME.other]: <></>,
    ...THERAPEUTIC_AREA_MESH_ICONS_BY_GROUP_NAME,
  },
  [DEAL_SNAPSHOT_KEYS.phase]: {
    [PHASE_GROUP_NAME.preclinical]: <></>,
    [PHASE_GROUP_NAME.phase1]: <></>,
    [PHASE_GROUP_NAME.phase2]: <></>,
    [PHASE_GROUP_NAME.phase3]: <></>,
    [PHASE_GROUP_NAME.phase4]: <></>,
    [PHASE_GROUP_NAME.approved]: <></>,
    [PHASE_GROUP_NAME.discontinued]: <></>,
    [PHASE_GROUP_NAME.other]: <></>,
    [PHASE_GROUP_NAME.ndaBla]: <></>,
    [PHASE_GROUP_NAME.undisclosed]: <></>,
    [PHASE_GROUP_NAME.clinical]: <></>,
    [PHASE_GROUP_NAME.screeningUndisclosedUndefined]: <></>,
    [PHASE_GROUP_NAME.screening]: <></>,
    [PHASE_GROUP_NAME.pivot]: <></>,
  },
  [DEAL_SNAPSHOT_KEYS.territoryBuyer]: {
    [TERRITORY_GROUP_NAME.asia]: <></>,
    [TERRITORY_GROUP_NAME.caribbean]: <></>,
    [TERRITORY_GROUP_NAME.centralAmerica]: <></>,
    [TERRITORY_GROUP_NAME.europe]: <></>,
    [TERRITORY_GROUP_NAME.europeRussiaCIS]: <></>,
    [TERRITORY_GROUP_NAME.northAmerica]: <></>,
    [TERRITORY_GROUP_NAME.oceaniaAfrica]: <></>,
    [TERRITORY_GROUP_NAME.southAmerica]: <></>,
    [TERRITORY_GROUP_NAME.oceania]: <></>,
    [TERRITORY_GROUP_NAME.africa]: <></>,
  },
  [DEAL_SNAPSHOT_KEYS.territorySeller]: {
    [TERRITORY_GROUP_NAME.asia]: <></>,
    [TERRITORY_GROUP_NAME.caribbean]: <></>,
    [TERRITORY_GROUP_NAME.centralAmerica]: <></>,
    [TERRITORY_GROUP_NAME.europe]: <></>,
    [TERRITORY_GROUP_NAME.europeRussiaCIS]: <></>,
    [TERRITORY_GROUP_NAME.northAmerica]: <></>,
    [TERRITORY_GROUP_NAME.oceaniaAfrica]: <></>,
    [TERRITORY_GROUP_NAME.southAmerica]: <></>,
    [TERRITORY_GROUP_NAME.oceania]: <></>,
    [TERRITORY_GROUP_NAME.africa]: <></>,
  },
  [DEAL_SNAPSHOT_KEYS.bigPharma]: {},
};

export const DEAL_SNAPSHOT_DEFAULT_COLORS_BY_CATEGORY: Record<
  DealDBSnapshotCategory,
  string
> = {
  [DEAL_SNAPSHOT_KEYS.modality]: "#B79FC8",
  [DEAL_SNAPSHOT_KEYS.therapeuticArea]: "#C195A5",
  [DEAL_SNAPSHOT_KEYS.phase]: "#D6D0BD",
  [DEAL_SNAPSHOT_KEYS.territoryBuyer]: "#547668",
  [DEAL_SNAPSHOT_KEYS.territorySeller]: "#547668",
  [DEAL_SNAPSHOT_KEYS.bigPharma]: "#E26B62",
};

export const DEAL_SNAPSHOT_MAIN_COLORS_BY_CATEGORY: Record<
  DealDBSnapshotCategory,
  Record<string, string>
> = {
  [DEAL_SNAPSHOT_KEYS.modality]: {
    [MODALITY_GROUP_NAME.smallMolecule]: "#8219D2",
    [MODALITY_GROUP_NAME.antibody]: "#8000FF",
    [MODALITY_GROUP_NAME.undisclosed]: "#9369C9",
    [MODALITY_GROUP_NAME.cellTherapy]: "#A34AFD",
    [MODALITY_GROUP_NAME.therapeuticProtein]: "#6B3EEB",
    [MODALITY_GROUP_NAME.genetherapy]: "#B149DB",
    [MODALITY_GROUP_NAME.vaccine]: "#8F67FF",
    [MODALITY_GROUP_NAME.radiopharmaceutical]: "#6E40C8",
    [MODALITY_GROUP_NAME.imagingAgent]: "#837AE5",
    [MODALITY_GROUP_NAME.microbiome]: "#AF6BC6",
    [MODALITY_GROUP_NAME.bloodDerivatives]: "#CC8EFA",
    [MODALITY_GROUP_NAME.biologicallyDerivedExtracts]: "#CC8EFA",
    [MODALITY_GROUP_NAME.exosome]:
      DEAL_SNAPSHOT_DEFAULT_COLORS_BY_CATEGORY[DEAL_SNAPSHOT_KEYS.modality],
    [MODALITY_GROUP_NAME.functionBased]:
      DEAL_SNAPSHOT_DEFAULT_COLORS_BY_CATEGORY[DEAL_SNAPSHOT_KEYS.modality],
    [MODALITY_GROUP_NAME.other]:
      DEAL_SNAPSHOT_DEFAULT_COLORS_BY_CATEGORY[DEAL_SNAPSHOT_KEYS.modality],
  },
  [DEAL_SNAPSHOT_KEYS.therapeuticArea]: {
    [THERAPEUTIC_AREA_GROUP_NAME.oncology]: "#CE0A51",
    [THERAPEUTIC_AREA_GROUP_NAME.immunology]: "#F2128B",
    [THERAPEUTIC_AREA_GROUP_NAME.neurology]: "#B71C1C",
    [THERAPEUTIC_AREA_GROUP_NAME.undisclosed]: "#9C657B",
    [THERAPEUTIC_AREA_GROUP_NAME.infectiousDisease]: "#861746",
    [THERAPEUTIC_AREA_GROUP_NAME.metabolicEndocrine]: "#AD1457",
    [THERAPEUTIC_AREA_GROUP_NAME.eye]: "#D32F2F",
    [THERAPEUTIC_AREA_GROUP_NAME.psychiatry]: "#9C0F27",
    [THERAPEUTIC_AREA_GROUP_NAME.musculoskeletal]: "#BC5050",
    [THERAPEUTIC_AREA_GROUP_NAME.dermatology]: "#E56B9F",
    [THERAPEUTIC_AREA_GROUP_NAME.cardiovascular]: "#825263",
    [THERAPEUTIC_AREA_GROUP_NAME.genitourinary]: "#C51162",
    [THERAPEUTIC_AREA_GROUP_NAME.etc]: "#4F2434",
    [THERAPEUTIC_AREA_GROUP_NAME.gastrointestinal]: "#A83250",
    [THERAPEUTIC_AREA_GROUP_NAME.blood]: "#AA003D",
    [THERAPEUTIC_AREA_GROUP_NAME.pulmonary]: "#FF4081",
    [THERAPEUTIC_AREA_GROUP_NAME.other]:
      DEAL_SNAPSHOT_DEFAULT_COLORS_BY_CATEGORY[
        DEAL_SNAPSHOT_KEYS.therapeuticArea
      ],
    ...THERAPEUTIC_AREA_MESH_MAIN_COLOR_BY_GROUP_NAME,
  },
  [DEAL_SNAPSHOT_KEYS.phase]: {
    [PHASE_GROUP_NAME.preclinical]: "#E7D05D",
    [PHASE_GROUP_NAME.phase1]: "#DEDE22",
    [PHASE_GROUP_NAME.phase2]: "#F1D900",
    [PHASE_GROUP_NAME.phase3]: "#D3AF04",
    [PHASE_GROUP_NAME.phase4]:
      DEAL_SNAPSHOT_DEFAULT_COLORS_BY_CATEGORY[DEAL_SNAPSHOT_KEYS.phase],
    [PHASE_GROUP_NAME.approved]: "#FFC800",
    [PHASE_GROUP_NAME.discontinued]: "#E7D05D",
    [PHASE_GROUP_NAME.other]:
      DEAL_SNAPSHOT_DEFAULT_COLORS_BY_CATEGORY[DEAL_SNAPSHOT_KEYS.phase],
    [PHASE_GROUP_NAME.ndaBla]: "#A49D21",
    [PHASE_GROUP_NAME.undisclosed]: "#AFA47E",
    [PHASE_GROUP_NAME.pivot]: "#BB9227",
    [PHASE_GROUP_NAME.clinical]: "#A48F59",
    [PHASE_GROUP_NAME.screening]: "#866B24",
    [PHASE_GROUP_NAME.screeningUndisclosedUndefined]: "#866B24",
  },
  [DEAL_SNAPSHOT_KEYS.territoryBuyer]: {
    [TERRITORY_GROUP_NAME.asia]: "#D1AE8D",
    [TERRITORY_GROUP_NAME.caribbean]: "#547668",
    [TERRITORY_GROUP_NAME.centralAmerica]: "#547668",
    [TERRITORY_GROUP_NAME.europe]: "#637196",
    [TERRITORY_GROUP_NAME.europeRussiaCIS]: "#637196",
    [TERRITORY_GROUP_NAME.northAmerica]: "#547668",
    [TERRITORY_GROUP_NAME.oceaniaAfrica]: "#C17E8E",
    [TERRITORY_GROUP_NAME.southAmerica]: "#86AF78",
    [TERRITORY_GROUP_NAME.oceania]: "#C17E8E",
    [TERRITORY_GROUP_NAME.africa]: "#C17E8E",
    [TERRITORY_GROUP_NAME.other]: "#515C6C",
  },
  [DEAL_SNAPSHOT_KEYS.territorySeller]: {
    [TERRITORY_GROUP_NAME.asia]: "#D1AE8D",
    [TERRITORY_GROUP_NAME.caribbean]: "#547668",
    [TERRITORY_GROUP_NAME.centralAmerica]: "#547668",
    [TERRITORY_GROUP_NAME.europe]: "#637196",
    [TERRITORY_GROUP_NAME.europeRussiaCIS]: "#637196",
    [TERRITORY_GROUP_NAME.northAmerica]: "#547668",
    [TERRITORY_GROUP_NAME.oceaniaAfrica]: "#C17E8E",
    [TERRITORY_GROUP_NAME.southAmerica]: "#86AF78",
    [TERRITORY_GROUP_NAME.oceania]: "#C17E8E",
    [TERRITORY_GROUP_NAME.africa]: "#C17E8E",
    [TERRITORY_GROUP_NAME.other]: "#515C6C",
  },
  [DEAL_SNAPSHOT_KEYS.bigPharma]: {
    [BIG_PHARMA_GROUP_NAME.bms]: "#D26BCF",
    [BIG_PHARMA_GROUP_NAME.abbvie]: "#516180",
    [BIG_PHARMA_GROUP_NAME.novartis]: "#F2B862",
    [BIG_PHARMA_GROUP_NAME.roche]: "#4DA0D4",
    [BIG_PHARMA_GROUP_NAME.astraZeneca]: "#F4C44D",
    [BIG_PHARMA_GROUP_NAME.msd]: "#4DAAA3",
    [BIG_PHARMA_GROUP_NAME.lilly]: "#E26B62",
    [BIG_PHARMA_GROUP_NAME.vertex]: "#79598F",
    [BIG_PHARMA_GROUP_NAME.boehringerIngelheim]: "#5F71A5",
    [BIG_PHARMA_GROUP_NAME.gsk]: "#F5875E",
    [BIG_PHARMA_GROUP_NAME.sanofi]: "#A24DEE",
    [BIG_PHARMA_GROUP_NAME.merckKgaa]: "#5696C8",
    [BIG_PHARMA_GROUP_NAME.jAndJ]: "#F15D4D",
    [BIG_PHARMA_GROUP_NAME.takeda]: "#F26066",
    [BIG_PHARMA_GROUP_NAME.novoNordisk]: "#4D5E93",
    [BIG_PHARMA_GROUP_NAME.biogen]: "#669DCF",
    [BIG_PHARMA_GROUP_NAME.otsuka]: "#7C98CF",
    [BIG_PHARMA_GROUP_NAME.pfizer]: "#4D4DD9",
    [BIG_PHARMA_GROUP_NAME.bayer]: "#ACE069",
    [BIG_PHARMA_GROUP_NAME.regeneron]: "#4D8DBB",
    [BIG_PHARMA_GROUP_NAME.chugai]: "#5484BD",
    [BIG_PHARMA_GROUP_NAME.daiichiSankyo]: "#5080B6",
    [BIG_PHARMA_GROUP_NAME.amgen]: "#6491C0",
    [BIG_PHARMA_GROUP_NAME.gilead]: "#D75376",
    other: "#5484BD",
  },
};

export const DEAL_SNAPSHOT_HORIZONTAL_BAR_CHART_COLOR = {
  [BIG_PHARMA_GROUP_NAME.bms]: "#ECC9EC",
  [BIG_PHARMA_GROUP_NAME.abbvie]: "#BEC6CF",
  [BIG_PHARMA_GROUP_NAME.novartis]: "#F8E5C5",
  [BIG_PHARMA_GROUP_NAME.roche]: "#BDDDEE",
  [BIG_PHARMA_GROUP_NAME.astraZeneca]: "#FBE9BD",
  [BIG_PHARMA_GROUP_NAME.msd]: "#BDE0DD",
  [BIG_PHARMA_GROUP_NAME.lilly]: "#F5C9C5",
  [BIG_PHARMA_GROUP_NAME.vertex]: "#CCC3D5",
  [BIG_PHARMA_GROUP_NAME.boehringerIngelheim]: "#C3CBDD",
  [BIG_PHARMA_GROUP_NAME.gsk]: "#F9D3C4",
  [BIG_PHARMA_GROUP_NAME.sanofi]: "#DBBFF7",
  [BIG_PHARMA_GROUP_NAME.merckKgaa]: "#C0D9E9",
  [BIG_PHARMA_GROUP_NAME.jAndJ]: "#FAC4BE",
  [BIG_PHARMA_GROUP_NAME.takeda]: "#F8C6C7",
  [BIG_PHARMA_GROUP_NAME.novoNordisk]: "#BFC4D7",
  [BIG_PHARMA_GROUP_NAME.biogen]: "#C5DBEC",
  [BIG_PHARMA_GROUP_NAME.otsuka]: "#F9D3C4",
  [BIG_PHARMA_GROUP_NAME.pfizer]: "#BDBFF0",
  [BIG_PHARMA_GROUP_NAME.bayer]: "#DFF4C7",
  [BIG_PHARMA_GROUP_NAME.regeneron]: "#DFEAF2",
  [BIG_PHARMA_GROUP_NAME.chugai]: "#BFD3E6",
  [BIG_PHARMA_GROUP_NAME.daiichiSankyo]: "#BDD1E3",
  [BIG_PHARMA_GROUP_NAME.amgen]: "#C5D7E7",
  [BIG_PHARMA_GROUP_NAME.gilead]: "#EFC1CC",
  [TERRITORY_GROUP_NAME.asia]: "#E8DBCD",
  [TERRITORY_GROUP_NAME.caribbean]: "#B7C4BE",
  [TERRITORY_GROUP_NAME.centralAmerica]: "#B7C4BE",
  [TERRITORY_GROUP_NAME.europe]: "#BEC1D1",
  [TERRITORY_GROUP_NAME.europeRussiaCIS]: "#BEC1D1",
  [TERRITORY_GROUP_NAME.northAmerica]: "#B7C4BE",
  [TERRITORY_GROUP_NAME.oceaniaAfrica]: "#E1C5CE",
  [TERRITORY_GROUP_NAME.southAmerica]: "#CBDDC4",
  [TERRITORY_GROUP_NAME.oceania]: "#E1C5CE",
  [TERRITORY_GROUP_NAME.africa]: "#E1C5CE",
  [TERRITORY_GROUP_NAME.other]: "#CBD0D8",
  undisclosed: "#CBD0D8",
  other: "#CBD0D8",
};

export const DEAL_SNAPSHOT_TEXT_COLOR_BY_CATEGORY: Record<
  DealDBSnapshotCategory,
  Record<string, string>
> = {
  [DEAL_SNAPSHOT_KEYS.modality]: {
    [MODALITY_GROUP_NAME.smallMolecule]: "#fff",
    [MODALITY_GROUP_NAME.antibody]: "#fff",
    [MODALITY_GROUP_NAME.undisclosed]: "#fff",
    [MODALITY_GROUP_NAME.exosome]: "#fff",
    [MODALITY_GROUP_NAME.cellTherapy]: "#fff",
    [MODALITY_GROUP_NAME.therapeuticProtein]: "#fff",
    [MODALITY_GROUP_NAME.genetherapy]: "#fff",
    [MODALITY_GROUP_NAME.vaccine]: "#fff",
    [MODALITY_GROUP_NAME.radiopharmaceutical]: "#fff",
    [MODALITY_GROUP_NAME.imagingAgent]: "#fff",
    [MODALITY_GROUP_NAME.microbiome]: "#fff",
    [MODALITY_GROUP_NAME.bloodDerivatives]: "#fff",
    [MODALITY_GROUP_NAME.biologicallyDerivedExtracts]: "#fff",
    [MODALITY_GROUP_NAME.functionBased]: "#fff",
    [MODALITY_GROUP_NAME.other]: "#fff",
  },
  [DEAL_SNAPSHOT_KEYS.therapeuticArea]: {
    [THERAPEUTIC_AREA_GROUP_NAME.oncology]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.immunology]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.neurology]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.undisclosed]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.infectiousDisease]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.metabolicEndocrine]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.eye]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.psychiatry]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.musculoskeletal]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.dermatology]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.cardiovascular]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.genitourinary]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.etc]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.gastrointestinal]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.blood]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.pulmonary]: "#fff",
    [THERAPEUTIC_AREA_GROUP_NAME.other]: "#fff",
    ...THERAPEUTIC_AREA_MESH_FONT_COLOR_BY_GROUP_NAME,
  },
  [DEAL_SNAPSHOT_KEYS.phase]: {
    [PHASE_GROUP_NAME.preclinical]: "#2A2F37",
    [PHASE_GROUP_NAME.phase1]: "#2A2F37",
    [PHASE_GROUP_NAME.phase2]: "#2A2F37",
    [PHASE_GROUP_NAME.phase3]: "#2A2F37",
    [PHASE_GROUP_NAME.phase4]: "#2A2F37",
    [PHASE_GROUP_NAME.approved]: "#2A2F37",
    [PHASE_GROUP_NAME.discontinued]: "#2A2F37",
    [PHASE_GROUP_NAME.other]: "#2A2F37",
    [PHASE_GROUP_NAME.ndaBla]: "#2A2F37",
    [PHASE_GROUP_NAME.undisclosed]: "#2A2F37",
    [PHASE_GROUP_NAME.pivot]: "#fff",
    [PHASE_GROUP_NAME.clinical]: "#fff",
    [PHASE_GROUP_NAME.screening]: "#fff",
    [PHASE_GROUP_NAME.screeningUndisclosedUndefined]: "#fff",
  },
  [DEAL_SNAPSHOT_KEYS.territoryBuyer]: {
    [TERRITORY_GROUP_NAME.asia]: "#fff",
    [TERRITORY_GROUP_NAME.caribbean]: "#fff",
    [TERRITORY_GROUP_NAME.centralAmerica]: "#fff",
    [TERRITORY_GROUP_NAME.europe]: "#fff",
    [TERRITORY_GROUP_NAME.europeRussiaCIS]: "#fff",
    [TERRITORY_GROUP_NAME.northAmerica]: "#fff",
    [TERRITORY_GROUP_NAME.oceaniaAfrica]: "#fff",
    [TERRITORY_GROUP_NAME.southAmerica]: "#fff",
    [TERRITORY_GROUP_NAME.oceania]: "#fff",
    [TERRITORY_GROUP_NAME.africa]: "#fff",
    [PHASE_GROUP_NAME.other]: "#fff",
  },
  [DEAL_SNAPSHOT_KEYS.territorySeller]: {
    [TERRITORY_GROUP_NAME.asia]: "#fff",
    [TERRITORY_GROUP_NAME.caribbean]: "#fff",
    [TERRITORY_GROUP_NAME.centralAmerica]: "#fff",
    [TERRITORY_GROUP_NAME.europe]: "#fff",
    [TERRITORY_GROUP_NAME.europeRussiaCIS]: "#fff",
    [TERRITORY_GROUP_NAME.northAmerica]: "#fff",
    [TERRITORY_GROUP_NAME.oceaniaAfrica]: "#fff",
    [TERRITORY_GROUP_NAME.southAmerica]: "#fff",
    [TERRITORY_GROUP_NAME.oceania]: "#fff",
    [TERRITORY_GROUP_NAME.africa]: "#fff",
    [PHASE_GROUP_NAME.other]: "#fff",
  },
  [DEAL_SNAPSHOT_KEYS.bigPharma]: {
    [BIG_PHARMA_GROUP_NAME.bms]: "#fff",
    [BIG_PHARMA_GROUP_NAME.abbvie]: "#fff",
    [BIG_PHARMA_GROUP_NAME.novartis]: "#fff",
    [BIG_PHARMA_GROUP_NAME.roche]: "#fff",
    [BIG_PHARMA_GROUP_NAME.astraZeneca]: "#2A2F37",
    [BIG_PHARMA_GROUP_NAME.msd]: "#fff",
    [BIG_PHARMA_GROUP_NAME.lilly]: "#fff",
    [BIG_PHARMA_GROUP_NAME.vertex]: "#fff",
    [BIG_PHARMA_GROUP_NAME.boehringerIngelheim]: "#fff",
    [BIG_PHARMA_GROUP_NAME.gsk]: "#fff",
    [BIG_PHARMA_GROUP_NAME.sanofi]: "#fff",
    [BIG_PHARMA_GROUP_NAME.merckKgaa]: "#fff",
    [BIG_PHARMA_GROUP_NAME.jAndJ]: "#fff",
    [BIG_PHARMA_GROUP_NAME.takeda]: "#fff",
    [BIG_PHARMA_GROUP_NAME.novoNordisk]: "#fff",
    [BIG_PHARMA_GROUP_NAME.biogen]: "#fff",
    [BIG_PHARMA_GROUP_NAME.otsuka]: "#fff",
    [BIG_PHARMA_GROUP_NAME.pfizer]: "#fff",
    [BIG_PHARMA_GROUP_NAME.bayer]: "#fff",
    [BIG_PHARMA_GROUP_NAME.regeneron]: "#fff",
    [BIG_PHARMA_GROUP_NAME.chugai]: "#fff",
    [BIG_PHARMA_GROUP_NAME.daiichiSankyo]: "#fff",
    [BIG_PHARMA_GROUP_NAME.amgen]: "#fff",
    [BIG_PHARMA_GROUP_NAME.gilead]: "#fff",
    other: "#fff",
  },
};

export const IS_DEAL_SNAPSHOT_WITH_ICON_BY_CATEGORY: Record<
  DealDBSnapshotCategory,
  boolean
> = {
  [DEAL_SNAPSHOT_KEYS.modality]: true,
  [DEAL_SNAPSHOT_KEYS.therapeuticArea]: false,
  [DEAL_SNAPSHOT_KEYS.phase]: false,
  [DEAL_SNAPSHOT_KEYS.territoryBuyer]: false,
  [DEAL_SNAPSHOT_KEYS.territorySeller]: false,
  [DEAL_SNAPSHOT_KEYS.bigPharma]: true,
};

export const DEAL_SNAPSHOT_GROUP_NAME_ALIASES: Record<string, string> = {
  [PHASE_GROUP_NAME.ndaBla]: "NDA/BLA",
  [PHASE_GROUP_NAME.screeningUndisclosedUndefined]: "Screening",
  [BIG_PHARMA_GROUP_NAME.gsk]: "GSK",
  [BIG_PHARMA_GROUP_NAME.msd]: "MSD",
  [BIG_PHARMA_GROUP_NAME.bms]: "BMS",
  [BIG_PHARMA_GROUP_NAME.merckKgaa]: "Merck KGaA",
  [TERRITORY_GROUP_NAME.europeRussiaCIS]: "Europe/Russia/CIS",
};

export const DEAL_SNAPSHOT_GROUP_NAME_WORD_SPACING: Record<string, string> = {
  [PHASE_GROUP_NAME.screeningUndisclosedUndefined]: "-0.3rem",
};
