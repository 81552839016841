import React from "react";

import CTGFillInTableContainer from "@/components/business/CTGFillInTableContainer/CTGFillInTableContainer";
import DeletedPressBoundary from "@/components/shared/business/DeletedPressBoundary/DeletedPressBoundary";

import FillInTableErrorBoundary from "./errors/components/FillInTableErrorBoundary/FillInTableErrorBoundary";

const CTGFillInTablePage = () => {
  return (
    <FillInTableErrorBoundary>
      <DeletedPressBoundary>
        <CTGFillInTableContainer />
      </DeletedPressBoundary>
    </FillInTableErrorBoundary>
  );
};

export default CTGFillInTablePage;
