import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconInfo = ({ width = 21, height = 20, color = "#7C899C" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 3.80573C6.57901 3.80573 3.80566 6.57907 3.80566 10.0002C3.80566 13.4213 6.57901 16.1946 10.0001 16.1946C13.4212 16.1946 16.1946 13.4213 16.1946 10.0002C16.1946 6.57907 13.4212 3.80573 10.0001 3.80573ZM2.30566 10.0002C2.30566 5.75064 5.75058 2.30573 10.0001 2.30573C14.2496 2.30573 17.6946 5.75064 17.6946 10.0002C17.6946 14.2497 14.2496 17.6946 10.0001 17.6946C5.75058 17.6946 2.30566 14.2497 2.30566 10.0002ZM10.0001 9.37516C10.3453 9.37516 10.6251 9.65499 10.6251 10.0002V12.7779C10.6251 13.1231 10.3453 13.4029 10.0001 13.4029C9.65494 13.4029 9.37512 13.1231 9.37512 12.7779V10.0002C9.37512 9.65499 9.65494 9.37516 10.0001 9.37516ZM10.0001 6.59739C9.65494 6.59739 9.37512 6.87721 9.37512 7.22239C9.37512 7.56757 9.65494 7.84739 10.0001 7.84739H10.0071C10.3522 7.84739 10.6321 7.56757 10.6321 7.22239C10.6321 6.87721 10.3522 6.59739 10.0071 6.59739H10.0001Z"
        fill={color}
      />
    </svg>
  );
};

IconInfo.displayName = "Info";
export default IconInfo;
