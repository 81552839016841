import styled, { css } from "styled-components";

import IconCheck from "@images/pressClassification/icon-checkbox.svg";

export const CheckItemLabelWrapper = styled.label`
  display: flex;
  justify-content: start;
  width: 100%;
  gap: 0.4rem;
  padding: 0.4rem;
  cursor: pointer;
`;

export const Checkbox = styled.input`
  all: unset;
  width: 1.2rem;
  min-width: 1.2rem;
  height: 1.2rem;
  border-radius: var(--Border-Radius-2, 0.2rem);
  border: 1px solid var(--Color-Border-Interactive-Secondary, #b1b8c4);
  margin-top: 0.3rem;

  &:checked {
    border-radius: var(--Border-Radius-2, 0.2rem);
    background: var(--Color-Bg-Interactive-Primary, #1baefe);
    border: 1px solid var(--Color-Bg-Interactive-Primary, #1baefe);
    background-image: url(${IconCheck});
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const CheckItemLabel = styled.span<{ isTarget: boolean }>`
  display: inline-block;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;

  ${({ isTarget }) =>
    isTarget
      ? css`
          color: var(--Color-Text-Interactive-Primary, #0198e9);
        `
      : css`
          color: var(--Color-Text-Interactive-Secondary, #515c6c);
        `};
`;

export const ReasonInputWrapper = styled.label<{
  $isFilled: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 1.8rem);
  height: 2.8rem;
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Border-Interactive-Secondary, #b1b8c4);
  background: var(--Color-Bg-Primary, #fff);
  margin-top: 1rem;
  margin-left: -1.8rem;
  padding: 0.6rem 0.8rem;

  ${({ $isFilled }) =>
    $isFilled &&
    css`
      border: 1px solid var(--Color-Border-Primary, #7c899c);
    `}

  &:hover {
    border: 1px solid var(--Color-Border-Interactive-Secondary-Hovered, #96a1b0);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  &:active {
    border: 1px solid var(--Color-Border-Interactive-Secondary-Pressed, #7c899c);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
      0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  }

  &:focus-within {
    border: 1px solid var(--Color-Border-Interactive-Primary, #0198e9);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const ReasonInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  color: var(--Color-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 130%;

  &::placeholder {
    color: var(--Color-Text-Tertiary, #7c899c);
  }

  &:placeholder-shown {
    .reason-input {
      border: 1px solid var(--Color-Border-Interactive-Secondary, #b1b8c4);
    }
  }
`;

export const ResetButton = styled.button`
  padding: 0;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
`;
