import React from "react";

import { LIST_SECTION_TITLE } from "@/components/business/CTGFillInTableContainer/shared/components/TableCreatorListBox/constants/tableCreatorListBox.constants";
import { TargetListType } from "@/components/business/CTGFillInTableContainer/shared/hooks/useHandleTargetListIndex";

import * as S from "./TableCreatorDictionaryDropdown.style";
import { DictionaryList } from "../../types/tableCreatorDropdown.types";

interface Props {
  dictionaryList: DictionaryList;
  targetListIndex: TargetListType;
  targetItemRef: React.RefObject<HTMLLIElement>;
  handleClickDictionaryItem: (item: string) => void;
}

const TableCreatorDictionaryDropdown = ({
  dictionaryList,
  targetListIndex,
  targetItemRef,
  handleClickDictionaryItem,
}: Props) => {
  const isDictionary = targetListIndex.key === "DICTIONARY";

  return (
    <S.ListWrapper>
      <S.ListSectionTitle>{LIST_SECTION_TITLE.dictionary}</S.ListSectionTitle>
      {dictionaryList.map(
        ({ key, shellKey: dictionaryShellKey, list, isCategory }) => {
          if (isCategory) {
            return list.map(
              (category, categoryIndex) =>
                !!category.list.length && (
                  <S.ListWrapper>
                    <S.CategorySubTitle>
                      {category.categoryKey}
                    </S.CategorySubTitle>
                    {category.list.map((listItem, listIndex) => (
                      <S.DictionaryItem
                        key={`${key}-${dictionaryShellKey}-${listItem}`}
                        data-dropdown="list-box"
                        onMouseDown={() => handleClickDictionaryItem(listItem)}
                        $isTarget={
                          isDictionary &&
                          categoryIndex === targetListIndex.categoryIndex &&
                          listIndex === targetListIndex.listIndex
                        }
                        $isCategory
                        ref={
                          isDictionary &&
                          categoryIndex === targetListIndex.categoryIndex &&
                          listIndex === targetListIndex.listIndex
                            ? targetItemRef
                            : null
                        }
                      >
                        {listItem}
                      </S.DictionaryItem>
                    ))}
                  </S.ListWrapper>
                ),
            );
          }

          return list.map((listItem, listIndex) => (
            <S.DictionaryItem
              key={`${dictionaryShellKey}-${listItem}`}
              data-dropdown="list-box"
              onMouseDown={() => handleClickDictionaryItem(listItem)}
              $isTarget={
                isDictionary && listIndex === targetListIndex.listIndex
              }
              ref={
                isDictionary && listIndex === targetListIndex.listIndex
                  ? targetItemRef
                  : null
              }
            >
              {listItem}
            </S.DictionaryItem>
          ));
        },
      )}
      {/* {dictionaryList.map((item, itemIndex) => {
        const currentDropdown = isCategoryDropdownList
          ? item.split("__")[1]
          : item;

        return (
          <S.DictionaryItemWrapper key={`${item}-${String(itemIndex)}`}>
            {isCategoryDropdownList && (
              <TableCreatorDictionaryDropdownCategory
                shellKey={shellKey}
                dictionaryList={dictionaryList}
                item={item}
              />
            )}
            <S.DictionaryItem
              data-dropdown="list-box"
              onMouseDown={() => handleClickDictionaryItem(currentDropdown)}
              isTarget={itemIndex === targetListIndex - aiListLength}
              isCategory={isCategoryDropdownList}
              ref={
                itemIndex === targetListIndex - aiListLength
                  ? targetItemRef
                  : null
              }
            >
              {currentDropdown}
            </S.DictionaryItem>
          </S.DictionaryItemWrapper>
        );
      })} */}
    </S.ListWrapper>
  );
};

export default TableCreatorDictionaryDropdown;
