import { ROUTE_PATHS } from "@routes/routePath";

export const ROLES_FROM_ROUTE_LIST: Record<string, string> = {
  EDTK0100: ROUTE_PATHS.pressClassification,
  EDTK0201: ROUTE_PATHS.fillInTable,
  EDTK0202: ROUTE_PATHS.fillInTable,
  EDTK0303: ROUTE_PATHS.fillInTable,
  EDTK0401: ROUTE_PATHS.fillInTable,
  EDTK0402: ROUTE_PATHS.fillInTable,
  EDTK0503: ROUTE_PATHS.fillInTable,
  EDTK0603: ROUTE_PATHS.fillInTable,
  EDTK0703: ROUTE_PATHS.fillInTable,
  EDTK0803: ROUTE_PATHS.fillInTable,
  EDTK0903: ROUTE_PATHS.ctgFillInTable,
};
