import {
  CLINICAL_TRIAL_DESIGN_1_SHELL_KEYS,
  CLINICAL_TRIAL_DESIGN_2_SHELL_KEYS,
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS,
  CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY,
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS,
  CLINICAL_TRIAL_DESIGN_SUB_SHELL_KEYS,
  CLINICAL_TRIAL_DESIGN_TABLE_KEY,
  ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION,
  ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS,
  ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE,
  ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS,
  ENTRY_DATA_CLINICAL_RESULTS,
  ENTRY_DATA_CLINICAL_RESULTS_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS,
  ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import { useClinicalTrialsIdentifierAllCheckQueries } from "@api/clinicalTrialsIdentifier/clinicalTrialsIdentifier";
import { useModalityCategoryValue } from "@FillInTableContainer/shared/context/ModalityCategoryContext";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  ValidatedInfoList,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

interface UseCheckValidationInTableCreatorReturn {
  handleCheckValidationHalf: () => boolean;
  handleCheckValidationFull: () => boolean;
}

export const useCheckValidationInClinicalTrialsTableCreator =
  (): UseCheckValidationInTableCreatorReturn => {
    const entryData = useEntryDataValue(getPressIdFromURL());
    const { updateModalityCategory } = useModalityCategoryValue();
    const { changeValidatedInfo } = useValidatedInTableCreator();
    const { changeInfo } = useTableCreatorPositionInfo();
    const { changeFocusStatus } = useTableCreatorFocusStatus();

    const allClinicalIdentifierValues =
      entryData?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.map((tableInfo) => {
        const clinicalTrialsIdentifierValues =
          tableInfo[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1][
            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName
          ]?.value?.flatMap((item) => item.text) || [];
        return clinicalTrialsIdentifierValues || [];
      }) || [];

    const { info: clinicalIdentifierValidationInfo } =
      useClinicalTrialsIdentifierAllCheckQueries(allClinicalIdentifierValues);

    const checkDrugProfileTable = () => {
      const drugProfileTableList =
        entryData?.["Clinical Trials"]?.["Drug Profile"];

      const valueInfo = drugProfileTableList?.flatMap(
        (tableInfo, tableInfoIndex) => {
          const checkDrugName =
            tableInfo?.["Brand Name"].value.length === 0 &&
            tableInfo?.["INN Name"].value.length === 0 &&
            tableInfo?.["Code Name"].value.length === 0;

          if (!checkDrugName) return null;
          return [
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "Brand Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "INN Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "Code Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
          ];
        },
      );

      const sortedValidatedInfo =
        valueInfo?.filter((info) => info !== null) || [];

      return sortedValidatedInfo;
    };
    const checkModalityCategory = () => {
      const drugProfileTableList =
        entryData?.["Clinical Trials"]?.["Drug Profile"];
      const modalityValues = drugProfileTableList?.map((table) =>
        table.Modality.value?.map((shell) => shell.text),
      );

      const valueInfo = modalityValues?.flatMap((table, tableIndex) =>
        table.map((shell) => {
          if (updateModalityCategory[shell] === "") {
            return {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex,
              groupShellKey: null,
              groupShellIndex: 0,
              shellKey: "Modality",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            };
          }
          return null;
        }),
      );
      return valueInfo?.filter((item) => item !== null);
    };

    const checkClinicalTrialDesignOneTable = () => {
      const clinicalTrialDesignTableList =
        entryData?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN
        ];

      const clinicalTrialDesignTableListLength =
        clinicalTrialDesignTableList?.length || 0;

      if (clinicalTrialDesignTableListLength === 0) return [];

      const valueInfo = clinicalTrialDesignTableList?.flatMap(
        (tableInfo, tableInfoIndex) => {
          const checkTrialIntervention =
            tableInfo[
              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
            ][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention]
              ?.length === 0;

          const checkAllClinicalTrialsIdentifierValues =
            clinicalIdentifierValidationInfo[tableInfoIndex];

          const checkClinicalTrialsIdentifier =
            !checkAllClinicalTrialsIdentifierValues;

          if (!checkTrialIntervention && !checkClinicalTrialsIdentifier)
            return null;

          return [
            checkClinicalTrialsIdentifier && {
              groupTableKey: CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY,
              groupTableIndex: 1,
              tableKey: CLINICAL_TRIAL_DESIGN_TABLE_KEY,
              tableIndex: tableInfoIndex,
              groupShellKey:
                CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign1,
              groupShellIndex: 0,
              subGroupShellKey: null,
              subGroupShellIndex: 0,
              shellKey: CLINICAL_TRIAL_DESIGN_1_SHELL_KEYS.nctName,
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
            checkTrialIntervention && {
              groupTableKey: CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY,
              groupTableIndex: 1,
              tableKey: CLINICAL_TRIAL_DESIGN_TABLE_KEY,
              tableIndex: tableInfoIndex,
              groupShellKey:
                CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign1,
              groupShellIndex: 0,
              subGroupShellKey: null,
              subGroupShellIndex: 0,
              shellKey: CLINICAL_TRIAL_DESIGN_1_SHELL_KEYS.trialIntervention,
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
          ];
        },
      );

      const sortedValidatedInfo = valueInfo?.filter((info) => info) || [];

      return sortedValidatedInfo;
    };

    const checkClinicalTrialDesignTwoTable = () => {
      const tableInfo =
        entryData?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN
        ];

      const sortedValidatedInfo = () => {
        const validatedInfoTemp: ValidatedInfoList = [];

        tableInfo?.forEach((currentTableInfo, tableInfoIndex) => {
          currentTableInfo[
            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
          ].forEach((subGroupShellInfo, subGroupShellInfoIndex) => {
            const isValidatedArmName =
              subGroupShellInfo[CLINICAL_TRIAL_DESIGN_2_SHELL_KEYS.armName]
                .value.length === 0;
            const isValidatedDesignGroupInformation =
              subGroupShellInfo[
                CLINICAL_TRIAL_DESIGN_2_SHELL_KEYS.designGroupInformation
              ].filter(
                (shellInfo) =>
                  shellInfo[CLINICAL_TRIAL_DESIGN_SUB_SHELL_KEYS.drugName].value
                    .length === 0,
              ).length > 0;

            if (isValidatedArmName || isValidatedDesignGroupInformation) {
              if (isValidatedArmName) {
                validatedInfoTemp.push({
                  groupTableKey: CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY,
                  groupTableIndex: 1,
                  tableKey: CLINICAL_TRIAL_DESIGN_TABLE_KEY,
                  tableIndex: tableInfoIndex,
                  groupShellKey:
                    CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2,
                  groupShellIndex: 1,
                  subGroupShellKey:
                    CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm,
                  subGroupShellIndex: subGroupShellInfoIndex,
                  shellKey: CLINICAL_TRIAL_DESIGN_2_SHELL_KEYS.armName,
                  shellIndex: 0,
                  valueIndex: null,
                  type: "alert",
                  container: "shell",
                });
              }
              if (isValidatedDesignGroupInformation) {
                subGroupShellInfo[
                  CLINICAL_TRIAL_DESIGN_2_SHELL_KEYS.designGroupInformation
                ].forEach((shellInfo, shellInfoIndex) => {
                  if (
                    shellInfo[CLINICAL_TRIAL_DESIGN_SUB_SHELL_KEYS.drugName]
                      .value.length === 0
                  ) {
                    validatedInfoTemp.push({
                      groupTableKey: CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY,
                      groupTableIndex: 1,
                      tableKey: CLINICAL_TRIAL_DESIGN_TABLE_KEY,
                      tableIndex: tableInfoIndex,
                      groupShellKey:
                        CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2,
                      groupShellIndex: 1,
                      subGroupShellKey:
                        CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm,
                      subGroupShellIndex: subGroupShellInfoIndex,
                      shellKey:
                        CLINICAL_TRIAL_DESIGN_2_SHELL_KEYS.designGroupInformation,
                      shellIndex: shellInfoIndex,
                      valueIndex: null,
                      type: "alert",
                      container: "shell",
                    });
                  }
                });
              }
            }
          });
        });

        return validatedInfoTemp;
      };

      return sortedValidatedInfo();
    };

    const checkClinicalResultsTable = () => {
      const tableInfo =
        entryData?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_RESULTS
        ];
      if (
        tableInfo?.[ENTRY_DATA_CLINICAL_RESULTS_KEYS.resultsClassification]
          .value.length === 0
      ) {
        return [
          {
            groupTableKey: ENTRY_DATA_CLINICAL_RESULTS,
            groupTableIndex: 2,
            tableKey: null,
            tableIndex: 0,
            groupShellKey: ENTRY_DATA_CLINICAL_RESULTS,
            groupShellIndex: 0,
            shellKey: ENTRY_DATA_CLINICAL_RESULTS_KEYS.resultsClassification,
            shellIndex: 0,
            valueIndex: null,
            type: "alert",
            container: "shell",
          },
        ];
      }
      return [];
    };

    const checkEndpointsTable = () => {
      const defaultTableData =
        entryData?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE
        ];

      const sortedValidatedInfo = () => {
        const validatedInfoTemp: ValidatedInfoList = [];

        defaultTableData?.forEach((tableInfo, tableInfoIndex) => {
          const isValidatedEndpointNameIncludingMeasurementTiming =
            tableInfo[
              ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation
            ].filter(
              (groupShellInfo) =>
                groupShellInfo[
                  ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS
                    .endpointNameIncludingMeasurementTiming
                ].value.length === 0,
            ).length > 0;
          const isValidatedEndpointGroupInformation =
            tableInfo[
              ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation
            ].filter((groupShellInfo) =>
              groupShellInfo[
                ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS
                  .endpointGroupInformation
              ].filter(
                (shellInfo) =>
                  shellInfo[
                    ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS.groupName
                  ].value.length === 0,
              ),
            ).length > 0;
          const isValidatedEndpointGroupData =
            tableInfo[
              ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation
            ].filter((groupShellInfo) =>
              groupShellInfo[
                ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS
                  .endpointGroupInformation
              ].filter(
                (shellInfo) =>
                  shellInfo[
                    ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS.groupData
                  ].value.length === 0 ||
                  shellInfo[
                    ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS.groupData
                  ].value.filter((item) => item.text.trim() === "").length > 0,
              ),
            ).length > 0;

          if (
            isValidatedEndpointNameIncludingMeasurementTiming ||
            isValidatedEndpointGroupInformation ||
            isValidatedEndpointGroupData
          ) {
            if (isValidatedEndpointNameIncludingMeasurementTiming) {
              tableInfo[
                ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS
                  .endpointsTableInformation
              ].forEach((groupShellInfo, groupShellInfoIndex) => {
                if (
                  groupShellInfo[
                    ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS
                      .endpointNameIncludingMeasurementTiming
                  ].value.length === 0
                ) {
                  validatedInfoTemp.push({
                    groupTableKey: ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE,
                    groupTableIndex: 3,
                    tableKey:
                      ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation,
                    tableIndex: tableInfoIndex,
                    groupShellKey:
                      ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation,
                    groupShellIndex: groupShellInfoIndex,
                    shellKey:
                      ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS.endpointNameIncludingMeasurementTiming,
                    shellIndex: 0,
                    valueIndex: null,
                    type: "alert",
                    container: "shell",
                  });
                }
              });
            }
            if (isValidatedEndpointGroupInformation) {
              tableInfo[
                ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS
                  .endpointsTableInformation
              ].forEach((groupShellInfo, groupShellInfoIndex) => {
                groupShellInfo[
                  ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS
                    .endpointGroupInformation
                ].forEach((shellInfo, shellInfoIndex) => {
                  if (
                    shellInfo[
                      ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS.groupName
                    ].value.length === 0
                  ) {
                    validatedInfoTemp.push({
                      groupTableKey: ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE,
                      groupTableIndex: 3,
                      tableKey:
                        ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation,
                      tableIndex: tableInfoIndex,
                      groupShellKey:
                        ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation,
                      groupShellIndex: groupShellInfoIndex,
                      shellKey:
                        ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS.groupName,
                      shellIndex: shellInfoIndex,
                      valueIndex: null,
                      type: "alert",
                      container: "shell",
                    });
                  }
                });
              });
            }
            if (isValidatedEndpointGroupData) {
              tableInfo[
                ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS
                  .endpointsTableInformation
              ].forEach((groupShellInfo, groupShellInfoIndex) => {
                groupShellInfo[
                  ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS
                    .endpointGroupInformation
                ].forEach((shellInfo, shellInfoIndex) => {
                  if (
                    shellInfo[
                      ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS.groupData
                    ].value.length === 0
                  ) {
                    validatedInfoTemp.push({
                      groupTableKey: ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE,
                      groupTableIndex: 3,
                      tableKey:
                        ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation,
                      tableIndex: tableInfoIndex,
                      groupShellKey:
                        ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation,
                      groupShellIndex: groupShellInfoIndex,
                      shellKey:
                        ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS.endpointGroupInformation,
                      shellIndex: shellInfoIndex,
                      valueIndex: null,
                      type: "alert",
                      container: "shell",
                    });
                  }
                });
              });
            }
          }
        });

        return [...new Set(validatedInfoTemp)];
      };
      return sortedValidatedInfo();
    };

    const checkEndpointsDescriptionTable = () => {
      const tableInfo =
        entryData?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION
        ];
      if (
        tableInfo?.[
          ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS.endpointDescription
        ].value.length === 0
      ) {
        return [
          {
            groupTableKey: ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION,
            groupTableIndex: 4,
            tableKey: null,
            tableIndex: 0,
            groupShellKey:
              ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS.endpointDescription,
            groupShellIndex: 0,
            shellKey:
              ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS.endpointDescription,
            shellIndex: 0,
            valueIndex: null,
            type: "alert",
            container: "shell",
          },
        ];
      }
      return [];
    };

    // NOTE: Endpoint Table, Endpoint Description Table 값 유무에 따른 유효성 체크를 위해 Endpoint Table 값이 입력 여부 확인
    const checkIsEndpointsData = () => {
      const defaultTableData =
        entryData?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE
        ];
      if (!defaultTableData) return false;

      const endpointsValueList = defaultTableData
        ?.flatMap((table) => {
          return [
            table[
              ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableTitle
            ].value?.flatMap((item) => item.text),
            table[
              ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableNotes
            ].value?.flatMap((item) => item.text),
            table[
              ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation
            ]?.flatMap((row) => {
              return [
                row[
                  ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS
                    .endpointClassification
                ]?.value?.flatMap((item) => item.text),
                row[
                  ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS
                    .endpointNameIncludingMeasurementTiming
                ]?.value?.flatMap((item) => item.text),
                row[
                  ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS
                    .endpointGroupInformation
                ]?.flatMap((shell) => {
                  return [
                    shell[
                      ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS.groupName
                    ]?.value?.flatMap((item) => item.text),
                    shell[
                      ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS.groupData
                    ]?.value?.flatMap((item) => item.text),
                    shell[
                      ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS.pValue
                    ]?.value?.flatMap((item) => item.text),
                  ].flat();
                }),
              ].flat();
            }),
          ].flat();
        })
        .filter((item) => !!item);

      return endpointsValueList.length > 0;
    };

    // Endpoint Description 값이 입력되어 있는 지 확인
    const checkIsEndpointDescriptionData = () => {
      const defaultTableData =
        entryData?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION
        ];
      if (!defaultTableData) return false;

      const description =
        defaultTableData?.[
          ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS.endpointDescription
        ]?.value.length > 0 &&
        defaultTableData?.[
          ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS.endpointDescription
        ]?.value.filter((item) => item.text !== "").length > 0;
      if (description) return true;

      return false;
    };

    // Endpoint Table, Endpoint Description Table 값 유무에 따라 유효성 체크 필요여부 확인
    const checkNeedValidationForEndpointsGroup = () => {
      const isEndpointsTableData = checkIsEndpointsData();
      const isEndpointsDescriptionTableData = checkIsEndpointDescriptionData();

      if (!isEndpointsTableData && !isEndpointsDescriptionTableData) {
        return {
          table: true,
          description: true,
        };
      }
      if (isEndpointsTableData && isEndpointsDescriptionTableData) {
        return {
          table: true,
          description: false,
        };
      }
      if (isEndpointsTableData && !isEndpointsDescriptionTableData) {
        return {
          table: true,
          description: false,
        };
      }
      if (!isEndpointsTableData && isEndpointsDescriptionTableData) {
        return {
          table: false,
          description: false,
        };
      }
      return {
        table: true,
        description: true,
      };
    };

    const handleCheckValidationHalf = () => {
      if (
        checkDrugProfileTable().length === 0 &&
        checkModalityCategory()?.length === 0
      ) {
        changeInfo(DEFAULT_POSITION_INFO);
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        return true;
      }

      const validatedList = [
        ...(checkDrugProfileTable() || []),
        ...(checkModalityCategory() || []),
      ];

      changeValidatedInfo(validatedList as ValidatedInfoList);
      return false;
    };

    const handleCheckValidationFull = () => {
      if (
        checkDrugProfileTable().length === 0 &&
        checkModalityCategory()?.length === 0 &&
        checkClinicalTrialDesignOneTable().length === 0 &&
        checkClinicalTrialDesignTwoTable().length === 0 &&
        checkClinicalResultsTable().length === 0 &&
        (checkNeedValidationForEndpointsGroup().table
          ? checkEndpointsTable().length === 0
          : true) &&
        (checkNeedValidationForEndpointsGroup().description
          ? checkEndpointsDescriptionTable().length === 0
          : true)
      ) {
        changeInfo(DEFAULT_POSITION_INFO);
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        return true;
      }

      const validatedList = [
        ...(checkDrugProfileTable() || []),
        ...(checkModalityCategory() || []),
        ...(checkClinicalTrialDesignOneTable() || []),
        ...(checkClinicalTrialDesignTwoTable() || []),
        ...(checkClinicalResultsTable() || []),
        ...(checkNeedValidationForEndpointsGroup().description
          ? checkEndpointsDescriptionTable() || []
          : []),
        ...(checkNeedValidationForEndpointsGroup().table
          ? checkEndpointsTable() || []
          : []),
      ];

      changeValidatedInfo(validatedList as ValidatedInfoList);
      return false;
    };
    return {
      handleCheckValidationHalf,
      handleCheckValidationFull,
    };
  };
