import React from "react";

import { invalidatePressListFilterQuery } from "@/queries/hooks/usePressListFilter";
import { PressListOrderingKey } from "@PressListContainer/pressList.types";

import { useHandlePressListTableOrdering } from "./hooks/useHandlePressListTableOrdering";
import * as S from "./PressListTableHead.style";
import { Ordering } from "../../constants/pressListTable.constants";

const PressListTableHead = () => {
  const { tableHeaderList, checkIsOrdering, getOrderingIcon, handleOrdering } =
    useHandlePressListTableOrdering();
  const updatePressList = invalidatePressListFilterQuery;

  const handleClickOrdering = (
    orderingTitle: PressListOrderingKey,
    ordering: Ordering,
  ) => {
    handleOrdering(orderingTitle, ordering);
    updatePressList();
  };

  return (
    <S.PressListTableHead>
      <S.TableRow>
        {tableHeaderList.map((item) => (
          <S.TableHeadShell
            key={`thead-${item.title}`}
            onClick={() =>
              handleClickOrdering(item.orderingTitle, item.ordering)
            }
          >
            <S.TableHeadShellContents
              isOrdering={checkIsOrdering(item.ordering)}
            >
              <S.TableHeadShellTitle
                isOrdering={checkIsOrdering(item.ordering)}
              >
                {item.title.split("\n").map((word, wordIndex) => (
                  <S.TableHeadShellTitleWord key={word + String(wordIndex)}>
                    {word}
                  </S.TableHeadShellTitleWord>
                ))}
              </S.TableHeadShellTitle>

              <S.OrderingIconWrapper>
                {getOrderingIcon(item.ordering)}
              </S.OrderingIconWrapper>
            </S.TableHeadShellContents>
          </S.TableHeadShell>
        ))}
      </S.TableRow>
    </S.PressListTableHead>
  );
};

export default PressListTableHead;
