"use client";

import styled, { css } from "styled-components";

export const TableRow = styled.div<{
  $isBorder?: boolean;
  $isPressed: boolean;
  $isRowBorderAll: boolean;
}>`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);

  &:last-child {
    ${({ $isRowBorderAll }) =>
      $isRowBorderAll
        ? css``
        : css`
            border-bottom: none;
          `}
  }

  &:hover {
    background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #f0f9ff);

    &:last-child {
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }

    .deal-type {
      color: var(--Color-Label-Text-Interactive-Endpoint-Pressed, #21919c);
    }

    .drug-name {
      color: var(--Color-Label-Text-Interactive-Medicine, #005eeb);
    }

    .modality {
      color: var(--Color-Label-Text-Interactive-Modality, #8219d2);
    }

    .target-indication {
      color: var(--Color-Label-Text-Interactive-Disease, #db0f72);
    }

    .payment {
      color: var(--Color-Label-Text-Interactive-Payment, #597181);
    }
  }

  ${({ $isPressed }) =>
    $isPressed &&
    css`
      cursor: pointer;

      &:active {
        background: var(
          --Color-Common-Bg-Interactive-Primary-Subtle-Hovered,
          #e0f4ff
        );
      }
    `}

  ${({ $isBorder }) =>
    $isBorder &&
    css`
      .shell {
        border-right: var(--Border-Width-1, 1px) solid rgba(229, 231, 235, 0.4);
        &:last-child {
          border-right: none;
        }
      }
    `}
`;
