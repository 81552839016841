import styled from "styled-components";

export const TitleDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.4rem;
`;

export const MainGroupShellTitle = styled.h3<{
  titleFontSize?: string;
}>`
  min-width: 6.4rem;
  height: 2.4rem;
  padding: 0 0.6rem;
  cursor: pointer;

  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: ${({ titleFontSize }) => titleFontSize || "1.2rem"};
  font-weight: 600;
`;

export const Description = styled.h6`
  min-width: 6.4rem;
  height: 2.4rem;
  padding: 0 0.6rem;

  color: var(--Color-Common-Text-Tertiary, #7c899c);
  font-size: 1.2rem;
  font-weight: 500;
`;
