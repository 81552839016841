import * as Sentry from "@sentry/react";

export const initSentry = () => {
  if (import.meta.env.MODE === "development") {
    return;
  }

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENV,
    ignoreErrors: [
      // NOTE: 다양한 경우의 401을 모두 처리하기 위해서 메시지, 객체, 코드 모두 작성
      "401",
      /.*401.*/,
      "Unauthorized",
      /.*unauthorized.*/i,
      // 401 상태 텍스트
      "HTTP 401",
      "HTTP Error 401",
      // status 관련
      "status: 401",
      /status.*401/i,
      /Status.*401/i,
      /.*\[401\].*/,
      /.*status.*401.*/i,
      // error 객체 관련
      /error.*status.*401.*/i,
      /.*response.*status.*401.*/i,
      /"status":\s*401/,
      /{.*"status":\s*401.*}/,
    ],
  });
};
