import { atomFamily, useRecoilState, useRecoilValue } from "recoil";

import { CTGEntryData } from "@/types/ctgEntryData.types";
import { getCTGEntryData } from "@api/ctg/ctgEntryData/ctgEntryData";

import { CTG_RECOIL_KEYS } from "./ctgRecoilKey";

type ArticleId = string;

const ctgEntryDataState = atomFamily<CTGEntryData, ArticleId>({
  key: CTG_RECOIL_KEYS.ctgEntryDataState,
  default: (articleId) => getCTGEntryData(articleId),
});

export const useCTGEntryDataValue = (articleId: ArticleId) =>
  useRecoilValue(ctgEntryDataState(articleId));
export const useCTGEntryDataState = (articleId: ArticleId) =>
  useRecoilState(ctgEntryDataState(articleId));
