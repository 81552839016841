import { CtgListData } from "@/components/business/CtgListContainer/ctgList.types";
import { DRAFT_STATUS_LIST } from "@PressListContainer/draftStatusInfo.constants";
import { PressListData } from "@PressListContainer/pressList.types";

export const handleDraftError = (data: PressListData): [boolean, string] => {
  const { pressId, pressTitle, companyName, draftArticleStatus } = data;
  if (!pressId) {
    return [true, "Press Id is empty"];
  }
  if (!pressTitle) {
    return [true, "Press Title is empty"];
  }
  if (!companyName) {
    return [true, "Company Name is empty"];
  }
  if (!draftArticleStatus) {
    return [true, "Article Status is empty"];
  }
  if (!DRAFT_STATUS_LIST.includes(draftArticleStatus)) {
    return [true, "Article Status is not valid"];
  }
  return [false, ""];
};

export const handleCtgError = (data: CtgListData): [boolean, string] => {
  const { ctgId, ctgTitle, draftArticleStatus } = data;
  if (!ctgId) {
    return [true, "Id is empty"];
  }
  if (!ctgTitle) {
    return [true, "Title is empty"];
  }
  if (!draftArticleStatus) {
    return [true, "Article Status is empty"];
  }
  if (!DRAFT_STATUS_LIST.includes(draftArticleStatus)) {
    return [true, "Article Status is not valid"];
  }
  // 회사 정보 없는 경우도 필요하다면 추후 추가하기
  return [false, ""];
};
