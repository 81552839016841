import {
  CtgListOrderingKey,
  CtgListOrderingValue,
} from "@/components/business/CtgListContainer/ctgList.types";
import { useCtgListFilterValue } from "@stores/ctgList";

export const useGetCtgListFilterData = () => {
  const ctgListFilter = useCtgListFilterValue();

  const { search, page, pageLimit, status, order, publishable, isTranslated } =
    ctgListFilter;

  const updateOrder = Object.entries(order).map(
    ([key, value]): {
      sortBy: CtgListOrderingKey;
      sortOrder: CtgListOrderingValue;
    } => ({
      sortBy: key as CtgListOrderingKey,
      sortOrder: value as unknown as CtgListOrderingValue,
    }),
  );

  const ctgListFilterListForUpdate = {
    page,
    size: pageLimit,
    filter: {
      draftArticleStatusHighest: status,
      ...(publishable === "true" && { publishable }),
      ...(isTranslated === "true" && { hasTranslateParentId: isTranslated }),
      ...(search && { search }),
    },
    order: [...updateOrder],
  };

  return ctgListFilterListForUpdate;
};
