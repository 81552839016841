/* eslint-disable @typescript-eslint/no-unused-vars */

import { CTGEntryData } from "@/types/ctgEntryData.types";
import { createRandomString } from "@organisms/TableCreatorComment/components/CommentModalContainer/components/CommentModalMessageComposer/utils/createRandomString";

import { CTG_SECTION } from "./articleSection.constants";

import type { DrugProfileDefaultShellKey } from "@/types/drugProfile.types";

export const TEXTFIELD_PATH_TO_ENTER_ENTRY_DATA: Record<
  string,
  {
    [key in string]?: (
      data: CTGEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
      dictionaryList: string[],
    ) => CTGEntryData;
  }
> = {
  [CTG_SECTION]: {
    "Drug Profile": (
      data: CTGEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
      dictionaryList: string[],
    ) => ({
      ...data,
      [CTG_SECTION]: {
        ...data[CTG_SECTION],
        "Drug Profile": data[CTG_SECTION]?.["Drug Profile"]?.map(
          (item, index) => {
            if (index === tableIndex) {
              if (shellKey === "Therapeutic Area") {
                return {
                  ...item,
                  attributionInfo: {
                    attributionId:
                      item?.attributionInfo?.attributionId ||
                      createRandomString(8),
                  },
                  "Therapeutic Area": {
                    ...item["Therapeutic Area"],
                    attributionId:
                      item["Therapeutic Area"]?.attributionId ||
                      createRandomString(8),
                    value:
                      item["Therapeutic Area"] &&
                      item["Therapeutic Area"]?.value.length > valueIndex
                        ? item["Therapeutic Area"].value
                            ?.map((valueItem, valueItemIndex) => {
                              if (valueItemIndex === valueIndex) {
                                const checkDictionaryList = dictionaryList
                                  .map((listItem) => {
                                    const currentListItem =
                                      listItem.toLocaleLowerCase();
                                    const currentValueText =
                                      valueText.toLocaleLowerCase();

                                    if (currentListItem === currentValueText)
                                      return listItem;

                                    return null;
                                  })
                                  .filter(
                                    (listValueItem) => listValueItem !== null,
                                  );

                                return {
                                  text: checkDictionaryList[0] || "",
                                  is_modified: true,
                                };
                              }

                              return valueItem;
                            })
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text.toLowerCase() ===
                                  valueItem?.text.toLowerCase(),
                              );

                              return valueItemIndex === lastValueIndex;
                            })
                        : [
                            ...item["Therapeutic Area"].value,
                            { text: valueText, is_modified: true },
                          ]
                            ?.map(
                              (
                                valueItem: {
                                  text: string;
                                  is_modified: boolean;
                                },
                                valueItemIndex: number,
                              ) => {
                                if (valueItemIndex === valueIndex) {
                                  const checkDictionaryList =
                                    dictionaryList.filter((listItem) => {
                                      const currentListItem =
                                        listItem.toLocaleLowerCase();
                                      const currentValueText =
                                        valueText.toLocaleLowerCase();

                                      return (
                                        currentListItem === currentValueText
                                      );
                                    });

                                  return {
                                    text: checkDictionaryList[0],
                                    is_modified: true,
                                  };
                                }

                                return valueItem;
                              },
                            )
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text?.toLowerCase() ===
                                  valueItem?.text?.toLowerCase(),
                              );
                              return valueItemIndex === lastValueIndex;
                            }),
                  },
                };
              }

              return {
                ...item,
                attributionInfo: {
                  attributionId:
                    item?.attributionInfo?.attributionId ||
                    createRandomString(8),
                },
                [shellKey as DrugProfileDefaultShellKey]: {
                  ...item[shellKey as DrugProfileDefaultShellKey],
                  attributionId:
                    item[shellKey as DrugProfileDefaultShellKey]
                      ?.attributionId || createRandomString(8),
                  value:
                    item[shellKey as DrugProfileDefaultShellKey] &&
                    item[shellKey as DrugProfileDefaultShellKey]?.value.length >
                      valueIndex
                      ? item[shellKey as DrugProfileDefaultShellKey].value
                          ?.map((valueItem, valueItemIndex) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return { text: valueText, is_modified: true };
                            }

                            return valueItem;
                          })
                          .filter((valueItem) => valueItem !== null)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text.toLowerCase() ===
                                valueItem?.text.toLowerCase(),
                            );
                            return valueItemIndex === lastValueIndex;
                          })
                      : [
                          ...item[shellKey as DrugProfileDefaultShellKey].value,
                          { text: valueText, is_modified: true },
                        ]
                          ?.map(
                            (
                              valueItem: {
                                text: string;
                                is_modified: boolean;
                              },
                              valueItemIndex: number,
                            ) => {
                              if (valueItemIndex === valueIndex) {
                                if (valueText === "") {
                                  return null;
                                }
                                return { text: valueText, is_modified: true };
                              }

                              return valueItem;
                            },
                          )
                          .filter((valueItem) => valueItem !== null)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text.toLowerCase() ===
                                valueItem?.text.toLowerCase(),
                            );
                            return valueItemIndex === lastValueIndex;
                          }),
                },
              };
            }
            return item;
          },
        ),
      },
    }),
  },
};
