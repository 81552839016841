import { useRef, useCallback, useEffect } from "react";

import { TableCreatorHistory } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorHistoryContext";
import { useCTGEntryDataState } from "@stores/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useCTGUndoRedo = ({ state }: { state: TableCreatorHistory }) => {
  const [, setEntryData] = useCTGEntryDataState(getPressIdFromURL());
  const canUpdate = useRef(true);
  const history = useRef<TableCreatorHistory[]>([]);
  const currentIndex = useRef(-1);

  const undo = useCallback(() => {
    if (currentIndex.current <= 0) return;
    const nextIndex = currentIndex.current - 1;

    currentIndex.current = nextIndex;
    setEntryData(history.current[nextIndex]?.entryData ?? {});
    canUpdate.current = false;
  }, [setEntryData]);

  const redo = useCallback(() => {
    if (currentIndex.current >= history.current.length - 1) return;
    const nextIndex = currentIndex.current + 1;

    currentIndex.current = nextIndex;
    setEntryData(history.current[nextIndex]?.entryData ?? {});
    canUpdate.current = false;
  }, [setEntryData]);

  const updatePresent = useCallback((newState: TableCreatorHistory) => {
    if (newState === null) return;
    if (canUpdate.current) {
      history.current.length = currentIndex.current + 1;
      history.current.push(newState);
      currentIndex.current = history.current.length - 1;
    } else {
      canUpdate.current = true;
    }
  }, []);

  const isDisabledRedo = currentIndex.current >= history.current.length - 1;
  const isDisabledUndo = currentIndex.current <= 0;

  const resetHistory = useCallback(() => {
    history.current = [];
    currentIndex.current = -1;
  }, []);

  useEffect(() => {
    updatePresent(state);
  }, [state, updatePresent]);

  return {
    undo,
    redo,
    resetHistory,
    isDisabledRedo,
    isDisabledUndo,
  };
};
