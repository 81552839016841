import { useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";

import TopFixInfoList from "./components/TopFixInfoList/TopFixInfoList";
import { TopFixInfoData } from "./components/types/topFixInfo.types";
import {
  DEFAULT_TOP_FIX_INFO_VALUE,
  TOP_FIX_INFO_DEFAULT,
} from "./constants/topFixInfo.constants";
import * as S from "./CTGTopFixInfo.style";

const CTGTopFixInfo = () => {
  const { data: articleInfo } = useCTGInfoQuery();

  const {
    nctId,
    ctgDate,
    sponsorList,
    collaboratorList,
    mcEditorName,
    ftEditor2Name,
  } = articleInfo || {};

  const PRESS_INFO: TopFixInfoData[] = [
    {
      title: "NCT No.",
      minWidth: "auto",
      maxWidth: "16rem",
      content: nctId,
    },
    {
      title: "CTG Date",
      minWidth: "auto",
      maxWidth: "12rem",
      content: ctgDate,
    },
    {
      title: "Sponsor Name",
      minWidth: "16rem",
      maxWidth: "40rem",
      content: sponsorList.join(", "),
    },
    {
      title: "Collaborator Name",
      minWidth: "16rem",
      maxWidth: "auto",
      content: collaboratorList.join(", "),
    },
  ];

  const DEFAULT_EDITOR_INFO: TopFixInfoData[] = [
    {
      content: mcEditorName || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.mcEditorName,
    },
    {
      content: ftEditor2Name || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.ftEditor2Name,
    },
  ];

  return (
    <S.TopFixInfoWrapper>
      <S.ListWrapper $minWidth="56rem">
        <TopFixInfoList list={PRESS_INFO} maxWidth="none" minWidth="none" />
      </S.ListWrapper>
      <S.ListWrapper $maxWidth="40rem">
        <TopFixInfoList
          list={DEFAULT_EDITOR_INFO}
          maxWidth="40rem"
          minWidth="none"
        />
      </S.ListWrapper>
    </S.TopFixInfoWrapper>
  );
};

export default CTGTopFixInfo;
