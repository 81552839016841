import { useEffect, useState } from "react";

import IconX from "@images/pressClassification/icon-unsuitable-x-mark.svg";

import { UNSUITABLE_REASON_LIST_TITLE_BY_TYPE } from "./components/UnsuitableReasonCheckItem/constants/UnsuitableReason.constants";
import UnsuitableReasonCheckItem from "./components/UnsuitableReasonCheckItem/UnsuitableReasonCheckItem";
import * as S from "./UnsuitableReasonModalContainer.style";
import { useContentInfo } from "../../hooks/useContentInfo";
import { useHandleUnsuitablePress } from "../../hooks/useHandleUnsuitablePress";
import {
  UnsuitableReasonModalPosition,
  UnsuitableReasonUsageType,
} from "../../types/unsuitableReason.types";

interface Props {
  closeModalUnsuitablePress: () => void;
  isOpenModalUnsuitablePress: boolean;
  articleRejectReason: string | null;
  unsuitableReasonUsageType: UnsuitableReasonUsageType;
  draftArticleId: number;
  modalPosition?: UnsuitableReasonModalPosition;
}

const UnsuitableReasonModalContainer = ({
  closeModalUnsuitablePress,
  isOpenModalUnsuitablePress,
  articleRejectReason,
  unsuitableReasonUsageType,
  draftArticleId,
  modalPosition,
}: Props) => {
  const { data: articleInfo } = useContentInfo(Number(draftArticleId));
  const [isUpdated, setIsUpdated] = useState(false);
  const {
    unsuitablePressReasonList,
    setUnsuitablePressReasonList,
    targetUnsuitableReasonList,
    targetDefaultReasonList,
  } = useHandleUnsuitablePress({ type: unsuitableReasonUsageType });

  useEffect(() => {
    const updateResponseDataList = targetUnsuitableReasonList.map((item) => {
      if (
        item.reason === articleInfo.articleRejectReason &&
        !!articleInfo.articleRejectReason
      ) {
        if (item.type === "addId")
          return {
            ...item,
            duplId: articleInfo.duplArticleId,
            isTarget: true,
          };

        return {
          ...item,
          isTarget: true,
        };
      } else if (
        articleInfo.articleRejectReason &&
        !targetDefaultReasonList.includes(articleInfo.articleRejectReason)
      ) {
        if (item.type === "writing")
          return {
            ...item,
            reason: articleInfo.articleRejectReason,
            isTarget: true,
          };
      }

      return item;
    });

    setUnsuitablePressReasonList(updateResponseDataList);
    setIsUpdated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleInfo.articleRejectReason, setUnsuitablePressReasonList]);

  return (
    <S.PressUnsuitableReasonContainer
      isOpenModalUnsuitablePress={isOpenModalUnsuitablePress}
      $modalPosition={modalPosition}
    >
      <S.TopWrapper>
        <S.Text>
          {UNSUITABLE_REASON_LIST_TITLE_BY_TYPE[unsuitableReasonUsageType]}
        </S.Text>
        <S.CloseButton onClick={closeModalUnsuitablePress}>
          <img src={IconX} alt="close button" />
        </S.CloseButton>
      </S.TopWrapper>
      <S.ReasonCheckListBox>
        {isUpdated &&
          unsuitablePressReasonList.map((item) => (
            <UnsuitableReasonCheckItem
              key={item.id}
              draftArticleId={draftArticleId}
              name="unsuitableReason"
              reasonId={item.id}
              reasonType={item.type}
              isTarget={item.isTarget}
              placeholder={item.placeholder}
              reason={item.reason}
              explanation={item.explanation}
              duplId={item.duplId}
              articleRejectReason={articleRejectReason}
              unsuitablePressReasonList={unsuitablePressReasonList}
              setUnsuitablePressReasonList={setUnsuitablePressReasonList}
              unsuitableReasonUsageType={unsuitableReasonUsageType}
              closeModalUnsuitablePress={closeModalUnsuitablePress}
            />
          ))}
      </S.ReasonCheckListBox>
    </S.PressUnsuitableReasonContainer>
  );
};

export default UnsuitableReasonModalContainer;
