import {
  DEAL_TYPE_KEYS,
  checkIsMergeAndAcquisitionLayout,
  formatDealTypeToLowerCase,
} from "../../../business";

export const returnTableLayoutAffectingDealType = (
  currentDealTypeList: string[],
) => {
  const formatLowerCaseDealTypeList = currentDealTypeList.map((dealTypeItem) =>
    formatDealTypeToLowerCase(dealTypeItem),
  );

  const isMergeAcquisitionType = checkIsMergeAndAcquisitionLayout(
    formatLowerCaseDealTypeList,
  );

  const isStrategicCollaborationType = formatLowerCaseDealTypeList.every(
    (dealType) =>
      dealType === DEAL_TYPE_KEYS.strategicRAndDCollaboration ||
      dealType === DEAL_TYPE_KEYS.strategicSalesCollaboration,
  );

  const isCrossLicenseAgreementType = formatLowerCaseDealTypeList.some(
    (dealType) =>
      dealType === DEAL_TYPE_KEYS.crossLicenseAgreement ||
      dealType === "license agreement (cross license)",
  );

  const isVentureFormationType = formatLowerCaseDealTypeList.some(
    (dealType) => dealType === DEAL_TYPE_KEYS.ventureFormation,
  );

  if (isMergeAcquisitionType) return DEAL_TYPE_KEYS.mergeAndAcquisition;

  if (isCrossLicenseAgreementType) return DEAL_TYPE_KEYS.crossLicenseAgreement;

  if (isVentureFormationType) return DEAL_TYPE_KEYS.ventureFormation;

  if (isStrategicCollaborationType)
    return DEAL_TYPE_KEYS.strategicRAndDCollaboration;

  return DEAL_TYPE_KEYS.licenseAgreement;
};
