import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";
import { BASIC_DATA_FOR_ADDING_TABLES } from "@/components/business/CTGFillInTableContainer/shared/constants/tableCreatorTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  DEFAULT_VALIDATED_INFO,
  useValidatedInTableCreator,
} from "@/components/business/CTGFillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useUpdateCTGEntryData } from "@/components/business/CTGFillInTableContainer/shared/hooks/useUpdateCTGEntryData";
import { CTGEntryData } from "@/types/ctgEntryData.types";
import { DrugProfileValue } from "@/types/drugProfile.types";
import { useCTGEntryDataState } from "@stores/ctgEntryData";
import { useCTGInitEntryDataValue } from "@stores/ctgInitEntryData";
import { deepCopy } from "@utils/deepCopy";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { CHECK_TABLE_KEY_BY_GROUP_TABLE_KEY } from "../constants/tableCreatorTable.constants";

interface UseHandleTableCreatorGroupTableReturn {
  handleAddTable: (tableIndex: number) => void;
  isFocused: boolean;
}

interface Props {
  groupTableKey: "Drug Profile";
}

export const useTableCreatorDrugProfileGroupTable = ({
  groupTableKey,
}: Props): UseHandleTableCreatorGroupTableReturn => {
  const articleId = getPressIdFromURL();
  const articleSection = CTG_SECTION;
  const [entryData, setEntryData] = useCTGEntryDataState(articleId);
  const initEntryData = useCTGInitEntryDataValue(articleId);
  const updateEntryData = useUpdateCTGEntryData();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();

  const isFocused = info.groupTableKey === groupTableKey;

  const handleAddTable = (tableIndex: number) => {
    if (focusStatus.container !== "noFocus") return;

    const targetTableListInEntryData =
      deepCopy(entryData?.[articleSection]?.[groupTableKey]) || [];

    if (!Array.isArray(targetTableListInEntryData)) return;

    const newArrayLength = targetTableListInEntryData.length + 1;
    const emptyArray = new Array(newArrayLength).fill({});

    const addTableData = () => {
      return emptyArray.map((_, index) => {
        if (index === tableIndex) {
          return BASIC_DATA_FOR_ADDING_TABLES[articleSection]?.[groupTableKey](
            initEntryData,
          );
        }
        if (index > tableIndex) {
          return targetTableListInEntryData[index - 1];
        }
        return targetTableListInEntryData[index];
      });
    };

    const updatedEntryData =
      newArrayLength === 2 && groupTableKey === "Drug Profile"
        ? {
            [articleSection]: {
              ...entryData[articleSection],
              [groupTableKey as "Drug Profile"]:
                addTableData() as DrugProfileValue[],
            },
          }
        : {
            [articleSection]: {
              ...entryData[articleSection],
              [groupTableKey]: addTableData(),
            },
          };

    setEntryData(updatedEntryData as CTGEntryData);
    updateEntryData(updatedEntryData as CTGEntryData);
    changeCurrentHistory({
      entryData: updatedEntryData as CTGEntryData,
      changedDataInfo: {
        target: "table",
        action: "add",
        position: {
          ...DEFAULT_POSITION_INFO,
          groupTableKey,
          tableIndex,
          tableKey:
            CHECK_TABLE_KEY_BY_GROUP_TABLE_KEY[articleSection][groupTableKey],
          value: "",
        },
      },
    });
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  return {
    handleAddTable,
    isFocused,
  };
};
