/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENT,
  ENTRY_DATA_KEY_COMMENTS_SHELL_KEYS as KEY_COMMENT_SHELL_KEYS,
  ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY as FUNDED_COMPANY,
  ENTRY_DATA_FUNDED_COMPANY_SHELL_KEYS as FUNDED_COMPANY_SHELL_KEYS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY as ROUND_TYPE_AND_STATUS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_SHELL_KEYS as ROUND_TYPE_AND_STATUS_SHELL_KEYS,
  ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY as FINANCING_INFORMATION,
  ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS as FINANCING_INFORMATION_SHELL_KEYS,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY as ADDITIONAL_INFORMATION_VENTURE_CAPITAL,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_KEYS as ADDITIONAL_INFORMATION_VENTURE_CAPITAL_SHELL_KEYS,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as INVESTORS,
  ENTRY_DATA_INVESTORS_SHELL_KEYS as INVESTORS_SHELL_KEYS,
  DEAL_TYPE_SHELL_KEYS,
  ENTRY_DATA_DEAL_TYPE_AND_STATUS,
  LICENSE_AGREEMENT_SHELL_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS,
  ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS,
  ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS,
} from "@frontend/common";

import { createRandomString } from "@organisms/TableCreatorComment/components/CommentModalContainer/components/CommentModalMessageComposer/utils/createRandomString";

import {
  EXPEDITED_PROGRAM_CONTENTS as k,
  EXPEDITED_PROGRAM,
  EXPEDITED_PROGRAM_GROUP_KEY as eg,
} from "./expeditedProgramKey.constants";

import type { ShellColumnSize } from "../types/shellSize.types";
import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type {
  ArmGroupInformation,
  EndpointsGroupInformation,
  EntryDataValueInfo,
} from "@/types/clinicalTrials.types";

export const TABLE_CREATOR_SHELL: Record<
  string,
  {
    key: string;
    title: string;
    customTitle?: string;
    placeholder?: string;
    isRequired: boolean;
    isDictionary: boolean;
    columnSize: ShellColumnSize;
    countValueLength: number;
    isReviseShell: boolean;
    isDisabledInsertText: boolean;
    isBasedOnShellValueDropdown?: boolean;
    isAutocomplete?: boolean;
  }[]
> = {
  "Clinical Trials": [
    {
      key: "Brand Name",
      title: "Brand Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Code Name",
      title: "Code Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "INN Name",
      title: "INN Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 3,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "ROA",
      title: "ROA",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Modality",
      title: "Modality",
      isRequired: false,
      isDictionary: true,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "MOA",
      title: "MOA",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Therapeutic Area",
      title: "Therapeutic Area",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target",
      title: "Target",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target Indication",
      title: "Target Indication",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Other Characteristics",
      title: "Other Characteristics",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Generic / Biosimilar",
      title: "Generic / Biosimilar",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Ref.Product Name",
      title: "Ref.Product Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Originator/Developer",
      title: "Originator/Developer",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Phase",
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Clinical Design Phase",
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Inclusion Criteria - Diagnostic",
      title: "Inclusion Criteria - Diagnostic",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Inclusion Criteria - Detail",
      title: "Inclusion Criteria - Detail",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Trial Name",
      title: "Trial Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 2,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "NCT Name",
      title: "Clinical Trial Identifier",
      isRequired: false,
      isDictionary: false,
      columnSize: "regularWithValidation",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Total Patients Number",
      title: "Total Patients Num.",
      placeholder: "Total Patients Number",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Trial Intervention",
      title: "Trial Intervention",
      placeholder: "Trial Intervention",
      isRequired: true,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Arm Name",
      title: "Arm Name",
      isRequired: true,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Patients Number",
      title: "Arm Patients Num.",
      placeholder: "Arm Patients Number",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Design Group Information",
      title: "Treatment",
      isRequired: true,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 1,
      isReviseShell: true,
      isDisabledInsertText: false,
    },
    {
      key: "Group Data",
      title: "Group Data",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Group Name",
      title: "Group Name",
      isRequired: true,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "P-Value",
      title: "P-Value",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Drug Name",
      title: "Drug Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Dose",
      title: "Dose",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Duration",
      title: "Duration",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Phase",
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Results Classification",
      title: "Results Classification",
      isRequired: true,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Endpoint Classification",
      title: "Endpoint Classification",
      isRequired: false,
      isDictionary: true,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Endpoint Name including Measurement Timing",
      title: "Endpoint (Measurement Timing)",
      isRequired: true,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Endpoint Group Information",
      title: "Group Name",
      customTitle: "Group Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: true,
      isDisabledInsertText: false,
    },
    {
      key: "Endpoints Table Notes",
      title: "Table Notes",
      placeholder: "To add a Table Note in the text field, type '**' or '++'",
      isRequired: false,
      isDictionary: false,
      columnSize: "jumbo",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Endpoint Description",
      title: "Endpoint Description",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Quote",
      title: "Quote",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Spokesperson",
      title: "Spokesperson",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
  ],
  "Clinical Trial Status": [
    {
      key: "Brand Name",
      title: "Brand Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Code Name",
      title: "Code Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "INN Name",
      title: "INN Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 3,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "ROA",
      title: "ROA",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Modality",
      title: "Modality",
      isRequired: false,
      isDictionary: true,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "MOA",
      title: "MOA",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Therapeutic Area",
      title: "Therapeutic Area",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target",
      title: "Target",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target Indication",
      title: "Target Indication",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Other Characteristics",
      title: "Other Characteristics",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Generic / Biosimilar",
      title: "Generic / Biosimilar",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Ref.Product Name",
      title: "Ref.Product Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Originator/Developer",
      title: "Originator/Developer",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Phase",
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Clinical Design Phase",
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Inclusion Criteria - Diagnostic",
      title: "Inclusion Criteria - Diagnostic",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Inclusion Criteria - Detail",
      title: "Inclusion Criteria - Detail",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },

    {
      key: "Trial Name",
      title: "Trial Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 2,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "NCT Name",
      title: "Clinical Trial Identifier",
      isRequired: false,
      isDictionary: false,
      columnSize: "regularWithValidation",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Total Patients Number",
      title: "Total Patients Num.",
      placeholder: "Total Patients Number",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Trial Intervention",
      title: "Trial Intervention",
      placeholder: "Trial Intervention",
      isRequired: true,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
  ],
  Regulatory: [
    {
      key: "Brand Name",
      title: "Brand Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Code Name",
      title: "Code Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "INN Name",
      title: "INN Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 3,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "ROA",
      title: "ROA",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Modality",
      title: "Modality",
      isRequired: false,
      isDictionary: true,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "MOA",
      title: "MOA",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Therapeutic Area",
      title: "Therapeutic Area",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target",
      title: "Target",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target Indication",
      title: "Target Indication",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Other Characteristics",
      title: "Other Characteristics",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Generic / Biosimilar",
      title: "Generic / Biosimilar",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Phase",
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Ref.Product Name",
      title: "Ref.Product Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Originator/Developer",
      title: "Originator/Developer",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Additional Information on the Approval Process",
      title: "Additional Information on Approval Process",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Marketing Status",
      title: "Marketing Status",
      isRequired: true,
      isDictionary: true,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Indication on Label",
      title: "Indication on Label",
      isRequired: false,
      isDictionary: false,
      columnSize: "large",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Dosage and Administration on Label",
      title: "Dosage and Administration on Label",
      isRequired: false,
      isDictionary: false,
      columnSize: "large",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Regulatory Agency",
      title: "Regulatory Agency",
      isRequired: true,
      isDictionary: true,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Update Date",
      title: "Update Date",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Application Type",
      title: "Application Type",
      isRequired: true,
      isDictionary: true,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
      isBasedOnShellValueDropdown: true,
    },
    {
      key: "Authorized Company",
      title: "Authorized Company",
      isRequired: true,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 2,
      isReviseShell: false,
      isDisabledInsertText: false,
      isAutocomplete: true,
    },

    {
      key: "Quote",
      title: "Quote",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Spokesperson",
      title: "Spokesperson",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
  ],
  Deals: [
    {
      key: DEAL_TYPE_SHELL_KEYS.dealName,
      title: "Deal Name",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: DEAL_TYPE_SHELL_KEYS.dealType,
      title: "Deal Type",
      isRequired: true,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: DEAL_TYPE_SHELL_KEYS.dealStatus,
      title: "Deal Status",
      isRequired: true,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Brand Name",
      title: "Brand Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Code Name",
      title: "Code Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "INN Name",
      title: "INN Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 3,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Licensor Name",
      title: "Licensor/Originator",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Licensor Responsibility & Rights",
      title: "Responsibility & Rights",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Licensee Name",
      title: "Licensee/Partner",
      isRequired: true,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Licensee Responsibility & Rights (SC & LA)",
      title: "Responsibility & Rights",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Drug",
      title: "Drug / Asset",
      isRequired: false,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Indication",
      title: "Main Usage",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "ROA",
      title: "ROA",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Exclusivity",
      title: "Exclusivity",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Modality",
      title: "Modality",
      isRequired: false,
      isDictionary: true,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Territory",
      title: "Territory",
      isRequired: false,
      isDictionary: false,
      columnSize: "large",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Upfront Payment",
      title: "Upfront Payment",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Total Payment (SC & LA)",
      title: "Total Payment",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumScLa,
      title: "Disclosed Payment Sum",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Royalty",
      title: "Royalty",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Milestone Payment",
      title: "Milestone Payment",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Milestone Condition Title",
      title: "Milestone",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.upfrontPaymentMa,
      title: "Upfront Payment",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.upfrontPricePerShareMa,
      title: "Price per Share (Upfront)",
      isRequired: false,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentPaymentMa,
      title: "Contingent payment",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentPricePerShareMa,
      title: "Price per Share (Contingent)",
      isRequired: false,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentMilestoneMa,
      title: "Milestone",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: LICENSE_AGREEMENT_SHELL_KEYS.otherPaymentScLa,
      title: "Other Payment",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: LICENSE_AGREEMENT_SHELL_KEYS.otherPaymentDetailScLa,
      title: "Payment Detail",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Other Financial Terms (SC & LA)",
      title: "Other Financial Terms",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Licensee Responsibility & Rights (MA)",
      title: "Responsibility & Rights",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "MOA",
      title: "MOA",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Total Payment (MA)",
      title: "Total Payment",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Price Per Share",
      title: "Price per Share (Total)",
      isRequired: false,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumMa,
      title: "Disclosed Payment Sum",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumPricePerShareMa,
      title: "Price per Share (Disclosed Sum)",
      isRequired: false,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Number of acquiring shares",
      title: "Number of Acquiring Shares",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.mergerFinancialTermsMa,
      title: "Merger financial terms",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Other Financial Terms (MA)",
      title: "Financial Term Details",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Licensee Responsibility & Rights (VF)",
      title: "Responsibility & Rights",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Initial Investment",
      title: "Initial Investment",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Contingent Investment (VF)",
      title: "Contingent Investment",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Contingent Detail (VF)",
      title: "Detail",
      isRequired: false,
      isDictionary: false,
      columnSize: "large",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Disclosed Payments Sum (VF)",
      title: "Disclosed Payment Sum",
      isRequired: false,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Total Investment (VF)",
      title: "Total Investment",
      isRequired: false,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Ownership Structure Details",
      title: "Ownership Structure Detail",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Other Financial Terms (VF)",
      title: "Other Financial Terms",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Therapeutic Area",
      title: "Therapeutic Area",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target",
      title: "Target",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target Indication",
      title: "Target Indication",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Other Characteristics",
      title: "Other Characteristics",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Phase",
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Generic / Biosimilar",
      title: "Generic / Biosimilar",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Ref.Product Name",
      title: "Ref.Product Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Originator/Developer",
      title: "Originator/Developer",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Additional Information",
      title: "Additional Information",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Purpose of the Deal",
      title: "Deal Purpose",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Quote",
      title: "Quote",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Spokesperson",
      title: "Spokesperson",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
  ],
  [EXPEDITED_PROGRAM]: [
    {
      key: "Brand Name",
      title: "Brand Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Code Name",
      title: "Code Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "INN Name",
      title: "INN Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 3,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "ROA",
      title: "ROA",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Modality",
      title: "Modality",
      isRequired: false,
      isDictionary: true,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "MOA",
      title: "MOA",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Therapeutic Area",
      title: "Therapeutic Area",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target",
      title: "Target",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target Indication",
      title: "Target Indication",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Other Characteristics",
      title: "Other Characteristics",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Generic / Biosimilar",
      title: "Generic / Biosimilar",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Ref.Product Name",
      title: "Ref.Product Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Phase",
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Originator/Developer",
      title: "Originator/Developer",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: k.updateDate,
      title: "Update Date",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: k.designatedCompanyName,
      title: "Designated Company name",
      isRequired: false,
      isDictionary: false,
      columnSize: "large",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
      isAutocomplete: true,
    },
    {
      key: k.regulatoryAgency,
      title: "Regulatory Agency",
      isRequired: true,
      isDictionary: true,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: k.expeditedProgram,
      title: "Expedited Program",
      isRequired: true,
      isDictionary: true,
      columnSize: "large",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: k.designatedIndication,
      title: "Designated Indication",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: k.AdditionalInformation,
      title: "Additional Information on the Expedited Program",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Quote",
      title: "Quote",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Spokesperson",
      title: "Spokesperson",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
  ],

  [SECTION_KEYS.ventureCapital]: [
    {
      key: DRUG_PROFILE_SHELL_KEYS.brandName,
      title: "Brand Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: DRUG_PROFILE_SHELL_KEYS.codeName,
      title: "Code Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },

    {
      key: DRUG_PROFILE_SHELL_KEYS.innName,
      title: "INN Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 3,
      isReviseShell: false,
      isDisabledInsertText: false,
    },

    {
      key: DRUG_PROFILE_SHELL_KEYS.roa,
      title: "ROA",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },

    {
      key: DRUG_PROFILE_SHELL_KEYS.modality,
      title: "Modality",
      isRequired: false,
      isDictionary: true,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },

    {
      key: DRUG_PROFILE_SHELL_KEYS.moa,
      title: "MOA",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },

    {
      key: DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
      title: "Therapeutic Area",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: DRUG_PROFILE_SHELL_KEYS.target,
      title: "Target",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: DRUG_PROFILE_SHELL_KEYS.targetIndication,
      title: "Target Indication",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
      title: "Other Characteristics",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: DRUG_PROFILE_SHELL_KEYS.phase,
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
      title: "Generic / Biosimilar",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: DRUG_PROFILE_SHELL_KEYS.refProductName,
      title: "Ref.Product Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
      title: "Originator/Developer",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: KEY_COMMENT_SHELL_KEYS.quote,
      title: "Quote",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: KEY_COMMENT_SHELL_KEYS.spokesperson,
      title: "Spokesperson",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: FUNDED_COMPANY_SHELL_KEYS.companyName,
      title: "Company Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: FUNDED_COMPANY_SHELL_KEYS.companyDescription,
      title: "Company Description",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: FINANCING_INFORMATION_SHELL_KEYS.financingPurpose,
      title: "Use of Proceeds",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundName,
      title: "Round Name",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundType,
      title: "Round Type",
      isRequired: true,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: FINANCING_INFORMATION_SHELL_KEYS.amount,
      title: "Amount",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail,
      title: "Financing Information",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: INVESTORS_SHELL_KEYS.investorName,
      title: "Investor",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: ADDITIONAL_INFORMATION_VENTURE_CAPITAL_SHELL_KEYS.additionalInformation,
      title: "Additional Information",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
  ],
};

export const SHELL_PATH_TO_READ_SHELL_INFO: Record<
  string,
  {
    [key: string]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) => {
      value: EntryDataValueInfo[];
      options: string[];
    };
  }
> = {
  "Clinical Trials": {
    "Brand Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Brand Name"
      ] || {
        value: [],
        options: [],
      },
    "Code Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Code Name"
      ] || {
        value: [],
        options: [],
      },
    "INN Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "INN Name"
      ] || {
        value: [],
        options: [],
      },
    ROA: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.ROA || {
        value: [],
        options: [],
      },
    MOA: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.MOA || {
        value: [],
        options: [],
      },
    Modality: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]
        ?.Modality || {
        value: [],
        options: [],
      },
    Target: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]
        ?.Target || {
        value: [],
        options: [],
      },
    "Target Indication": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Target Indication"
      ] || {
        value: [],
        options: [],
      },
    "Therapeutic Area": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Therapeutic Area"
      ] || {
        value: [],
        options: [],
      },
    "Other Characteristics": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Other Characteristics"
      ] || {
        value: [],
        options: [],
      },
    "Generic / Biosimilar": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Generic / Biosimilar"
      ] || {
        value: [] as string[],
        options: [],
      },
    "Ref.Product Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Ref.Product Name"
      ] || {
        value: [],
        options: [],
      },
    "Originator/Developer": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Originator/Developer"
      ] || {
        value: [],
        options: [],
      },
    Phase: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.[tableIndex || 0]?.Phase || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.clinicalDesignPhase]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
      ][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.clinicalDesignPhase] || {
        value: [],
        options: [],
      },

    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDiagnostic]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
      ][
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDiagnostic
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDetail]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
      ][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDetail] || {
        value: [],
        options: [],
      },

    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
      ][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName] || {
        value: [],
        options: [],
      },

    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
      ][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName] || {
        value: [],
        options: [],
      },

    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.totalPatientsNumber]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
      ][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.totalPatientsNumber] || {
        value: [],
        options: [],
      },

    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
      ]?.[subGroupShellIndex]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName
      ] || {
        value: [],
        options: [],
      },

    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.patientsNumber]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
      ]?.[subGroupShellIndex]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.patientsNumber
      ] || {
        value: [],
        options: [],
      },

    [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
      ]?.[subGroupShellIndex]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation
      ]?.[shellIndex]?.[ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
      ]?.[subGroupShellIndex]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation
      ]?.[shellIndex]?.[ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
      ]?.[subGroupShellIndex]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation
      ]?.[shellIndex]?.[ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration] || {
        value: [],
        options: [],
      },
    "Results Classification": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Clinical Results"]?.[
        "Results Classification"
      ] || {
        value: [],
        options: [],
      },

    "Endpoint Classification": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableIndex]?.[
        "Endpoints Table Information"
      ]?.[groupShellIndex]?.["Endpoint Classification"] || {
        value: [],
        options: [],
      },
    "Endpoint Name including Measurement Timing": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableIndex]?.[
        "Endpoints Table Information"
      ]?.[groupShellIndex]?.["Endpoint Name including Measurement Timing"] || {
        value: [],
        options: [],
      },
    "Endpoints Table Notes": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableIndex]?.[
        "Endpoints Table Notes"
      ] || {
        value: [],
        options: [],
      },

    "Group Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableIndex]?.[
        "Endpoints Table Information"
      ]?.[groupShellIndex]?.["Endpoint Group Information"]?.[shellIndex]?.[
        "Group Name"
      ] || {
        value: [],
        options: [],
      },
    "Group Data": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableIndex]?.[
        "Endpoints Table Information"
      ]?.[groupShellIndex]?.["Endpoint Group Information"]?.[shellIndex]?.[
        "Group Data"
      ] || {
        value: [],
        options: [],
      },

    "P-Value": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableIndex]?.[
        "Endpoints Table Information"
      ]?.[groupShellIndex]?.["Endpoint Group Information"]?.[shellIndex]?.[
        "P-Value"
      ] || {
        value: [],
        options: [],
      },

    "Endpoint Description": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Clinical Endpoints (Description)"]?.[
        "Endpoint Description"
      ] || {
        value: [],
        options: [],
      },
    Quote: (data: ArticleEntryData, tableIndex: number, shellIndex: number) =>
      data?.["Clinical Trials"]?.["Key Comment"]?.Quote || {
        value: [],
        options: [],
      },
    Spokesperson: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Key Comment"]?.Spokesperson || {
        value: [],
        options: [],
      },
  },
  "Clinical Trial Status": {
    "Brand Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Brand Name"
      ] || {
        value: [],
        options: [],
      },
    "Code Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Code Name"
      ] || {
        value: [],
        options: [],
      },
    "INN Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "INN Name"
      ] || {
        value: [],
        options: [],
      },
    ROA: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]
        ?.ROA || {
        value: [],
        options: [],
      },
    MOA: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]
        ?.MOA || {
        value: [],
        options: [],
      },
    Modality: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]
        ?.Modality || {
        value: [],
        options: [],
      },
    Target: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]
        ?.Target || {
        value: [],
        options: [],
      },
    "Target Indication": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Target Indication"
      ] || {
        value: [],
        options: [],
      },
    "Therapeutic Area": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Therapeutic Area"
      ] || {
        value: [],
        options: [],
      },
    "Other Characteristics": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Other Characteristics"
      ] || {
        value: [],
        options: [],
      },
    "Generic / Biosimilar": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Generic / Biosimilar"
      ] || {
        value: [],
        options: [],
      },
    "Ref.Product Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Ref.Product Name"
      ] || {
        value: [],
        options: [],
      },
    "Originator/Developer": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Originator/Developer"
      ] || {
        value: [],
        options: [],
      },
    Phase: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.[tableIndex || 0]
        ?.Phase || {
        value: [],
        options: [],
      },
    "Clinical Design Phase": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Clinical Trial Design-1"]?.[
        tableIndex || 0
      ]?.["Clinical Design Phase"] || {
        value: [],
        options: [],
      },

    "Inclusion Criteria - Diagnostic": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Clinical Trial Design-1"]?.[
        tableIndex || 0
      ]?.["Inclusion Criteria - Diagnostic"] || {
        value: [],
        options: [],
      },
    "Inclusion Criteria - Detail": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Clinical Trial Design-1"]?.[
        tableIndex || 0
      ]?.["Inclusion Criteria - Detail"] || {
        value: [],
        options: [],
      },

    "Trial Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Clinical Trial Design-1"]?.[
        tableIndex || 0
      ]?.["Trial Name"] || {
        value: [],
        options: [],
      },

    "NCT Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Clinical Trial Design-1"]?.[
        tableIndex || 0
      ]?.["NCT Name"] || {
        value: [],
        options: [],
      },

    "Total Patients Number": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.["Clinical Trial Status"]?.["Clinical Trial Design-1"]?.[
        tableIndex || 0
      ]?.["Total Patients Number"] || {
        value: [],
        options: [],
      },

    // "Trial Intervention": (
    //   data: ArticleEntryData,
    //   tableIndex: number,
    //   groupShellIndex: number,
    //   shellIndex: number,
    // ) =>
    //   data?.["Clinical Trials"]?.["Clinical Trial Design-1"]?.[
    //     tableIndex || 0
    //   ]?.["Trial Intervention"] || [],
  },
  Regulatory: {
    "Brand Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.["Brand Name"] || {
        value: [],
        options: [],
      },
    "Code Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.["Code Name"] || {
        value: [],
        options: [],
      },
    "INN Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.["INN Name"] || {
        value: [],
        options: [],
      },
    ROA: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.ROA || {
        value: [],
        options: [],
      },
    MOA: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.MOA || {
        value: [],
        options: [],
      },
    Modality: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.Modality || {
        value: [],
        options: [],
      },
    Target: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.Target || {
        value: [],
        options: [],
      },
    "Target Indication": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Target Indication"
      ] || {
        value: [],
        options: [],
      },
    "Therapeutic Area": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Therapeutic Area"
      ] || {
        value: [],
        options: [],
      },
    "Generic / Biosimilar": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Generic / Biosimilar"
      ] || {
        value: [],
        options: [],
      },
    "Ref.Product Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Ref.Product Name"
      ] || {
        value: [],
        options: [],
      },
    "Other Characteristics": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Other Characteristics"
      ] || {
        value: [],
        options: [],
      },
    Phase: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.Phase || {
        value: [],
        options: [],
      },
    "Originator/Developer": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Originator/Developer"
      ] || {
        value: [],
        options: [],
      },
    "Marketing Status": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Approval Process Contents"]?.["Approval Process"]?.[
        groupShellIndex
      ]?.["Marketing Status"] || {
        value: [],
        options: [],
      },
    "Regulatory Agency": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Approval Process Contents"]?.["Approval Process"]?.[
        groupShellIndex
      ]?.["Regulatory Agency"] || {
        value: [],
        options: [],
      },
    "Update Date": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Approval Process Contents"]?.["Approval Process"]?.[
        groupShellIndex
      ]?.["Update Date"] || {
        value: [],
        options: [],
      },
    "Application Type": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Approval Process Contents"]?.["Approval Process"]?.[
        groupShellIndex
      ]?.["Application Type"] || {
        value: [],
        options: [],
      },
    "Authorized Company": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Approval Process Contents"]?.["Approval Process"]?.[
        groupShellIndex
      ]?.["Authorized Company"] || {
        value: [],
        options: [],
      },
    "Indication on Label": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Approval Process Contents"]?.["Approval Process"]?.[
        groupShellIndex
      ]?.["Indication on Label"] || {
        value: [],
        options: [],
      },
    "Dosage and Administration on Label": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Approval Process Contents"]?.["Approval Process"]?.[
        groupShellIndex
      ]?.["Dosage and Administration on Label"] || {
        value: [],
        options: [],
      },
    "Additional Information on the Approval Process": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Approval Process Contents"]?.[
        "Additional Information on the Approval Process"
      ] || {
        value: [],
        options: [],
      },
    Quote: (data: ArticleEntryData, tableIndex: number, shellIndex: number) =>
      data?.Regulatory?.["Key Comment"]?.Quote || {
        value: [],
        options: [],
      },
    Spokesperson: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Regulatory?.["Key Comment"]?.Spokesperson || {
        value: [],
        options: [],
      },
  },
  Deals: {
    "Brand Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.["Brand Name"] || {
        value: [],
        options: [],
      },
    "Code Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.["Code Name"] || {
        value: [],
        options: [],
      },
    "INN Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.["INN Name"] || {
        value: [],
        options: [],
      },
    ROA: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.ROA || {
        value: [],
        options: [],
      },
    MOA: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.MOA || {
        value: [],
        options: [],
      },
    Modality: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.Modality || {
        value: [],
        options: [],
      },
    Target: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.Target || {
        value: [],
        options: [],
      },
    "Target Indication": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Target Indication"
      ] || {
        value: [],
        options: [],
      },
    "Therapeutic Area": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Therapeutic Area"
      ] || {
        value: [],
        options: [],
      },
    "Other Characteristics": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Other Characteristics"
      ] || {
        value: [],
        options: [],
      },
    Phase: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.Phase || {
        value: [],
        options: [],
      },
    "Generic / Biosimilar": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Generic / Biosimilar"
      ] || {
        value: [],
        options: [],
      },
    "Ref.Product Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Ref.Product Name"
      ] || {
        value: [],
        options: [],
      },
    "Originator/Developer": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.Deals?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Originator/Developer"
      ] || {
        value: [],
        options: [],
      },
    [DEAL_TYPE_SHELL_KEYS.dealName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data[SECTION_KEYS.deals]?.[ENTRY_DATA_DEAL_TYPE_AND_STATUS]?.[
        DEAL_TYPE_SHELL_KEYS.dealName
      ] || {
        value: [],
        options: [],
      },

    [DEAL_TYPE_SHELL_KEYS.dealType]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.[ENTRY_DATA_DEAL_TYPE_AND_STATUS]?.[
        DEAL_TYPE_SHELL_KEYS.dealType
      ] || {
        value: [],
        options: [],
      },
    [DEAL_TYPE_SHELL_KEYS.dealStatus]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.[ENTRY_DATA_DEAL_TYPE_AND_STATUS]?.[
        DEAL_TYPE_SHELL_KEYS.dealStatus
      ] || {
        value: [],
        options: [],
      },
    "Licensor Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensor Table"]?.["Licensor Name"] || {
        value: [],
        options: [],
      },
    "Licensor Responsibility & Rights": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensor Table"]?.["Licensor Responsibility & Rights"] || {
        value: [],
        options: [],
      },
    "Licensee Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]["Licensee Name"] || {
        value: [],
        options: [],
      },
    "Licensee Responsibility & Rights (SC & LA)": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        "Strategic Collaboration & License Agreement terms"
      ]["Licensee Responsibility & Rights (SC & LA)"] || {
        value: [],
        options: [],
      },
    Drug: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]["Rights Scope"]?.[
        groupShellIndex
      ].Drug || {
        value: [],
        options: [],
      },
    Indication: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]["Rights Scope"]?.[
        groupShellIndex
      ]?.Indication || {
        value: [],
        options: [],
      },
    Exclusivity: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]["Rights Scope"]?.[
        groupShellIndex
      ].Exclusivity || {
        value: [],
        options: [],
      },
    Territory: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]["Rights Scope"]?.[
        groupShellIndex
      ].Territory || {
        value: [],
        options: [],
      },
    "Upfront Payment": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        "Strategic Collaboration & License Agreement terms"
      ]["Upfront Payment"] || {
        value: [],
        options: [],
      },
    "Total Payment (SC & LA)": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        "Strategic Collaboration & License Agreement terms"
      ]["Total Payment (SC & LA)"] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumScLa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        "Strategic Collaboration & License Agreement terms"
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumScLa] || {
        value: [],
        options: [],
      },
    Royalty: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        "Strategic Collaboration & License Agreement terms"
      ].Royalty || {
        value: [],
        options: [],
      },
    "Other Financial Terms (SC & LA)": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        "Strategic Collaboration & License Agreement terms"
      ]["Other Financial Terms (SC & LA)"] || {
        value: [],
        options: [],
      },
    "Milestone Payment": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) => {
      return (
        data.Deals?.["Licensee Table"]?.[tableIndex][
          "Strategic Collaboration & License Agreement terms"
        ]["Milestone Payment and Condition"]?.[subGroupShellIndex]?.[
          "Milestone Payment"
        ] || {
          value: [],
          options: [],
        }
      );
    },
    "Milestone Condition Title": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]?.[
        "Strategic Collaboration & License Agreement terms"
      ]?.["Milestone Payment and Condition"]?.[subGroupShellIndex]?.[
        "Milestone Condition Title"
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherPaymentScLa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data[SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE]?.[tableIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS
          .strategicCollaborationLicenseAgreementTerms
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherPaymentAndDetailScLa]?.[
        subGroupShellIndex
      ]?.[ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherPaymentScLa] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherPaymentDetailScLa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data[SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE]?.[tableIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS
          .strategicCollaborationLicenseAgreementTerms
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherPaymentAndDetailScLa]?.[
        subGroupShellIndex
      ]?.[ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherPaymentDetailScLa] || {
        value: [],
        options: [],
      },
    "Licensee Responsibility & Rights (MA)": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]["Merge & Acquisition terms"][
        "Licensee Responsibility & Rights (MA)"
      ] || {
        value: [],
        options: [],
      },

    "Total Payment (MA)": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]["Merge & Acquisition terms"][
        "Total Payment (MA)"
      ] || {
        value: [],
        options: [],
      },
    "Price Per Share": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]["Merge & Acquisition terms"][
        "Price Per Share"
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumMa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]["Merge & Acquisition terms"][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumMa
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumPricePerShareMa]:
      (
        data: ArticleEntryData,
        tableIndex: number,
        groupShellIndex: number,
        shellIndex: number,
      ) =>
        data.Deals?.["Licensee Table"]?.[tableIndex][
          "Merge & Acquisition terms"
        ][
          ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS
            .disclosedPaymentsSumPricePerShareMa
        ] || {
          value: [],
          options: [],
        },
    "Number of acquiring shares": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]["Merge & Acquisition terms"][
        "Number of acquiring shares"
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.upfrontPaymentMa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE]?.[
        tableIndex
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.upfrontPaymentMa
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.upfrontPricePerShareMa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE]?.[
        tableIndex
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.upfrontPricePerShareMa
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentPaymentMa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE]?.[
        tableIndex
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentPayment
      ][subGroupShellIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentPaymentMa
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentPricePerShareMa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE]?.[
        tableIndex
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentPayment
      ][subGroupShellIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentPricePerShareMa
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentMilestoneMa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE]?.[
        tableIndex
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentPayment
      ][subGroupShellIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentMilestoneMa
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.mergerFinancialTermsMa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE]?.[
        tableIndex
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.mergerFinancialTermsMa
      ] || {
        value: [],
        options: [],
      },
    "Other Financial Terms (MA)": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex]["Merge & Acquisition terms"][
        "Other Financial Terms (MA)"
      ] || {
        value: [],
        options: [],
      },
    "Additional Information": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data.Deals?.["Additional Information (Deal)"]?.[
        "Additional Information"
      ] || {
        value: [],
        options: [],
      },

    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.initialInvestment]: (
      data: ArticleEntryData,
      tableIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.ventureFormationTerms
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.initialInvestment] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentVf]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.ventureFormationTerms
      ][
        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentAndDetailVf
      ]?.[subGroupShellIndex]?.[
        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentVf
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentDetailVf]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.ventureFormationTerms
      ][
        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentAndDetailVf
      ]?.[subGroupShellIndex]?.[
        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentDetailVf
      ] || {
        value: [],
        options: [],
      },
    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumVf]: (
      data: ArticleEntryData,
      tableIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.ventureFormationTerms
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumVf] || {
        value: [],
        options: [],
      },

    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.licenseeResponsibilityAndRightsVf]:
      (data: ArticleEntryData, tableIndex: number) =>
        data.Deals?.["Licensee Table"]?.[tableIndex][
          ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.ventureFormationTerms
        ][
          ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS
            .licenseeResponsibilityAndRightsVf
        ] || {
          value: [],
          options: [],
        },

    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.totalInvestmentVf]: (
      data: ArticleEntryData,
      tableIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.ventureFormationTerms
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.totalInvestmentVf] || {
        value: [],
        options: [],
      },

    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.ownershipStructureDetails]: (
      data: ArticleEntryData,
      tableIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.ventureFormationTerms
      ][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.ownershipStructureDetails
      ] || {
        value: [],
        options: [],
      },

    [ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherFinancialTermsVf]: (
      data: ArticleEntryData,
      tableIndex: number,
    ) =>
      data.Deals?.["Licensee Table"]?.[tableIndex][
        ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.ventureFormationTerms
      ][ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherFinancialTermsVf] || {
        value: [],
        options: [],
      },

    "Purpose of the Deal": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data.Deals?.["Additional Information (Deal)"]?.[
        "Purpose of the Deal"
      ] || {
        value: [],
        options: [],
      },
    Quote: (data: ArticleEntryData, tableIndex: number, shellIndex: number) =>
      data?.Deals?.["Key Comment"]?.Quote || {
        value: [],
        options: [],
      },
    Spokesperson: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) =>
      data?.Deals?.["Key Comment"]?.Spokesperson || {
        value: [],
        options: [],
      },
  },
  [EXPEDITED_PROGRAM]: {
    "Brand Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Brand Name"
      ] || {
        value: [],
        options: [],
      },
    "Code Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Code Name"
      ] || {
        value: [],
        options: [],
      },
    "INN Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "INN Name"
      ] || {
        value: [],
        options: [],
      },
    ROA: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.ROA || {
        value: [],
        options: [],
      },
    MOA: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.MOA || {
        value: [],
        options: [],
      },
    Modality: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]
        ?.Modality || {
        value: [],
        options: [],
      },
    Target: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]
        ?.Target || {
        value: [],
        options: [],
      },
    "Target Indication": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Target Indication"
      ] || {
        value: [],
        options: [],
      },
    "Therapeutic Area": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Therapeutic Area"
      ] || {
        value: [],
        options: [],
      },
    "Other Characteristics": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Other Characteristics"
      ] || {
        value: [],
        options: [],
      },
    Phase: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.Phase || {
        value: [],
        options: [],
      },
    "Generic / Biosimilar": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Generic / Biosimilar"
      ] || {
        value: [],
        options: [],
      },
    "Ref.Product Name": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Ref.Product Name"
      ] || {
        value: [],
        options: [],
      },
    "Originator/Developer": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Originator/Developer"
      ] || {
        value: [],
        options: [],
      },
    [k.AdditionalInformation]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.[eg.expeditedProgramContents]?.[
        k.AdditionalInformation
      ] || {
        value: [],
        options: [],
      },
    [k.designatedCompanyName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.[eg.expeditedProgramContents]?.[
        k.designatedCompanyName
      ] || {
        value: [],
        options: [],
      },
    [k.updateDate]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.[eg.expeditedProgramContents]?.[
        k.updateDate
      ] || {
        value: [],
        options: [],
      },
    [k.designatedIndication]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.[eg.expeditedProgramContents]?.[
        k.designatedIndication
      ] || {
        value: [],
        options: [],
      },
    [k.expeditedProgram]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.[eg.expeditedProgramContents]?.[
        k.expeditedProgram
      ] || {
        value: [],
        options: [],
      },
    [k.regulatoryAgency]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.[eg.expeditedProgramContents]?.[
        k.regulatoryAgency
      ] || {
        value: [],
        options: [],
      },
    Quote: (data: ArticleEntryData, tableIndex: number, shellIndex: number) =>
      data?.[EXPEDITED_PROGRAM]?.[eg.keyComment]?.Quote || {
        value: [],
        options: [],
      },
    Spokesperson: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[EXPEDITED_PROGRAM]?.[eg.keyComment]?.Spokesperson || {
        value: [],
        options: [],
      },
  },
  [SECTION_KEYS.ventureCapital]: {
    [DRUG_PROFILE_SHELL_KEYS.brandName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.brandName
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.codeName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.codeName
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.innName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.innName
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.roa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.roa
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.moa]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.moa
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.modality]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.modality
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.target]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.target
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.targetIndication]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.targetIndication
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.therapeuticArea
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.otherCharacteristics
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.phase]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.phase
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.genericBiosimilar]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.genericBiosimilar
      ] || {
        value: ["false"] as string[],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.refProductName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.refProductName
      ] || {
        value: [],
        options: [],
      },
    [DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[tableIndex || 0]?.[
        DRUG_PROFILE_SHELL_KEYS.originatorDeveloper
      ] || {
        value: [],
        options: [],
      },
    [KEY_COMMENT_SHELL_KEYS.quote]: (
      data: ArticleEntryData,
      tableIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[KEY_COMMENT]?.[
        KEY_COMMENT_SHELL_KEYS.quote
      ] || {
        value: [],
        options: [],
      },
    [KEY_COMMENT_SHELL_KEYS.spokesperson]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[KEY_COMMENT]?.[
        KEY_COMMENT_SHELL_KEYS.spokesperson
      ] || {
        value: [],
        options: [],
      },

    [FUNDED_COMPANY_SHELL_KEYS.companyName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[FUNDED_COMPANY]?.[
        FUNDED_COMPANY_SHELL_KEYS.companyName
      ] || {
        value: [],
        options: [],
      },
    [FUNDED_COMPANY_SHELL_KEYS.companyDescription]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[FUNDED_COMPANY]?.[
        FUNDED_COMPANY_SHELL_KEYS.companyDescription
      ] || {
        value: [],
        options: [],
      },
    [FINANCING_INFORMATION_SHELL_KEYS.financingPurpose]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[FINANCING_INFORMATION]?.[
        FINANCING_INFORMATION_SHELL_KEYS.financingPurpose
      ] || {
        value: [],
        options: [],
      },

    [ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundType]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[ROUND_TYPE_AND_STATUS]?.[
        ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundType
      ] || {
        value: [],
        options: [],
      },
    [ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[ROUND_TYPE_AND_STATUS]?.[
        ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundName
      ] || {
        value: [],
        options: [],
      },
    [FINANCING_INFORMATION_SHELL_KEYS.amount]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[FINANCING_INFORMATION]?.[
        FINANCING_INFORMATION_SHELL_KEYS.amount
      ] || {
        value: [],
        options: [],
      },
    [FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[FINANCING_INFORMATION]?.[
        FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail
      ] || {
        value: [],
        options: [],
      },
    [INVESTORS_SHELL_KEYS.investorName]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[INVESTORS]?.[
        INVESTORS_SHELL_KEYS.investorName
      ] || {
        value: [],
        options: [],
      },
    [ADDITIONAL_INFORMATION_VENTURE_CAPITAL_SHELL_KEYS.additionalInformation]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[SECTION_KEYS.ventureCapital]?.[
        ADDITIONAL_INFORMATION_VENTURE_CAPITAL
      ]?.[
        ADDITIONAL_INFORMATION_VENTURE_CAPITAL_SHELL_KEYS.additionalInformation
      ] || {
        value: [],
        options: [],
      },
  },
};

export const SHELL_PATH_TO_ADD_SHELL: Record<
  string,
  (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
    subGroupShellIndex?: number,
  ) => ArmGroupInformation[] | EndpointsGroupInformation[]
> = {
  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation]: (
    data,
    tableIndex,
    groupShellIndex,
    subGroupShellIndex,
  ) =>
    data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
      ENTRY_DATA_CLINICAL_TRIAL_DESIGN
    ]?.[tableIndex][
      ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
    ]?.[subGroupShellIndex || 0]?.[
      ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation
    ] || [],

  "Endpoint Group Information": (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) =>
    data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableIndex]?.[
      "Endpoints Table Information"
    ]?.[groupShellIndex]?.["Endpoint Group Information"] || [],
};

export const BASIC_DATA_FOR_ADDING_SHELLS: Record<
  string,
  Record<
    string,
    (data: ArticleEntryData) => ArmGroupInformation | EndpointsGroupInformation
  >
> = {
  "Clinical Trials": {
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation]: (
      data: ArticleEntryData,
    ) => {
      const createAttributionId = () => createRandomString(8);
      const designGroupInformationData =
        data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN
        ]?.[0][
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
        ]?.[0]?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation
        ];

      return {
        [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName]: {
          value: [],
          options:
            designGroupInformationData?.[0]?.[
              ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName
            ]?.options,
          attributionId: createAttributionId(),
        },
        [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does]: {
          value: [],
          options:
            designGroupInformationData?.[0]?.[
              ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName
            ]?.options,
          attributionId: createAttributionId(),
        },
        [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration]: {
          value: [],
          options:
            designGroupInformationData?.[0]?.[
              ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration
            ]?.options,
          attributionId: createAttributionId(),
        },
      } as ArmGroupInformation;
    },

    "Endpoint Group Information": (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const endpointsGroupInformationData =
        data["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[0]?.[
          "Endpoints Table Information"
        ]?.[0]?.["Endpoint Group Information"]?.[0];

      const format = {
        "Group Name": {
          value: [],
          options: endpointsGroupInformationData?.["Group Name"].options,
          attributionId: createAttributionId(),
        },
        "Group Data": {
          value: [],
          options: endpointsGroupInformationData?.["Group Data"].options,
          attributionId: createAttributionId(),
        },
        "P-Value": {
          value: [],
          options: endpointsGroupInformationData?.["P-Value"].options,
          attributionId: createAttributionId(),
        },
      };

      return format as EndpointsGroupInformation;
    },
  },
};
