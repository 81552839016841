import { TABLE_CREATOR_GROUP_SHELL } from "../constants/tableCreatorGroupShell.constants";
import { TABLE_CREATOR_GROUP_TABLE } from "../constants/tableCreatorGroupTable.constants";
import { TABLE_CREATOR_SHELL } from "../constants/tableCreatorShell.constants";
import { TABLE_CREATOR_TABLE } from "../constants/tableCreatorTable.constants";

import type { ArticleSectionType } from "@/components/business/CTGFillInTableContainer/types/fillInTable.types";

export const getGroupTableTitle = (
  articleSection: ArticleSectionType,
  groupTableKey: string | null,
) =>
  TABLE_CREATOR_GROUP_TABLE[articleSection].find(
    ({ key }) => key === groupTableKey,
  )?.title || "None";

export const getTableTitle = (
  articleSection: ArticleSectionType,
  tableKey: string | null,
) =>
  TABLE_CREATOR_TABLE[articleSection].find(({ key }) => key === tableKey)
    ?.title || null;

export const getGroupShellTitle = (
  articleSection: ArticleSectionType,
  groupShellKey: string | null,
) =>
  TABLE_CREATOR_GROUP_SHELL[articleSection].find(
    ({ key }) => key === groupShellKey,
  )?.title || "";

export const getShellTitle = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.title || "None";
