import { useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useCTGFillInTableRoles } from "../../../../../libs/roles/hooks/useCTGFillInTableRoles";
import { CTG_SECTION } from "../constants/articleSection.constants";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "../constants/tableCreatorGroupTable.constants";

import type { GroupTableKeyType } from "../types/tableCreator.types";

export const useCanEditGroupTable = () => {
  const articleSection = CTG_SECTION;
  const { data: articleInfo } = useCTGInfoQuery(Number(getPressIdFromURL()));

  const { hasEditFullTableRoles } = useCTGFillInTableRoles();

  const checkTableRolesFromTableList = () => {
    return hasEditFullTableRoles;
  };

  const checkDisableEdit = (groupTableKey: GroupTableKeyType) => {
    const isUnsuitableStatus =
      articleInfo?.draftArticleStatus === DRAFT_STATUS_CODE.unsuitable;

    if (isUnsuitableStatus) return true;

    if (!articleInfo) return false || !checkTableRolesFromTableList();

    const enableGroupTableKeyList = ENABLE_GROUP_TABLE_KEY_LIST[articleSection];

    const isEnableEditByStatus = enableGroupTableKeyList[
      groupTableKey as keyof typeof enableGroupTableKeyList
    ].includes(articleInfo.draftArticleStatus);

    return !isEnableEditByStatus || !checkTableRolesFromTableList();
  };

  return {
    checkDisableEdit,
  };
};
