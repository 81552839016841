import styled, { css } from "styled-components";

import IconCheckedModal from "@images/common/icon-check-publishable-white.svg";
import IconCheckedDefault from "@images/pressList/icon-column-check.svg";

import type { PublishablePressCheckboxStyle } from "./PublishablePressCheckbox";

export const PublishableCheckboxLabel = styled.label<{
  $styleType: PublishablePressCheckboxStyle;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #2a2f37;
  cursor: pointer;
  white-space: nowrap;

  ${({ $styleType }) =>
    $styleType === "modal" &&
    css`
      font-size: 1.2rem;
      font-weight: 400;
      color: ${({ theme }) => theme.color.neural.gray06};
    `}
`;

export const PublishableCheckboxInput = styled.input<{
  $styleType: PublishablePressCheckboxStyle;
}>`
  all: unset;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.color.status.todo};
  overflow: hidden;

  &:checked {
    background-image: url(${IconCheckedDefault});
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${({ theme }) => theme.color.status.todo};
  }

  ${({ $styleType }) =>
    $styleType === "modal" &&
    css`
      width: 1.2rem;
      height: 1.2rem;

      &:checked {
        background-image: url(${IconCheckedModal});
        background-color: ${({ theme }) => theme.color.status.todo};
      }
    `}
`;
