import React from "react";

import { ModalityCategoryProvider } from "../../shared/context/ModalityCategoryContext";
import { TableCreatorDropdownProvider } from "../../shared/context/TableCreatorDropdownContext";
import { TableCreatorFocusStatusProvider } from "../../shared/context/TableCreatorFocusStatusContext";
import { TableCreatorFormatErrorPressIdProvider } from "../../shared/context/TableCreatorFormatErrorPressIdContext";
import { TableCreatorHistoryProvider } from "../../shared/context/TableCreatorHistoryContext";
import { TableCreatorPositionInfoProvider } from "../../shared/context/TableCreatorPositionInfoContext";
import { TableCreatorWrapperPositionInfoProvider } from "../../shared/context/TableCreatorWrapperPositionContext";
import { TrialInterventionDrugListProvider } from "../../shared/context/TrialInterventionDrugListContext";
import { ValidatedInTableCreatorProvider } from "../../shared/context/ValidatedInTableCreatorContext";
import { FillInTableRefMapProvider } from "../CTGFillInTable/contexts/FillInTableRefMapContext";

type Props = {
  children: React.ReactNode;
};

const FillInTableProvider = ({ children }: Props) => {
  return (
    <TableCreatorFormatErrorPressIdProvider>
      <FillInTableRefMapProvider>
        <TableCreatorPositionInfoProvider>
          <TableCreatorFocusStatusProvider>
            <ValidatedInTableCreatorProvider>
              <TableCreatorDropdownProvider>
                <TableCreatorWrapperPositionInfoProvider>
                  <TableCreatorHistoryProvider>
                    <TrialInterventionDrugListProvider>
                      <ModalityCategoryProvider>
                        {children}
                      </ModalityCategoryProvider>
                    </TrialInterventionDrugListProvider>
                  </TableCreatorHistoryProvider>
                </TableCreatorWrapperPositionInfoProvider>
              </TableCreatorDropdownProvider>
            </ValidatedInTableCreatorProvider>
          </TableCreatorFocusStatusProvider>
        </TableCreatorPositionInfoProvider>
      </FillInTableRefMapProvider>
    </TableCreatorFormatErrorPressIdProvider>
  );
};

export default FillInTableProvider;
