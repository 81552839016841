/* eslint-disable @typescript-eslint/no-unused-vars */

import { CTGEntryData } from "@/types/ctgEntryData.types";

import { CTG_SECTION } from "./articleSection.constants";

import type { ShellColumnSize } from "../types/shellSize.types";
import type { EntryDataValueInfo } from "@/types/drugProfile.types";

export const TABLE_CREATOR_SHELL: Record<
  string,
  {
    key: string;
    title: string;
    customTitle?: string;
    placeholder?: string;
    isRequired: boolean;
    isDictionary: boolean;
    columnSize: ShellColumnSize;
    countValueLength: number;
    isReviseShell: boolean;
    isDisabledInsertText: boolean;
    isBasedOnShellValueDropdown?: boolean;
    isAutocomplete?: boolean;
  }[]
> = {
  [CTG_SECTION]: [
    {
      key: "Brand Name",
      title: "Brand Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Code Name",
      title: "Code Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "INN Name",
      title: "INN Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 3,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "ROA",
      title: "ROA",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Modality",
      title: "Modality",
      isRequired: false,
      isDictionary: true,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "MOA",
      title: "MOA",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Therapeutic Area",
      title: "Therapeutic Area",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target",
      title: "Target",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Target Indication",
      title: "Target Indication",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Other Characteristics",
      title: "Other Characteristics",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Generic / Biosimilar",
      title: "Generic / Biosimilar",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Ref.Product Name",
      title: "Ref.Product Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Originator/Developer",
      title: "Originator/Developer",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Phase",
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Clinical Design Phase",
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Inclusion Criteria - Diagnostic",
      title: "Inclusion Criteria - Diagnostic",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Inclusion Criteria - Detail",
      title: "Inclusion Criteria - Detail",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Trial Name",
      title: "Trial Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 2,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "NCT Name",
      title: "Clinical Trial Identifier",
      isRequired: false,
      isDictionary: false,
      columnSize: "regularWithValidation",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Total Patients Number",
      title: "Total Patients Num.",
      placeholder: "Total Patients Number",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Trial Intervention",
      title: "Trial Intervention",
      placeholder: "Trial Intervention",
      isRequired: true,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Arm Name",
      title: "Arm Name",
      isRequired: true,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Patients Number",
      title: "Arm Patients Num.",
      placeholder: "Arm Patients Number",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Design Group Information",
      title: "Treatment",
      isRequired: true,
      isDictionary: false,
      columnSize: "medium",
      countValueLength: 1,
      isReviseShell: true,
      isDisabledInsertText: false,
    },
    {
      key: "Group Data",
      title: "Group Data",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Group Name",
      title: "Group Name",
      isRequired: true,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "P-Value",
      title: "P-Value",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Drug Name",
      title: "Drug Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Dose",
      title: "Dose",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Duration",
      title: "Duration",
      isRequired: false,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Phase",
      title: "Phase",
      isRequired: false,
      isDictionary: true,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Results Classification",
      title: "Results Classification",
      isRequired: true,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Endpoint Classification",
      title: "Endpoint Classification",
      isRequired: false,
      isDictionary: true,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Endpoint Name including Measurement Timing",
      title: "Endpoint (Measurement Timing)",
      isRequired: true,
      isDictionary: false,
      columnSize: "regular",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Endpoint Group Information",
      title: "Group Name",
      customTitle: "Group Name",
      isRequired: false,
      isDictionary: false,
      columnSize: "small",
      countValueLength: 1,
      isReviseShell: true,
      isDisabledInsertText: false,
    },
    {
      key: "Endpoints Table Notes",
      title: "Table Notes",
      placeholder: "To add a Table Note in the text field, type '**' or '++'",
      isRequired: false,
      isDictionary: false,
      columnSize: "jumbo",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Endpoint Description",
      title: "Endpoint Description",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 100,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Quote",
      title: "Quote",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
    {
      key: "Spokesperson",
      title: "Spokesperson",
      isRequired: false,
      isDictionary: false,
      columnSize: "extraLarge",
      countValueLength: 1,
      isReviseShell: false,
      isDisabledInsertText: false,
    },
  ],
};

export const SHELL_PATH_TO_READ_SHELL_INFO: Record<
  string,
  {
    [key: string]: (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
      subGroupShellIndex: number,
    ) => {
      value: EntryDataValueInfo[];
      options: string[];
    };
  }
> = {
  [CTG_SECTION]: {
    "Brand Name": (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Brand Name"
      ] || {
        value: [],
        options: [],
      },
    "Code Name": (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Code Name"
      ] || {
        value: [],
        options: [],
      },
    "INN Name": (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "INN Name"
      ] || {
        value: [],
        options: [],
      },
    ROA: (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.ROA || {
        value: [],
        options: [],
      },
    MOA: (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.MOA || {
        value: [],
        options: [],
      },
    Modality: (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.Modality || {
        value: [],
        options: [],
      },
    Target: (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.Target || {
        value: [],
        options: [],
      },
    "Target Indication": (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Target Indication"
      ] || {
        value: [],
        options: [],
      },
    "Therapeutic Area": (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Therapeutic Area"
      ] || {
        value: [],
        options: [],
      },
    "Other Characteristics": (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Other Characteristics"
      ] || {
        value: [],
        options: [],
      },
    "Generic / Biosimilar": (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Generic / Biosimilar"
      ] || {
        value: [],
        options: [],
      },
    "Ref.Product Name": (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Ref.Product Name"
      ] || {
        value: [],
        options: [],
      },
    "Originator/Developer": (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.[
        "Originator/Developer"
      ] || {
        value: [],
        options: [],
      },
    Phase: (
      data: CTGEntryData,
      tableIndex: number,
      groupShellIndex: number,
      shellIndex: number,
    ) =>
      data?.[CTG_SECTION]?.["Drug Profile"]?.[tableIndex || 0]?.Phase || {
        value: [],
        options: [],
      },
  },
};
