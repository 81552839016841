import styled, { css } from "styled-components";

import {
  DeleteButton,
  DeleteButtonWrapper,
  FixedWrapper,
} from "@/components/business/CTGFillInTableContainer/shared/styles/deleteButton.style";
import {
  GroupShellTitleWrapper,
  OutsideWrapper,
} from "@/components/business/CTGFillInTableContainer/shared/styles/groupShell.style";
import {
  SelectButton,
  SelectButtonDivider,
  SelectButtonDividerWrapper,
  SelectContentWrapper,
} from "@/components/business/CTGFillInTableContainer/shared/styles/rowSelectButton.style";

export const GroupShellOutsideWrapper = styled(OutsideWrapper)``;
export const GroupShellWrapper = styled.div<{
  isFocused: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
  isTableScroll: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ isFocused }) => isFocused && css``}

  ${({ isTableScroll }) =>
    isTableScroll &&
    css`
      min-width: max-content;
    `}

  ${({ isClearGroupShell }) => isClearGroupShell && css``};

  ${({ isAlertGroupShell }) =>
    isAlertGroupShell &&
    css`
      border: 0.2rem solid rgba(251, 110, 82, 0.5);
    `};
`;

export const GroupShell = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem 0.8rem;
  width: 100%;
  padding-right: 1.6rem;
`;

export const FixedDeleteButtonWrapper = styled(FixedWrapper)`
  top: -2.6rem;
  left: -0.2rem;
  width: 100%;

  ${({ isShellTitle }) =>
    isShellTitle &&
    css`
      top: -2.6rem;
      left: -0.2rem;
    `}
`;

export const TitleWrapper = styled(GroupShellTitleWrapper)``;

export const DeleteTableButtonWrapper = styled(DeleteButtonWrapper)``;
export const DeleteTableButton = styled(DeleteButton)``;

export const SelectWrapper = styled(SelectContentWrapper)`
  padding: 0;
`;
export const GroupShellSelectButton = styled(SelectButton)``;

export const GroupShellSelectButtonDivider = styled(SelectButtonDivider)``;

export const GroupShellSelectButtonDividerWrapper = styled(
  SelectButtonDividerWrapper,
)<{ isPossibleSelected: boolean }>`
  position: sticky;
  top: 50%;
  left: 0;
`;

export const ShellWrapperForSeparateShell = styled.div`
  width: 100%;
`;
