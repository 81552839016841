import styled from "styled-components";

export const BottomButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 92rem;
  background-color: #ffffff;
  padding: var(--Spacing-12, 1.2rem) var(--Spacing-16, 1.6rem);
  border-top: 0.1rem solid #e5e7eb;
  z-index: 30;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
`;
