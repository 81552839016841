import styled from "styled-components";

export const CtgListCompanyShell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-6, 0.6rem);

  &:hover {
    .tooltip-wrapper {
      display: block;
    }
  }
`;

export const CtgListCompanyItem = styled.li`
  position: relative;
  padding-left: 0.7rem;
  list-style: none;
`;

export const CtgListCompanyItemText = styled.span`
  &::before {
    position: absolute;
    left: 0rem;
    top: 0;
    display: block;
    content: "•";
    color: #2a2f37;
    font-size: 1.4rem;
  }
`;

export const CtgListCompanyNumber = styled.div``;

export const TooltipWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 100%;
  margin-top: 0.2rem;
  transform: translateX(-50%);
  z-index: 100;
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Primary, #fff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16),
    0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  display: none;

  .tooltip {
    color: var(--Color-Common-Text-Primary, #2a2f37);
    font-size: 1.2rem;
    font-weight: 400;
    white-space: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    width: 18.4rem;
    padding: var(--Spacing-12, 1.2rem) var(--Spacing-8, 0.8rem);
    gap: var(--Spacing-6, 0.6rem);
    border: none;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
