/* eslint-disable @typescript-eslint/no-unused-vars */

import { CTGEntryData } from "@/types/ctgEntryData.types";
import { createRandomString } from "@organisms/TableCreatorComment/components/CommentModalContainer/components/CommentModalMessageComposer/utils/createRandomString";

import { CTG_SECTION } from "./articleSection.constants";
import { ShellColumnSize } from "../types/shellSize.types";

export const TABLE_CREATOR_TABLE: Record<
  string,
  {
    key: string;
    title: string;
    isRequired: boolean;
    isReviseTable: boolean;
    isTableScroll: boolean;
    columnSize: ShellColumnSize;
  }[]
> = {
  [CTG_SECTION]: [
    {
      key: "Drug",
      title: "Drug",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
  ],
};

export const BASIC_DATA_FOR_ADDING_TABLES = {
  [CTG_SECTION]: {
    "Drug Profile": (data: CTGEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const drugProfileTableData = data?.[CTG_SECTION]?.["Drug Profile"]?.[0];

      if (!drugProfileTableData) return;
      return {
        ROA: {
          value: [],
          options: drugProfileTableData?.ROA?.options,
          attributionId: createAttributionId(),
        },
        Modality: {
          value: [],
          options: drugProfileTableData?.Modality?.options,
          attributionId: createAttributionId(),
        },
        Target: {
          value: [],
          options: drugProfileTableData?.Target?.options,
          attributionId: createAttributionId(),
        },
        MOA: {
          value: [],
          options: drugProfileTableData?.MOA?.options,
          attributionId: createAttributionId(),
        },
        "Code Name": {
          value: [],
          options: drugProfileTableData?.["Code Name"]?.options,
          attributionId: createAttributionId(),
        },
        "INN Name": {
          value: [],
          options: drugProfileTableData?.["INN Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Brand Name": {
          value: [],
          options: drugProfileTableData?.["Brand Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Target Indication": {
          value: [],
          options: drugProfileTableData?.["Target Indication"]?.options,
          attributionId: createAttributionId(),
        },
        "Therapeutic Area": {
          value: [],
          options: drugProfileTableData?.["Therapeutic Area"]?.options,
          attributionId: createAttributionId(),
        },
        "Generic / Biosimilar": {
          value: ["false"],
          options: drugProfileTableData?.["Generic / Biosimilar"]?.options,
          attributionId: createAttributionId(),
        },
        "Ref.Product Name": {
          value: [],
          options: drugProfileTableData?.["Ref.Product Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Other Characteristics": {
          value: [],
          options: drugProfileTableData?.["Other Characteristics"]?.options,
          attributionId: createAttributionId(),
        },
        "Originator/Developer": {
          value: [],
          options: drugProfileTableData?.["Originator/Developer"]?.options,
          attributionId: createAttributionId(),
        },
        Phase: {
          value: drugProfileTableData?.Phase?.value, // NOTE: Phase는 편집 불가능한 쉘이므로 [0]번째의 기준 값을 유지한다.
          options: drugProfileTableData?.Phase?.options,
          attributionId: createAttributionId(),
        },
        attributionInfo: {
          attributionId: createAttributionId(),
        },
      };
    },
  },
};
