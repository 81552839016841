import styled, { css } from "styled-components";

export const TooltipWrapper = styled.div<{
  $maxWidth?: string;
  $minWidth?: string;
  $width?: string;
}>`
  position: absolute;
  left: 0;
  top: 100%;
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  border: 1px solid #e5e7eb;
  background: white;
  white-space: nowrap;

  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  color: #2a2f37;
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 130%;
  z-index: 100;

  ${({ $maxWidth, $minWidth, $width }) => css`
    ${$maxWidth && `max-width: ${$maxWidth};`}
    ${$minWidth && `min-width: ${$minWidth};`}
    ${$width &&
    `
      width: ${$width}; 
      white-space: normal;
    `}
  `}
`;
