import React, { useId, useEffect, useRef, Suspense } from "react";

import UnsuitableReasonModalContainer from "@/components/shared/business/UnsuitableReason/components/UnsuitableReasonModalContainer/UnsuitableReasonModalContainer";
import { UNSUITABLE_REASON_KEYS } from "@/components/shared/business/UnsuitableReason/constants/unsuitableReason.constants";

import * as S from "./UnsuitableCheckboxIcon.style";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  isChecked: boolean;
  draftArticleId: number;
  isOpenModalUnsuitablePress: boolean;
  articleRejectReason: string | null;
  closeModalUnsuitablePress: () => void;
  openModalUnsuitablePress: () => void;
  mcEditorName: string | null;
  aiDone: boolean;
}

const UnsuitableCheckboxIcon = ({
  isChecked,
  draftArticleId,
  isOpenModalUnsuitablePress,
  articleRejectReason,
  closeModalUnsuitablePress,
  openModalUnsuitablePress,
  ...rest
}: Props) => {
  const checkboxId = useId();
  const checkboxRef = useRef<HTMLDivElement>(null);

  const handleCheckboxClick = () => {
    if (isOpenModalUnsuitablePress) {
      closeModalUnsuitablePress();
    } else {
      openModalUnsuitablePress();
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const isClickInside = checkboxRef.current?.contains(target);
      const isDeleteButton = target.dataset.button;

      if (isClickInside || isDeleteButton) return;

      if (isOpenModalUnsuitablePress) {
        closeModalUnsuitablePress();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpenModalUnsuitablePress, closeModalUnsuitablePress]);

  const modalPosition = {
    top: "103%",
    left: "-150%",
    right: "auto",
    bottom: "auto",
  };

  return (
    <div ref={checkboxRef}>
      <S.CheckboxLabel htmlFor={checkboxId} onClick={handleCheckboxClick}>
        <S.CheckboxInput
          id={checkboxId}
          type="checkbox"
          checked={isChecked}
          {...rest}
        />
      </S.CheckboxLabel>
      <Suspense>
        {isOpenModalUnsuitablePress && (
          <S.UnsuitablePosition>
            <UnsuitableReasonModalContainer
              closeModalUnsuitablePress={closeModalUnsuitablePress}
              articleRejectReason={articleRejectReason}
              draftArticleId={draftArticleId}
              unsuitableReasonUsageType={UNSUITABLE_REASON_KEYS.ctgList}
              isOpenModalUnsuitablePress={isOpenModalUnsuitablePress}
              modalPosition={modalPosition}
            />
          </S.UnsuitablePosition>
        )}
      </Suspense>
    </div>
  );
};

export default UnsuitableCheckboxIcon;
