import {
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS,
  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import { useTableCreatorPositionInfo } from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  clearSubGroupShellData: () => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    subGroupShellIndex: number;
    subGroupShellKey: string | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useClearVentureFormationSubGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const { info } = useTableCreatorPositionInfo();

  const clearSubGroupShellData = () => {
    const clearUpfrontPaymentSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                  ],
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.initialInvestment]: {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                    ][
                      ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.initialInvestment
                    ],
                    value: [],
                  },
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearContingentSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                  ],
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentAndDetailVf]:
                    tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                    ][
                      ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                        .contingentInvestmentAndDetailVf
                    ].map((subGroupShellInfo, subGroupShellInfoIndex) => {
                      if (subGroupShellInfoIndex === info.subGroupShellIndex) {
                        return {
                          ...subGroupShellInfo,
                          [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentVf]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                                  .contingentInvestmentVf
                              ],
                              value: [],
                            },
                          [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentDetailVf]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                                  .contingentDetailVf
                              ],
                              value: [],
                            },
                        };
                      }

                      return subGroupShellInfo;
                    }),
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearTotalPaymentSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                  ],
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.totalInvestmentVf]: {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                    ][
                      ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.totalInvestmentVf
                    ],
                    value: [],
                  },
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.disclosedPaymentsSumVf]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                      ][
                        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                          .disclosedPaymentsSumVf
                      ],
                      value: [],
                    },
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearOwnershipStructureDetailsSubGroupShell =
      (): ArticleEntryData => {
        if (info.groupShellIndex === null) return entryData;

        return {
          ...entryData,
          [ENTRY_DATA_SECTION_KEYS.deals]: {
            ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
            [ENTRY_DATA_LICENSEE_TABLE]: entryData[
              ENTRY_DATA_SECTION_KEYS.deals
            ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms]: {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                    ],
                    [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.ownershipStructureDetails]:
                      {
                        ...tableInfo[
                          ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                        ][
                          ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                            .ownershipStructureDetails
                        ],
                        value: [],
                      },
                  },
                };
              }
              return tableInfo;
            }),
          },
        };
      };

    const clearOtherFinancialTermsSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                  ],
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.otherFinancialTermsVf]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                      ][
                        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                          .otherFinancialTermsVf
                      ],
                      value: [],
                    },
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    if (
      info.subGroupShellKey ===
      VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.initialInvestment
    ) {
      setEntryData(clearUpfrontPaymentSubGroupShell());
      changeCurrentHistory({
        entryData: clearUpfrontPaymentSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (
      info.subGroupShellKey ===
      VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.contingentInvestment
    ) {
      const contingentPaymentLength =
        entryData?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_LICENSEE_TABLE
        ]?.[info.tableIndex][
          ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
        ][
          ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
            .contingentInvestmentAndDetailVf
        ]?.length || 0;

      setEntryData(clearContingentSubGroupShell());
      changeCurrentHistory({
        entryData: clearContingentSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(
        contingentPaymentLength > 1
          ? {
              type: "delete",
              container: "subGroupShell",
            }
          : DEFAULT_FOCUS_STATUS,
      );
    }

    if (
      info.subGroupShellKey ===
      VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.totalInvestmentVf
    ) {
      setEntryData(clearTotalPaymentSubGroupShell());
      changeCurrentHistory({
        entryData: clearTotalPaymentSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (
      info.subGroupShellKey ===
      VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.ownershipStructureDetails
    ) {
      setEntryData(clearOwnershipStructureDetailsSubGroupShell());
      changeCurrentHistory({
        entryData: clearOwnershipStructureDetailsSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (
      info.subGroupShellKey ===
      VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.otherFinancialTerms
    ) {
      setEntryData(clearOtherFinancialTermsSubGroupShell());
      changeCurrentHistory({
        entryData: clearOtherFinancialTermsSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }
  };

  return {
    clearSubGroupShellData,
  };
};
