import * as S from "./TextHoverTooltip.style";

interface Props {
  text: string;
  tooltipStyle?: {
    maxWidth?: string;
    minWidth?: string;
    width?: string;
  };
}
const TextHoverTooltip = ({ text, tooltipStyle }: Props) => {
  return (
    <S.TooltipWrapper
      $maxWidth={tooltipStyle?.maxWidth}
      $minWidth={tooltipStyle?.minWidth}
      $width={tooltipStyle?.width}
    >
      {text}
    </S.TooltipWrapper>
  );
};

export default TextHoverTooltip;
