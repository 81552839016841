import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  PositionInfo,
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@/components/business/CTGFillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import {
  getMaxShellValueLength,
  isEnterTableNotes,
  isDisabledInsertText,
} from "@/components/business/CTGFillInTableContainer/shared/utils/getOptionsFromData";
import { getPressIdFromURL } from "@/utils/getStateFromURL";
import { normalizeWhitespaceAndTrim } from "@/utils/normalizeWhitespaceAndTrim";
import { useCTGEntryDataState } from "@stores/ctgEntryData";

import useCheckIsActiveDropdownKeyboardNavigation from "./useCheckIsActiveDropdownKeyboardNavigation";
import { useHandleInfoOfDropdownLists } from "./useHandleInfoOfDropdownLists";
import {
  DEFAULT_TARGET_INDEX,
  TargetListType,
  useHandleTargetListIndex,
} from "./useHandleTargetListIndex";
import {
  AIDropdownListFormat,
  DataSetDropdownList,
  DefaultDataSetType,
  DictionaryCategoryDropdownList,
  DictionaryDefaultDropdownList,
  DictionaryList,
} from "../components/TableCreatorListBox/types/tableCreatorDropdown.types";
import { CTG_SECTION } from "../constants/articleSection.constants";
import { SHELL_PATH_TO_READ_SHELL_INFO } from "../constants/tableCreatorShell.constants";
import { TEXTFIELD_PATH_TO_ENTER_ENTRY_DATA } from "../constants/tableCreatorTextField.constants";
import { useDatasetDropdownState } from "../stores/datasetDropdown";
import { changeFormatAiDropdownList } from "../utils/changeFormatAiDropdownList";

import type {
  EntryDataValueAndOptions,
  EntryDataValueInfo,
} from "@/types/drugProfile.types";

interface UseHandleTextFieldInputEventReturn {
  selectedValue: string;
  validationMessage: [boolean, string] | null;
  textFieldTextareaRef: React.RefObject<HTMLDivElement>;
  targetListIndex: TargetListType;
  valueInfo: EntryDataValueInfo;
  aiList: AIDropdownListFormat[];
  dictionaryList: DictionaryList;
  dataSetList: DataSetDropdownList[];
  isFocusInputField: boolean;
  isDictionaryList: boolean;
  isOriginalAiList: boolean;
  isOriginalDictionaryList: boolean;
  isDataSetList: boolean;
  copyStatus: "default" | "success" | "error";
  handleChangedEnterValue: (event: React.FormEvent<HTMLDivElement>) => void;
  handleEnterValueKeyboard: (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => void;
  changeSelectedValue: (selectedItem: string) => void;
  handleFocusTextField: (event: React.FocusEvent<HTMLDivElement>) => void;
  handleFocusTextFieldAndOpenListBox: () => void;
  handleBlurTextField: () => void;
  checkIndexOfValueInDefaultAiList: () => number;
  handleFocusOutTextField: () => void;
  handleChangeDataSetValue: (selectItem: DefaultDataSetType) => void;
  deleteCurrentTextField: (event: React.MouseEvent<HTMLButtonElement>) => void;
  copyCurrentTextValue: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => Promise<void>;
}

export const useHandleTextFieldInputEvent = (
  positionInfo: PositionInfo,
  shellValue: EntryDataValueAndOptions,
): UseHandleTextFieldInputEventReturn => {
  const textFieldTextareaRef = useRef<HTMLDivElement>(null);

  const draftArticleId = getPressIdFromURL();
  const articleSection = CTG_SECTION;
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { checkExistNextLine } = useCheckIsActiveDropdownKeyboardNavigation();

  const {
    targetListIndex,
    changeTargetListIndex,
    arrowDownChangeIndex,
    arrowUpChangeIndex,
  } = useHandleTargetListIndex();

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();

  const currentTextareaInput = textFieldTextareaRef?.current?.innerText || "";

  const {
    groupTableKey,
    groupTableIndex,
    tableKey,
    tableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellKey,
    subGroupShellIndex,
    shellKey,
    shellIndex,
    valueIndex,
  } = positionInfo;

  const {
    initialDropdownList,
    initialDataSetList,
    dictionaryList,
    aiList,
    dataSetList,
    isDictionaryList,
    isCategoryList,
    isDataSetList,
    isOriginalAiList,
    isOriginalDictionaryList,
    changeDictionaryList,
    changeAiList,
    changeDatasetDropdownList,
    currentDictionaryStringList,
    currentAiStringList,
    currentShellKeyDatasetList,
  } = useHandleInfoOfDropdownLists({
    shellValue,
    shellKey,
  });

  const valueInfo = shellValue.value[valueIndex || 0] || [
    { text: "", is_modified: true },
  ];

  const currentDictionaryList = currentDictionaryStringList;
  const currentAiList = currentAiStringList;
  const entireDropdownList = useMemo(
    () => [...dataSetList, ...aiList, ...dictionaryList],
    [dataSetList, aiList, dictionaryList],
  );

  const [entryData, setEntryData] = useCTGEntryDataState(draftArticleId);
  const [selectedDataset, setSelectedDataset] =
    useDatasetDropdownState(groupTableKey);
  const [enterValue, setEnterValue] = useState<string>(valueInfo.text);
  const [validationMessage, setValidationMessage] = useState<
    [boolean, string] | null
  >(null);
  const [isFocusInputField, setIsFocusInputField] = useState<boolean>(false);
  const [copyStatus, setCopyStatus] = useState<"default" | "success" | "error">(
    "default",
  );

  const copyTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const copyCurrentTextValue = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      await navigator.clipboard.writeText(enterValue);
      setCopyStatus("success");

      copyTimeoutRef.current = setTimeout(() => {
        setCopyStatus("default");
      }, 1000);
    } catch (error) {
      setCopyStatus("error");

      copyTimeoutRef.current = setTimeout(() => {
        setCopyStatus("default");
      }, 1000);
    }
  };

  useEffect(() => {
    return () => {
      if (copyTimeoutRef.current) {
        clearTimeout(copyTimeoutRef.current);
      }
    };
  }, []);

  const handleFocusOutTextField = () => {
    setIsFocusInputField(false);
    setSelectedDataset({});
    changeTargetListIndex(DEFAULT_TARGET_INDEX);
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);

    if (
      getMaxShellValueLength(articleSection, shellKey || "") > 1 &&
      valueIndex &&
      valueIndex > shellValue.value.length - 1
    ) {
      if (textFieldTextareaRef.current)
        textFieldTextareaRef.current.innerText = "";
    }

    changeAiList(changeFormatAiDropdownList(currentAiStringList, shellKey));
    changeDictionaryList(initialDropdownList);
    changeDatasetDropdownList(initialDataSetList);
  };

  const focusEventToChangePositionInfo = () => {
    changeInfo(positionInfo);
    changeFocusStatus({
      type: "focusInput",
      container: "textField",
    });
    setIsFocusInputField(true);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const changedEnterOrSelectedValue = (
    text: string,
    selectShellKey?: string,
  ) => {
    const valueText = normalizeWhitespaceAndTrim(text);

    if (groupTableKey === "") return;
    const isAbleCheckValue =
      shellKey !== null && shellIndex !== null && valueIndex !== null;

    const currentShellKey = selectShellKey || shellKey;

    const changedEntryData =
      TEXTFIELD_PATH_TO_ENTER_ENTRY_DATA?.[articleSection]?.[groupTableKey] &&
      isAbleCheckValue
        ? TEXTFIELD_PATH_TO_ENTER_ENTRY_DATA[articleSection][groupTableKey]?.(
            entryData,
            tableIndex,
            tableKey,
            groupShellKey,
            groupShellIndex,
            subGroupShellIndex,
            currentShellKey as string,
            shellIndex,
            valueText,
            valueIndex,
            [...currentDictionaryList, ...currentAiList],
          )
        : {};

    if (changedEntryData) {
      changeAiList(changeFormatAiDropdownList(currentAiStringList, shellKey));
      changeDatasetDropdownList(initialDataSetList);
      changeDictionaryList(initialDropdownList);
      changeTargetListIndex(DEFAULT_TARGET_INDEX);
      setEntryData(() => changedEntryData);
      changeCurrentHistory({
        entryData: changedEntryData,
        changedDataInfo: {
          target: "value",
          action: "update",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey,
            groupShellIndex,
            shellKey,
            shellIndex,
            value: valueText,
            valueIndex,
          },
        },
      });

      handleFocusOutTextField();
    }
  };

  const changeAiListBasedOnEnteredValue = (enteredValue: string) => {
    const currentOptions = shellValue.options;
    const lowerCaseValue = enteredValue.toLowerCase();
    const aiListIncludingValue =
      enteredValue === ""
        ? currentOptions
        : currentOptions.filter((option) =>
            option.toLowerCase().includes(lowerCaseValue),
          );
    changeAiList(changeFormatAiDropdownList(aiListIncludingValue, shellKey));
    changeTargetListIndex(DEFAULT_TARGET_INDEX);
  };

  const handleCompareCurrentInputDictionaryDropdown = (
    currentInput: string,
  ) => {
    if (isCategoryList) {
      const currentDropdownList =
        initialDropdownList as DictionaryCategoryDropdownList[];

      const updateIncludesCurrentInputList = currentDropdownList.map(
        (dropdown) => ({
          ...dropdown,
          list: dropdown.list
            .map((category) => ({
              ...category,
              list: category.list.filter((listItem) =>
                listItem.toLocaleLowerCase().includes(currentInput),
              ),
            }))
            .filter((listItem) => listItem.list.length > 0),
        }),
      );

      return updateIncludesCurrentInputList;
    }

    const currentDropdownList =
      initialDropdownList as DictionaryDefaultDropdownList[];

    const updateIncludesCurrentInputList = currentDropdownList.map(
      (dropdown) => ({
        ...dropdown,
        list: dropdown.list.filter((listItem) =>
          listItem.toLocaleLowerCase().includes(currentInput),
        ),
      }),
    );

    return updateIncludesCurrentInputList;
  };

  const changeDictionaryListBasedOnEnteredValue = (enteredValue: string) => {
    const originalDictionaryList = initialDropdownList;
    const lowerCaseValue = enteredValue.toLowerCase();

    const dictionaryListIncludingValue =
      enteredValue === ""
        ? originalDictionaryList
        : handleCompareCurrentInputDictionaryDropdown(lowerCaseValue);

    changeDictionaryList(dictionaryListIncludingValue);
    changeTargetListIndex(DEFAULT_TARGET_INDEX);
  };

  const handleChangedEnterValue = (event: React.FormEvent<HTMLDivElement>) => {
    if (groupTableKey === "") return;

    const { innerText, textContent } = event.currentTarget;

    if (isEnterTableNotes(articleSection, groupTableKey)) {
      const isNeedToChangeSymbol = textContent?.match(/\+\+/g);
      const transformedValueToRemark = textContent
        ? textContent.replace(/\+\+/g, "†")
        : "";

      if (textFieldTextareaRef.current) {
        const cursorPosition = window.getSelection()?.focusOffset || 0;

        textFieldTextareaRef.current.textContent = transformedValueToRemark;

        if (isNeedToChangeSymbol) {
          const range = document.createRange();
          const selection = window.getSelection();

          if (selection) {
            range.setStart(
              textFieldTextareaRef.current.childNodes[0],
              cursorPosition > 0 ? cursorPosition - 1 : 0,
            );
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }
      }

      setEnterValue(transformedValueToRemark);
    }
    if (!isEnterTableNotes(articleSection, groupTableKey)) {
      setEnterValue(innerText);
    }

    focusEventToChangePositionInfo();
    changeAiListBasedOnEnteredValue(innerText);
    changeDictionaryListBasedOnEnteredValue(innerText);
  };

  const handleChangeDataSetValue = (
    selectedValue: DefaultDataSetType,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _isFocusOut = true,
  ) => {
    setSelectedDataset(selectedValue);
  };

  const handleChangeArrowEventDatasetValue = (
    selectedValue: DefaultDataSetType,
  ) => {
    setSelectedDataset(selectedValue);
    setEnterValue(selectedValue[shellKey as string]);
  };

  const handleChangeFocusDropdownValue = (
    currentValue: string | DefaultDataSetType,
  ) => {
    if (!textFieldTextareaRef.current) return;

    if (typeof currentValue === "object")
      return handleChangeArrowEventDatasetValue(currentValue);

    setSelectedDataset({});
    setEnterValue(currentValue);
  };

  const handleEnterValueKeyboard = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (
      isDisabledInsertText(articleSection, shellKey) &&
      event.key !== "Enter" &&
      event.key !== "ArrowDown" &&
      event.key !== "ArrowUp"
    ) {
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    if (textFieldTextareaRef.current) {
      if (!event.shiftKey && event.key === "Enter") {
        event.preventDefault();

        changedEnterOrSelectedValue(enterValue || "");
        textFieldTextareaRef.current.innerText = enterValue;
        textFieldTextareaRef.current.blur();
      }
    }

    if (!isDictionaryList && !isDataSetList && !aiList.length) return;

    if (event.key === "ArrowDown") {
      if (!textFieldTextareaRef.current) return;

      const currentCaretSelectionStatus = checkExistNextLine({
        textFieldRefCurrent: textFieldTextareaRef.current,
      });

      const { isHaveNextBottomCaretLine } = currentCaretSelectionStatus;

      if (!isHaveNextBottomCaretLine || !currentTextareaInput)
        event.preventDefault();

      if (!isHaveNextBottomCaretLine || !currentTextareaInput) {
        arrowDownChangeIndex({
          entireDropdownList,
          handleChangeFocusDropdownValue,
          event,
          currentCaretSelectionStatus,
          currentTextareaInput,
        });
      }
    }

    if (event.key === "ArrowUp") {
      if (!textFieldTextareaRef.current) return;

      const currentCaretSelectionStatus = checkExistNextLine({
        textFieldRefCurrent: textFieldTextareaRef.current,
      });

      const { isHaveNextTopCaretLine } = currentCaretSelectionStatus;

      if (!isHaveNextTopCaretLine || !currentTextareaInput)
        event.preventDefault();

      if (!isHaveNextTopCaretLine || !currentTextareaInput) {
        arrowUpChangeIndex({
          entireDropdownList,
          handleChangeFocusDropdownValue,
          event,
          currentCaretSelectionStatus,
          currentTextareaInput,
        });
      }
    }
  };

  const handleBlurTextField = () => {
    if (!textFieldTextareaRef.current) return;

    const targetPathInEntryData = SHELL_PATH_TO_READ_SHELL_INFO[
      articleSection
    ]?.[shellKey || ""]?.(
      entryData,
      tableIndex,
      groupShellIndex || 0,
      shellIndex || 0,
      subGroupShellIndex || 0,
    )?.value;
    const targetValueInEntryData =
      targetPathInEntryData?.[valueIndex || 0]?.text || "";

    if (targetValueInEntryData === textFieldTextareaRef.current.innerText)
      return;

    if (Object.values(selectedDataset).length > 1) {
      return setIsFocusInputField(false);
    }

    changedEnterOrSelectedValue(enterValue || "");
    setIsFocusInputField(false);
  };

  const deleteCurrentTextField = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (textFieldTextareaRef.current) {
      textFieldTextareaRef.current.innerText = "";

      changedEnterOrSelectedValue("");
      textFieldTextareaRef.current.blur();
    }
  };

  const isFocused =
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.subGroupShellIndex === subGroupShellIndex &&
    info.subGroupShellKey === subGroupShellKey &&
    info.shellKey === shellKey &&
    info.shellIndex === shellIndex &&
    info.valueIndex === valueIndex;

  const changeSelectedValue = (selectedItem: string) => {
    setEnterValue(selectedItem);

    changedEnterOrSelectedValue(selectedItem);
  };

  const handleFocusTextField = (event: React.FocusEvent<HTMLDivElement>) => {
    event.stopPropagation();

    focusEventToChangePositionInfo();
  };

  const handleFocusTextFieldAndOpenListBox = () => {
    if (isFocusInputField) {
      handleFocusOutTextField();
      return;
    }
    focusEventToChangePositionInfo();
  };

  const checkIndexOfValueInDefaultAiList = () => {
    if (
      valueInfo.is_modified === false &&
      currentAiList.includes(valueInfo.text)
    ) {
      const targetIndexInAiList = currentAiList.indexOf(valueInfo.text);
      return targetIndexInAiList;
    }
    return -1;
  };

  const changeTargetCategoryDictionaryList = useCallback(
    (
      categoryDropdown: DictionaryCategoryDropdownList,
      mainDropdownIndex: number,
    ) => {
      const targetValue = currentTextareaInput;

      const targetCategoryIndex = categoryDropdown.list
        .map((listItem, categoryIndex) => {
          if (listItem.list.includes(targetValue)) {
            return {
              listIndex: listItem.list.indexOf(targetValue),
              categoryIndex,
            };
          }

          return null;
        })
        .find((item) => item !== null);

      if (targetCategoryIndex?.listIndex !== undefined) {
        changeTargetListIndex({
          mainIndex: mainDropdownIndex,
          listIndex: targetCategoryIndex?.listIndex || 0,
          categoryIndex: targetCategoryIndex?.categoryIndex || 0,
          key: categoryDropdown.key,
        });

        return true;
      }

      return false;
    },
    [changeTargetListIndex, currentTextareaInput],
  );

  const changeTargetDefaultDropdownList = useCallback(
    ({
      currentDropdownList,
      dropdownIndex,
      dropdownKey,
    }: {
      currentDropdownList: string[];
      dropdownIndex: number;
      dropdownKey: "DICTIONARY" | "AI";
    }) => {
      const targetValue = currentTextareaInput;

      const currentTargetIndex = currentDropdownList.indexOf(targetValue);

      if (currentTargetIndex > -1) {
        changeTargetListIndex({
          mainIndex: dropdownIndex,
          categoryIndex: 0,
          listIndex: currentTargetIndex,
          key: dropdownKey,
        });
        return true;
      }

      return false;
    },
    [changeTargetListIndex, currentTextareaInput],
  );

  const updateCurrentDropdownTargetFocus = useCallback(() => {
    entireDropdownList.some((dropdown, dropdownIndex) => {
      const isCategory = dropdown.key === "DICTIONARY" && dropdown.isCategory;

      if (isCategory) {
        return changeTargetCategoryDictionaryList(dropdown, dropdownIndex);
      }

      if (!isCategory) {
        return changeTargetDefaultDropdownList({
          currentDropdownList: dropdown.list as string[],
          dropdownKey: dropdown.key as "DICTIONARY" | "AI",
          dropdownIndex,
        });
      }

      return false;
    });
  }, [
    changeTargetCategoryDictionaryList,
    changeTargetDefaultDropdownList,
    entireDropdownList,
  ]);

  useEffect(() => {
    if (!isFocused) {
      setIsFocusInputField(false);
    }
    // NOTE : 포커스 상태가 바뀔 때만 실행될 수 있도록 주석처리
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  useEffect(() => {
    // NOTE :포커스 상태가 바뀔 때마다 가장 최신 entryData로 입력 값을 관리하는 State 업데이트
    setEnterValue(valueInfo.text);
  }, [valueInfo.text]);

  useEffect(() => {
    const targetValue = currentTextareaInput;

    if (isFocusInputField) return;

    if (!targetValue) {
      changeTargetListIndex(DEFAULT_TARGET_INDEX);
      return;
    }

    /** NOTE : clear 버튼을 통해 삭제하였을 때 targetValue가 바로 초기화 되지 않아
     * 별도로 포커스 인덱스를 초기화하기 위한 조건
     */
    if (
      targetValue &&
      valueInfo.text === undefined &&
      focusStatus.type === "default"
    ) {
      changeTargetListIndex(DEFAULT_TARGET_INDEX);
    }

    changeTargetListIndex(DEFAULT_TARGET_INDEX);

    updateCurrentDropdownTargetFocus();
  }, [
    updateCurrentDropdownTargetFocus,
    changeTargetListIndex,
    currentTextareaInput,
    focusStatus.type,
    isFocusInputField,
    valueInfo.text,
    groupTableKey,
    setSelectedDataset,
    selectedDataset,
    shellKey,
  ]);

  useEffect(() => {
    const draftDropdownList = initialDropdownList;
    changeDictionaryList(draftDropdownList);
  }, [shellKey, initialDropdownList, changeDictionaryList]);

  useEffect(() => {
    if (!textFieldTextareaRef.current) return;

    textFieldTextareaRef.current.innerText = valueInfo.text ?? "";

    if (shellKey === "NCT Name") {
      if (!valueInfo.text) setValidationMessage(null);
    }
  }, [entryData, shellKey, valueInfo.text]);

  return {
    selectedValue: valueInfo.text,
    validationMessage,
    textFieldTextareaRef,
    isFocusInputField,
    targetListIndex,
    valueInfo,
    aiList,
    dataSetList: currentShellKeyDatasetList,
    dictionaryList,
    isDictionaryList,
    isDataSetList,
    isOriginalAiList,
    isOriginalDictionaryList,
    copyStatus,
    copyCurrentTextValue,
    handleChangedEnterValue,
    handleEnterValueKeyboard,
    handleFocusTextField,
    handleFocusTextFieldAndOpenListBox,
    handleFocusOutTextField,
    handleBlurTextField,
    handleChangeDataSetValue,
    changeSelectedValue,
    checkIndexOfValueInDefaultAiList,
    deleteCurrentTextField,
  };
};
