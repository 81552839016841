"use client";

import { forwardRef } from "react";

import {
  CHANGE,
  CLOSING_PRICE,
  COMPANY_NAME,
  LAST_PRICE,
  STOCK_MARKET_NAME,
  TICKER,
} from "./constants/marketInfoKey.constants";
import * as S from "./MarketTooltip.style";
import { formatDateToFullStringTime } from "./utils/formatDateToFullStringTime/formatDateToFullStringTime";
import { formatDateToShortStringDate } from "./utils/formatDateToShortStringDate/formatDateToShortStringDate";
import { Tooltip } from "../../../../../../shared";
import {
  Position,
  Alignment,
} from "../../../../../../shared/components/core/Tooltip/types/tooltip.types";
import { CompanyInfo } from "../../../../types/marketInfo.types";

type Props = {
  marketInfo: CompanyInfo;
  chipStatus: "up" | "keep" | "down";
  showTooltip: boolean;
  tooltipPosition: { position: Position; alignment: Alignment };
};

const MarketTooltip = forwardRef<HTMLDivElement, Props>(
  ({ marketInfo, chipStatus, showTooltip, tooltipPosition }: Props, ref) => {
    const {
      exchange,
      symbol,
      roc,
      startDt, // 기사 전날 종가 시간
      endDt, // 최근 주가 시간
      companyName,
      startClose, // 기사 전날 종가
      endClose, // 최근 주가
      pre,
      timezoneAbbreviation,
      timezoneIdentifier,
    } = marketInfo;

    const letters = marketInfo?.currencyLetters || "$";

    const statusSymbol = (() => {
      if (chipStatus === "up") return "+";

      return "";
    })();

    const currentPercentage = `${statusSymbol} ${
      roc === 0 ? "0.00" : roc?.toFixed(2)
    }%`;

    return (
      <S.TooltipWrapper
        className="market-tooltip"
        ref={ref}
        $position={tooltipPosition.position}
        $alignment={tooltipPosition.alignment}
        $isVisible={showTooltip}
      >
        <Tooltip
          position={tooltipPosition.position}
          alignment={tooltipPosition.alignment}
        >
          <S.TooltipContent>
            <S.List>
              <div>{CHANGE} :&nbsp;</div>
              <S.ChangeValue $chipStatus={chipStatus}>
                {currentPercentage}
              </S.ChangeValue>
            </S.List>
            <S.MultiLineList>
              {LAST_PRICE}
              <S.PriceValueWrapper>
                <S.DateValue>
                  (
                  {formatDateToFullStringTime({
                    currentDate: endDt,
                    isPre: pre,
                    timezoneIdentifier,
                    timezoneAbbreviation,
                  })}
                  ) :&nbsp;
                </S.DateValue>
                <S.PriceValue>
                  {letters} {endClose}
                </S.PriceValue>
              </S.PriceValueWrapper>
            </S.MultiLineList>
            <S.MultiLineList>
              {CLOSING_PRICE}
              <S.PriceValueWrapper>
                <S.DateValue>
                  (
                  {formatDateToShortStringDate({
                    currentDate: startDt,
                    timezoneIdentifier,
                  })}
                  ) :&nbsp;
                </S.DateValue>
                <S.PriceValue>
                  {letters} {startClose}
                </S.PriceValue>
              </S.PriceValueWrapper>
            </S.MultiLineList>
            <S.List>
              {STOCK_MARKET_NAME} : {exchange}
            </S.List>
            <S.List>
              {TICKER} : {symbol}
            </S.List>
            <S.List>
              {COMPANY_NAME} : {companyName}
            </S.List>
          </S.TooltipContent>
        </Tooltip>
      </S.TooltipWrapper>
    );
  },
);

export default MarketTooltip;
