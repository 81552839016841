import styled from "styled-components";

export const SearchWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: var(--Spacing-40, 4rem);
  align-self: stretch;
  width: 100%;
  flex-wrap: no-wrap;
`;
