import type { PressSearchFilterType } from "@PressListContainer/pressList.types";

export const PRESS_INFO_FILTER: PressSearchFilterType[] = [
  {
    key: "allInfo",
    name: "All Info",
    isAllInfo: true,
  },
  {
    key: "pressId",
    name: "Press ID",
    isAllInfo: false,
  },
  {
    key: "pressTitle",
    name: "Press Title",
    isAllInfo: false,
  },
  {
    key: "companyName",
    name: "Company",
    isAllInfo: false,
  },
];

export const PRESS_LIST_PRESS_INFO_FILTER_KEY = [
  "allInfo",
  "companyName",
  "pressId",
  "pressTitle",
] as const;
