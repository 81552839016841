import styled, { css } from "styled-components";

import { ProcessStatusCode } from "@/types/code.types";

export const FilterTagWrapper = styled.div<{
  code?: ProcessStatusCode;
  type: "search" | "result" | "all";
  isAllInfo?: boolean;
  isUserInput?: boolean;
  isClassification?: boolean;
  aiSelected?: boolean;
  isEditor?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.4rem;
  height: 2.4rem;
  background-color: ${({ code, theme }) =>
    code ? theme.status[code] : "#CBD0D8"};
  border-radius: var(--Border-Radius-circle, 99999.9rem);
  padding: var(--Spacing-12, 1.2rem);
  cursor: pointer;

  ${({ code, type }) =>
    type === "result" &&
    css`
      background-color: white;
      min-width: 8.6rem;
      width: auto;
      border: 1px solid
        ${({ theme }) =>
          code ? theme.status[code] : theme.color.neural.gray05};
      padding: 0 1.2rem;
      gap: 0.8rem;
    `}

  ${({ isAllInfo, type }) =>
    isAllInfo &&
    css`
      border: ${({ theme }) =>
        type === "search" ? "none" : `1px solid ${theme.color.neural.gray10}`};
      background-color: ${() => (type === "search" ? "#3D4551" : "white")};
    `}

  ${({ isEditor }) =>
    isEditor &&
    css`
      color: white;
    `}

    ${({ isClassification }) =>
    isClassification &&
    css`
      border: 1px solid ${({ theme }) => theme.color.node.blue};
      span {
        color: ${({ theme }) => theme.color.node.blue};
      }
    `}

    ${({ type, aiSelected }) =>
    type === "result" &&
    aiSelected &&
    css`
      border: 0.1rem solid ${({ theme }) => theme.color.status.todo};
      span {
        color: ${({ theme }) => theme.color.status.todo};
      }
    `}
`;

export const FilterTagText = styled.span<{
  code?: ProcessStatusCode;
  type: "search" | "result" | "all";
  isUserInput?: boolean;
  isAllInfo?: boolean;
  isEditor?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-pretendard);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;

  ${({ code, type }) =>
    type === "result" &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: ${({ theme }) =>
        code ? theme.status[code] : theme.color.neural.gray09};
    `}

  ${({ isUserInput }) =>
    isUserInput &&
    css`
      color: ${({ theme }) => theme.color.neural.gray09};
    `}

  ${({ isAllInfo, type, isEditor }) =>
    isAllInfo &&
    !isEditor &&
    css`
      color: ${type === "search" ? "#FFFFFF" : "#2A2F37"};
    `}
    

  ${({ isEditor }) =>
    isEditor &&
    css`
      color: white !important;
    `}
`;

export const FilterDeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
