import CtgListTableBody from "./components/CtgListTableBody/CtgListTableBody";
import CtgListTableHead from "./components/CtgListTableHead/CtgListTableHead";
import * as S from "./CtgListTable.style";

const CtgListTable = () => {
  return (
    <S.CtgListTable>
      <CtgListTableHead />
      <CtgListTableBody />
    </S.CtgListTable>
  );
};

export default CtgListTable;
