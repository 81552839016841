import styled from "styled-components";

export const PressTitleTableShell = styled.td`
  position: relative;
  color: transparent !important;
  font-size: 1.2rem;
  line-height: 1.8rem;
  padding: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};
  vertical-align: top;
  cursor: pointer;
  height: 8.8rem;

  * {
    font-weight: 600 !important;
  }

  &:hover {
    background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #f0f9ff);
    .tooltip-container {
      display: block;
    }
  }
`;

export const TitleWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 1rem;
  height: 100%;

  & > div {
    color: ${({ theme }) => theme.color.neural.gray08};
    font-size: 1.2rem;
    line-height: 130%;
    letter-spacing: -0.012rem;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: calc(1.2rem * 1.3 * 4);
  }

  * {
    font-weight: 600 !important;
  }
  .translate-id {
    color: var(--Color-Common-Text-Success, #0198e9);
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.012rem;
  }

  .reupload-id {
    color: var(--Color-Text-Progress, #eb8d00);
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.012rem;
  }
`;

export const TooltipWrapper = styled.div`
  font-weight: 600;
  position: absolute;
  left: 50%;
  top: 100%;
  margin-top: 0.3rem;
  transform: translateX(-50%);
  z-index: 100;
  display: none;
  width: 100rem;
  padding: var(--Spacing-12, 1.2rem) var(--Spacing-16, 1.6rem);
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Primary, #fff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16),
    0px 0px 4px 0px rgba(0, 0, 0, 0.12);

  .title-tooltip {
    border: none;
    box-shadow: none;
    padding: 0;
    font-weight: 600 !important;
  }

  .title-tooltip * {
    font-weight: 600 !important;
  }
`;

export const TranslatedId = styled.span`
  color: var(--Color-Text-Progress, #eb8d00);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.012rem;
`;
