import styled, { css } from "styled-components";

import { SHELL_WIDTH } from "@/components/business/CTGFillInTableContainer/shared/constants/shellSize.constants";

import type { ShellColumnSize } from "@/components/business/CTGFillInTableContainer/shared/types/shellSize.types";

export const TableTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  width: 100%;
  height: 3.2rem;
  padding: 0 0.8rem;
  background-color: white;
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const TitleContentsWrapper = styled.div<{
  column: ShellColumnSize;
  isDragging: boolean;
}>`
  height: 3.2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ column }) => `calc(${SHELL_WIDTH[column]} + 0rem)`};
  background-color: #fff;
  padding: 0.7rem 0.6rem;
  margin-left: -0.8rem;
  cursor: pointer;

  ${({ isDragging }) =>
    isDragging &&
    css`
      border-radius: var(--Border-Radius-0, 0rem);
      background: var(--Color-Bg-Draft, #d9f4f7);
    `}
`;

export const Title = styled.h3<{
  isDragging: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--Color-Common-Text-Primary, #2a2f37);

  ${({ isDragging }) =>
    isDragging &&
    css`
      color: var(--Color-Common-Text-Primary, #2a2f37);
    `}
`;

export const RightButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  height: 100%;
`;

export const DragButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
`;
