import UndoRedoButton from "@/components/shared/ui/UndoRedoButton/UndoRedoButton";
import {
  useArticleInfoMutation,
  useArticleInfoQuery,
} from "@api/articleInfo/useArticleInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { useTableCreatorFormatErrorPressId } from "@FillInTableContainer/shared/context/TableCreatorFormatErrorPressIdContext";
import { useResetArticleContent } from "@hooks/useResetArticleContent";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { useFillInTableRoles } from "@libs/roles/hooks/useFillInTableRoles";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastDraftSave } from "@utils/toast";

import { useModalityCategoryUpdate } from "../../hooks/useModalityCategoryUpdate";

interface Props {
  undoRedoEvents: {
    undo: () => void;
    redo: () => void;
    isDisabledUndo: boolean;
    isDisabledRedo: boolean;
  };
}

const FillInTableContentsFabContainer = ({ undoRedoEvents }: Props) => {
  const draftArticleId = getPressIdFromURL();

  // NOTE : DataFormat으로 에러가 발생한 경우, Bottom Button을 비활성화 시키기 위한 로직
  const { errorPressId } = useTableCreatorFormatErrorPressId();
  const isDisabledPress = errorPressId === draftArticleId;

  const [entryData] = useEntryDataState(draftArticleId);
  const articleSection = useArticleSection();
  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const { mutateAsync: updateArticleInfo } = useArticleInfoMutation(
    Number(draftArticleId),
  );
  const { updateModalityCategoryValue } = useModalityCategoryUpdate();
  const resetArticleContent = useResetArticleContent();

  const { hasEditHalfTableRoles, hasEditFullTableRoles } =
    useFillInTableRoles();

  const { undo, redo, isDisabledRedo, isDisabledUndo } = undoRedoEvents;

  const updateEntryData = useUpdateEntryData();
  const draftArticleStatus = articleInfo?.draftArticleStatus;
  const isNotReviewInProgress =
    draftArticleStatus !== DRAFT_STATUS_CODE.reviewInProgress &&
    draftArticleStatus !== DRAFT_STATUS_CODE.reviewInProgressHalf;

  const isDisabledUndoRedoByRoles =
    !hasEditHalfTableRoles && !hasEditFullTableRoles;

  const updateClinicalTrialsEditor = async () => {
    if (
      draftArticleStatus === DRAFT_STATUS_CODE.aiEntryDone ||
      draftArticleStatus === DRAFT_STATUS_CODE.reviewInProgressHalf
    ) {
      const ftEditorName = articleInfo?.ftEditorName;
      if (ftEditorName === null || ftEditorName === "") {
        await updateArticleInfo({
          type: "FT",
          isCheckStartDate: "true",
        });
      }
    }

    if (
      draftArticleStatus === DRAFT_STATUS_CODE.dbSavedHalf ||
      draftArticleStatus === DRAFT_STATUS_CODE.reviewInProgress
    ) {
      const ftEditor2Name = articleInfo?.ftEditor2Name;
      if (ftEditor2Name === null || ftEditor2Name === "") {
        await updateArticleInfo({
          type: "FT2",
        });
      }
    }
  };

  const updateRegulatoryEditor = async () => {
    if (
      draftArticleStatus === DRAFT_STATUS_CODE.aiEntryDone ||
      draftArticleStatus === DRAFT_STATUS_CODE.reviewInProgressHalf
    ) {
      const ftEditorName = articleInfo?.ftEditorName;
      if (ftEditorName === null || ftEditorName === "") {
        await updateArticleInfo({
          type: "FT",
          isCheckStartDate: "true",
        });
      }
    }

    if (
      draftArticleStatus === DRAFT_STATUS_CODE.dbSavedHalf ||
      draftArticleStatus === DRAFT_STATUS_CODE.reviewInProgress
    ) {
      const ftEditor2Name = articleInfo?.ftEditor2Name;
      if (ftEditor2Name === null || ftEditor2Name === "") {
        await updateArticleInfo({
          type: "FT2",
        });
      }
    }
  };

  const updateClinicalTrialStatusEditor = async () => {
    if (
      draftArticleStatus === DRAFT_STATUS_CODE.aiEntryDone ||
      draftArticleStatus === DRAFT_STATUS_CODE.reviewInProgressHalf
    ) {
      const ftEditorName = articleInfo?.ftEditorName;
      if (ftEditorName === null || ftEditorName === "") {
        await updateArticleInfo({
          type: "FT",
          isCheckStartDate: "true",
          design1Hidden: true,
        });
      }
    }
  };

  const updateDealsStatusEditor = async () => {
    if (
      draftArticleStatus === DRAFT_STATUS_CODE.aiEntryDone ||
      draftArticleStatus === DRAFT_STATUS_CODE.reviewInProgress
    ) {
      const ftEditor2Name = articleInfo?.ftEditor2Name;
      if (ftEditor2Name === null || ftEditor2Name === "") {
        await updateArticleInfo({
          type: "FT2",
          isCheckStartDate: "true",
        });
      }
    }
  };

  const updateExpeditedProgramEditor = async () => {
    if (
      draftArticleStatus === DRAFT_STATUS_CODE.aiEntryDone ||
      draftArticleStatus === DRAFT_STATUS_CODE.reviewInProgressHalf
    ) {
      const ftEditorName = articleInfo?.ftEditorName;
      if (ftEditorName === null || ftEditorName === "") {
        await updateArticleInfo({
          type: "FT",
          isCheckStartDate: "true",
        });
      }
    }

    if (
      draftArticleStatus === DRAFT_STATUS_CODE.dbSavedHalf ||
      draftArticleStatus === DRAFT_STATUS_CODE.reviewInProgress
    ) {
      const ftEditor2Name = articleInfo?.ftEditor2Name;
      if (ftEditor2Name === null || ftEditor2Name === "") {
        await updateArticleInfo({
          type: "FT2",
        });
      }
    }
  };

  const updateVentureCapitalEditor = async () => {
    if (
      draftArticleStatus === DRAFT_STATUS_CODE.aiEntryDone ||
      draftArticleStatus === DRAFT_STATUS_CODE.reviewInProgress
    ) {
      const ftEditor2Name = articleInfo?.ftEditor2Name;
      if (ftEditor2Name === null || ftEditor2Name === "") {
        await updateArticleInfo({
          type: "FT2",
          isCheckStartDate: "true",
        });
      }
    }
  };

  const saveDraft = async () => {
    if (articleSection === "Clinical Trials") updateClinicalTrialsEditor();
    if (articleSection === "Regulatory") updateRegulatoryEditor();
    if (articleSection === "Clinical Trial Status")
      updateClinicalTrialStatusEditor();
    if (articleSection === "Deals") updateDealsStatusEditor();
    if (articleSection === "Expedited Program") updateExpeditedProgramEditor();
    if (articleSection === "Venture Capital") updateVentureCapitalEditor();

    await updateEntryData({
      ...entryData,
    });

    updateModalityCategoryValue();

    resetArticleContent();

    toastDraftSave();
  };

  const isDisabledUndoButton =
    isDisabledUndoRedoByRoles || isDisabledUndo || isDisabledPress;
  const isDisabledDraftButton =
    isNotReviewInProgress || isDisabledUndoRedoByRoles || isDisabledPress;
  const isDisabledRedoButton =
    isDisabledUndoRedoByRoles || isDisabledRedo || isDisabledPress;

  return (
    <UndoRedoButton
      undoIsDisabled={isDisabledUndoButton}
      undoOnClickHandler={undo}
      redoIsDisabled={isDisabledRedoButton}
      redoOnClickHandler={redo}
      saveIsDisabled={isDisabledDraftButton}
      saveOnClickHandler={saveDraft}
    />
  );
};

export default FillInTableContentsFabContainer;
