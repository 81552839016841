import {
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENTS,
  ENTRY_DATA_KEY_COMMENTS_SHELL_KEYS as KEY_COMMENTS_SHELL_KEYS,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS as EXPEDITED_PROGRAM_CONTENTS,
  ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS_KEYS as EXPEDITED_PROGRAM_CONTENTS_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";

const getDrugProfileFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath = entryData?.[SECTION_KEYS.expeditedProgram]?.[DRUG_PROFILE];
  if (!targetPath) return [`${DRUG_PROFILE} data is missing`];
  if (!Array.isArray(targetPath)) return [`${DRUG_PROFILE} data is missing`];
  if (targetPath.length === 0) return [`${DRUG_PROFILE} data is missing`];

  const checkDrugProfileTableValues = targetPath.flatMap(
    (tableItem, tableIndex) => {
      const requiredFields = [
        {
          key: DRUG_PROFILE_SHELL_KEYS.brandName,
          label: DRUG_PROFILE_SHELL_KEYS.brandName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.innName,
          label: DRUG_PROFILE_SHELL_KEYS.innName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.codeName,
          label: DRUG_PROFILE_SHELL_KEYS.codeName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.moa,
          label: DRUG_PROFILE_SHELL_KEYS.moa,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.modality,
          label: DRUG_PROFILE_SHELL_KEYS.modality,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
          label: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.roa,
          label: DRUG_PROFILE_SHELL_KEYS.roa,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.target,
          label: DRUG_PROFILE_SHELL_KEYS.target,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.targetIndication,
          label: DRUG_PROFILE_SHELL_KEYS.targetIndication,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
          label: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
          label: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.refProductName,
          label: DRUG_PROFILE_SHELL_KEYS.refProductName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.phase,
          label: DRUG_PROFILE_SHELL_KEYS.phase,
        },
      ];

      return requiredFields
        .map(({ key, label }) =>
          !tableItem[key]?.value
            ? `${label} is missing in ${DRUG_PROFILE} table ${tableIndex}`
            : "",
        )
        .filter((message) => !!message);
    },
  );

  return [...checkDrugProfileTableValues];
};

const getExpeditedProgramContentsFormatCheckList = (
  entryData: ArticleEntryData,
) => {
  const targetPath =
    entryData?.[SECTION_KEYS.expeditedProgram]?.[EXPEDITED_PROGRAM_CONTENTS];

  if (!targetPath) return [`${EXPEDITED_PROGRAM_CONTENTS} data is missing`];

  const requiredFields = [
    {
      key: EXPEDITED_PROGRAM_CONTENTS_KEYS.additionalInformationOnExpeditedProgram,
      label:
        EXPEDITED_PROGRAM_CONTENTS_KEYS.additionalInformationOnExpeditedProgram,
    },
    {
      key: EXPEDITED_PROGRAM_CONTENTS_KEYS.designatedCompanyName,
      label: EXPEDITED_PROGRAM_CONTENTS_KEYS.designatedCompanyName,
    },
    {
      key: EXPEDITED_PROGRAM_CONTENTS_KEYS.designatedIndication,
      label: EXPEDITED_PROGRAM_CONTENTS_KEYS.designatedIndication,
    },
    {
      key: EXPEDITED_PROGRAM_CONTENTS_KEYS.expeditedProgram,
      label: EXPEDITED_PROGRAM_CONTENTS_KEYS.expeditedProgram,
    },
    {
      key: EXPEDITED_PROGRAM_CONTENTS_KEYS.regulatoryAgency,
      label: EXPEDITED_PROGRAM_CONTENTS_KEYS.regulatoryAgency,
    },
    {
      key: EXPEDITED_PROGRAM_CONTENTS_KEYS.updateDate,
      label: EXPEDITED_PROGRAM_CONTENTS_KEYS.updateDate,
    },
  ];

  return (
    requiredFields
      ?.map(({ key, label }) =>
        !targetPath[key]?.value
          ? `${label} is missing in ${EXPEDITED_PROGRAM_CONTENTS}`
          : "",
      )
      ?.filter((message) => !!message) || []
  );
};

const getKeyCommentFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath = entryData?.[SECTION_KEYS.expeditedProgram]?.[KEY_COMMENTS];
  if (!targetPath) return [`${KEY_COMMENTS} data is missing`];

  const requiredFields = [
    {
      key: KEY_COMMENTS_SHELL_KEYS.quote,
      label: KEY_COMMENTS_SHELL_KEYS.quote,
    },
    {
      key: KEY_COMMENTS_SHELL_KEYS.spokesperson,
      label: KEY_COMMENTS_SHELL_KEYS.spokesperson,
    },
  ];

  return (
    requiredFields
      ?.map(({ key, label }) =>
        !targetPath[key]?.value ? `${label} is missing in ${KEY_COMMENTS}` : "",
      )
      ?.filter((message) => !!message) || []
  );
};

export const getExpeditedProgramEntryDataFormatCheckList = (
  entryData: ArticleEntryData,
) => [
  ...getDrugProfileFormatCheckList(entryData),
  ...getExpeditedProgramContentsFormatCheckList(entryData),
  ...getKeyCommentFormatCheckList(entryData),
];
