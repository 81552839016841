import styled, { css } from "styled-components";

import {
  DeleteButton,
  DeleteButtonWrapper,
  FixedWrapper,
} from "@FillInTableContainer/shared/styles/deleteButton.style";
import { DefaultDraggableGroupShellWrapper } from "@FillInTableContainer/shared/styles/groupShell.style";
import { SectionOutsideWrapper } from "@FillInTableContainer/shared/styles/groupShellSection.style";
import {
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
  DefaultGroupShellWrapper,
  TableContentWrapper,
} from "@FillInTableContainer/shared/styles/table.style";

export const TableWrapper = styled(TableContentWrapper)`
  gap: 0;
`;

export const GroupShellSpaceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.color.neural.white};
`;

export const GroupShellWrapper = styled(DefaultGroupShellWrapper)``;

export const DeleteTableButtonWrapper = styled(
  DefaultDeleteTableButtonWrapper,
)``;

export const Title = styled.div`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-family: var(--font-pretendard);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 2.03rem */
`;
export const DeleteTableButton = styled(DefaultDeleteTableButton)``;
export const DraggableGroupShellWrapper = styled(
  DefaultDraggableGroupShellWrapper,
)``;

export const GroupShellSectionWrapper = styled(SectionOutsideWrapper)`
  margin-bottom: 1.6rem;
`;

export const FixedDeleteButtonWrapper = styled(FixedWrapper)<{
  isShellTitle?: boolean;
}>`
  top: -2.4rem;
  left: -0.2rem;
  right: auto;

  ${({ isShellTitle }) =>
    isShellTitle &&
    css`
      top: -2.6rem;
      left: -0.2rem;
    `}
`;

export const DeleteGroupShellSectionButtonWrapper = styled(
  DeleteButtonWrapper,
)``;

export const DeleteGroupShellSectionButton = styled(DeleteButton)``;
