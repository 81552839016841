import React from "react";

import { TooltipBase } from "@frontend/common/src/components";

import * as S from "./CtgListCompanyShell.style";

type Props = {
  companyName: string[] | null;
};

const CtgListCompanyShell = ({ companyName }: Props) => {
  if (companyName && companyName.length === 0) {
    return <S.CtgListCompanyShell>-</S.CtgListCompanyShell>;
  }

  const visibleCompanies = companyName ? companyName.slice(0, 2) : [];
  const remainingCount =
    companyName && companyName.length > 2 ? companyName.length - 2 : 0;

  return (
    <S.CtgListCompanyShell>
      {visibleCompanies.map((item) => (
        <S.CtgListCompanyItem key={item}>
          <S.CtgListCompanyItemText>{item}</S.CtgListCompanyItemText>
        </S.CtgListCompanyItem>
      ))}

      {remainingCount > 0 && (
        <>
          <S.CtgListCompanyNumber>+{remainingCount}</S.CtgListCompanyNumber>
          <S.TooltipWrapper className="tooltip-wrapper">
            <TooltipBase className="tooltip">
              {companyName?.map((item) => (
                <S.CtgListCompanyItem key={item}>
                  <S.CtgListCompanyItemText>{item}</S.CtgListCompanyItemText>
                </S.CtgListCompanyItem>
              ))}
            </TooltipBase>
          </S.TooltipWrapper>
        </>
      )}
    </S.CtgListCompanyShell>
  );
};

export default CtgListCompanyShell;
