import { Dispatch, SetStateAction, useState } from "react";

import { CtgSearchFilterType } from "@/components/business/CtgListContainer/ctgList.types";
import SearchInput from "@atoms/SearchInput/SearchInput";
import { useCtgListFilterState } from "@stores/ctgList";

import CtgListSearchFilterSelect from "./components/CtgListSearchFilterSelect/CtgListSearchFilterSelect";
import {
  CTG_LIST_INFO_FILTER,
  CTG_LIST_PRESS_INFO_FILTER_KEY,
} from "./constants/pressInfoFilter.constants";
import * as S from "./CtgListPressInfoSearch.style";

interface Props {
  currentPressFilter: CtgSearchFilterType;
  setCurrentFilter: Dispatch<SetStateAction<CtgSearchFilterType>>;
}

const CtgListPressInfoSearch = ({
  currentPressFilter,
  setCurrentFilter,
}: Props) => {
  const [ctgListFilter, setCtgListFilter] = useCtgListFilterState();
  const [typing, setTyping] = useState("");

  const { search } = ctgListFilter;

  const handleSearchKeyboard = () => {
    const currentInput = typing.trim();

    const newSearch = { ...search };
    CTG_LIST_PRESS_INFO_FILTER_KEY.forEach((key) => delete newSearch[key]);
    newSearch[currentPressFilter.key] = currentInput;

    if (currentInput) {
      setCtgListFilter({
        ...ctgListFilter,
        search: newSearch,
        page: 1,
      });
    }
  };

  const handleSearchButton = () => {
    const currentInput = typing.trim();

    if (currentInput) {
      const newSearch = { ...search };
      CTG_LIST_PRESS_INFO_FILTER_KEY.forEach((key) => delete newSearch[key]);
      newSearch[currentPressFilter.key] = currentInput;

      setCtgListFilter({
        ...ctgListFilter,
        search: newSearch,
        page: 1,
      });
    }
  };

  return (
    <S.PressInfoSearchWrapper>
      <CtgListSearchFilterSelect
        subTitle="Info Filter"
        filterList={CTG_LIST_INFO_FILTER}
        currentFilter={currentPressFilter}
        setCurrentFilter={setCurrentFilter}
      />
      <S.PressInfoSearchTitle>
        <SearchInput
          placeholder="Enter text for CTG Information search"
          onSearchButton={handleSearchButton}
          handleSearchKeyboard={handleSearchKeyboard}
          typing={typing}
          setTyping={setTyping}
          widthResize
        />
      </S.PressInfoSearchTitle>
    </S.PressInfoSearchWrapper>
  );
};

export default CtgListPressInfoSearch;
