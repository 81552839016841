import {
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
} from "@frontend/common";

import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";
import { CTGEntryData } from "@/types/ctgEntryData.types";

const getDrugProfileFormatCheckList = (entryData: CTGEntryData) => {
  const targetPath = entryData?.[CTG_SECTION]?.[DRUG_PROFILE];
  if (!targetPath) return [`${DRUG_PROFILE} data is missing`];
  if (!Array.isArray(targetPath)) return [`${DRUG_PROFILE} data is missing`];
  if (targetPath.length === 0) return [`${DRUG_PROFILE} data is missing`];

  const checkDrugProfileTableValues = targetPath.flatMap(
    (tableItem, tableIndex) => {
      const requiredFields = [
        {
          key: DRUG_PROFILE_SHELL_KEYS.brandName,
          label: DRUG_PROFILE_SHELL_KEYS.brandName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.innName,
          label: DRUG_PROFILE_SHELL_KEYS.innName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.codeName,
          label: DRUG_PROFILE_SHELL_KEYS.codeName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.moa,
          label: DRUG_PROFILE_SHELL_KEYS.moa,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.modality,
          label: DRUG_PROFILE_SHELL_KEYS.modality,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
          label: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.roa,
          label: DRUG_PROFILE_SHELL_KEYS.roa,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.target,
          label: DRUG_PROFILE_SHELL_KEYS.target,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.targetIndication,
          label: DRUG_PROFILE_SHELL_KEYS.targetIndication,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
          label: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
          label: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.refProductName,
          label: DRUG_PROFILE_SHELL_KEYS.refProductName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.phase,
          label: DRUG_PROFILE_SHELL_KEYS.phase,
        },
      ];

      return requiredFields
        .map(({ key, label }) =>
          !tableItem[key]?.value
            ? `${label} is missing in ${DRUG_PROFILE} table ${tableIndex}`
            : "",
        )
        .filter((message) => !!message);
    },
  );

  return [...checkDrugProfileTableValues];
};

export const getCTGEntryDataFormatCheckList = (entryData: CTGEntryData) => [
  ...getDrugProfileFormatCheckList(entryData),
];
