import { atomFamily, useRecoilValue } from "recoil";

import { getCTGInitEntryData } from "@api/ctg/ctgInitEntryData/ctgInitEntryData";

import { CTG_RECOIL_KEYS } from "./ctgRecoilKey";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

type ArticleId = string;

const ctgInitEntryDataState = atomFamily<ArticleEntryData, ArticleId>({
  key: CTG_RECOIL_KEYS.ctgInitEntryDataState,
  default: (articleId) => getCTGInitEntryData(articleId),
});

export const useCTGInitEntryDataValue = (articleId: ArticleId) =>
  useRecoilValue(ctgInitEntryDataState(articleId));
