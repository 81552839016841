import React from "react";

import TableCreatorDragButton from "@/components/business/CTGFillInTableContainer/shared/components/TableCreatorDragButton/TableCreatorDragButton";
import { useTableCreatorFocusStatus } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { GroupTableKeyType } from "@/components/business/CTGFillInTableContainer/shared/types/tableCreator.types";

import * as S from "./ValueDragButton.style";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd: () => void;
  handleDragOn: () => void;
  isMultipleLastValue: boolean;
  isFocused: boolean;
  positionInfo: {
    groupTableKey: GroupTableKeyType;
    groupTableIndex: number;
    tableKey: string | null;
    tableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number;
    subGroupShellIndex: number | null;
    subGroupShellKey: string | null;
    shellKey: string;
    shellIndex: number;
    valueIndex: number;
  };
}

const ValueDragButton = ({
  handleDragEnd,
  handleDragOn,
  handleDragStart,
  isFocused,
  isMultipleLastValue,
  positionInfo,
  ...rest
}: Props) => {
  const { changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();

  const { valueIndex } = positionInfo;

  const changePositionInfo = () => {
    changeInfo({ ...positionInfo });
  };

  const changePositionInfoToDefault = () => {
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const changeFocusStatusForDragging = () => {
    changePositionInfo();
    changeFocusStatus({
      type: "dragging",
      container: "shell",
    });
  };

  const changeFocusStatusForDragEnd = () => {
    changePositionInfoToDefault();
    changeFocusStatus({
      type: "default",
      container: "noFocus",
    });
  };

  if (isMultipleLastValue) return <S.HiddenBox className="value-drag-button" />;

  return (
    <S.DragButton
      draggable
      onClick={(e) => {
        e.stopPropagation();
        changeFocusStatusForDragging();
      }}
      onDragStart={(e) => {
        handleDragStart(e, valueIndex);
        changeFocusStatusForDragging();
      }}
      onDragEnd={() => {
        if (changeFocusStatusForDragEnd) {
          handleDragEnd();
          changeFocusStatusForDragEnd();
        }
      }}
      onDrag={handleDragOn}
      {...rest}
    >
      <TableCreatorDragButton
        dragContainer="value"
        isDragging={isFocused && focusStatus.type === "dragging"}
        onClick={(e) => {
          e.stopPropagation();
          changeFocusStatusForDragging();
        }}
      />
    </S.DragButton>
  );
};

export default ValueDragButton;
