import { useEffect, useState } from "react";

import * as Sentry from "@sentry/react";

import { useTableCreatorFormatErrorPressId } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorFormatErrorPressIdContext";
import { labelingIdInTitle } from "@/components/shared/utils/labelingIdInTitle/labelingIdInTitle";
import { ProcessStatusCode } from "@/types/code.types";
import { useGetPressContentQuery } from "@api/pressContent/pressContent";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { PressListData } from "@PressListContainer/pressList.types";
import { pressListApis } from "@services/pressList.service";
import { toastFail } from "@utils/toast";

import PressPreviewModal from "./components/PressPreviewModal/PressPreviewModal";
import { useMovePressDetailPage } from "./hooks/useMovePressDetailPage";
import * as S from "./PressListTableShellForPressTitle.style";

interface Props {
  pressData: PressListData;
  openCheckStatusModal: () => void;
  openAccessDeniedModal: () => void;
  openConcurrentAccessDeniedModal: () => void;
  handleTargetPressId: () => void;
  handleCurrentStatus: (status: ProcessStatusCode | "") => void;
}

const PressListTableShellForPressTitle = ({
  pressData,
  openCheckStatusModal,
  openAccessDeniedModal,
  openConcurrentAccessDeniedModal,
  handleTargetPressId,
  handleCurrentStatus,
}: Props) => {
  const [isHover, setIsHover] = useState(false);
  const { movePressDetailPage } = useMovePressDetailPage({
    draftArticleId: pressData.pressId.toString(),
    draftArticleStatus: pressData.draftArticleStatusHighest,
    classificationAi: pressData.classificationAi,
    classificationManual: pressData.classificationManual,
    ftEditorName: pressData.ftEditorName,
    onAccessDenied: openAccessDeniedModal,
    onConcurrentAccessDenied: openConcurrentAccessDeniedModal,
  });

  const { changeErrorPressId } = useTableCreatorFormatErrorPressId();

  const saveScrollPositionToSessionStorage = () => {
    sessionStorage.setItem("scrollPosition", window.scrollY.toString());
  };

  const checkCurrentStatus = async () => {
    try {
      const { data: currentStatus } = await pressListApis.checkPressStatus(
        pressData.pressId.toString(),
      );

      // currentStatus가 ai done, draftArticleStatusHighest가 not for ai인 경우 Alert이 표출되지 않도록 설정
      if (
        currentStatus === DRAFT_STATUS_CODE.aiEntryDone &&
        pressData.draftArticleStatusHighest === DRAFT_STATUS_CODE.notForAiEntry
      ) {
        saveScrollPositionToSessionStorage();
        await movePressDetailPage();

        return;
      }
      if (currentStatus !== pressData.draftArticleStatusHighest) {
        handleCurrentStatus(currentStatus as ProcessStatusCode | "");
        handleTargetPressId();
        openCheckStatusModal();
      } else {
        saveScrollPositionToSessionStorage();
        await movePressDetailPage();
      }
    } catch (error) {
      toastFail("Could not check the current status of the article.");
    }
  };

  const title = pressData?.pressTitle || "-";

  const clickToMovePressDetailPage = async () => {
    await checkCurrentStatus();
  };

  const mouseEnterTitle = () => {
    setIsHover(true);
  };

  const mouseLeaveTitle = () => {
    setIsHover(false);
  };

  const {
    data: previewPress,
    isLoading,
    isError,
    error,
  } = useGetPressContentQuery(pressData.pressId, { skip: !isHover });

  useEffect(() => {
    if (isError && isHover) {
      toastFail("Failed to preview Press Content");
      changeErrorPressId(pressData.pressId.toString());

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const axiosError = error as any;
      const statusCode = axiosError?.response?.status || axiosError?.status;

      if (statusCode !== 401) {
        Sentry.captureMessage(
          `[#${pressData.pressId}] Preview Press Content Error`,
          {
            level: "error",
            tags: {
              pressId: pressData.pressId,
              type: "preview-press-content-error",
              errorStatus: statusCode || "unknown",
            },
            extra: {
              title: `[#${pressData.pressId}] Preview Press Content Error`,
            },
          },
        );
      }
    }
  }, [pressData.pressId, changeErrorPressId, isError, isHover, error]);

  return (
    <S.PressTitleTableShell onClick={clickToMovePressDetailPage}>
      {/* DESCRIPTION(유진) : td > div 높이를 100%로 맞추기 위해 보이지 않는 text를 함께 넣음 */}
      {title}
      <S.TitleWrapper
        onMouseEnter={mouseEnterTitle}
        onMouseLeave={mouseLeaveTitle}
      >
        {labelingIdInTitle(title)}
      </S.TitleWrapper>
      {isHover && (
        <PressPreviewModal
          previewPress={previewPress?.data?.data || ""}
          isLoading={isLoading}
          isError={isError}
        />
      )}
    </S.PressTitleTableShell>
  );
};

export default PressListTableShellForPressTitle;
