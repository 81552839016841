import { useCallback, useEffect, useRef } from "react";

import { getCTGInfoData, useCTGInfoMutation } from "@api/ctg/ctgInfo/ctgInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { pageEntryApis } from "@services/pageEntry.service";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import useArticleSection from "../../../hooks/useArticleSection";
import { CTG_SECTION } from "../../../shared/constants/articleSection.constants";

export const useLeaveFillInTablePageEffect = async () => {
  const draftArticleId = useRef(Number(getPressIdFromURL()));
  const { mutate: updateArticleInfo } = useCTGInfoMutation();
  const articleSection = useArticleSection();
  const path = window.location.href;

  const handleLeavePage = useCallback(() => {
    const articleInfo = getCTGInfoData(draftArticleId.current);
    if (!articleInfo) return;

    const status = articleInfo.data.draftArticleStatus;
    const editor2 = articleInfo.data.ftEditor2Name;

    if (articleSection === CTG_SECTION) {
      if (status === DRAFT_STATUS_CODE.reviewInProgress && !editor2) {
        updateArticleInfo({
          draftArticleStatus: DRAFT_STATUS_CODE.aiEntryDone,
        });
      }
    }

    pageEntryApis.delete(path);
  }, [articleSection, updateArticleInfo, path]);

  useEffect(() => {
    return () => {
      handleLeavePage();
    };
  }, [handleLeavePage]);

  // 화면을 종료할때
  useEffect(() => {
    window.addEventListener("beforeunload", handleLeavePage);
    return () => {
      window.removeEventListener("beforeunload", handleLeavePage);
    };
  }, [handleLeavePage]);
};
