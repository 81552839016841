import React, { useEffect, useState } from "react";

import {
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
} from "@frontend/common";

import { useTableCreatorFocusStatus } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorPositionInfo } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@/components/business/CTGFillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { GroupTableKeyType } from "@/components/business/CTGFillInTableContainer/shared/types/tableCreator.types";
import { EntryDataValueAndOptions } from "@/types/drugProfile.types";

import TableCreatorTextFieldCheckbox from "./components/TableCreatorTextFieldCheckbox/TableCreatorTextFieldCheckbox";
import TableCreatorTextFieldInput from "./components/TableCreatorTextFieldInput/TableCreatorTextFieldInput";
import ValueDragButton from "./components/ValueDragButton/ValueDragButton";
import { IS_NOT_EDITABLE_SHELL_KEYS } from "./constants/notEditableShellKey.constants";
import * as S from "./TableCreatorTextField.style";

interface Props {
  groupTableKey: GroupTableKeyType;
  tableKey: string | null;
  groupShellKey: string | null;
  groupTableIndex: number;
  tableIndex: number;
  groupShellIndex: number;
  subGroupShellKey: string | null;
  subGroupShellIndex: number | null;
  placeholder: string;
  shellKey: string;
  shellIndex: number;
  shellValue: EntryDataValueAndOptions;
  valueIndex: number;
  isMultipleLastValue: boolean;
  isMultipleValue: boolean;
  openValidationInfo?: () => void;
  closeValidationInfo?: () => void;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd: () => void;
  handleDragOn: () => void;
}

const dropdownInsideShellBoxGroupTableKeyList = [
  "Clinical Endpoints (Table)",
  "Approval Process Contents",
];

const dropdownInsideShellBoxGroupShellKeyList: string[] = [
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2,
];

const TableCreatorTextField = ({
  groupTableKey,
  tableKey,
  groupShellKey,
  groupTableIndex,
  tableIndex,
  groupShellIndex,
  subGroupShellKey,
  subGroupShellIndex,
  placeholder,
  shellKey,
  shellIndex,
  shellValue,
  valueIndex,
  isMultipleLastValue,
  isMultipleValue,
  openValidationInfo = () => null,
  closeValidationInfo = () => null,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
}: Props) => {
  const listBoxRef = React.useRef<HTMLDivElement>(null);
  const textFieldRef = React.useRef<HTMLDivElement>(null);
  const [textFieldMargin, setTextFieldMargin] = useState<number>(0);
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();

  const isFocused =
    info.tableIndex === tableIndex &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.shellKey === shellKey &&
    info.shellIndex === shellIndex &&
    info.subGroupShellIndex === subGroupShellIndex &&
    info.subGroupShellKey === subGroupShellKey &&
    info.valueIndex === valueIndex;

  const positionInfo = {
    groupTableKey: groupTableKey as GroupTableKeyType,
    groupTableIndex,
    tableKey,
    tableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    shellKey,
    shellIndex,
    valueIndex,
  };

  const changeTextFieldMarginBottom = (margin: number) => {
    setTextFieldMargin(margin);
  };

  const handleClickTextField = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    changeInfo(positionInfo);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  useEffect(() => {
    if (
      !dropdownInsideShellBoxGroupTableKeyList.includes(groupTableKey) &&
      !dropdownInsideShellBoxGroupShellKeyList.includes(groupShellKey || "")
    )
      return;

    const listBoxHeight = listBoxRef.current
      ? listBoxRef.current.offsetHeight
      : 0;
    setTextFieldMargin(listBoxHeight);
  }, [info, groupTableKey, focusStatus, groupShellKey]);

  const isEditableShell = !IS_NOT_EDITABLE_SHELL_KEYS.includes(shellKey);

  if (shellKey === DRUG_PROFILE_SHELL_KEYS.genericBiosimilar) {
    return (
      <TableCreatorTextFieldCheckbox
        shellKey={shellKey}
        labelText="Generic / Biosimilar"
        shellValue={shellValue.value as string[]}
        tableIndex={tableIndex}
      />
    );
  }

  return (
    <S.DrugAndDropInputWrapper className="modality-textfield-height">
      <S.TextFieldWrapper
        ref={textFieldRef}
        key={`${tableIndex}-${groupShellIndex}-${shellKey}-${valueIndex}`}
        isFocused={isFocused}
        focusStatus={focusStatus}
        textFieldMargin={!isFocused ? 0 : textFieldMargin}
        onClick={handleClickTextField}
        isMultipleLastValue={isMultipleLastValue}
        isDragging={isFocused && focusStatus.type === "dragging"}
        className={`${
          isMultipleLastValue
            ? "last-empty-text-field"
            : shellValue.value[valueIndex || 0] && isFocused
            ? "filled-text-field"
            : "empty-text-field"
        } modality-textfield-height`}
        $isNotEditable={!isEditableShell}
      >
        <TableCreatorTextFieldInput
          placeholder={isMultipleLastValue ? `Add ${placeholder}` : placeholder}
          shellValue={shellValue}
          shellKey={shellKey}
          groupTableKey={groupTableKey}
          groupTableIndex={groupTableIndex}
          groupShellKey={groupShellKey}
          subGroupShellKey={subGroupShellKey}
          subGroupShellIndex={subGroupShellIndex}
          tableKey={tableKey}
          tableIndex={tableIndex}
          groupShellIndex={groupShellIndex}
          shellIndex={shellIndex}
          valueIndex={valueIndex}
          changeTextFieldMarginBottom={changeTextFieldMarginBottom}
          ref={listBoxRef}
          isMultipleLastValue={isMultipleLastValue}
          isMultipleValue={isMultipleValue}
          openValidationInfo={openValidationInfo}
          closeValidationInfo={closeValidationInfo}
        />
      </S.TextFieldWrapper>
      {isMultipleValue && isEditableShell && (
        <ValueDragButton
          handleDragEnd={handleDragEnd}
          handleDragOn={handleDragOn}
          handleDragStart={handleDragStart}
          isMultipleLastValue={isMultipleLastValue}
          isFocused={isFocused}
          positionInfo={positionInfo}
          className="value-drag-button"
        />
      )}
    </S.DrugAndDropInputWrapper>
  );
};

export default TableCreatorTextField;
