export const CLINICAL_TRIALS_IDENTIFIER_VALIDATION_INFOS = [
  {
    id: "NCT",
    format: "NCT########",
    country: "United States",
  },
  {
    id: "ISRCTN",
    format: "ISRCTN########",
    country: "United Kingdom",
  },
  {
    id: "ACTRN",
    format: "ACTRN##############",
    country: "Australia/New Zealand",
  },
  {
    id: "ChiCTR",
    format: "ChiCTR##########",
    country: "China",
  },
  {
    id: "CTR",
    format: "CTR########",
    country: "China",
  },
  {
    id: "Y4-N6-C2",
    format: `YYYY-NNNNNN-CC\nYYYY-NNNNNN-CC-XX`,
    country: "European Union",
  },
  {
    id: "DRKS",
    format: "DRKS########",
    country: "Germany",
  },
  {
    id: "jRCT",
    format: "jRCT##########",
    country: "Japan",
  },
  {
    id: "SNCTP",
    format: "SNCTP#########",
    country: "Switzerland",
  },
  {
    id: "KCT",
    format: "KCT#######",
    country: "South Korea",
  },
];
