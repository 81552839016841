import { ArticleSectionType } from "../../types/fillInTable.types";
import { TABLE_CREATOR_GROUP_SHELL } from "../constants/tableCreatorGroupShell.constants";
import { TABLE_CREATOR_GROUP_TABLE } from "../constants/tableCreatorGroupTable.constants";
import { TABLE_CREATOR_SHELL } from "../constants/tableCreatorShell.constants";
import { TABLE_CREATOR_TABLE } from "../constants/tableCreatorTable.constants";
import { ShellColumnSize } from "../types/shellSize.types";

import type { GroupTableKeyType } from "../types/tableCreator.types";

// GroupTable
export const isTableCustomTitle = (
  articleSection: ArticleSectionType,
  groupTableKey: GroupTableKeyType,
) =>
  TABLE_CREATOR_GROUP_TABLE[articleSection].find(
    ({ key }) => key === groupTableKey,
  )?.isCustomTitle || false;

export const isRequiredGroupTable = (
  articleSection: ArticleSectionType,
  groupTableKey: GroupTableKeyType,
) =>
  TABLE_CREATOR_GROUP_TABLE[articleSection].find(
    ({ key }) => key === groupTableKey,
  )?.isRequired || false;

export const isEnterTableNotes = (
  articleSection: ArticleSectionType,
  groupTableKey: GroupTableKeyType,
) =>
  TABLE_CREATOR_GROUP_TABLE[articleSection].find(
    ({ key }) => key === groupTableKey,
  )?.isEnterTableNotes || false;

// Table
export const isAddDeleteTable = (
  articleSection: ArticleSectionType,
  tableKey: string | null,
) =>
  TABLE_CREATOR_TABLE[articleSection].find(({ key }) => key === tableKey)
    ?.isReviseTable || false;

export const isRequiredTable = (
  articleSection: ArticleSectionType,
  tableKey: string | null,
) =>
  TABLE_CREATOR_TABLE[articleSection].find(({ key }) => key === tableKey)
    ?.isRequired || false;

export const getTableColumnSize = (
  articleSection: ArticleSectionType,
  tableKey: string | null,
): ShellColumnSize =>
  TABLE_CREATOR_TABLE[articleSection].find(({ key }) => key === tableKey)
    ?.columnSize || "regular";

export const isRequiredGroupShell = (
  articleSection: ArticleSectionType,
  groupShellKey: string | null,
) =>
  TABLE_CREATOR_GROUP_SHELL[articleSection].find(
    ({ key }) => key === groupShellKey,
  )?.isRequired || false;

export const isRequiredShell = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.isRequired || false;
export const getShellColumnSize = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
): ShellColumnSize =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.columnSize || "regular";

export const getMaxShellValueLength = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.countValueLength || 100;

export const getShellPlaceholder = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.placeholder || "";

export const isDisabledInsertText = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.isDisabledInsertText || false;
