import { DealsSection } from "@/types/deals.types";
import { TableCreatorTableList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface DealsDrugProfileLayout {
  groupTableKey: DealsSection;
  tableList: TableCreatorTableList;
}

export const DEALS_DRUG_PROFILE_GROUP_TABLE_LAYOUT: DealsDrugProfileLayout = {
  groupTableKey: "Drug Profile",
  tableList: [
    {
      tableKey: "Drug",
      groupShellList: [
        {
          groupShellKey: "Drug Name",
          shellList: [
            {
              shellKey: "Brand Name",
            },
            {
              shellKey: "INN Name",
            },
            {
              shellKey: "Code Name",
            },
          ],
        },
        {
          groupShellKey: null,
          shellList: [
            {
              shellKey: "ROA",
            },
            {
              shellKey: "Modality",
            },
            {
              shellKey: "Target",
            },
            {
              shellKey: "MOA",
            },
            {
              shellKey: "Target Indication",
            },
            {
              shellKey: "Other Characteristics",
            },
            {
              shellKey: "Phase",
            },
            {
              shellKey: "Generic / Biosimilar",
            },
            {
              shellKey: "Ref.Product Name",
            },
            {
              shellKey: "Originator/Developer",
            },
          ],
        },
      ],
    },
  ],
};
