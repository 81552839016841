import styled from "styled-components";

import { hideScrollbarY } from "@styles/mixin";

import type { ShellColumnSize } from "@/components/business/CTGFillInTableContainer/shared/types/shellSize.types";

export const ListBoxWrapper = styled.div<{ columnSize: ShellColumnSize }>`
  position: absolute;
  left: -0.1rem;
  top: calc(100% + 0.1rem);
  width: calc(100% + 0.2rem);
  max-height: 32rem;
  background-color: ${({ theme }) => theme.color.neural.white};
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
  border-radius: 0 0 0.4rem 0.4rem;
  padding: 0rem 0rem 1.6rem;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.08);
  z-index: 12;
`;

export const ListScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 28rem;

  ${hideScrollbarY};
`;
