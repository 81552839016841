import useArticleSection from "@/components/business/CTGFillInTableContainer/hooks/useArticleSection";
import { useCTGEntryDataValue } from "@stores/ctgEntryData";
import { useCTGInitEntryDataValue } from "@stores/ctgInitEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { SHELL_PATH_TO_READ_SHELL_INFO } from "../constants/tableCreatorShell.constants";

import type { EntryDataValueInfo } from "@/types/drugProfile.types";

interface UseGetInfoOfShellReturn {
  shellValue: (
    shellKey: string,
    tableIndex: number,
    groupShellIndex: number,
    shellIndex: number,
    subGroupShellIndex?: number,
  ) => {
    value: EntryDataValueInfo[];
    options: string[];
    attributionId?: string;
  };
}

export const useGetInfoOfShell = (): UseGetInfoOfShellReturn => {
  const entryData = useCTGEntryDataValue(getPressIdFromURL());
  const initEntryData = useCTGInitEntryDataValue(getPressIdFromURL());

  const articleSection = useArticleSection();

  const shellValue = (
    shellKey: string,
    tableIndex: number,
    groupShellIndex: number,
    shellIndex: number,
    subGroupShellIndex?: number,
  ) => {
    if (!SHELL_PATH_TO_READ_SHELL_INFO[articleSection][shellKey]) {
      return {
        value: [],
        options: [],
      };
    }
    const currentValue = SHELL_PATH_TO_READ_SHELL_INFO[articleSection][
      shellKey
    ](
      entryData,
      tableIndex,
      groupShellIndex,
      shellIndex,
      subGroupShellIndex || 0,
    ).value;

    const initOptions = SHELL_PATH_TO_READ_SHELL_INFO[articleSection][shellKey](
      initEntryData,
      0, // NOTE: TableIndex
      0, // NOTE: GroupShellIndex
      0, // NOTE: ShellIndex
      0, // NOTE: SubGroupShellIndex
    )?.options;

    const currentDropdown = initOptions;

    return {
      value: currentValue,
      options: currentDropdown || [],
    };
  };
  return {
    shellValue,
  };
};
