import { ClassificationCode, ProcessStatusCode } from "@/types/code.types";
import { ROLES_REDIRECT_LIST } from "@libs/roles/constants/rolesRedirectList.constants";

import { CLASSIFICATION_KEYS_CODE } from "../constants/returnRolesList.constants";
import { EditorRoleCode } from "../types/roles.types";

export const handlePressListRedirectRoles = (
  role: EditorRoleCode,
  status: ProcessStatusCode,
  classification?: ClassificationCode,
) => {
  if (role === "EDTK0100") {
    const checkClassification = true;
    const checkStatus = ROLES_REDIRECT_LIST.EDTK0100_STATUS.includes(status);
    return checkClassification && checkStatus;
  }
  if (role === "EDTK0201") {
    const checkClassification =
      classification === CLASSIFICATION_KEYS_CODE.clinicalTrials;

    const checkStatus = ROLES_REDIRECT_LIST.EDTK0201_STATUS.includes(status);
    return checkClassification && checkStatus;
  }
  if (role === "EDTK0202") {
    const checkClassification =
      classification === CLASSIFICATION_KEYS_CODE.clinicalTrials;

    const checkStatus = ROLES_REDIRECT_LIST.EDTK0202_STATUS.includes(status);
    return checkClassification && checkStatus;
  }
  if (role === "EDTK0303") {
    const checkClassification =
      classification === CLASSIFICATION_KEYS_CODE.clinicalTrialStatus;

    const checkStatus = ROLES_REDIRECT_LIST.EDTK0303_STATUS.includes(status);
    return checkClassification && checkStatus;
  }
  if (role === "EDTK0401") {
    const checkClassification =
      classification === CLASSIFICATION_KEYS_CODE.posterContents;

    const checkStatus = ROLES_REDIRECT_LIST.EDTK0401_STATUS.includes(status);
    return checkClassification && checkStatus;
  }
  if (role === "EDTK0402") {
    const checkClassification =
      classification === CLASSIFICATION_KEYS_CODE.posterContents;

    const checkStatus = ROLES_REDIRECT_LIST.EDTK0401_STATUS.includes(status);
    return checkClassification && checkStatus;
  }
  if (role === "EDTK0503") {
    const checkClassification =
      classification === CLASSIFICATION_KEYS_CODE.deals;

    const checkStatus = ROLES_REDIRECT_LIST.EDTK0503_STATUS.includes(status);
    return checkClassification && checkStatus;
  }
  if (role === "EDTK0603") {
    const checkClassification =
      classification === CLASSIFICATION_KEYS_CODE.expeditedProgram;

    const checkStatus = ROLES_REDIRECT_LIST.EDTK0603_STATUS.includes(status);
    return checkClassification && checkStatus;
  }
  if (role === "EDTK0703") {
    const checkClassification =
      classification === CLASSIFICATION_KEYS_CODE.approvalProcess;

    const checkStatus = ROLES_REDIRECT_LIST.EDTK0703_STATUS.includes(status);
    return checkClassification && checkStatus;
  }
  if (role === "EDTK0803") {
    const checkClassification =
      classification === CLASSIFICATION_KEYS_CODE.ventureCapital;

    const checkStatus = ROLES_REDIRECT_LIST.EDTK0803_STATUS.includes(status);
    return checkClassification && checkStatus;
  }
  if (role === "EDTK0903") {
    const checkClassification = true;

    const checkStatus = ROLES_REDIRECT_LIST.EDTK0903_STATUS.includes(status);
    return checkClassification && checkStatus;
  }

  return false;
};
