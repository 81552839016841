/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS,
  LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS,
  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseGetNumberOfSubGroupShellReturn {
  subGroupShellCount: (
    subGroupShellKey: string | null,
    tableIndex: number,
    groupShellIndex: number,
  ) => number;
}

const SHELL_PATH_TO_COUNT_SUP_GROUP_SHELL: Record<
  string,
  {
    [key: string]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
    ) => number;
  }
> = {
  Deals: {
    Milestone: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
    ) =>
      data?.Deals?.["Licensee Table"]?.[tableIndex]?.[
        "Strategic Collaboration & License Agreement terms"
      ]["Milestone Payment and Condition"]?.length || 1,
    [LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE]?.[
        tableIndex
      ]?.[
        ENTRY_DATA_LICENSEE_TABLE_KEYS
          .strategicCollaborationLicenseAgreementTerms
      ][
        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
          .otherPaymentAndDetailScLa
      ]?.length || 1,
    [MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE]?.[
        tableIndex
      ]?.[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment
      ]?.length || 1,

    [VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.contingentInvestment]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE]?.[
        tableIndex
      ]?.[ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms][
        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentAndDetailVf
      ]?.length || 1,
  },
  [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
    [CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
      ]?.length || 1,
  },
};

export const useGetNumberOfSupGroupShell =
  (): UseGetNumberOfSubGroupShellReturn => {
    const articleSection = useArticleSection();
    const entryData = useEntryDataValue(getPressIdFromURL());

    return {
      subGroupShellCount: (
        subGroupShellKey: string | null,
        tableIndex: number,
        groupShellIndex: number,
      ) =>
        subGroupShellKey &&
        SHELL_PATH_TO_COUNT_SUP_GROUP_SHELL[articleSection][subGroupShellKey]
          ? SHELL_PATH_TO_COUNT_SUP_GROUP_SHELL[articleSection][
              subGroupShellKey
            ](entryData, tableIndex, groupShellIndex) || 1
          : 1,
    };
  };
