import React, { useCallback } from "react";

import { DealsSection } from "@/types/deals.types";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useAddVentureFormationSubGroupShell } from "./useAddVentureFormationSubGroupShell";
import { useClearVentureFormationSubGroupShell } from "./useClearVentureFormationSubGroupShell";
import { useDeleteVentureFormationSubGroupShell } from "./useDeleteVentureFormationSubGroupShell";

interface UseHandleGroupShellReturn {
  focusStatus: FocusStatus;
  isFocused: boolean;
  isClearSubGroupShell: boolean;
  isDeleteSubGroupShell: boolean;
  isAlertSubGroupShell: boolean;
  isEnableEdit: boolean;
  isLastSubGroupShell: boolean;
  isFocusedAddButton: boolean;
  isDraggingSubGroupShell: boolean;
  handleFocusSubGroupShell: (e: React.MouseEvent) => void;
  clickAddSubGroupShellButton: (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
      subGroupShellIndexParameter: number;
      subGroupShellKeyParameter: string | null;
    },
  ) => void;
  handleClickToDeleteSubGroupShell: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  handleDefaultFocus: () => void;
  changePositionInfo: () => void;
  changePositionInfoToDefault: () => void;
  changeFocusStatus: (focusStatus: FocusStatus) => void;
  keydownClearOrDeleteSubGroupShellData: (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    subGroupShellIndex: number;
    subGroupShellKey: string | null;
    tableKey: string | null;
    tableIndex: number;
  };
  subGroupShellCount: number;
}

export const useTableCreatorVentureFormationSubGroupShell = ({
  positionPropsInfo,
  subGroupShellCount,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    tableIndex,
    tableKey,
  } = positionPropsInfo;

  const articleSection = "Deals";
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { validatedInfo, changeValidatedInfo } = useValidatedInTableCreator();

  const { clickAddContingentSubGroupShellButton } =
    useAddVentureFormationSubGroupShell({
      positionPropsInfo,
    });

  const { clearSubGroupShellData } = useClearVentureFormationSubGroupShell({
    positionPropsInfo,
  });

  const { deleteSubGroupShellData } = useDeleteVentureFormationSubGroupShell({
    positionPropsInfo,
  });

  const { data: articleData } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const isEnableEdit = articleData
    ? ENABLE_GROUP_TABLE_KEY_LIST[articleSection][groupTableKey].includes(
        articleData.draftArticleStatus,
      )
    : false;

  const isLastSubGroupShell = subGroupShellIndex === subGroupShellCount - 1;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.subGroupShellIndex === subGroupShellIndex &&
    info.subGroupShellKey === subGroupShellKey &&
    info.shellKey === null &&
    subGroupShellKey !== null &&
    isEnableEdit;

  const isFocusedAddButton =
    focusStatus.container === "noFocus" && isEnableEdit;

  const clickAddSubGroupShellButton = (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
      subGroupShellIndexParameter: number;
      subGroupShellKeyParameter: string | null;
    },
  ) => {
    if (focusStatus.container !== "noFocus") return;
    clickAddContingentSubGroupShellButton(event, groupShellInfo);
  };

  const handleDefaultFocus = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleFocusSubGroupShell = (e: React.MouseEvent) => {
    if (subGroupShellKey) e.stopPropagation();

    changeInfo({
      ...positionPropsInfo,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });

    changeFocusStatus({ type: "clear", container: "subGroupShell" });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const changePositionInfo = () => {
    changeInfo({
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellKey,
      groupShellIndex,
      subGroupShellIndex,
      subGroupShellKey,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
  };

  const changePositionInfoToDefault = () => {
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const isClearSubGroupShell =
    isFocused &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    focusStatus.type === "clear";

  const isDeleteSubGroupShell =
    isFocused &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    focusStatus.type === "delete";

  const isDraggingSubGroupShell =
    isFocused &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    subGroupShellCount > 1 &&
    (focusStatus.type === "dragging" ||
      focusStatus.type === "delete" ||
      focusStatus.type === "clear");

  const isAlertSubGroupShell =
    validatedInfo.filter(
      (validatedItem) =>
        validatedItem.groupTableKey === groupTableKey &&
        validatedItem.groupTableIndex === groupTableIndex &&
        validatedItem.tableIndex === tableIndex &&
        validatedItem.groupShellKey === groupShellKey &&
        validatedItem.groupShellIndex === groupShellIndex &&
        validatedItem.shellKey === null &&
        validatedItem.shellIndex === null &&
        validatedItem.valueIndex === null &&
        validatedItem.type === "alert" &&
        validatedItem.container === "subGroupShell",
    ).length > 0;

  const handleClickToDeleteSubGroupShell = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (focusStatus.type === "clear") clearSubGroupShellData();

    if (focusStatus.type === "delete") deleteSubGroupShellData();
  };

  const keydownClearOrDeleteSubGroupShellData = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key !== "Delete") return;
      if (!info.subGroupShellKey) return;

      if (focusStatus.type === "clear") {
        clearSubGroupShellData();
      }
      if (focusStatus.type === "delete") {
        deleteSubGroupShellData();
      }
    },
    [
      clearSubGroupShellData,
      deleteSubGroupShellData,
      focusStatus.type,
      info.subGroupShellKey,
    ],
  );

  return {
    focusStatus,
    isFocused,
    isClearSubGroupShell,
    isDeleteSubGroupShell,
    isAlertSubGroupShell,
    isEnableEdit,
    isLastSubGroupShell,
    isFocusedAddButton,
    isDraggingSubGroupShell,
    handleFocusSubGroupShell,
    clickAddSubGroupShellButton,
    handleClickToDeleteSubGroupShell,
    handleDefaultFocus,
    changePositionInfo,
    changePositionInfoToDefault,
    changeFocusStatus,
    keydownClearOrDeleteSubGroupShellData,
  };
};
