import React from "react";

export const THERAPEUTIC_AREA_INFO = [
  { code: "C04", name: "Neoplasms", viewOrd: 1, color: "#FF0057" },
  {
    code: "C10",
    name: "Nervous System Diseases",
    viewOrd: 2,
    color: "#D92663",
  },
  {
    code: "C23",
    name: "Pathological Conditions, Signs and Symptoms",
    viewOrd: 3,
    color: "#B34D70",
  },
  {
    code: "C16",
    name: "Congenital, Hereditary, and Neonatal Diseases and Abnormalities",
    viewOrd: 4,
    color: "#CC0046",
  },
  { code: "C01", name: "Infections", viewOrd: 5, color: "#990034" },
  {
    code: "C20",
    name: "Immune System Diseases",
    viewOrd: 6,
    color: "#660023",
  },
  {
    code: "C15",
    name: "Hemic and Lymphatic Diseases",
    viewOrd: 7,
    color: "#FF0004",
  },
  {
    code: "C17",
    name: "Skin and Connective Tissue Diseases",
    viewOrd: 8,
    color: "#D92629",
  },
  {
    code: "C06",
    name: "Digestive System Diseases",
    viewOrd: 9,
    color: "#B34D4E",
  },
  {
    code: "C18",
    name: "Nutritional and Metabolic Diseases",
    viewOrd: 10,
    color: "#CC0003",
  },
  {
    code: "C12",
    name: "Urogenital Diseases",
    viewOrd: 11,
    color: "#990002",
  },
  {
    code: "C11",
    name: "Eye Diseases",
    viewOrd: 12,
    color: "#660002",
  },
  {
    code: "C14",
    name: "Cardiovascular Diseases",
    viewOrd: 13,
    color: "#FF00AA",
  },
  {
    code: "C05",
    name: "Musculoskeletal Diseases",
    viewOrd: 14,
    color: "#D9269D",
  },
  {
    code: "C08",
    name: "Respiratory Tract Diseases",
    viewOrd: 15,
    color: "#B34D91",
  },
  {
    code: "F03",
    name: "Mental Disorders",
    viewOrd: 16,
    color: "#CC0088",
  },
  {
    code: "C19",
    name: "Endocrine System Diseases",
    viewOrd: 17,
    color: "#990066",
  },
  {
    code: "C07",
    name: "Stomatognathic Diseases",
    viewOrd: 18,
    color: "#660044",
  },
  {
    code: "C09",
    name: "Otorhinolaryngologic Diseases",
    viewOrd: 19,
    color: "#FF008C",
  },
  {
    code: "C25",
    name: "Chemically-Induced Disorders",
    viewOrd: 20,
    color: "#D92688",
  },
  {
    code: "C26",
    name: "Wounds and Injuries",
    viewOrd: 21,
    color: "#B34D85",
  },
  {
    code: "C24",
    name: "Occupational Diseases",
    viewOrd: 22,
    color: "#CC0070",
  },
  {
    code: "C21",
    name: "Disorders of Environmental Origin",
    viewOrd: 23,
    color: "#990054",
  },
  {
    code: "C99",
    name: "Undisclosed",
    viewOrd: 24,
    color: "#CBD0D8",
  },
];

export const THERAPEUTIC_AREA_KEYS = {
  neoplasms: "Neoplasms",
  nervousSystemDiseases: "Nervous System Diseases",
  pathologicalConditionsSignsAndSymptoms:
    "Pathological Conditions, Signs and Symptoms",
  congenitalHereditaryAndNeonatalDiseasesAndAbnormalities:
    "Congenital, Hereditary, and Neonatal Diseases and Abnormalities",
  infections: "Infections",
  immuneSystemDiseases: "Immune System Diseases",
  hemicAndLymphaticDiseases: "Hemic and Lymphatic Diseases",
  skinAndConnectiveTissueDiseases: "Skin and Connective Tissue Diseases",
  digestiveSystemDiseases: "Digestive System Diseases",
  nutritionalAndMetabolicDiseases: "Nutritional and Metabolic Diseases",
  urogenitalDiseases: "Urogenital Diseases",
  eyeDiseases: "Eye Diseases",
  cardiovascularDiseases: "Cardiovascular Diseases",
  musculoskeletalDiseases: "Musculoskeletal Diseases",
  respiratoryTractDiseases: "Respiratory Tract Diseases",
  mentalDisorders: "Mental Disorders",
  endocrineSystemDiseases: "Endocrine System Diseases",
  stomatognathicDiseases: "Stomatognathic Diseases",
  otorhinolaryngologicDiseases: "Otorhinolaryngologic Diseases",
  chemicallyInducedDisorders: "Chemically-Induced Disorders",
  woundsAndInjuries: "Wounds and Injuries",
  occupationalDiseases: "Occupational Diseases",
  disordersOfEnvironmentalOrigin: "Disorders of Environmental Origin",
};

const formatArrayToObject = (list: [string, React.ReactNode][]) =>
  Object.fromEntries(list);

export const THERAPEUTIC_AREA_MESH_FONT_COLOR_BY_GROUP_NAME =
  formatArrayToObject(
    THERAPEUTIC_AREA_INFO.map(({ name }) => {
      if (name.toLowerCase() === "undisclosed")
        return [name.toLowerCase(), "#2A2F37"];
      return [name.toLowerCase(), "#fff"];
    }),
  );

export const THERAPEUTIC_AREA_MESH_MAIN_COLOR_BY_GROUP_NAME =
  formatArrayToObject(
    THERAPEUTIC_AREA_INFO.map(({ name, color }) => [name.toLowerCase(), color]),
  );

export const THERAPEUTIC_AREA_MESH_ICONS_BY_GROUP_NAME = formatArrayToObject(
  THERAPEUTIC_AREA_INFO.map(({ name }) => [name.toLowerCase(), <></>]),
);

export const THERAPEUTIC_AREA_DISPLAY_TEXT: Record<string, string> = {
  "cardiovascular diseases": "Cardiovascular Diseases",
  "chemically-induced disorders": "Chemically-Induced Disorders",
  "congenital, hereditary, and neonatal diseases and abnormalities":
    "Congenital, Hereditary, and Neonatal Diseases and Abnormalities",
  "digestive system diseases": "Digestive System Diseases",
  "disorders of environmental origin": "Disorders of Environmental Origin",
  "endocrine system diseases": "Endocrine System Diseases",
  "eye diseases": "Eye Diseases",
  "hemic and lymphatic diseases": "Hemic and Lymphatic Diseases",
  "immune system diseases": "Immune System Diseases",
  infections: "Infections",
  "mental disorders": "Mental Disorders",
  "musculoskeletal diseases": "Musculoskeletal Diseases",
  neoplasms: "Neoplasms",
  "nervous system diseases": "Nervous System Diseases",
  "nutritional and metabolic diseases": "Nutritional and Metabolic Diseases",
  "occupational diseases": "Occupational Diseases",
  "otorhinolaryngologic diseases": "Otorhinolaryngologic Diseases",
  "pathological conditions, signs and symptoms":
    "Pathological Conditions, Signs and Symptoms",
  "respiratory tract diseases": "Respiratory Tract Diseases",
  "skin and connective tissue diseases": "Skin and Connective Tissue Diseases",
  "stomatognathic diseases": "Stomatognathic Diseases",
  "urogenital diseases": "Urogenital Diseases",
  "wounds and injuries": "Wounds and Injuries",
};
