import React from "react";

import PressLabelViewer from "@/components/shared/ui/PressLabelViewer/PressLabelViewer";
import { usePressInfoQuery } from "@/queries/hooks/usePressInfo";
import { ClassificationCode } from "@/types/code.types";
import { ParsedJsonContentType } from "@/types/fillInTableContent.types";
import { usePressIdFromURL } from "@utils/getStateFromURL";
import { parseJSON } from "@utils/parseJSON";

interface Props {
  columnLabelViewerSize?: {
    width: number;
    y: number;
  };
  classificationManual: ClassificationCode;
  additionalBoldKeywords: string[];
}

const PressClassificationPress = ({
  columnLabelViewerSize,
  classificationManual,
  additionalBoldKeywords,
}: Props) => {
  const draftArticleId = usePressIdFromURL();
  const { data: pressInfo } = usePressInfoQuery(draftArticleId);

  if (!pressInfo) return null;
  const rawPressContent = pressInfo.pressContent;
  const pressContent: ParsedJsonContentType[] = parseJSON(rawPressContent);

  return (
    <PressLabelViewer
      parsedContentArray={pressContent}
      draftArticleId={draftArticleId}
      showLabelTooltip
      columnLabelViewerWidth={columnLabelViewerSize?.width}
      columnLabelViewerTop={columnLabelViewerSize?.y}
      classificationManual={classificationManual}
      additionalBoldKeywords={additionalBoldKeywords}
    />
  );
};

export default PressClassificationPress;
