import { useEffect } from "react";

import { useScroll } from "@/components/business/CTGFillInTableContainer/components/CTGFillInTable/hooks/useScroll";
import {
  DefaultShellTitle,
  TableCreatorTextFieldContainer,
} from "@/components/business/CTGFillInTableContainer/shared";
import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";
import useCheckPressedShell from "@/components/business/CTGFillInTableContainer/shared/hooks/useCheckPressedShell";
import { useGetInfoOfShell } from "@/components/business/CTGFillInTableContainer/shared/hooks/useGetInfoOfShell";
import { TableCreatorSubShellList } from "@/components/business/CTGFillInTableContainer/shared/types/tableCreator.types";
import {
  isRequiredShell,
  getShellPlaceholder,
} from "@/components/business/CTGFillInTableContainer/shared/utils/getOptionsFromData";
import { getShellTitle } from "@/components/business/CTGFillInTableContainer/shared/utils/getTextFromData";
import { DrugProfileSection } from "@/types/drugProfile.types";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { padStart } from "@utils/padStart/padStart";

import * as S from "./DrugProfileShell.style";
import { useTableCreatorDrugProfileShell } from "./hooks/useTableCreatorDrugProfileShell";

export interface PositionInfoProps {
  groupTableKey: DrugProfileSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  tableKey: string | null;
  tableIndex: number;
  shellKey: string;
  shellIndex: number;
  shellCount: number;
}

interface Props extends PositionInfoProps {
  shellCount: number;
  subShellList?: TableCreatorSubShellList;
  isShellOverOne: boolean;
  isSeparatedShellTitle: boolean;
  isTitleRowShell?: boolean;
  isFocusedGroupShell: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
}

const DrugProfileShell = ({
  groupTableKey,
  groupTableIndex,
  groupShellKey,
  groupShellIndex,
  tableKey,
  shellKey,
  tableIndex,
  shellIndex,
  shellCount,
  isShellOverOne,
  isSeparatedShellTitle,
  isTitleRowShell = false,
  isClearGroupShell,
  isDeleteGroupShell,
  isAlertGroupShell,
}: Props) => {
  const positionPropsInfo: PositionInfoProps = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
    shellCount,
  };

  const {
    isFocused,
    isClearShell,
    isDeleteShell,
    isEnableEdit,
    isAlertShell,
    focusStatus,
    columnSize,
    isDeleteTable,
    isClearTable,
    handleFocusTextFieldWrapper,
    handleClickToDeleteShell,
    handleFocusOutShell,
  } = useTableCreatorDrugProfileShell(positionPropsInfo);
  const { isPressed, handleChangePressedStatus } = useCheckPressedShell();

  const { register } = useScroll();

  const articleSection = CTG_SECTION;

  const { shellValue } = useGetInfoOfShell();

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.dataset.button) return;

      if (isFocused) {
        handleFocusOutShell();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleFocusOutShell, isFocused]);

  if (shellKey === "Modality") {
    const modalityShellTitleColumnClassName = `is-modality-shell-value-empty-${
      shellValue(shellKey, tableIndex, groupShellIndex, shellIndex).value
        .length === 0
    }`;

    return (
      <S.ShellWrapper
        column={columnSize}
        {...register(
          `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${shellKey}__${shellIndex}`,
        )}
      >
        {(isClearShell || isDeleteShell) && isEnableEdit && (
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton
              type="button"
              onClick={handleClickToDeleteShell}
            >
              <img src={IconXMark} alt="button for clear" />
              {focusStatus.type === "clear" ? "Clear" : null}
              {focusStatus.type === "delete" ? "Delete" : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <S.ShellBorderWrapper
          isFocused={isFocused && focusStatus.type !== "dragging"}
          $isDragging={isFocused && focusStatus.type === "dragging"}
          isClearStatus={isClearShell || isClearGroupShell || isClearTable}
          isDeleteStatus={isDeleteShell || isDeleteGroupShell || isDeleteTable}
          isAlertStatus={isAlertShell || isAlertGroupShell}
          isDisableEdit={!isEnableEdit}
          isSeparatedShellTitle={isSeparatedShellTitle}
          isTitleRowShell={isTitleRowShell}
          $isPressed={isPressed}
          onClick={handleFocusTextFieldWrapper}
          onMouseDown={(e) => handleChangePressedStatus(e, true)}
          onMouseUp={(e) => handleChangePressedStatus(e, false)}
        >
          <S.ModalityShellRow>
            <S.ModalityShellColumn
              className={modalityShellTitleColumnClassName}
              $isMultipleValue={
                shellValue(shellKey, tableIndex, groupShellIndex, shellIndex)
                  .value.length > 1
              }
            >
              <DefaultShellTitle
                isFocused={isFocused}
                isClearShell={isClearShell}
                isDeleteShell={isDeleteShell}
                isAlertShell={isAlertShell}
                isRequiredShell={isRequiredShell(articleSection, shellKey)}
                shellTitle={getShellTitle(articleSection, shellKey)}
                shellTitleNumbering=""
                isSeparatedShellTitle={isSeparatedShellTitle}
                columnSize={columnSize}
              />
            </S.ModalityShellColumn>
            <S.ModalityShellColumn>
              <DefaultShellTitle
                isFocused={isFocused}
                isClearShell={isClearShell}
                isDeleteShell={isDeleteShell}
                isAlertShell={isAlertShell}
                isRequiredShell={isRequiredShell(articleSection, shellKey)}
                shellTitle="Modality Category"
                shellTitleNumbering=""
                isSeparatedShellTitle={isSeparatedShellTitle}
                columnSize={columnSize}
              />
            </S.ModalityShellColumn>
          </S.ModalityShellRow>

          <>
            <TableCreatorTextFieldContainer
              placeholder={
                getShellPlaceholder(articleSection, shellKey) ||
                getShellTitle(articleSection, shellKey)
              }
              shellValue={shellValue(
                shellKey,
                tableIndex,
                groupShellIndex,
                shellIndex,
              )}
              shellKey={shellKey}
              shellIndex={shellIndex}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              groupShellKey={groupShellKey}
              groupShellIndex={groupShellIndex}
              tableKey={tableKey}
              tableIndex={tableIndex}
            />
          </>
        </S.ShellBorderWrapper>
      </S.ShellWrapper>
    );
  }
  return (
    <S.ShellWrapper
      column={columnSize}
      {...register(
        `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${shellKey}__${shellIndex}`,
      )}
    >
      {(isClearShell || isDeleteShell) && isEnableEdit && (
        <S.DeleteTableButtonWrapper>
          <S.DeleteTableButton
            type="button"
            data-button="delete-button"
            onClick={handleClickToDeleteShell}
          >
            <img src={IconXMark} alt="button for clear" />
            {focusStatus.type === "clear" ? "Clear" : null}
            {focusStatus.type === "delete" ? "Delete" : null}
          </S.DeleteTableButton>
        </S.DeleteTableButtonWrapper>
      )}
      <S.ShellBorderWrapper
        isFocused={isFocused && focusStatus.type !== "dragging"}
        $isDragging={isFocused && focusStatus.type === "dragging"}
        isClearStatus={isClearShell || isClearGroupShell || isClearTable}
        isDeleteStatus={isDeleteShell || isDeleteGroupShell || isDeleteTable}
        isAlertStatus={isAlertShell || isAlertGroupShell}
        isDisableEdit={!isEnableEdit}
        isSeparatedShellTitle={isSeparatedShellTitle}
        isTitleRowShell={isTitleRowShell}
        $isPressed={isPressed}
        column={columnSize}
        onClick={handleFocusTextFieldWrapper}
        onMouseDown={(e) => handleChangePressedStatus(e, true)}
        onMouseUp={(e) => handleChangePressedStatus(e, false)}
      >
        <DefaultShellTitle
          isFocused={isFocused}
          isClearShell={isClearShell}
          isDeleteShell={isDeleteShell}
          isAlertShell={isAlertShell}
          isRequiredShell={isRequiredShell(articleSection, shellKey)}
          shellTitle={getShellTitle(articleSection, shellKey)}
          shellTitleNumbering={
            isShellOverOne ? `${padStart(shellIndex + 1)}` : ""
          }
          isSeparatedShellTitle={isSeparatedShellTitle}
          columnSize={columnSize}
        />

        <TableCreatorTextFieldContainer
          placeholder={
            getShellPlaceholder(articleSection, shellKey) ||
            getShellTitle(articleSection, shellKey)
          }
          shellValue={shellValue(
            shellKey,
            tableIndex,
            groupShellIndex,
            shellIndex,
          )}
          shellKey={shellKey}
          shellIndex={shellIndex}
          groupTableKey={groupTableKey}
          groupTableIndex={groupTableIndex}
          groupShellKey={groupShellKey}
          groupShellIndex={groupShellIndex}
          tableKey={tableKey}
          tableIndex={tableIndex}
        />
      </S.ShellBorderWrapper>
    </S.ShellWrapper>
  );
};

export default DrugProfileShell;
