import { ProcessStatusCode } from "@/types/code.types";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";

export const HALF_TABLE_ROLES_LIST: Record<string, ProcessStatusCode[]> = {
  EDTK0201: [
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
  ],
  EDTK0303: [
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
  ],
  EDTK0401: [
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
  ],
};

export const FULL_TABLE_ROLES_LIST: Record<string, ProcessStatusCode[]> = {
  EDTK0202: [DRAFT_STATUS_CODE.reviewInProgress, DRAFT_STATUS_CODE.dbSavedHalf],
  EDTK0303: [DRAFT_STATUS_CODE.reviewInProgress],
  EDTK0402: [DRAFT_STATUS_CODE.reviewInProgress, DRAFT_STATUS_CODE.dbSavedHalf],
  EDTK0503: [DRAFT_STATUS_CODE.reviewInProgress],
  EDTK0603: [DRAFT_STATUS_CODE.reviewInProgress],
  EDTK0703: [DRAFT_STATUS_CODE.reviewInProgress],
  EDTK0803: [DRAFT_STATUS_CODE.reviewInProgress],
  EDTK0903: [DRAFT_STATUS_CODE.reviewInProgress],
};

export const HALF_BUTTON_ROLES_LIST: Record<string, ProcessStatusCode[]> = {
  EDTK0201: [
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
  ],
  EDTK0303: [
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
  ],
  EDTK0401: [
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
  ],
};

export const FULL_BUTTON_ROLES_LIST: Record<string, ProcessStatusCode[]> = {
  EDTK0202: [
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
  ],
  EDTK0303: [DRAFT_STATUS_CODE.reviewInProgress, DRAFT_STATUS_CODE.dbSaved],
  EDTK0402: [
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
  ],
  EDTK0503: [DRAFT_STATUS_CODE.reviewInProgress, DRAFT_STATUS_CODE.dbSaved],
  EDTK0603: [DRAFT_STATUS_CODE.reviewInProgress, DRAFT_STATUS_CODE.dbSaved],
  EDTK0703: [DRAFT_STATUS_CODE.reviewInProgress, DRAFT_STATUS_CODE.dbSaved],
  EDTK0803: [DRAFT_STATUS_CODE.reviewInProgress, DRAFT_STATUS_CODE.dbSaved],
  EDTK0903: [DRAFT_STATUS_CODE.reviewInProgress, DRAFT_STATUS_CODE.dbSaved],
};

export const FILL_IN_TABLE_MOVE_NEXT_ROLES_LIST: Record<
  string,
  ProcessStatusCode[]
> = {
  EDTK0204: [
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0304: [
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0404: [
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0504: [
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0604: [
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0704: [
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0804: [
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
};

export const FILL_IN_TABLE_MOVE_PREV_ROLES_LIST: Record<
  string,
  ProcessStatusCode[]
> = {
  EDTK0201: [
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0202: [
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0303: [
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0401: [
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0402: [
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0503: [
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0603: [
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0703: [
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0803: [
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0903: [DRAFT_STATUS_CODE.reviewInProgress, DRAFT_STATUS_CODE.dbSaved],
};
