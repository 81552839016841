import React, { useState } from "react";

import {
  CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY as CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
} from "@frontend/common";

import { ENTRY_DATA_TO_CHANGE_TABLE_ORDER } from "@/components/business/CTGFillInTableContainer/shared/constants/changeEntryDataOrder.constants";
import { GroupTableKeyType } from "@/components/business/CTGFillInTableContainer/shared/types/tableCreator.types";
import { ArticleSectionType } from "@/components/business/CTGFillInTableContainer/types/fillInTable.types";
import { useCTGEntryDataState } from "@stores/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export type DragInfoType = [number | null, number | null];

export const useHandleTableCreatorTableDrag = (
  articleSection: ArticleSectionType,
) => {
  const [entryData, setEntryData] = useCTGEntryDataState(getPressIdFromURL());
  const [draggedIndexInfo, setDraggedIndexInfo] = useState<DragInfoType>([
    null,
    null,
  ]);

  const [isDraggingTable, setIsDraggingTable] = useState<boolean>(false);

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    setDraggedIndexInfo([index, null]);
  };

  const handleDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    event.preventDefault();
    setDraggedIndexInfo([draggedIndexInfo[0], index]);
  };

  const handleDragEnd = (groupTableKey: GroupTableKeyType) => {
    const activeGroupTableKeys = [
      DRUG_PROFILE,
      CLINICAL_TRIAL_DESIGN,
      "Clinical Endpoints (Table)",
      "Licensee Table",
    ];

    if (!activeGroupTableKeys.includes(groupTableKey)) return;

    setEntryData(
      ENTRY_DATA_TO_CHANGE_TABLE_ORDER?.[articleSection]?.[groupTableKey](
        entryData,
        draggedIndexInfo,
      ) || {},
    );
    setIsDraggingTable(false);
  };

  const handleDragOn = () => {
    setIsDraggingTable(true);
  };
  return {
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragOn,
    draggedIndexInfo,
    isDraggingTable,
  };
};
