import styled, { css } from "styled-components";

import type { ProcessStatusCode } from "@/types/code.types";

interface FilterTagStyleProps {
  filterType?:
    | "pressListClassification"
    | "status"
    | "pressManualClassification";
  isAll?: boolean;
  code?: ProcessStatusCode;
  aiSelected?: boolean;
  manualSelected?: boolean;
}

export const FilterTagLabel = styled.label<FilterTagStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  line-height: 2.4rem;
  border-radius: var(--Border-Radius-circle, 99999.9rem);
  padding: 0rem 1.2rem;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.color.neural.gray03};
  background-color: white;

  ${({ filterType, aiSelected, isAll }) =>
    filterType === "pressListClassification" &&
    aiSelected &&
    !isAll &&
    css`
      border: 0.1rem solid ${({ theme }) => theme.color.status.todo};

      svg {
        path {
          fill: ${({ theme }) => theme.color.status.todo} !important;
        }
      }

      &.checked-tag-label {
        border: 0.1rem solid ${({ theme }) => theme.color.status.todo};
        background-color: ${({ theme }) => theme.color.status.todo};
        span {
          color: white;
        }
      }
    `}

  ${({ filterType, manualSelected }) =>
    filterType === "pressListClassification" &&
    manualSelected &&
    css`
      border: 0.1rem solid ${({ theme }) => theme.color.node.blue};

      svg {
        path {
          fill: ${({ theme }) => theme.color.node.blue} !important;
        }
      }

      &.checked-tag-label {
        border: 0.1rem solid ${({ theme }) => theme.color.node.blue};
        background-color: ${({ theme }) => theme.color.node.blue};

        span {
          color: white;
        }
      }
    `}

  ${({ filterType }) =>
    filterType === "pressManualClassification" &&
    css`
      border: 0.1rem solid ${({ theme }) => theme.color.node.blue};

      svg {
        path {
          fill: ${({ theme }) => theme.color.node.blue} !important;
        }
      }

      &.checked-tag-label {
        border: 0.1rem solid ${({ theme }) => theme.color.node.blue};
        background-color: ${({ theme }) => theme.color.node.blue};

        span {
          color: white;
        }
      }
    `}

  ${({ filterType, code, isAll }) =>
    filterType === "status" &&
    code &&
    !isAll &&
    css`
      border: 0.1rem solid ${({ theme }) => theme.status[code]};

      svg {
        path {
          fill: ${({ theme }) => theme.status[code]} !important;
        }
      }

      &.checked-tag-label {
        border: 0.1rem solid ${({ theme }) => theme.status[code]};
        background-color: ${({ theme }) => theme.status[code]};

        span {
          color: white;
        }
      }
    `}

  ${({ isAll }) =>
    isAll &&
    css`
      border: none;
    `}
`;

export const InputWrapper = styled.div<FilterTagStyleProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.2rem;
    height: 1.2rem;
    z-index: 1;
    background-color: none;
  }
`;

export const FilterInput = styled.input<FilterTagStyleProps>`
  all: unset;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.2rem;
  border: 0.1rem solid #b1b8c4;
  align-items: center;
  justify-content: center;

  &:checked {
    border: 0.1rem solid ${({ theme }) => theme.color.node.blue};
    border-radius: 0.2rem;
    background-color: blue;

    ${({ filterType, aiSelected }) =>
      filterType === "pressListClassification" &&
      aiSelected &&
      css`
        border: 0.1rem solid ${({ theme }) => theme.color.status.todo};
        background-color: white;
        opacity: 0.6;
      `}
    ${({ filterType, manualSelected }) =>
      filterType === "pressListClassification" &&
      manualSelected &&
      css`
        border: 0.1rem solid ${({ theme }) => theme.color.node.blue};
        background-color: white;
        opacity: 0.6;
      `}
      ${({ filterType }) =>
      filterType === "pressManualClassification" &&
      css`
        border: 0.1rem solid ${({ theme }) => theme.color.node.blue};
        background-color: white;
        opacity: 0.6;
      `}
      ${({ filterType, code, isAll }) =>
      filterType === "status" &&
      code &&
      !isAll &&
      css`
        border: 0.1rem solid ${({ theme }) => theme.status[code]};
        background-color: white;
        opacity: 0.6;
      `}
      ${({ isAll }) =>
      isAll &&
      css`
        border: 0.1rem solid ${({ theme }) => theme.color.node.sky};
        background-color: ${({ theme }) => theme.color.node.sky};
      `};
  }

  ${({ filterType, aiSelected, isAll }) =>
    filterType === "pressListClassification" &&
    aiSelected &&
    !isAll &&
    css`
      &:checked {
        border: 0.1rem solid ${({ theme }) => theme.color.status.todo};
        background-color: white;
        opacity: 0.6;
      }
    `}

  ${({ filterType, manualSelected, isAll }) =>
    filterType === "pressListClassification" &&
    manualSelected &&
    !isAll &&
    css`
      &:checked {
        border: 0.1rem solid ${({ theme }) => theme.color.node.blue};
        background-color: white;
        opacity: 0.6;
      }
    `}
`;

export const FilterTagText = styled.span<FilterTagStyleProps>`
  display: flex;
  align-items: center;
  height: 1rem;
  margin-left: 0.8rem;
  color: ${({ theme }) => theme.color.neural.gray08};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;

  ${({ isAll }) =>
    isAll &&
    css`
      margin-left: 0.4rem;
      vertical-align: middle;
      color: ${({ theme }) => theme.color.neural.gray08} !important;
    `}
`;
