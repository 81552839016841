"use client";

import styled, { css } from "styled-components";

export const OptionGroupItem = styled.div<{
  $isSelected: boolean;
  $padding?: string;
  $gap?: string;
  $isCapitalize?: boolean;
  $fontSize?: string;
}>`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: 500;
  overflow-wrap: anywhere;
  text-transform: ${({ $isCapitalize }) =>
    $isCapitalize ? "capitalize" : "none"};
  line-height: 130%;

  display: flex;
  align-items: center;
  padding: ${({ $padding }) =>
    $padding || "var(--Spacing-6, 0.6rem) var(--Spacing-8, 0.8rem)"};
  gap: ${({ $gap }) => $gap || "var(--Spacing-4, 0.4rem)"};
  align-self: stretch;
  width: 100%;

  cursor: pointer;

  &:hover {
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);
  }

  &:active {
    background: var(--Color-Common-Bg-Interactive-Secondary-Pressed, #e5e7eb);
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: var(--Color-Common-Text-Interactive-Selected, #0198e9);
      background: var(--Color-Common-Bg-Interactive-Selected, #e5f7ff);
      path {
        fill: #0198e9;
      }

      &:hover {
        background: var(
          --Color-Common-Bg-Interactive-Selected-Hovered,
          #d1efff
        );
        color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);

        path {
          fill: #017abc;
        }
      }

      &:active {
        background: var(
          --Color-Common-Bg-Interactive-Selected-Pressed,
          #a4dfff
        );
        color: var(--Color-Common-Text-Interactive-Primary-Pressed, #015d8e);
        path {
          fill: #015d8e;
        }
      }
    `}
`;
