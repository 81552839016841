import * as S from "./TopFixInfoList.style";
import TopFixInfoItem from "../../../TopFixInfoItem/TopFixInfoItem";
import { TopFixInfoData } from "../types/topFixInfo.types";

interface Props {
  list: TopFixInfoData[];
  maxWidth: string;
  minWidth: string;
  isFlex?: boolean;
}

const TopFixInfoList = ({ list, maxWidth, minWidth, isFlex }: Props) => {
  const COMPANY_TOOLTIP_STYLE = {
    minWidth: "40rem",
    width: "100%",
  };

  return (
    <S.TopFixInfoListWrapper $maxWidth={maxWidth} $minWidth={minWidth}>
      {list.map(
        ({
          title,
          content,
          subContent,
          maxWidth: itemMaxWidth,
          minWidth: itemMinWidth,
          width: itemWidth,
        }) => (
          <TopFixInfoItem
            key={title}
            title={title}
            content={content}
            maxWidth={itemMaxWidth}
            minWidth={itemMinWidth}
            width={itemWidth}
            isFlex={isFlex}
            subContent={subContent}
            tooltipStyle={
              title === "Collaborator Name" ? COMPANY_TOOLTIP_STYLE : undefined
            }
          />
        ),
      )}
    </S.TopFixInfoListWrapper>
  );
};

export default TopFixInfoList;
