import { useNavigate } from "react-router-dom";

import { FillInTableConcurrentAccessChecker } from "@libs/concurrentAccess/services/fillInTableConcurrentAccessChecker";
import { checkFillInTableLock } from "@libs/concurrentAccess/utils/checkFillInTableLock";
import { useAuthorityType } from "@libs/roles/hooks/useAuthorityType";
import { useEditorRoles } from "@libs/roles/hooks/useEditorRoles";
import { CTGFillInTableRoleChecker } from "@libs/roles/services/ctgFillInTableRoleChecker";
import { ROUTE_PATHS } from "@routes/routePath";

interface Args {
  draftArticleId: string;
  ftEditorName: string | null;
  onAccessDenied: () => void;
  onConcurrentAccessDenied: () => void;
  nctNumber: string;
}

export const useMoveCtgDetailPage = ({
  draftArticleId,
  ftEditorName,
  onAccessDenied,
  onConcurrentAccessDenied,
  nctNumber,
}: Args) => {
  const navigate = useNavigate();
  const editorRoles = useEditorRoles();
  const authorityType = useAuthorityType();

  const moveCtgDetailPage = async () => {
    const pathname = ROUTE_PATHS.ctgFillInTable;
    const url = `${pathname}?id=${draftArticleId}&nctNumber=${nctNumber}`;

    if (pathname === ROUTE_PATHS.ctgFillInTable) {
      const roleChecker = new CTGFillInTableRoleChecker({
        authorityType,
        editorRoles,
      });

      if (roleChecker.canAccess) {
        // 동시 접근 체크를 할때 get을 이용하여 체크하지 않고 post로 바로 선점하고 있음.
        const hasLock = await checkFillInTableLock({
          url: `${window.location.origin}${url}`,
          draftArticleId: draftArticleId.toString(),
          ftEditorName,
        });
        const fillInTableConcurrentAccessChecker =
          new FillInTableConcurrentAccessChecker({
            authorityType,
            ftEditorName,
            hasLock,
          });

        if (fillInTableConcurrentAccessChecker.canAccess) {
          navigate(url);
        } else {
          onConcurrentAccessDenied();
        }
      } else {
        onAccessDenied();
      }
    }
  };

  return {
    moveCtgDetailPage,
  };
};
