import React, { HTMLAttributes } from "react";

import { GroupTableKeyType } from "@/components/business/CTGFillInTableContainer/shared/types/tableCreator.types";
import { isRequiredGroupShell } from "@/components/business/CTGFillInTableContainer/shared/utils/getOptionsFromData";
import { getGroupShellTitle } from "@/components/business/CTGFillInTableContainer/shared/utils/getTextFromData";
import RequiredMark from "@atoms/RequiredMark/RequiredMark";
import { padStart } from "@utils/padStart/padStart";

import * as S from "./TableCreatorGroupShellTitle.style";
import { CTG_SECTION } from "../../constants/articleSection.constants";
import TableCreatorDragButton from "../TableCreatorDragButton/TableCreatorDragButton";

interface Props extends HTMLAttributes<HTMLDivElement> {
  groupShellKey: string | null;
  groupShellCount: number;
  groupShellIndex: number;
  customGroupShellTitle?: string;
  isAlertGroupShell?: boolean;
  onClickHandler?: () => void;
  changeFocusStatusForDragging?: () => void;
  changeFocusStatusForDragEnd?: () => void;
  handleDragStart?: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd?: (
    groupTableKey: GroupTableKeyType,
    tableKey?: string | null,
  ) => void;
  handleDragOn?: () => void;
  isDraggableGroupShell?: boolean;
  isDragging?: boolean;
  groupTableKey?: GroupTableKeyType;
}

const IS_DRAGGED_GROUP_SHELL = ["Rights Scope"];

const TableCreatorGroupShellTitle = ({
  groupTableKey,
  groupShellKey,
  groupShellCount,
  groupShellIndex,
  customGroupShellTitle,
  isAlertGroupShell = false,
  isDraggableGroupShell = false,
  isDragging = false,
  onClickHandler,
  changeFocusStatusForDragEnd,
  changeFocusStatusForDragging,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
  ...rest
}: Props) => {
  const articleSection = CTG_SECTION;
  const isRequired = isRequiredGroupShell(articleSection, groupShellKey);
  const currentGroupShellTitle =
    customGroupShellTitle || getGroupShellTitle(articleSection, groupShellKey);
  const groupShellTitle = () => {
    if (groupShellCount > 1)
      return `${currentGroupShellTitle} ${padStart(groupShellIndex + 1)}`;
    return currentGroupShellTitle;
  };

  return (
    <S.GroupShellTitle
      isAlertGroupShell={isAlertGroupShell}
      isDragging={isDragging}
      onClick={onClickHandler}
      onDragStart={(e) => {
        if (handleDragStart && changeFocusStatusForDragging) {
          handleDragStart(e, groupShellIndex);
          changeFocusStatusForDragging();
        }
      }}
      onDragEnd={() => {
        if (handleDragEnd && changeFocusStatusForDragEnd && groupTableKey) {
          handleDragEnd(groupTableKey);
          changeFocusStatusForDragEnd();
        }
      }}
      onDrag={() => handleDragOn && handleDragOn()}
      draggable={isDraggableGroupShell}
      {...rest}
    >
      <S.TitleTextWrapper>
        {isRequired && <RequiredMark text="*" />}
        {groupShellTitle()}
      </S.TitleTextWrapper>

      <S.RightButtonsWrapper>
        {groupShellCount > 1 &&
          IS_DRAGGED_GROUP_SHELL.includes(groupShellKey || "") && (
            <TableCreatorDragButton
              isDragging={isDragging}
              dragContainer="groupShell"
              onClick={(e) => {
                e.stopPropagation();
                if (changeFocusStatusForDragging) {
                  changeFocusStatusForDragging();
                }
              }}
            />
          )}
      </S.RightButtonsWrapper>
    </S.GroupShellTitle>
  );
};

export default TableCreatorGroupShellTitle;
