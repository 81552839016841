export const ENTRY_DATA_LICENSEE_TABLE = "Licensee Table" as const;

export const ENTRY_DATA_LICENSEE_TABLE_KEYS = {
  licenseeName: "Licensee Name",
  strategicCollaborationLicenseAgreementTerms:
    "Strategic Collaboration & License Agreement terms",
  mergeAcquisitionTerms: "Merge & Acquisition terms",
  rightsScope: "Rights Scope",
  ventureFormationTerms: "Venture Formation terms",
} as const;

export const ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS = {
  licenseeResponsibilityAndRightsScLa:
    "Licensee Responsibility & Rights (SC & LA)",
  upfrontPayment: "Upfront Payment",
  milestonePaymentAndCondition: "Milestone Payment and Condition",
  otherPaymentAndDetailScLa: "Other Payment and Detail (SC & LA)",
  royalty: "Royalty",
  totalPaymentScLa: "Total Payment (SC & LA)",
  otherFinancialTermsScLa: "Other Financial Terms (SC & LA)",
  disclosedPaymentsSumScLa: "Disclosed Payments Sum (SC & LA)",
} as const;

export const ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS = {
  milestonePayment: "Milestone Payment",
  milestoneConditionTitle: "Milestone Condition Title",
} as const;

export const ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS = {
  otherPaymentScLa: "Other Payment (SC & LA)",
  otherPaymentDetailScLa: "Other Payment Detail (SC & LA)",
} as const;

export const ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS = {
  licenseResponsibilityRightsMa: "Licensee Responsibility & Rights (MA)",
  numberOfAcquiringShares: "Number of acquiring shares",
  pricePerShare: "Price Per Share",
  totalPaymentMa: "Total Payment (MA)",
  upfrontPaymentMa: "Upfront Payment (MA)",
  upfrontPricePerShareMa: "Upfront Price Per Share (MA)",
  contingentPayment: "Contingent Payment",
  mergerFinancialTermsMa: "Merger Financial Terms (MA)",
  otherFinancialTermsMa: "Other Financial Terms (MA)",
  disclosedPaymentsSumMa: "Disclosed Payments Sum (MA)",
  disclosedPaymentsSumPricePerShareMa:
    "Disclosed Payments Sum Price Per Share (MA)",
} as const;

export const ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS = {
  contingentInvestmentAndDetailVf: "Contingent Investment and Detail (VF)",
  contingentInvestmentVf: "Contingent Investment (VF)",
  contingentDetailVf: "Contingent Detail (VF)",
  disclosedPaymentsSumVf: "Disclosed Payments Sum (VF)",
  initialInvestment: "Initial Investment",
  licenseeResponsibilityAndRightsVf: "Licensee Responsibility & Rights (VF)",
  otherFinancialTermsVf: "Other Financial Terms (VF)",
  ownershipStructureDetails: "Ownership Structure Details",
  totalInvestmentVf: "Total Investment (VF)",
} as const;

export const ENTRY_DATA_CONTINGENT_PAYMENT_KEYS = {
  contingentPaymentMa: "Contingent Payment (MA)",
  contingentPricePerShareMa: "Contingent Price per share (MA)",
  contingentMilestoneMa: "Contingent Milestone (MA)",
} as const;

export const ENTRY_DATA_RIGHTS_SCOPE_KEYS = {
  drug: "Drug",
  indication: "Indication",
  exclusivity: "Exclusivity",
  territory: "Territory",
} as const;

export const ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS = {
  ...ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ...ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  ...ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS,
  ...ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS,
  ...ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS,
  ...ENTRY_DATA_CONTINGENT_PAYMENT_KEYS,
  ...ENTRY_DATA_RIGHTS_SCOPE_KEYS,
  ...ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS,
} as const;
