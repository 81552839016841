import styled, { css } from "styled-components";

import { UnsuitableReasonModalPosition } from "../../types/unsuitableReason.types";

export const PressUnsuitableReasonContainer = styled.div<{
  isOpenModalUnsuitablePress: boolean;
  $modalPosition?: UnsuitableReasonModalPosition;
}>`
  position: absolute;
  bottom: 4rem;
  left: -0.1rem;
  width: 40rem;
  overflow: hidden;
  border-radius: 0.4rem;
  border: 1px solid var(--Color-Border-Secondary, #b1b8c4);
  background: var(--Color-Bg-Primary, #fff);
  padding: 2rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);

  z-index: 10000;

  ${({ isOpenModalUnsuitablePress }) =>
    isOpenModalUnsuitablePress
      ? css`
          height: fit-content;
          opacity: 1;
        `
      : css`
          height: 0;
          opacity: 0;
        `}

  ${({ $modalPosition }) => {
    return (
      !!$modalPosition &&
      css`
        top: ${$modalPosition.top};
        left: ${$modalPosition.left};
        right: ${$modalPosition.right};
        bottom: ${$modalPosition.bottom};
      `
    );
  }}
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.6rem;
`;

export const Text = styled.p`
  color: var(--grayscale-spectrum-neural-blue-gray-06, #8c98aa);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

export const ReasonCheckListBox = styled.div`
  flex: 1;
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
`;
