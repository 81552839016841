import { useQuery } from "@tanstack/react-query";

import { getTargetIndicationTherapeuticArea } from "./targetIndicationTherapeuticArea.service";
import { QUERY_KEYS } from "../queryKeys";

export const useGetTargetIndicationTherapeuticAreaQuery = (
  targetIndications: string[],
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.targetIndicationTherapeuticArea, targetIndications],
    queryFn: () => getTargetIndicationTherapeuticArea(targetIndications),
  });
};
