import { ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS } from "@frontend/common";

import { CTGEntryData } from "@/types/ctgEntryData.types";
import { DrugProfileValue } from "@/types/drugProfile.types";

import { CTG_SECTION } from "./articleSection.constants";
import { moveItemToPosition } from "../utils/changeEntryDataOrder";
import { dragChangeDrugProfileValueOrder } from "../utils/dragChangeDrugProfileValueOrder";

type DragInfoType = [number | null, number | null];

export const ENTRY_DATA_TO_CHANGE_TABLE_ORDER: Record<
  string,
  Record<
    string,
    (data: CTGEntryData, draggedIndexInfo: DragInfoType) => CTGEntryData
  >
> = {
  [CTG_SECTION]: {
    "Drug Profile": (data, draggedIndexInfo) => {
      return {
        ...data,
        [CTG_SECTION]: {
          ...data?.[CTG_SECTION],
          "Drug Profile": moveItemToPosition(
            data?.[CTG_SECTION]?.["Drug Profile"] || [],
            draggedIndexInfo[0] || 0,
            draggedIndexInfo[1] || 0,
          ) as DrugProfileValue[],
        },
      } as CTGEntryData;
    },
  },
};

type IndexType = {
  tableIndex: number;
  groupShellIndex: number;
  subGroupShellIndex?: number | null;
};

export const ENTRY_DATA_TO_CHANGE_VALUE_ORDER: Record<
  string,
  Record<
    string,
    (
      data: CTGEntryData,
      draggedIndexInfo: DragInfoType,
      positionIndex?: IndexType,
      isLicenseAgreement?: boolean,
    ) => CTGEntryData
  >
> = {
  [CTG_SECTION]: {
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: CTG_SECTION,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName,
        tableIndex: positionIndex?.tableIndex,
      }),
  },
};
