import React, { useState } from "react";

import {
  UNSUITABLE_LIST_BY_TYPE,
  UNSUITABLE_REASON_DEFAULT_LIST_BY_TYPE,
  UnsuitableReasonList,
} from "../components/UnsuitableReasonModalContainer/components/UnsuitableReasonCheckItem/constants/UnsuitableReason.constants";
import { UnsuitableReasonUsageType } from "../types/unsuitableReason.types";

interface UseHandleUnsuitablePressReturn {
  isOpenModalUnsuitablePress: boolean;
  closeModalUnsuitablePress: () => void;
  openModalUnsuitablePress: () => void;
  draftArticleStatus?: string;
  selectedUnsuitablePressReason: string | null;
  setSelectedUnsuitablePressReason: React.Dispatch<
    React.SetStateAction<string>
  >;
  unsuitablePressReasonList: UnsuitableReasonList[];
  targetUnsuitableReasonList: UnsuitableReasonList[];
  targetDefaultReasonList: string[];
  setUnsuitablePressReasonList: React.Dispatch<
    React.SetStateAction<UnsuitableReasonList[]>
  >;
}

type Props = {
  type: UnsuitableReasonUsageType;
};

const checkUnsuitableReasonListByType = (
  usageType: UnsuitableReasonUsageType,
) => {
  return UNSUITABLE_LIST_BY_TYPE[usageType];
};

const checkUnsuitableDefaultReasonListByType = (
  usageType: UnsuitableReasonUsageType,
) => {
  return UNSUITABLE_REASON_DEFAULT_LIST_BY_TYPE[usageType];
};
export const useHandleUnsuitablePress = ({
  type,
}: Props): UseHandleUnsuitablePressReturn => {
  const targetUnsuitableReasonList = checkUnsuitableReasonListByType(type);
  const targetDefaultReasonList = checkUnsuitableDefaultReasonListByType(type);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [selectedUnsuitablePressReason, setSelectedUnsuitablePressReason] =
    useState<string>("");
  const [unsuitablePressReasonList, setUnsuitablePressReasonList] = useState<
    UnsuitableReasonList[]
  >(targetUnsuitableReasonList);

  return {
    isOpenModalUnsuitablePress: isOpenModal,
    closeModalUnsuitablePress: () => setIsOpenModal(false),
    openModalUnsuitablePress: () => setIsOpenModal(true),
    selectedUnsuitablePressReason,
    setSelectedUnsuitablePressReason,
    unsuitablePressReasonList,
    setUnsuitablePressReasonList,
    targetUnsuitableReasonList,
    targetDefaultReasonList,
  };
};
