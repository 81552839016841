import styled, { css } from "styled-components";

import {
  BorderWrapper,
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
  DefaultShellWrapper,
} from "@/components/business/CTGFillInTableContainer/shared/styles/shell.style";

export const ShellAddWrapper = styled.div<{ isHideComponent?: boolean }>`
  position: relative;

  ${({ isHideComponent }) =>
    isHideComponent &&
    css`
      width: 0;
      height: 0;
      overflow: hidden;
    `}
`;
export const ShellWrapper = styled(DefaultShellWrapper)``;

export const ShellBorderWrapper = styled(BorderWrapper)`
  ${({ isSeparatedShellTitle }) =>
    isSeparatedShellTitle &&
    css`
      padding: 2.4rem 0.8rem 0.8rem 0.8rem;
    `}
`;

export const DeleteTableButtonWrapper = styled(DefaultDeleteTableButtonWrapper)`
  &.button-reposition-medium-shell-true {
    left: 148px;
  }
`;
export const DeleteTableButton = styled(DefaultDeleteTableButton)``;

export const EmptyContentEditable = styled.div`
  width: 100%;
  height: 1px;
  outline: none;
  padding: 0;
  opacity: 0;
`;

export const ModalityShellRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 0.8rem;
`;

export const ModalityShellColumn = styled.div<{ $isMultipleValue?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;

  &:first-child {
    max-width: ${({ $isMultipleValue }) =>
      $isMultipleValue ? "25.9rem" : "27.4rem"};
    .filled-text-field {
      height: 100%;
    }
  }

  &.is-modality-shell-value-empty-true {
    max-width: none;
  }
`;
