import { ProcessStatusCode } from "@/types/code.types";

export type CtgListPressInfoFilterKey =
  | "allInfo"
  | "nctId"
  | "ctgTitle"
  | "companyName";

export interface CtgListPressInfoFilterType {
  key: CtgListPressInfoFilterKey;
  name: string;
  isAllInfo: boolean;
  code?: ProcessStatusCode;
}

export const CTG_LIST_INFO_FILTER: CtgListPressInfoFilterType[] = [
  {
    key: "allInfo",
    name: "All Info",
    isAllInfo: true,
  },
  {
    key: "nctId",
    name: "NCT No",
    isAllInfo: false,
  },
  {
    key: "ctgTitle",
    name: "CTG Title",
    isAllInfo: false,
  },
  {
    key: "companyName",
    name: "Company",
    isAllInfo: false,
  },
];

export const CTG_LIST_PRESS_INFO_FILTER_KEY = [
  "allInfo",
  "nctId",
  "ctgTitle",
  "companyName",
] as const;
