import { ARTICLE_CONTENTS_DEAL_TERMS_KEYS as DEAL_TERMS_KEYS } from "@frontend/common";

import { DealTermsContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/dealTermsContent.types";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

const getTargetPartnerLicenseeTable = (dealTerms: DealTermsContent) => {
  const LICENSEE_TABLE_PATH =
    dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable];
  const LICENSEE_TABLE_INDEX = 0;

  return LICENSEE_TABLE_PATH.at(LICENSEE_TABLE_INDEX);
};

export const licensorNameText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licensorTable][
        DEAL_TERMS_KEYS.licensorName
      ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licensorResponsibilityText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licensorTable][
        DEAL_TERMS_KEYS.licensorResponsibilityRights
      ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licenseeNameText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      getTargetPartnerLicenseeTable(dealTerms)?.[
        DEAL_TERMS_KEYS.licenseeName
      ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licenseeResponsibilityText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      getTargetPartnerLicenseeTable(dealTerms)?.[
        DEAL_TERMS_KEYS.ventureFormationTerms
      ][DEAL_TERMS_KEYS.licenseeResponsibilityRightsVf]?.value?.map(
        (item) => item.text ?? "",
      ) ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licenseeInitialInvestmentText = (dealTerms: DealTermsContent) =>
  getTargetPartnerLicenseeTable(dealTerms)?.[
    DEAL_TERMS_KEYS.ventureFormationTerms
  ][DEAL_TERMS_KEYS.initialInvestment].value.at(0)?.text ?? "";

export const licenseeContingentInvestmentText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      getTargetPartnerLicenseeTable(dealTerms)?.[
        DEAL_TERMS_KEYS.ventureFormationTerms
      ][DEAL_TERMS_KEYS.contingentInvestmentAndDetailVf]?.map((item) =>
        [
          item[DEAL_TERMS_KEYS.contingentInvestmentVf].value
            .at(0)
            ?.text?.trim() ?? "",
          item[DEAL_TERMS_KEYS.contingentInvestmentDetailVf]?.value
            ?.at(0)
            ?.text?.trim()
            ? `(${item[DEAL_TERMS_KEYS.contingentInvestmentDetailVf]?.value
                ?.at(0)
                ?.text?.trim()})`
            : "",
        ]
          .filter((text) => text !== "")
          .join(" "),
      ) ?? [],
    parseString: (text) => `- ${text}`,
    bulletStartIndex: 1,
  });

export const licenseeTotalInvestmentText = (dealTerms: DealTermsContent) =>
  getTargetPartnerLicenseeTable(dealTerms)?.[
    DEAL_TERMS_KEYS.ventureFormationTerms
  ][DEAL_TERMS_KEYS.totalInvestmentVf].value.at(0)?.text ?? "";

export const licenseeOwnershipStructureDetailsText = (
  dealTerms: DealTermsContent,
) =>
  createMultiLineText({
    textList:
      getTargetPartnerLicenseeTable(dealTerms)?.[
        DEAL_TERMS_KEYS.ventureFormationTerms
      ][DEAL_TERMS_KEYS.ownershipStructureDetails].value.map(
        (item) => item.text ?? "",
      ) ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licenseeOtherFinancialTermsText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      getTargetPartnerLicenseeTable(dealTerms)?.[
        DEAL_TERMS_KEYS.ventureFormationTerms
      ][DEAL_TERMS_KEYS.otherFinancialTermsVf].value.map(
        (item) => item.text ?? "",
      ) ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });
