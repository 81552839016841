export const EDITOR_ROLES = {
  EDTK0100: "EDTK0100",
  EDTK0201: "EDTK0201",
  EDTK0202: "EDTK0202",
  EDTK0204: "EDTK0204",
  EDTK0303: "EDTK0303",
  EDTK0304: "EDTK0304",
  EDTK0401: "EDTK0401",
  EDTK0402: "EDTK0402",
  EDTK0404: "EDTK0404",
  EDTK0503: "EDTK0503",
  EDTK0504: "EDTK0504",
  EDTK0603: "EDTK0603",
  EDTK0604: "EDTK0604",
  EDTK0703: "EDTK0703",
  EDTK0704: "EDTK0704",
  EDTK0803: "EDTK0803",
  EDTK0804: "EDTK0804",
  EDTK0903: "EDTK0903",
} as const;

export const EDITOR_ROLES_KEYS = [
  EDITOR_ROLES.EDTK0100,
  EDITOR_ROLES.EDTK0201,
  EDITOR_ROLES.EDTK0202,
  EDITOR_ROLES.EDTK0204,
  EDITOR_ROLES.EDTK0303,
  EDITOR_ROLES.EDTK0304,
  EDITOR_ROLES.EDTK0401,
  EDITOR_ROLES.EDTK0402,
  EDITOR_ROLES.EDTK0404,
  EDITOR_ROLES.EDTK0503,
  EDITOR_ROLES.EDTK0504,
  EDITOR_ROLES.EDTK0603,
  EDITOR_ROLES.EDTK0604,
  EDITOR_ROLES.EDTK0703,
  EDITOR_ROLES.EDTK0704,
  EDITOR_ROLES.EDTK0803,
  EDITOR_ROLES.EDTK0804,
  EDITOR_ROLES.EDTK0903,
] as const;
