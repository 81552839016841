import React from "react";

import { useHandleTableCreatorWrapperPositionInfo } from "@/components/business/CTGFillInTableContainer/shared/hooks/useHandleTableCreatorWrapperPositionInfo";

import FillInTableNavigation from "./components/FillInTableNavigation/FillInTableNavigation";
import { CTG_NAVIGATION_LIST } from "./constants/fillInTableNavigationList.constants";
import * as S from "./TableCreatorNavigationTableWrapper.style";

interface Props {
  children: JSX.Element;
}

const TableCreatorNavigationTableWrapper = ({ children }: Props) => {
  const { tableCreatorWrapperRef } = useHandleTableCreatorWrapperPositionInfo();

  const currentNavigationList = CTG_NAVIGATION_LIST;
  return (
    <S.TableCreatorWrapper>
      <FillInTableNavigation itemList={currentNavigationList} />
      <S.GroupTableWrapper ref={tableCreatorWrapperRef}>
        {children}
      </S.GroupTableWrapper>
    </S.TableCreatorWrapper>
  );
};

export default TableCreatorNavigationTableWrapper;
