import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
} from "@frontend/common";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { useFillInTableRoles } from "@libs/roles/hooks/useFillInTableRoles";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { ENABLE_GROUP_TABLE_KEY_LIST } from "../constants/tableCreatorGroupTable.constants";

import type { GroupTableKeyType } from "../types/tableCreator.types";

export const useCanEditGroupTable = () => {
  const articleSection = useArticleSection();
  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const { hasEditHalfTableRoles, hasEditFullTableRoles } =
    useFillInTableRoles();

  const checkTableRolesFromTableList = (groupTableKey: GroupTableKeyType) => {
    if (
      articleSection === SECTION_KEYS.clinicalTrial ||
      articleSection === SECTION_KEYS.regulatory ||
      articleSection === SECTION_KEYS.expeditedProgram
    ) {
      if (groupTableKey === DRUG_PROFILE) {
        return hasEditHalfTableRoles;
      }
      return hasEditFullTableRoles;
    }
    if (articleSection === SECTION_KEYS.clinicalTrialStatus) {
      return hasEditHalfTableRoles;
    }

    if (
      articleSection === SECTION_KEYS.deals ||
      articleSection === SECTION_KEYS.ventureCapital
    ) {
      return hasEditFullTableRoles;
    }
  };

  const checkDisableEdit = (groupTableKey: GroupTableKeyType) => {
    if (!articleInfo)
      return false || !checkTableRolesFromTableList(groupTableKey);

    const enableGroupTableKeyList = ENABLE_GROUP_TABLE_KEY_LIST[articleSection];

    const isEnableEditByStatus = enableGroupTableKeyList[
      groupTableKey as keyof typeof enableGroupTableKeyList
    ].includes(articleInfo.draftArticleStatus);

    return (
      !isEnableEditByStatus || !checkTableRolesFromTableList(groupTableKey)
    );
  };

  return {
    checkDisableEdit,
  };
};
