import { useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import { AUTHORITY_TYPE } from "@libs/roles/constants/authorityType.constants";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useAuthorityType } from "./useAuthorityType";
import { useEditorRoles } from "./useEditorRoles";
import { checkEditFullTableRoles } from "../util/checkEditFullTableRoles";
import { checkFullButtonRoles } from "../util/checkFullButtonRoles";

export const useCTGFillInTableRoles = () => {
  const { data: articleInfo } = useCTGInfoQuery(Number(getPressIdFromURL()));
  const editorRoles = useEditorRoles();
  const authorityType = useAuthorityType();
  const isSuperAdmin = authorityType === AUTHORITY_TYPE.superAdmin;

  const status = articleInfo?.draftArticleStatus;
  const classification = "CTG";
  const fillInTableEditor2 = articleInfo?.ftEditor2Name;

  if (!status)
    return {
      hasEditFullTableRoles: false,
      hasFullButtonRoles: false,
    };

  const hasEditFullTableRoles = checkEditFullTableRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
    fillInTableEditor2,
  );

  const hasFullButtonRoles = checkFullButtonRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
    fillInTableEditor2,
  );

  return {
    hasEditFullTableRoles,
    hasFullButtonRoles,
    isSuperAdmin,
  };
};
