import { useCallback, useEffect, useRef } from "react";

import {
  getArticleInfoData,
  useArticleInfoMutation,
} from "@api/articleInfo/useArticleInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { FILL_IN_TABLE_KEY } from "@FillInTableContainer/shared/constants/fillInTableKey";
import { pageEntryApis } from "@services/pageEntry.service";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useLeaveFillInTablePageEffect = async () => {
  const draftArticleId = useRef(Number(getPressIdFromURL()));
  const { mutate: updateArticleInfo } = useArticleInfoMutation();
  const articleSection = useArticleSection();
  const path = window.location.href;

  const handleLeavePage = useCallback(() => {
    const articleInfo = getArticleInfoData(draftArticleId.current);
    if (!articleInfo) return;

    const status = articleInfo.data.draftArticleStatus;
    const editor1 = articleInfo.data.ftEditorName;
    const editor2 = articleInfo.data.ftEditor2Name;

    if (
      articleSection === FILL_IN_TABLE_KEY.ClinicalTrials ||
      articleSection === FILL_IN_TABLE_KEY.ClinicalTrialStatus ||
      articleSection === FILL_IN_TABLE_KEY.ExpeditedProgram ||
      articleSection === FILL_IN_TABLE_KEY.Regulatory
    ) {
      if (
        status === DRAFT_STATUS_CODE.reviewInProgressHalf &&
        !editor1 &&
        !editor2
      ) {
        updateArticleInfo({
          draftArticleStatus: DRAFT_STATUS_CODE.aiEntryDone,
        });
      }

      if (
        status === DRAFT_STATUS_CODE.reviewInProgress &&
        editor1 &&
        !editor2
      ) {
        updateArticleInfo({
          draftArticleStatus: DRAFT_STATUS_CODE.dbSavedHalf,
        });
      }
    } else if (
      status === DRAFT_STATUS_CODE.reviewInProgress &&
      !editor1 &&
      !editor2
    ) {
      updateArticleInfo({
        draftArticleStatus: DRAFT_STATUS_CODE.aiEntryDone,
      });
    }

    pageEntryApis.delete(path);
  }, [articleSection, updateArticleInfo, path]);

  // 앱 내부에서 다른 페이지로 이동할 때
  useEffect(() => {
    return () => {
      handleLeavePage();
    };
  }, [handleLeavePage]);

  // 화면을 종료할때
  useEffect(() => {
    window.addEventListener("beforeunload", handleLeavePage);
    return () => {
      window.removeEventListener("beforeunload", handleLeavePage);
    };
  }, [handleLeavePage]);
};
