import styled from "styled-components";

import { mq } from "@styles/mediaQuery";

export const HeaderWrapper = styled.header`
  position: fixed;
  width: 100%;
  height: 4rem;
  border: 1px solid ${({ theme }) => theme.color.neural.gray02};
  background-color: #fff;
  padding: 0;
  z-index: 30;
  padding: var(--Spacing-0, 0rem) var(--Spacing-16, 1.6rem);
  font-family: var(--font-pretendard);
`;

export const HeaderCenterBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  transition: all 0.7s ease-in-out;
  width: 100%;
  //max-width: calc(100% - 8rem);

  // 반응형 스타일 적용
  /* ${mq[1]} {
    max-width: calc(100% - 4rem);
  } */
`;

export const HeaderColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
`;

export const HeaderText = styled.div`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  cursor: pointer;
  padding-right: 1.6rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  white-space: nowrap;
`;

export const Divider = styled.div`
  border-right: 1px solid ${({ theme }) => theme.color.neural.gray02};
  height: 100%;
`;

const tapTextStyle = `
  display: flex;
  height: 4rem;
  padding: var(--Spacing-0, 0rem) var(--Spacing-16, 1.6rem);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  border-bottom: 2px solid transparent;`;

export const PressText = styled.div<{ focus: boolean }>`
  margin-left: 1.6rem;
  ${tapTextStyle}
  box-sizing: border-box;

  ${({ focus }) =>
    focus &&
    `
    border-bottom: 2px solid #019eE9;
    color: #0198e9;

      &:hover {
        border-bottom: 2px solid #017ABC;
        color: #017ABC;
      }

      &:active {
        border-bottom: 2px solid #015D8E;
        color: #015D8E;
      }
  `}

  ${({ focus }) =>
    !focus &&
    `
    color: #515C6C;

      &:hover {
        border-bottom: 2px solid #96A1B0;
        color: #96A1B0;
      }

      &:active {
        border-bottom: 2px solid ##7C899C;
        color: #7C899C;
}
      
  `}
`;

export const CTGText = styled.div<{ focus: boolean }>`
  ${tapTextStyle}
  box-sizing: border-box;

  ${({ focus }) =>
    focus &&
    `
    border-bottom: 2px solid #019eE9;
    color: #0198e9;

      &:hover {
        border-bottom: 2px solid #017ABC;
        color: #017ABC;
      }

      &:active {
        border-bottom: 2px solid #015D8E;
        color: #015D8E;
      }
  `}

  ${({ focus }) =>
    !focus &&
    `
    color: #515C6C;

      &:hover {
        border-bottom: 2px solid #96A1B0;
        color: #96A1B0;
      }

      &:active {
        border-bottom: 2px solid ##7C899C;
        color: #7C899C;
}
  `}
`;

export const Date = styled.strong`
  color: var(--Color-Common-Text-Tertiary, #7c899c);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  padding-right: 1.6rem;
`;

export const SignOutButton = styled.button`
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  text-align: center;
  padding-left: 1.6rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  cursor: pointer;
`;
