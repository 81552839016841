import React, { Dispatch, SetStateAction, useState } from "react";

import { CtgSearchFilterType } from "@/components/business/CtgListContainer/ctgList.types";
import SearchInput from "@atoms/SearchInput/SearchInput";
import { useCtgListFilterState } from "@stores/ctgList";

import {
  CTG_LIST_EDITOR_INFO_FILTER_KEY,
  CTG_LIST_EDITOR_INFO_FILTER,
} from "./constants/editorInfoFilter.constants";
import * as S from "./CtgListEditorInfoSearch.style";
import CtgListSearchFilterSelect from "../CtgListPressInfoSearch/components/CtgListSearchFilterSelect/CtgListSearchFilterSelect";

interface Props {
  currentEditorFilter: CtgSearchFilterType;
  setCurrentFilter: Dispatch<SetStateAction<CtgSearchFilterType>>;
}

const CtgListEditorInfoSearch = ({
  currentEditorFilter,
  setCurrentFilter,
}: Props) => {
  const [ctgListFilter, setCtgListFilter] = useCtgListFilterState();
  const [typing, setTyping] = useState("");

  const { search } = ctgListFilter;

  const handleSearchKeyboard = () => {
    const currentInput = typing.trim();

    const newSearch = { ...search };
    CTG_LIST_EDITOR_INFO_FILTER_KEY.forEach((key) => delete newSearch[key]);
    newSearch[currentEditorFilter.key] = currentInput;

    if (currentInput) {
      setCtgListFilter({
        ...ctgListFilter,
        search: newSearch,
        page: 1,
      });
    }
  };

  const handleSearchButton = () => {
    const currentInput = typing || search?.[currentEditorFilter.key];

    const newSearch = { ...search };
    CTG_LIST_EDITOR_INFO_FILTER_KEY.forEach((key) => delete newSearch[key]);
    newSearch[currentEditorFilter.key] = currentInput;

    if (currentInput) {
      setCtgListFilter({
        ...ctgListFilter,
        search: newSearch,
        page: 1,
      });
    }
  };

  return (
    <S.EditorInfoSearchWrapper>
      <CtgListSearchFilterSelect
        subTitle="Editor Filter"
        filterList={CTG_LIST_EDITOR_INFO_FILTER}
        currentFilter={currentEditorFilter}
        setCurrentFilter={setCurrentFilter}
        isEditor
      />
      <S.EditorInfoSearchTitle>
        <SearchInput
          placeholder="Search Editors"
          onSearchButton={handleSearchButton}
          handleSearchKeyboard={handleSearchKeyboard}
          typing={typing}
          setTyping={setTyping}
        />
      </S.EditorInfoSearchTitle>
    </S.EditorInfoSearchWrapper>
  );
};

export default CtgListEditorInfoSearch;
