"use client";

import styled from "styled-components";

export const DealTypeChangeButton = styled.button`
  display: flex;
  max-height: var(--Size-Btn-Lg, 4.8rem);
  height: 2rem;
  padding: 0 0.3rem;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-Btn-2, 0.2rem);

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);

  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  white-space: nowrap;

  font-family: var(--font-pretendard);
  font-size: 1.1rem;
  font-weight: 500;

  > svg {
    width: 1.6rem;
    height: 1.6rem;
  }

  &:hover {
    color: var(--Color-Common-Text-Interactive-Secondary-Hovered, #3d4551);

    border: 1px solid
      var(--Color-Common-Border-Interactive-Secondary-Hovered, #96a1b0);
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);

    > svg {
      path {
        fill: #3d4551;
      }
    }
  }

  &:active {
    color: var(--Color-Common-Text-Interactive-Secondary-Pressed, #2a2f37);

    border: 1px solid
      var(--Color-Common-Border-Interactive-Secondary-Pressed, #7c899c);
    background: var(--Color-Common-Bg-Interactive-Secondary-Pressed, #e5e7eb);

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
      0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;

    > svg {
      path {
        fill: #2a2f37;
      }
    }
  }

  &:disabled {
    border-radius: var(--Border-Radius-4, 0.4rem);
    border: 1px solid var(--Color-Common-Border-Disabled, #b1b8c4);
    background: var(--Color-Common-Bg-Primary, #fff);
    color: #96a1b0;
    pointer-events: none;
    > svg {
      path {
        fill: #96a1b0;
      }
    }
  }
`;
