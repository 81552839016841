import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";
import { useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import { NotCorrectDataFormatError } from "@pages/FillInTablePage/errors/classes/notCorrectDataFormat";
import { useCTGEntryDataValue } from "@stores/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { getCTGEntryDataFormatCheckList } from "../utils/initialEntryDataFormat/initialEntryDataFormatDrugProfile";

export const useCheckInitialEntryDataFormat = () => {
  const entryData = useCTGEntryDataValue(getPressIdFromURL());
  const { data: articleInfo } = useCTGInfoQuery();

  const articleSection = articleInfo ? CTG_SECTION : "none";

  const checkClassificationEntryDataFormat = () => {
    if (articleSection === "none") return ["Article section not found."];

    if (articleSection === CTG_SECTION)
      return getCTGEntryDataFormatCheckList(entryData);

    return [];
  };

  const checkData = checkClassificationEntryDataFormat();

  const checkDataLength = checkData.length;
  if (checkDataLength > 0) {
    throw new NotCorrectDataFormatError(checkData);
  }
};
