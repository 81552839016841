/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY,
  CLINICAL_TRIAL_DESIGN_TABLE_KEY,
  CROSS_LICENSE_AGREEMENT_TABLE_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_CONTINGENT_PAYMENT_KEYS,
  ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS,
  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS,
  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  VENTURE_FORMATION_TABLE_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { createRandomString } from "@organisms/TableCreatorComment/components/CommentModalContainer/components/CommentModalMessageComposer/utils/createRandomString";

import { EXPEDITED_PROGRAM } from "./expeditedProgramKey.constants";
import { ShellColumnSize } from "../types/shellSize.types";

export const TABLE_CREATOR_TABLE: Record<
  string,
  {
    key: string;
    title: string;
    isRequired: boolean;
    isReviseTable: boolean;
    isTableScroll: boolean;
    columnSize: ShellColumnSize;
  }[]
> = {
  "Clinical Trials": [
    {
      key: "Drug",
      title: "Drug",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: CLINICAL_TRIAL_DESIGN_TABLE_KEY,
      title: "Clinical Design",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Data",
      title: "Table",
      isRequired: true,
      isReviseTable: true,
      isTableScroll: true,
      columnSize: "extraSmall",
    },
  ],
  "Clinical Trial Status": [
    {
      key: "Drug",
      title: "Drug",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Clinical Design",
      title: "Clinical Design",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
  ],
  Regulatory: [
    {
      key: "Drug",
      title: "Drug",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Approval Process",
      title: "",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
  ],
  [EXPEDITED_PROGRAM]: [
    {
      key: "Drug",
      title: "Drug",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Expedited Program Contents",
      title: "",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
  ],
  Deals: [
    {
      key: "Deal Purpose",
      title: "Deal Purpose",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Licensor",
      title: "Licensor/Originator",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Licensee",
      title: "Licensee/Partner",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Originator",
      title: "Originator",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Partner",
      title: "Partner",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Acquired Company",
      title: "Acquired Company",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Acquiring Company",
      title: "Acquiring Company",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Drug",
      title: "Drug",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: "Additional Information",
      title: "Additional Information",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
    // Cross License Table
    {
      key: CROSS_LICENSE_AGREEMENT_TABLE_KEYS.licensee,
      title: "Licensing partner",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: CROSS_LICENSE_AGREEMENT_TABLE_KEYS.rightsScope,
      title: "Rights scope",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: CROSS_LICENSE_AGREEMENT_TABLE_KEYS.financialTerms,
      title: "Main Financial Terms",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: CROSS_LICENSE_AGREEMENT_TABLE_KEYS.otherFinancialTerms,
      title: "Other Financial Terms",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: VENTURE_FORMATION_TABLE_KEYS.newCompany,
      title: "New Company",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
    {
      key: VENTURE_FORMATION_TABLE_KEYS.existingCompany,
      title: "Existing Company",
      isRequired: false,
      isReviseTable: false,
      isTableScroll: false,
      columnSize: "regular",
    },
  ],
  [SECTION_KEYS.ventureCapital]: [
    {
      key: "Drug",
      title: "Drug",
      isRequired: false,
      isReviseTable: true,
      isTableScroll: false,
      columnSize: "regular",
    },
  ],
};

export const BASIC_DATA_FOR_ADDING_TABLES = {
  "Clinical Trials": {
    "Drug Profile": (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const drugProfileTableData =
        data?.["Clinical Trials"]?.["Drug Profile"]?.[0];

      if (!drugProfileTableData) return;
      return {
        ROA: {
          value: [],
          options: drugProfileTableData?.ROA?.options,
          attributionId: createAttributionId(),
        },
        Modality: {
          value: [],
          options: drugProfileTableData?.Modality?.options,
          attributionId: createAttributionId(),
        },
        Target: {
          value: [],
          options: drugProfileTableData?.Target?.options,
          attributionId: createAttributionId(),
        },
        MOA: {
          value: [],
          options: drugProfileTableData?.MOA?.options,
          attributionId: createAttributionId(),
        },
        "Code Name": {
          value: [],
          options: drugProfileTableData?.["Code Name"]?.options,
          attributionId: createAttributionId(),
        },
        "INN Name": {
          value: [],
          options: drugProfileTableData?.["INN Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Brand Name": {
          value: [],
          options: drugProfileTableData?.["Brand Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Target Indication": {
          value: [],
          options: drugProfileTableData?.["Target Indication"]?.options,
          attributionId: createAttributionId(),
        },
        "Therapeutic Area": {
          value: [],
          options: drugProfileTableData?.["Therapeutic Area"]?.options,
          attributionId: createAttributionId(),
        },
        "Generic / Biosimilar": {
          value: ["false"],
          options: drugProfileTableData?.["Generic / Biosimilar"]?.options,
          attributionId: createAttributionId(),
        },
        "Ref.Product Name": {
          value: [],
          options: drugProfileTableData?.["Ref.Product Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Other Characteristics": {
          value: [],
          options: drugProfileTableData?.["Other Characteristics"]?.options,
          attributionId: createAttributionId(),
        },
        "Originator/Developer": {
          value: [],
          options: drugProfileTableData?.["Originator/Developer"]?.options,
          attributionId: createAttributionId(),
        },
        Phase: {
          value: [],
          options: drugProfileTableData?.Phase?.options,
          attributionId: createAttributionId(),
        },
        attributionInfo: {
          attributionId: createAttributionId(),
        },
      };
    },

    "Clinical Endpoints (Table)": (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const endpointsTableData =
        data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[0];

      if (!endpointsTableData) return;
      return {
        "Endpoints Table Title": {
          value: [],
          options: endpointsTableData?.["Endpoints Table Title"]?.options,
          attributionId: createAttributionId(),
        },
        "Endpoints Table Information": [
          {
            "Endpoint Classification": {
              value: [],
              options:
                endpointsTableData["Endpoints Table Information"][0][
                  "Endpoint Classification"
                ]?.options,
              attributionId: createAttributionId(),
            },
            "Endpoint Name including Measurement Timing": {
              value: [],
              options:
                endpointsTableData["Endpoints Table Information"]?.[0]?.[
                  "Endpoint Name including Measurement Timing"
                ]?.options,
              attributionId: createAttributionId(),
            },
            "Endpoint Group Information": [
              {
                "Group Name": {
                  value: [],
                  options:
                    endpointsTableData["Endpoints Table Information"]?.[0]?.[
                      "Endpoint Group Information"
                    ]?.[0]?.["Group Name"]?.options,
                  attributionId: createAttributionId(),
                },
                "Group Data": {
                  value: [],
                  options:
                    endpointsTableData["Endpoints Table Information"]?.[0]?.[
                      "Endpoint Group Information"
                    ]?.[0]?.["Group Data"]?.options,
                  attributionId: createAttributionId(),
                },
                "P-Value": {
                  value: [],
                  options:
                    endpointsTableData["Endpoints Table Information"]?.[0]?.[
                      "Endpoint Group Information"
                    ]?.[0]?.["P-Value"]?.options,
                  attributionId: createAttributionId(),
                },
              },
            ],
          },
        ],
        "Endpoints Table Notes": {
          value: [],
          options: endpointsTableData?.["Endpoints Table Notes"]?.options,
          attributionId: createAttributionId(),
        },
        attributionInfo: {
          attributionId: createAttributionId(),
        },
      };
    },
    [CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY]: (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const clinicalTrialDesignData =
        data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN
        ]?.[0];

      const clinicalTrialDesign1Data =
        clinicalTrialDesignData?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
        ];

      const clinicalTrialDesign2Data =
        clinicalTrialDesignData?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
        ][0];

      if (!clinicalTrialDesignData) return;
      return {
        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]: {
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.clinicalDesignPhase]: {
            value: [],
            options:
              clinicalTrialDesign1Data?.[
                ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.clinicalDesignPhase
              ]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDiagnostic]:
            {
              value: [],
              options:
                clinicalTrialDesign1Data?.[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                    .inclusionCriteriaDiagnostic
                ]?.options,
              attributionId: createAttributionId(),
            },
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDetail]: {
            value: [],
            options:
              clinicalTrialDesign1Data?.[
                ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDetail
              ]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.totalPatientsNumber]: {
            value: [],
            options:
              clinicalTrialDesign1Data?.[
                ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.totalPatientsNumber
              ]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName]: {
            value: [],
            options:
              clinicalTrialDesign1Data?.[
                ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName
              ]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName]: {
            value: [],
            options:
              clinicalTrialDesign1Data?.[
                ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName
              ]?.options,
            attributionId: createAttributionId(),
          },

          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention]: [],
          attributionInfo: {
            attributionId: createAttributionId(),
          },
        },
        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2]: [
          {
            [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName]: {
              value: [],
              options:
                clinicalTrialDesign2Data?.[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName
                ]?.options,
              attributionId: createAttributionId(),
            },
            [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.patientsNumber]: {
              value: [],
              options:
                clinicalTrialDesign2Data?.[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.patientsNumber
                ]?.options,
              attributionId: createAttributionId(),
            },
            [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation]: [
              {
                [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName]: {
                  value: [],
                  options:
                    clinicalTrialDesign2Data?.[
                      ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS
                        .designGroupInformation
                    ][0][ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName]
                      ?.options,
                  attributionId: createAttributionId(),
                },
                [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does]: {
                  value: [],
                  options:
                    clinicalTrialDesign2Data?.[
                      ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS
                        .designGroupInformation
                    ][0][ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does]
                      ?.options,
                  attributionId: createAttributionId(),
                },
                [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration]: {
                  value: [],
                  options:
                    clinicalTrialDesign2Data?.[
                      ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS
                        .designGroupInformation
                    ][0][ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration]
                      ?.options,
                  attributionId: createAttributionId(),
                },
              },
            ],
          },
        ],
      };
    },
    "Clinical Results": (data: ArticleEntryData) => null,
    "Clinical Endpoints (Description)": (data: ArticleEntryData) => null,
    "Key Comment": (data: ArticleEntryData) => null,
  },
  "Clinical Trial Status": {
    "Drug Profile": (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const drugProfileTableData =
        data?.["Clinical Trial Status"]?.["Drug Profile"]?.[0];

      if (!drugProfileTableData) return;
      return {
        ROA: {
          value: [],
          options: drugProfileTableData?.ROA?.options,
          attributionId: createAttributionId(),
        },
        Modality: {
          value: [],
          options: drugProfileTableData?.Modality?.options,
          attributionId: createAttributionId(),
        },
        Target: {
          value: [],
          options: drugProfileTableData?.Target?.options,
          attributionId: createAttributionId(),
        },
        MOA: {
          value: [],
          options: drugProfileTableData?.MOA?.options,
          attributionId: createAttributionId(),
        },
        "Code Name": {
          value: [],
          options: drugProfileTableData?.["Code Name"]?.options,
          attributionId: createAttributionId(),
        },
        "INN Name": {
          value: [],
          options: drugProfileTableData?.["INN Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Brand Name": {
          value: [],
          options: drugProfileTableData?.["Brand Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Target Indication": {
          value: [],
          options: drugProfileTableData?.["Target Indication"]?.options,
          attributionId: createAttributionId(),
        },
        "Therapeutic Area": {
          value: [],
          options: drugProfileTableData?.["Therapeutic Area"]?.options,
          attributionId: createAttributionId(),
        },
        "Other Characteristics": {
          value: [],
          options: drugProfileTableData?.["Other Characteristics"]?.options,
          attributionId: createAttributionId(),
        },
        "Generic / Biosimilar": {
          value: ["false"],
          options: drugProfileTableData?.["Generic / Biosimilar"]?.options,
          attributionId: createAttributionId(),
        },
        "Ref.Product Name": {
          value: [],
          options: drugProfileTableData?.["Ref.Product Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Originator/Developer": {
          value: [],
          options: drugProfileTableData?.["Originator/Developer"]?.options,
          attributionId: createAttributionId(),
        },
        Phase: {
          value: [],
          options: drugProfileTableData?.Phase?.options,
          attributionId: createAttributionId(),
        },
        attributionInfo: {
          attributionId: createAttributionId(),
        },
      };
    },
    "Clinical Trial Design-1": (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const clinicalDesign1Data =
        data?.["Clinical Trial Status"]?.["Clinical Trial Design-1"]?.[0];

      if (!clinicalDesign1Data) return;
      return {
        "Clinical Design Phase": {
          value: [],
          options: clinicalDesign1Data?.["Clinical Design Phase"]?.options,
          attributionId: createAttributionId(),
        },
        "Inclusion Criteria - Diagnostic": {
          value: [],
          options:
            clinicalDesign1Data?.["Inclusion Criteria - Diagnostic"]?.options,
          attributionId: createAttributionId(),
        },
        "Inclusion Criteria - Detail": {
          value: [],
          options:
            clinicalDesign1Data?.["Inclusion Criteria - Detail"]?.options,
          attributionId: createAttributionId(),
        },
        "Total Patients Number": {
          value: [],
          options: clinicalDesign1Data?.["Total Patients Number"]?.options,
          attributionId: createAttributionId(),
        },
        "Trial Name": {
          value: [],
          options: clinicalDesign1Data?.["Trial Name"]?.options,
          attributionId: createAttributionId(),
        },
        "NCT Name": {
          value: [],
          options: clinicalDesign1Data?.["NCT Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Trial Intervention": [],
        attributionInfo: {
          attributionId: createAttributionId(),
        },
      };
    },
  },
  Regulatory: {
    "Drug Profile": (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const drugProfileTableData = data?.Regulatory?.["Drug Profile"]?.[0];

      if (!drugProfileTableData) return;
      return {
        ROA: {
          value: [],
          options: drugProfileTableData?.ROA?.options,
          attributionId: createAttributionId(),
        },
        Modality: {
          value: [],
          options: drugProfileTableData?.Modality?.options,
          attributionId: createAttributionId(),
        },
        Target: {
          value: [],
          options: drugProfileTableData?.Target?.options,
          attributionId: createAttributionId(),
        },
        MOA: {
          value: [],
          options: drugProfileTableData?.MOA?.options,
          attributionId: createAttributionId(),
        },
        "Code Name": {
          value: [],
          options: drugProfileTableData?.["Code Name"]?.options,
          attributionId: createAttributionId(),
        },
        "INN Name": {
          value: [],
          options: drugProfileTableData?.["INN Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Brand Name": {
          value: [],
          options: drugProfileTableData?.["Brand Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Target Indication": {
          value: [],
          options: drugProfileTableData?.["Target Indication"]?.options,
          attributionId: createAttributionId(),
        },
        "Therapeutic Area": {
          value: [],
          options: drugProfileTableData?.["Therapeutic Area"]?.options,
          attributionId: createAttributionId(),
        },
        "Other Characteristics": {
          value: [],
          options: drugProfileTableData?.["Other Characteristics"]?.options,
          attributionId: createAttributionId(),
        },
        Phase: {
          value: [],
          options: drugProfileTableData?.Phase?.options,
          attributionId: createAttributionId(),
        },
        "Generic / Biosimilar": {
          value: ["false"],
          options: drugProfileTableData?.["Generic / Biosimilar"]?.options,
          attributionId: createAttributionId(),
        },
        "Ref.Product Name": {
          value: [],
          options: drugProfileTableData?.["Ref.Product Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Originator/Developer": {
          value: [],
          options: drugProfileTableData?.["Originator/Developer"]?.options,
          attributionId: createAttributionId(),
        },
        attributionInfo: {
          attributionId: createAttributionId(),
        },
      };
    },
    "Approval Process Contents": (data: ArticleEntryData) => null,
    "Key Comment": (data: ArticleEntryData) => null,
  },
  Deals: {
    "Drug Profile": (data: ArticleEntryData) => null,
    "Additional Information (Deal)": (data: ArticleEntryData) => null,
    "Deal Type & Status": (data: ArticleEntryData) => null,
    "Licensor Table": (data: ArticleEntryData) => null,
    "Licensee Table": (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const licenseData = data.Deals?.["Licensee Table"]?.[0];
      const licenseAndStrategicSData =
        licenseData?.["Strategic Collaboration & License Agreement terms"];
      const rightsData = licenseData?.["Rights Scope"];
      const mergeData = licenseData?.["Merge & Acquisition terms"];
      const ventureFormationData = licenseData?.["Venture Formation terms"];

      if (!licenseData) return;
      return {
        attributionInfo: {
          attributionId: createAttributionId(),
        },
        "Licensee Name": {
          value: [],
          options: licenseData?.["Licensee Name"].options,
          attributionId: createAttributionId(),
        },
        "Strategic Collaboration & License Agreement terms": {
          "Licensee Responsibility & Rights (SC & LA)": {
            value: [],
            options:
              licenseAndStrategicSData?.[
                "Licensee Responsibility & Rights (SC & LA)"
              ]?.options,
            attributionId: createAttributionId(),
          },
          "Upfront Payment": {
            value: [],
            options: licenseAndStrategicSData?.["Upfront Payment"]?.options,
            attributionId: createAttributionId(),
          },
          "Milestone Payment and Condition": [
            {
              "Milestone Payment": {
                value: [],
                options:
                  licenseAndStrategicSData?.[
                    "Milestone Payment and Condition"
                  ][0]["Milestone Payment"]?.options,
                attributionId: createAttributionId(),
              },
              "Milestone Condition Title": {
                value: [],
                options:
                  licenseAndStrategicSData?.[
                    "Milestone Payment and Condition"
                  ][0]["Milestone Condition Title"]?.options,
                attributionId: createAttributionId(),
              },
            },
          ],
          [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
            [
              {
                [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa]: {
                  value: [],
                  options:
                    licenseAndStrategicSData?.[
                      ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                        .otherPaymentAndDetailScLa
                    ][0][
                      ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                        .otherPaymentDetailScLa
                    ]?.options,
                  attributionId: createAttributionId(),
                },
                [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa]: {
                  value: [],
                  options:
                    licenseAndStrategicSData?.[
                      ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                        .otherPaymentAndDetailScLa
                    ][0][ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa]
                      ?.options,
                  attributionId: createAttributionId(),
                },
              },
            ],
          Royalty: {
            value: [],
            options: licenseAndStrategicSData?.["Upfront Payment"]?.options,
            attributionId: createAttributionId(),
          },
          "Total Payment (SC & LA)": {
            value: [],
            options:
              licenseAndStrategicSData?.["Total Payment (SC & LA)"]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa]:
            {
              value: [],
              options:
                licenseAndStrategicSData?.[
                  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                    .disclosedPaymentsSumScLa
                ]?.options,
              attributionId: createAttributionId(),
            },
          "Other Financial Terms (SC & LA)": {
            value: [],
            options:
              licenseAndStrategicSData?.["Other Financial Terms (SC & LA)"]
                ?.options,
            attributionId: createAttributionId(),
          },
        },
        "Rights Scope": [
          {
            Drug: {
              value: [],
              options: rightsData?.[0].Drug?.options,
              attributionId: createAttributionId(),
            },
            Exclusivity: {
              value: [],
              options: rightsData?.[0].Exclusivity?.options,
              attributionId: createAttributionId(),
            },
            Indication: {
              value: [],
              options: rightsData?.[0].Indication?.options,
              attributionId: createAttributionId(),
            },
            Territory: {
              value: [],
              options: rightsData?.[0].Territory?.options,
              attributionId: createAttributionId(),
            },
          },
        ],
        "Merge & Acquisition terms": {
          "Licensee Responsibility & Rights (MA)": {
            value: [],
            options:
              mergeData?.["Licensee Responsibility & Rights (MA)"]?.options,
            attributionId: createAttributionId(),
          },
          "Number of acquiring shares": {
            value: [],
            options: mergeData?.["Number of acquiring shares"]?.options,
            attributionId: createAttributionId(),
          },
          "Other Financial Terms (MA)": {
            value: [],
            options: mergeData?.["Other Financial Terms (MA)"]?.options,
            attributionId: createAttributionId(),
          },
          "Price Per Share": {
            value: [],
            options: mergeData?.["Price Per Share"]?.options,
            attributionId: createAttributionId(),
          },
          "Total Payment (MA)": {
            value: [],
            options: mergeData?.["Total Payment (MA)"]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumMa]: {
            value: [],
            options:
              mergeData?.[
                ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumMa
              ]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumPricePerShareMa]:
            {
              value: [],
              options:
                mergeData?.[
                  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                    .disclosedPaymentsSumPricePerShareMa
                ]?.options,
              attributionId: createAttributionId(),
            },
          [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa]: {
            value: [],
            options: mergeData?.["Upfront Payment (MA)"].options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPricePerShareMa]: {
            value: [],
            options:
              mergeData?.[
                ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPricePerShareMa
              ].options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.mergerFinancialTermsMa]: {
            value: [],
            options:
              mergeData?.[
                ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.mergerFinancialTermsMa
              ].options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment]: [
            {
              [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentMilestoneMa]: {
                value: [],
                options:
                  mergeData?.[
                    ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment
                  ][0][ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentMilestoneMa]
                    .options,
                attributionId: createAttributionId(),
              },
              [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPaymentMa]: {
                value: [],
                options:
                  mergeData?.[
                    ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment
                  ][0][ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPaymentMa]
                    .options,
                attributionId: createAttributionId(),
              },
              [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPricePerShareMa]: {
                value: [],
                options:
                  mergeData?.[
                    ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment
                  ][0][
                    ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPricePerShareMa
                  ].options,
                attributionId: createAttributionId(),
              },
            },
          ],
        },
        "Venture Formation terms": {
          [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.licenseeResponsibilityAndRightsVf]:
            {
              value: [],
              options:
                ventureFormationData?.[
                  ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                    .licenseeResponsibilityAndRightsVf
                ]?.options,
              attributionId: createAttributionId(),
            },
          [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.disclosedPaymentsSumVf]: {
            value: [],
            options:
              ventureFormationData?.[
                ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.disclosedPaymentsSumVf
              ]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.initialInvestment]: {
            value: [],
            options:
              ventureFormationData?.[
                ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.initialInvestment
              ]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.otherFinancialTermsVf]: {
            value: [],
            options:
              ventureFormationData?.[
                ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.otherFinancialTermsVf
              ]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.ownershipStructureDetails]: {
            value: [],
            options:
              ventureFormationData?.[
                ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                  .ownershipStructureDetails
              ]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.totalInvestmentVf]: {
            value: [],
            options:
              ventureFormationData?.[
                ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.totalInvestmentVf
              ]?.options,
            attributionId: createAttributionId(),
          },
          [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentAndDetailVf]:
            [
              {
                [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentVf]:
                  {
                    value: [],
                    options:
                      ventureFormationData?.[
                        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                          .contingentInvestmentAndDetailVf
                      ][0]?.[
                        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                          .contingentInvestmentVf
                      ]?.options,
                    attributionId: createAttributionId(),
                  },
                [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentDetailVf]: {
                  value: [],
                  options:
                    ventureFormationData?.[
                      ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                        .contingentInvestmentAndDetailVf
                    ][0]?.[
                      ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentDetailVf
                    ]?.options,
                  attributionId: createAttributionId(),
                },
              },
            ],
        },
      };
    },
    "Key Comment": (data: ArticleEntryData) => null,
  },
  [SECTION_KEYS.ventureCapital]: {
    "Drug Profile": (data: ArticleEntryData) => null,
  },
};

export const DATA_FOR_ADDING_TABLE_TITLES = {
  "Clinical Trials": {
    "Drug Profile": () => [],
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: () => [],
    "Clinical Results": () => ({}),
    "Clinical Endpoints (Description)": () => ({}),
    "Key Comment": () => ({}),
    "Clinical Endpoints (Table)": (
      data: ArticleEntryData,
      tableIndex: number,
      title: string,
    ) => {
      const createAttributionId = () => createRandomString(8);
      return {
        ...data,
        "Clinical Trials": {
          ...data["Clinical Trials"],
          "Clinical Endpoints (Table)": data["Clinical Trials"]?.[
            "Clinical Endpoints (Table)"
          ]?.map((item, index) => {
            if (index === tableIndex) {
              return {
                ...item,
                "Endpoints Table Title": {
                  ...item["Endpoints Table Title"],
                  value: [
                    {
                      text: title,
                      is_modified: true,
                    },
                  ],
                  options: item["Endpoints Table Title"].options,
                  attributionId: createAttributionId(),
                },
              };
            }
            return item;
          }),
        },
      };
    },
  },
  "Clinical Trial Status": {
    "Drug Profile": () => [],
    "Clinical Trial Design-1": () => ({}),
  },
  Regulatory: {
    "Drug Profile": () => [],
    "Approval Process Contents": () => ({}),
  },
};
