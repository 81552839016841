import React, { ComponentProps, useId } from "react";

import { IconCheck } from "@frontend/common";

import * as S from "./FilterTag.style";

import type { ProcessStatusCode } from "@/types/code.types";

interface Props
  extends React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    ComponentProps<typeof S.FilterInput> {
  children: React.ReactNode;
  tagType?: "checkbox" | "radio";
  code?: ProcessStatusCode;
  checked?: boolean;
  filterType?:
    | "pressListClassification"
    | "status"
    | "pressManualClassification";
  isAll?: boolean;
  aiSelected?: boolean;
}

const FilterTag = ({
  children,
  tagType = "checkbox",
  code,
  checked,
  filterType,
  isAll,
  aiSelected,
  manualSelected,
  ...rest
}: Props) => {
  const tagId = useId();

  return (
    <S.FilterTagLabel
      htmlFor={tagId}
      filterType={filterType}
      isAll={isAll}
      code={code}
      aiSelected={aiSelected}
      className={checked ? "checked-tag-label" : ""}
      manualSelected={manualSelected}
    >
      <S.InputWrapper>
        <S.FilterInput
          type={tagType}
          id={tagId}
          checked={checked}
          filterType={filterType}
          aiSelected={aiSelected}
          manualSelected={manualSelected}
          code={code}
          isAll={isAll}
          {...rest}
        />
        {checked && <IconCheck width={20} height={20} color="white" />}
      </S.InputWrapper>
      <S.FilterTagText
        isAll={isAll}
        code={code}
        filterType={filterType}
        aiSelected={aiSelected}
      >
        {children}
      </S.FilterTagText>
    </S.FilterTagLabel>
  );
};

export default FilterTag;
