import { useTableCreatorFormatErrorPressId } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorFormatErrorPressIdContext";
import { useTableCreatorCurrentHistory } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorHistoryContext";
import { useUpdateCTGEntryData } from "@/components/business/CTGFillInTableContainer/shared/hooks/useUpdateCTGEntryData";
import UnsuitableButton from "@/components/shared/business/UnsuitableReason/components/UnsuitableButton/UnsuitableButton";
import { UNSUITABLE_REASON_KEYS } from "@/components/shared/business/UnsuitableReason/constants/unsuitableReason.constants";
import { useCTGInfoMutation, useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { useCTGUndoRedo } from "@hooks/useCTGUndoRedo";
import { useGetCTGEntryData } from "@hooks/useGetCTGEntryData";
import { useMovePage } from "@hooks/useMovePage";
import IconArrowLeft from "@images/common/icon-arrow-left.svg";
import IconStrokeCheck from "@images/common/icon-stroke-check.svg";
import IconSavedToStoreFullDisable from "@images/fillInTable/tableCreator/icon-saved-to-store-full-gray.svg";
import IconSavedToStoreFull from "@images/fillInTable/tableCreator/icon-saved-to-store-full.svg";
import { useCTGEntryDataValue } from "@stores/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import * as S from "./CTGBottomButtonContainer.style";
import { useCheckValidationInCTGTableCreator } from "./hooks/useCheckValidationInCTGTableCreator";
import { useCTGFillInTableRoles } from "../../../../../../../../../libs/roles/hooks/useCTGFillInTableRoles";
import { useModalityCategoryUpdate } from "../../../../hooks/useModalityCategoryUpdate";
import FillInTableContentsFabContainer from "../../../FillInTableContentsFabContainer/FillInTableContentsFabContainer";

const CTGBottomButtonContainer = () => {
  const draftArticleId = getPressIdFromURL();

  const entryData = useCTGEntryDataValue(draftArticleId);
  const getEntryData = useGetCTGEntryData();

  // NOTE : DataFormat으로 에러가 발생한 경우, Bottom Button을 비활성화 시키기 위한 로직
  const { errorPressId } = useTableCreatorFormatErrorPressId();
  const isDisabledPress = errorPressId === draftArticleId;

  const { updateModalityCategoryValue } = useModalityCategoryUpdate();
  const { data: articleInfo } = useCTGInfoQuery(Number(draftArticleId));
  const { mutateAsync: updateCTGInfo } = useCTGInfoMutation(
    Number(draftArticleId),
  );

  const updateEntryData = useUpdateCTGEntryData();
  const status = articleInfo?.draftArticleStatus;

  const movePage = useMovePage();

  const { handleCheckValidationFull } = useCheckValidationInCTGTableCreator();

  const { hasFullButtonRoles, isSuperAdmin } = useCTGFillInTableRoles();

  const { currentHistory } = useTableCreatorCurrentHistory();

  const { undo, redo, resetHistory, isDisabledRedo, isDisabledUndo } =
    useCTGUndoRedo({
      state: currentHistory,
    });

  const undoRedoEvents = {
    undo,
    redo,
    isDisabledRedo,
    isDisabledUndo,
  };

  const movePrev = async () => {
    await getEntryData();
    movePage("ctgList");
  };

  const moveNext = () => {
    movePage("ctgList");
  };

  const saveToDBFull = async () => {
    const ftEditor2Name = articleInfo?.ftEditor2Name;

    await updateEntryData(entryData, true);

    if (ftEditor2Name === null || ftEditor2Name === "") {
      await updateCTGInfo({
        draftArticleStatus: DRAFT_STATUS_CODE.dbSaved,
        type: "FT2",
        isCheckStartDate: "true",
      });
    } else {
      await updateCTGInfo({
        draftArticleStatus: DRAFT_STATUS_CODE.dbSaved,
      });
    }

    updateModalityCategoryValue();
    resetHistory();
  };

  const reviewInProgressFull = () => {
    updateCTGInfo({
      draftArticleStatus: DRAFT_STATUS_CODE.reviewInProgress,
    });
  };

  const handleClickSaveToDBFull = () => {
    if (handleCheckValidationFull()) {
      saveToDBFull();
    }
  };

  const isVisibleFullSaveToDB = status === DRAFT_STATUS_CODE.reviewInProgress;

  // SuperAdmin인 경우에는 Fill In Table이 완료되면 언제든 버튼이 활성화 되어야 한다.
  const isAbleSuperAdminButton =
    (status === DRAFT_STATUS_CODE.dbSaved ||
      status === DRAFT_STATUS_CODE.insertImage ||
      status === DRAFT_STATUS_CODE.editArticle ||
      status === DRAFT_STATUS_CODE.published) &&
    isSuperAdmin;

  const isFullButtonDisabled =
    (status === undefined ||
      (status !== DRAFT_STATUS_CODE.reviewInProgress &&
        status !== DRAFT_STATUS_CODE.dbSaved) ||
      !hasFullButtonRoles) &&
    !isAbleSuperAdminButton;

  const isDoneButtonDisabled =
    status === undefined ||
    status === DRAFT_STATUS_CODE.aiEntryDone ||
    status === DRAFT_STATUS_CODE.reviewInProgress;

  return (
    <>
      <S.LeftButtonWrapper>
        <S.PrevButton onClick={movePrev}>
          <img src={IconArrowLeft} alt="go to press class" />
          CTG List
        </S.PrevButton>
        <UnsuitableButton type={UNSUITABLE_REASON_KEYS.ctgDetail} />
      </S.LeftButtonWrapper>
      <S.RightButtonWrapper>
        <FillInTableContentsFabContainer undoRedoEvents={undoRedoEvents} />

        {isVisibleFullSaveToDB ? (
          <>
            <S.NextButton
              onClick={handleClickSaveToDBFull}
              disabled={isFullButtonDisabled || isDisabledPress}
            >
              Save to DB (Full)&nbsp;
              <img
                src={
                  isFullButtonDisabled || isDisabledPress
                    ? IconSavedToStoreFullDisable
                    : IconSavedToStoreFull
                }
                alt="store to DB"
              />
            </S.NextButton>
          </>
        ) : (
          <S.ReviewInProgressButton
            onClick={reviewInProgressFull}
            disabled={isFullButtonDisabled || isDisabledPress}
          >
            Review in Progress (Full)
            <img src={IconStrokeCheck} alt="Review in Progress" />
          </S.ReviewInProgressButton>
        )}
        <S.NextButton onClick={moveNext} disabled={isDoneButtonDisabled}>
          Done
        </S.NextButton>
      </S.RightButtonWrapper>
    </>
  );
};

export default CTGBottomButtonContainer;
