import { CtgListData } from "@/components/business/CtgListContainer/ctgList.types";
import { ProcessStatusCode } from "@/types/code.types";
import { useCreateEntryDataMutation } from "@api/createEntryData/createEntryData";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import {
  checkSuperAdmin,
  useAuthorityType,
} from "@libs/roles/hooks/useAuthorityType";
import { handleCtgError } from "@utils/handleDraftError";

import CtgStatus from "./components/CtgStatus/CtgStatus";

type Props = {
  data: CtgListData;
};

const CtgListStatusContainer = ({ data }: Props) => {
  const { mutateAsync: createEntryData } = useCreateEntryDataMutation();

  const authorityType = useAuthorityType();
  const isSuperAdmin = checkSuperAdmin(authorityType);

  const checkDisabledStatusButton = (status: ProcessStatusCode) => {
    if (status !== DRAFT_STATUS_CODE.aiEntryDone) return false;
    if (isSuperAdmin) return false;
    return true;
  };

  const createDAE = (id: number) => {
    if (!isSuperAdmin) return;
    createEntryData(id);
  };

  return (
    <CtgStatus
      data={data}
      isDraftError={handleCtgError(data)[0]}
      draftErrorMessage={handleCtgError(data)[1]}
      createDAE={createDAE}
      disabled={checkDisabledStatusButton(data.draftArticleStatusHighest)}
    />
  );
};

export default CtgListStatusContainer;
