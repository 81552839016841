import styled from "styled-components";

import {
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
  DefaultGroupShellWrapper,
  TableContentWrapper,
} from "@/components/business/CTGFillInTableContainer/shared/styles/table.style";
import { ScrollTableWrapper } from "@/components/business/CTGFillInTableContainer/shared/styles/tableScroll.style";

export const TableWrapper = styled(TableContentWrapper)``;

export const ScrollWrapper = styled(ScrollTableWrapper)``;

export const ScrollContentsWrapper = styled.div`
  width: fit-content;
  min-width: 100%;
`;

export const GroupShellWrapper = styled(DefaultGroupShellWrapper)``;

export const DeleteTableButtonWrapper = styled(
  DefaultDeleteTableButtonWrapper,
)``;

export const DeleteTableButton = styled(DefaultDeleteTableButton)``;
