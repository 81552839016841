import styled from "styled-components";

export const PressInfoSearchWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`;

export const PressInfoSearchTitle = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  min-width: 44rem;
  max-width: 60rem;
`;
