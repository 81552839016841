import { useState } from "react";

import { CtgListData } from "@/components/business/CtgListContainer/ctgList.types";
import { ProcessStatusCode } from "@/types/code.types";
import useModal from "@hooks/useModal";

import CtgListCheckStatusGuide from "./components/CtgListTableShellForPressTitle/components/CtgListCheckStatusGuide/CtgListCheckStatusGuide";
import CtgListTableShellForCtgTitle from "./components/CtgListTableShellForPressTitle/CtgListTableShellForCtgTitle";
import CtgListGuideModal from "../CtgLIstGuideModal/CtgListGuideModal";
import CtgListPageEntryGuide from "../CtgListPageEntryGuide/CtgListPageEntryGuide";

type Props = {
  ctgListItem: CtgListData;
  updateCtgList: () => void;
  ctgListData: CtgListData[];
};

const CtgTitleContainer = ({
  updateCtgList,
  ctgListItem,
  ctgListData,
}: Props) => {
  const [targetCtgId, setTargetCtgId] = useState(0);
  const [currentStatus, setCurrentStatus] = useState<ProcessStatusCode | "">(
    "",
  );

  const { isModal, closeModal, openModal } = useModal();
  const {
    isModal: isGuideModal,
    closeModal: closeGuideModal,
    openModal: openGuideModal,
  } = useModal();
  const {
    isModal: isCheckStatusModal,
    closeModal: closeCheckStatusModal,
    openModal: openCheckStatusModal,
  } = useModal();

  const handleTargetCtgId = (id: number) => {
    setTargetCtgId(id);
  };

  const handleCurrentStatus = (status: ProcessStatusCode | "") => {
    setCurrentStatus(status);
  };

  const openAccessDeniedModal = (ctgId: number) => {
    handleTargetCtgId(ctgId);
    openGuideModal();
  };

  const openConcurrentAccessDeniedModal = (ctgId: number) => {
    handleTargetCtgId(ctgId);
    openModal();
  };

  return (
    <>
      <CtgListTableShellForCtgTitle
        key={ctgListItem.ctgId}
        ctgData={ctgListItem}
        openCheckStatusModal={openCheckStatusModal}
        openAccessDeniedModal={() =>
          openAccessDeniedModal(ctgListItem?.ctgId || 0)
        }
        openConcurrentAccessDeniedModal={() =>
          openConcurrentAccessDeniedModal(ctgListItem?.ctgId || 0)
        }
        handleTargetCtgId={() => handleTargetCtgId(ctgListItem?.ctgId || 0)}
        handleCurrentStatus={handleCurrentStatus}
      />

      {isModal && (
        <CtgListPageEntryGuide
          closeModal={closeModal}
          updateCtgList={updateCtgList}
        />
      )}
      {isGuideModal && (
        <CtgListGuideModal
          closeModal={closeGuideModal}
          draftArticleId={targetCtgId}
        />
      )}
      {isCheckStatusModal && (
        <CtgListCheckStatusGuide
          closeModal={closeCheckStatusModal}
          draftArticleId={targetCtgId}
          updateCtgList={updateCtgList}
          ctgListData={ctgListData || []}
          openAccessDeniedModal={openGuideModal}
          openConcurrentAccessDeniedModal={openModal}
          currentStatus={currentStatus}
        />
      )}
    </>
  );
};

export default CtgTitleContainer;
