export const DRUG_PROFILE_TABLE_LAYOUT = [
  {
    groupTableKey: "Drug Profile",
    tableList: [
      {
        tableKey: "Drug",
        groupShellList: [
          {
            groupShellKey: "Drug Name",
            shellList: [
              {
                shellKey: "Brand Name",
              },
              {
                shellKey: "INN Name",
              },
              {
                shellKey: "Code Name",
              },
            ],
          },
          {
            groupShellKey: null,
            shellList: [
              {
                shellKey: "ROA",
              },
              {
                shellKey: "Modality",
              },
              {
                shellKey: "Target",
              },
              {
                shellKey: "MOA",
              },
              {
                shellKey: "Target Indication",
              },
              {
                shellKey: "Other Characteristics",
              },
              {
                shellKey: "Phase",
              },
              {
                shellKey: "Generic / Biosimilar",
              },
              {
                shellKey: "Ref.Product Name",
              },
              {
                shellKey: "Originator/Developer",
              },
            ],
          },
        ],
      },
    ],
  },
];
