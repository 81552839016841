import { useEffect } from "react";

import { useValidatedInTableCreator } from "@/components/business/CTGFillInTableContainer/shared/context/ValidatedInTableCreatorContext";

import DrugProfileTableCreator from "./components/DrugProfileTableCreator/DrugProfileTableCreator";
import { useScroll } from "../../hooks/useScroll";

const TableCreatorContainer = () => {
  const { scroll } = useScroll();
  const { validatedInfo } = useValidatedInTableCreator();

  useEffect(() => {
    if (validatedInfo.length > 0) {
      const {
        groupTableKey,
        groupTableIndex,
        tableIndex,
        tableKey,
        groupShellKey,
        groupShellIndex,
        subGroupShellKey,
        subGroupShellIndex,
        shellIndex,
        shellKey,
      } = validatedInfo[0];

      // NOTE: 레이아웃에 SubGroupShell 계층이 존재하나 Key 값을 설정하지 않는 경우를 위해 null은 SubGroupShell 계층이 있는 것으로 판단합니다.
      if (subGroupShellKey || subGroupShellKey === null)
        return scroll(
          `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${subGroupShellKey}__${subGroupShellIndex}__${shellKey}__${shellIndex}`,
        );

      // NOTE: SubGroupShellKey, SubGroupShellIndex를 아예 설정하지 않은 경우
      scroll(
        `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${shellKey}__${shellIndex}`,
      );
    }
  }, [validatedInfo, scroll]);

  return <DrugProfileTableCreator />;
};

export default TableCreatorContainer;
