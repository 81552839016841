import styled from "styled-components";

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.is-hide-drug-profile-tableData-true {
    display: none;
  }
`;
