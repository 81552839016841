import React, { useEffect, useRef } from "react";

import {
  VENTURE_FORMATION_GROUP_SHELL_KEYS,
  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  GroupShellDividerPlusBar,
  TableCreatorGroupShellTitle,
} from "@FillInTableContainer/shared";
import TableDragDivider from "@FillInTableContainer/shared/components/TableDragDivider/TableDragDivider";
import { useHandleTableCreatorSubGroupShellDrag } from "@FillInTableContainer/shared/hooks/useHandleTableCreatorSubGroupShellDrag";
import {
  GroupTableKeyType,
  TableCreatorSubGroupShellList,
} from "@FillInTableContainer/shared/types/tableCreator.types";
import { isAddDeleteGroupShell } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getGroupShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import DealTermsMergeSubGroupShell from "./components/DealTermsMergeSubGroupShell/DealTermsMergeSubGroupShell";
import * as S from "./DealTermsVentureFormationGroupShell.style";
import { useHandleDealTermsMergeTableCreatorGroupShell } from "./hooks/useHandleDealTermsMergeTableCreatorGroupShell";
import useCheckIsDisplaySubGroupShellDragDivider from "../../../../../DealTermsLicenseAgreementGroupTable/components/DealTermsLicenseAgreementTable/components/DealTermsLicenseAgreementGroupShellSection/components/DealTermsLicenseAgreementGroupShell/hooks/useCheckIsDisplaySubGroupShellDragDivider";
import { useGetNumberOfSupGroupShell } from "../../../../../DealTermsLicenseAgreementGroupTable/components/DealTermsLicenseAgreementTable/components/DealTermsLicenseAgreementGroupShellSection/components/DealTermsLicenseAgreementGroupShell/hooks/useGetNumberOfSubGroupShell";

interface Props {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  subGroupShellList: TableCreatorSubGroupShellList;
  tableKey: string | null;
  tableIndex: number;
  groupShellIndex: number;
  groupShellCount: number;
  isFocusGroupShellSection?: boolean;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd: (groupTableKey: GroupTableKeyType) => void;
  handleDragOn: () => void;
  isDraggableGroupShell: boolean;
}

const ARTICLE_SECTION = "Deals";

const dragSubGroupShellList = [
  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.contingentInvestment,
];

// NOTE: 그룹으로 안묶이게 (내부 항목들은 한 줄씩 잡혀야 함)
const notGroupShellFocusGroupShellKeyList: string[] = [
  VENTURE_FORMATION_GROUP_SHELL_KEYS.investmentTerms,
  VENTURE_FORMATION_GROUP_SHELL_KEYS.otherFinancialTerms,
];

const DealTermsVentureFormationGroupShell = ({
  groupShellIndex,
  groupTableIndex,
  groupTableKey,
  groupShellKey,
  groupShellCount,
  subGroupShellList,
  tableIndex,
  tableKey,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
  isDraggableGroupShell,
  isFocusGroupShellSection,
}: Props) => {
  const articleSection = useArticleSection();
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const { subGroupShellCount } = useGetNumberOfSupGroupShell();

  const positionPropsInfo = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  };

  const {
    focusStatus,
    isFocused,
    isClearGroupShell,
    isAlertGroupShell,
    isEnableEdit,
    isLastGroupShell,
    isDeleteGroupShell,
    isFocusedAddButton,
    handleFocusGroupShell,
    handleDefaultFocus,
    handleClickToDeleteGroupShell,
    clickAddGroupShellButton,
    changeFocusStatus,
    changePositionInfo,
    changePositionInfoToDefault,
  } = useHandleDealTermsMergeTableCreatorGroupShell({
    positionPropsInfo,
    groupShellCount,
  });

  const {
    handleSubGroupShellDragEnd,
    handleSubGroupShellDragOn,
    handleSubGroupShellDragOver,
    handleSubGroupShellDragStart,
    subGroupShellDraggedIndexInfo,
    isDraggingSubGroupShell,
  } = useHandleTableCreatorSubGroupShellDrag(ARTICLE_SECTION, tableIndex);

  const { isLastSubGroupShell, handleCheckDragDividerDisplay } =
    useCheckIsDisplaySubGroupShellDragDivider({
      tableIndex,
      groupShellIndex,
      isDraggingSubGroupShell,
    });

  // NOTE: noTitle이라는 값으로 표출되면 안되는 부분 구분
  const handleGetCustomGroupShellTitle = () => {
    if (groupShellKey === "Rights Scope") return "Asset Transfer";
    else if (groupShellKey === "Financial terms M&A") return "noTitle";
    else if (groupShellKey === "Other Financial Terms") return "noTitle";
  };

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;
        const clickSelectButton = selectButtonRef.current?.contains(target);
        const clickGroupShellTitle = titleRef.current?.contains(target);

        if (clickSelectButton || clickGroupShellTitle) return;
        if (target.dataset.button) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isFocused]);

  const checkIsDisplayDragDividerSubGroupShell = (
    subGroupShellKey: string | null,
  ) => {
    if (!subGroupShellKey) return false;

    return dragSubGroupShellList.includes(subGroupShellKey || "");
  };

  const isPossibleSelected = groupShellKey
    ? !notGroupShellFocusGroupShellKeyList.includes(groupShellKey)
    : true;

  return (
    <>
      {isAddDeleteGroupShell(articleSection, groupShellKey) && (
        <GroupShellDividerPlusBar
          onClick={(event) => {
            clickAddGroupShellButton(event, {
              tableIndexParameter: tableIndex,
              groupShellIndexParameter: groupShellIndex,
              groupShellKeyParameter: groupShellKey,
            });
          }}
          isLastPosition={false}
          buttonText="Add Transfer"
          isVisibility={isFocusedAddButton}
        />
      )}
      <S.GroupShellOutsideWrapper>
        {(isClearGroupShell || isDeleteGroupShell) && isEnableEdit && (
          <S.FixedDeleteButtonWrapper
            isShellTitle={!!getGroupShellTitle(articleSection, groupShellKey)}
          >
            <S.DeleteTableButtonWrapper>
              <S.DeleteTableButton
                data-button="delete-button"
                onClick={handleClickToDeleteGroupShell}
              >
                <img src={IconXMark} alt="button for clear Row" />
                {focusStatus.type === "clear" ? `Clear` : null}
                {focusStatus.type === "delete" ? `Delete` : null}
              </S.DeleteTableButton>
            </S.DeleteTableButtonWrapper>
          </S.FixedDeleteButtonWrapper>
        )}

        <S.GroupShellWrapper
          isFocused={isFocused}
          isClearGroupShell={isClearGroupShell}
          isAlertGroupShell={isAlertGroupShell}
          isDeleteGroupShell={isDeleteGroupShell}
          isDragging={isFocused && focusStatus.type === "dragging"}
        >
          <S.TitleWrapper ref={titleRef}>
            {getGroupShellTitle(articleSection, groupShellKey) &&
              handleGetCustomGroupShellTitle() !== "noTitle" && (
                <TableCreatorGroupShellTitle
                  groupShellKey={groupShellKey}
                  customGroupShellTitle={handleGetCustomGroupShellTitle()}
                  groupShellCount={groupShellCount}
                  groupShellIndex={groupShellIndex}
                  isAlertGroupShell={isAlertGroupShell}
                  onClickHandler={handleFocusGroupShell}
                  groupTableKey={groupTableKey}
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  handleDragOn={handleDragOn}
                  isDragging={isFocused && focusStatus.type === "dragging"}
                  isDraggableGroupShell={isDraggableGroupShell}
                  changeFocusStatusForDragging={() => {
                    changePositionInfo();
                    changeFocusStatus({
                      type: "dragging",
                      container: "groupShell",
                    });
                  }}
                  changeFocusStatusForDragEnd={() => {
                    changePositionInfoToDefault();
                    changeFocusStatus({
                      type: "default",
                      container: "noFocus",
                    });
                  }}
                />
              )}
          </S.TitleWrapper>
          <S.SelectWrapper>
            <S.GroupShellSelectButtonDividerWrapper
              ref={selectButtonRef}
              onClick={handleFocusGroupShell}
              isPossibleSelected={isPossibleSelected}
              isFinancialTerms={!isPossibleSelected}
            >
              <S.GroupShellSelectButtonDivider className="select-button divider" />
              <S.GroupShellSelectButton className="select-button button">
                SELECT
              </S.GroupShellSelectButton>
            </S.GroupShellSelectButtonDividerWrapper>
            <S.GroupShell>
              {subGroupShellList.map(
                ({ subGroupShellKey, shellList }, subGroupShellListIndex) => (
                  <React.Fragment
                    key={`subGroupShell-${String(subGroupShellListIndex)}`}
                  >
                    {newArrayToCountValue(
                      subGroupShellCount(
                        subGroupShellKey,
                        tableIndex,
                        groupShellIndex,
                      ),
                    ).map((_, subGroupShellIndex) => (
                      <S.DraggableSubGroupShellWrapper
                        isTargetDraggingTable={
                          isDraggingSubGroupShell &&
                          subGroupShellDraggedIndexInfo[0] !==
                            subGroupShellIndex &&
                          checkIsDisplayDragDividerSubGroupShell(
                            subGroupShellKey,
                          )
                        }
                        onDragOver={(event) =>
                          handleSubGroupShellDragOver(event, subGroupShellIndex)
                        }
                      >
                        {checkIsDisplayDragDividerSubGroupShell(
                          subGroupShellKey,
                        ) && (
                          <TableDragDivider
                            isDraggingEndPosition={handleCheckDragDividerDisplay(
                              {
                                moveDragIndex: subGroupShellDraggedIndexInfo[1],
                                startDragIndex:
                                  subGroupShellDraggedIndexInfo[0],
                                subGroupShellIndex,
                                subGroupShellKey,
                              },
                            )}
                          />
                        )}
                        <DealTermsMergeSubGroupShell
                          key={`shellList-${String(subGroupShellIndex)}`}
                          groupTableKey={groupTableKey}
                          groupTableIndex={groupTableIndex}
                          groupShellKey={groupShellKey}
                          groupShellIndex={groupShellIndex}
                          subGroupShellKey={subGroupShellKey}
                          shellList={shellList}
                          subGroupShellIndex={subGroupShellIndex}
                          tableKey={tableKey}
                          tableIndex={tableIndex}
                          subGroupShellCount={subGroupShellCount(
                            subGroupShellKey,
                            tableIndex,
                            groupShellIndex,
                          )}
                          isFocusedGroupShell={isFocused}
                          isClearGroupShell={isClearGroupShell}
                          isDeleteGroupShell={isDeleteGroupShell}
                          isAlertGroupShell={isAlertGroupShell}
                          isClearGroupShellSection={isFocusGroupShellSection}
                          handleDragEnd={handleSubGroupShellDragEnd}
                          handleDragOn={handleSubGroupShellDragOn}
                          handleDragStart={handleSubGroupShellDragStart}
                        />
                        {checkIsDisplayDragDividerSubGroupShell(
                          subGroupShellKey,
                        ) &&
                          isLastSubGroupShell(
                            subGroupShellIndex,
                            subGroupShellKey,
                          ) && (
                            <TableDragDivider
                              isDraggingEndPosition={handleCheckDragDividerDisplay(
                                {
                                  moveDragIndex:
                                    subGroupShellDraggedIndexInfo[1],
                                  startDragIndex:
                                    subGroupShellDraggedIndexInfo[0],
                                  subGroupShellIndex,
                                  subGroupShellKey,
                                  lastIndex: newArrayToCountValue(
                                    subGroupShellCount(
                                      subGroupShellKey,
                                      tableIndex,
                                      groupShellIndex,
                                    ),
                                  ).length,
                                },
                              )}
                            />
                          )}
                      </S.DraggableSubGroupShellWrapper>
                    ))}
                  </React.Fragment>
                ),
              )}
            </S.GroupShell>
          </S.SelectWrapper>
        </S.GroupShellWrapper>
      </S.GroupShellOutsideWrapper>
      {isAddDeleteGroupShell(articleSection, groupShellKey) &&
        isLastGroupShell && (
          <GroupShellDividerPlusBar
            onClick={(event) => {
              clickAddGroupShellButton(event, {
                tableIndexParameter: tableIndex,
                groupShellIndexParameter: groupShellIndex + 1,
                groupShellKeyParameter: groupShellKey,
              });
            }}
            buttonText="Add Key Assets / Indication"
            isLastPosition
            isVisibility={isFocusedAddButton}
          />
        )}
    </>
  );
};

export default DealTermsVentureFormationGroupShell;
