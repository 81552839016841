import { TableCreatorGroupTableTitle } from "@/components/business/CTGFillInTableContainer/shared";
import TableCreatorNavigationTableWrapper from "@/components/business/CTGFillInTableContainer/shared/components/TableCreatorNavigationTableWrapper/TableCreatorNavigationTableWrapper";
import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";
import { TableCreatorTableList } from "@/components/business/CTGFillInTableContainer/shared/types/tableCreator.types";
import { getGroupTableTitle } from "@/components/business/CTGFillInTableContainer/shared/utils/getTextFromData";
import ComponentErrorBoundary from "@/components/shared/business/ComponentErrorBoundary/ComponentErrorBoundary";

import DrugProfileGroupTable from "./components/DrugProfileGroupTable/DrugProfileGroupTable";
import { DRUG_PROFILE_TABLE_LAYOUT } from "./constants/drugProfileTableLayout.constants";

const DrugProfileTableCreator = () => {
  const errorWithComponent = (title: string) => (
    <TableCreatorGroupTableTitle title={title} isRequired={false} />
  );

  return (
    <TableCreatorNavigationTableWrapper>
      <>
        {DRUG_PROFILE_TABLE_LAYOUT.map(
          ({ groupTableKey, tableList }, groupTableIndex) => {
            return (
              <ComponentErrorBoundary
                addComponents={errorWithComponent(
                  getGroupTableTitle(CTG_SECTION, groupTableKey),
                )}
              >
                <DrugProfileGroupTable
                  key={`group-table-${String(groupTableIndex)}`}
                  groupTableKey={groupTableKey as "Drug Profile"}
                  tableList={tableList as TableCreatorTableList}
                  groupTableIndex={groupTableIndex}
                />
              </ComponentErrorBoundary>
            );
          },
        )}
      </>
    </TableCreatorNavigationTableWrapper>
  );
};

export default DrugProfileTableCreator;
