"use client";

import { css, styled } from "styled-components";

export const TableGroupWrapper = styled.div<{
  $isMobileViewer?: boolean;
  $isNoneDisplay?: boolean;
  $dataInfoByDirection?: {
    left: boolean;
    right: boolean;
  };
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  min-height: 24rem;
  padding: 1.2rem 1.6rem 1.6rem 1.6rem;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: calc(50% - 1.7rem);
    height: 100%;
    border-radius: var(--Border-Radius-4, 0.4rem);
    border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
    background: #f8f9fa;
    z-index: -1;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    display: block;
    width: calc(50% - 1.7rem);
    height: 100%;
    border-radius: var(--Border-Radius-4, 0.4rem);
    border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
    background: #f8f9fa;
    z-index: -1;
  }

  ${({ $isMobileViewer }) => {
    if ($isMobileViewer === false) return css``;
    return css`
      @media screen and (max-width: 768px) {
        min-height: 0rem;
        padding: 0;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }

        & td > div {
          font-size: 1.4rem;
          line-height: 145%;
          height: auto;
        }
      }
    `;
  }}

  ${({ $isNoneDisplay }) =>
    $isNoneDisplay &&
    css`
      display: none;
    `}

    ${({ $dataInfoByDirection }) => {
    if ($dataInfoByDirection?.left) {
      return css`
        &::before {
          display: none;
        }
      `;
    }
    if ($dataInfoByDirection?.right) {
      return css`
        &::after {
          display: none;
        }
      `;
    }
  }}
`;

export const TableWrapper = styled.div<{
  $isMobileViewer?: boolean;
  $isNoneDisplay?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;

  ${({ $isNoneDisplay }) =>
    $isNoneDisplay &&
    css`
      display: none;
    `}
`;

export const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  border: 0px solid transparent;
  width: 100%;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  height: 100%;
`;

export const BodyShell = styled.td<{ $isNoneDisplay?: boolean }>`
  height: 1px;
  border: 0px solid transparent;
  vertical-align: top;

  ${({ $isNoneDisplay }) =>
    $isNoneDisplay &&
    css`
      height: 0;
    `}
`;

export const TitleContentsShell = styled.div<{
  $isRadius?: boolean;
  $isNoneDisplay?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 4.4rem;
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  padding: 0.8rem 1rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pt-serif);
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 150%;

  ${({ $isRadius }) =>
    $isRadius &&
    css`
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
    `}

  ${({ $isNoneDisplay }) =>
    $isNoneDisplay &&
    css`
      display: none;
    `}
`;

export const ValueContentsShell = styled.div<{
  $isBottomBorder?: boolean;
  $isNoneDisplay?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
  border-left: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  border-right: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);

  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pt-serif);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  overflow-wrap: anywhere;

  ${({ $isBottomBorder }) =>
    $isBottomBorder &&
    css`
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      border-bottom: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
    `}

  ${({ $isNoneDisplay }) =>
    $isNoneDisplay &&
    css`
      display: none;
    `}
`;

export const TableSectionTitle = styled.div<{ $isNoneDisplay?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.4rem;
  color: var(--Color-Common-Text-Success-Bold, #015d8e);
  font-family: var(--font-pretendard);

  font-weight: 600;
  line-height: 150%;

  && {
    font-size: 1.6rem;
  }

  ${({ $isNoneDisplay }) =>
    $isNoneDisplay &&
    css`
      display: none;
    `}
`;

export const ArrowContainer = styled.div<{
  $isMobileViewer?: boolean;
  $isNoneDisplay?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 3.6rem;
  height: 100%;
  transform: translateX(-50%);
  z-index: 2;

  & > svg {
    width: 100%;
  }

  ${({ $isMobileViewer }) => {
    if ($isMobileViewer === false) return css``;
    return css`
      @media screen and (max-width: 768px) {
        display: none;
      }
    `;
  }}

  ${({ $isNoneDisplay }) =>
    $isNoneDisplay &&
    css`
      display: none;
    `}
`;

export const MobileDrugText = styled.div`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pt-serif);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
`;

export const MobileMainUsageText = styled.div`
  margin-top: -1rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pt-serif);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
`;
