/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS,
  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_CONTINGENT_PAYMENT_KEYS,
  ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS,
  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS,
  LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS,
  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { ArmValue } from "@/types/clinicalTrials.types";
import {
  ContingentInvestmentVf,
  ContingentPayment,
  MilestonePaymentAndCondition,
  OtherPaymentAndDetail,
  RightsScope,
} from "@/types/deals.types";
import { createRandomString } from "@organisms/TableCreatorComment/components/CommentModalContainer/components/CommentModalMessageComposer/utils/createRandomString";

import { EXPEDITED_PROGRAM } from "./expeditedProgramKey.constants";

export const TABLE_CREATOR_SUB_GROUP_SHELL = {
  "Clinical Trials": [
    {
      key: CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm,
      title: "arm",
      isRequired: false,
      isReviseSubGroupShell: true,
    },
  ],
  "Clinical Trial Status": [],
  Regulatory: [],
  Deals: [
    {
      key: LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone,
      title: "",
      isRequired: false,
      isReviseSubGroupShell: true,
    },
    {
      key: LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail,
      title: "",
      isRequired: false,
      isReviseSubGroupShell: true,
    },
    {
      key: MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment,
      title: "",
      isRequired: false,
      isReviseSubGroupShell: true,
    },
    {
      key: CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerA,
      title: "",
      isRequired: false,
      isReviseSubGroupShell: true,
    },
    {
      key: CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerB,
      title: "",
      isRequired: false,
      isReviseSubGroupShell: true,
    },
    {
      key: VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.contingentInvestment,
      title: "",
      isRequired: false,
      isReviseSubGroupShell: true,
    },
  ],
  [EXPEDITED_PROGRAM]: [],
  [ENTRY_DATA_SECTION_KEYS.ventureCapital]: [],
};

export const SUB_GROUP_SHELL_PATH_TO_ADD_SUB_GROUP_SHELL: Record<
  string,
  (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) => unknown[]
> = {
  Milestone: (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) => {
    return (
      data?.Deals?.["Licensee Table"]?.[tableIndex][
        "Strategic Collaboration & License Agreement terms"
      ]["Milestone Payment and Condition"] || []
    );
  },
  [LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail]: (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) => {
    return (
      data?.Deals?.[ENTRY_DATA_LICENSEE_TABLE]?.[tableIndex][
        ENTRY_DATA_LICENSEE_TABLE_KEYS
          .strategicCollaborationLicenseAgreementTerms
      ][
        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
          .otherPaymentAndDetailScLa
      ] || []
    );
  },
  [CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerA]: (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) => {
    return data?.Deals?.["Licensee Table"]?.[tableIndex]["Rights Scope"] || [];
  },
  [CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerB]: (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) => {
    return data?.Deals?.["Licensee Table"]?.[tableIndex]["Rights Scope"] || [];
  },
  [MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment]: (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) => {
    return (
      data?.Deals?.[ENTRY_DATA_LICENSEE_TABLE]?.[tableIndex][
        ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
      ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment] || []
    );
  },
  [CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm]: (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) => {
    return (
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex][
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
      ] || []
    );
  },
  [VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.contingentInvestment]: (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) => {
    return (
      data?.Deals?.[ENTRY_DATA_LICENSEE_TABLE]?.[tableIndex][
        ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
      ]?.[
        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentAndDetailVf
      ] || []
    );
  },
};

export const BASIC_DATA_FOR_ADDING_SUB_GROUP_SHELLS: Record<
  string,
  Record<
    string,
    (
      data: ArticleEntryData,
    ) =>
      | RightsScope
      | MilestonePaymentAndCondition
      | OtherPaymentAndDetail
      | ContingentPayment
      | ArmValue
      | ContingentInvestmentVf
  >
> = {
  [ENTRY_DATA_SECTION_KEYS.deals]: {
    "Rights Scope": (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const rightScopeGroupShellData =
        data.Deals?.["Licensee Table"]?.[0]["Rights Scope"]?.[0];

      return {
        Drug: {
          value: [],
          options: rightScopeGroupShellData?.Drug.options,
          attributionId: createAttributionId(),
        },
        Indication: {
          value: [],
          options: rightScopeGroupShellData?.Indication.options,
          attributionId: createAttributionId(),
        },
        Exclusivity: {
          value: [],
          options: rightScopeGroupShellData?.Exclusivity.options,
          attributionId: createAttributionId(),
        },
        Territory: {
          value: [],
          options: rightScopeGroupShellData?.Territory.options,
          attributionId: createAttributionId(),
        },
      } as RightsScope;
    },
    [VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.contingentInvestment]: (
      data: ArticleEntryData,
    ) => {
      const createAttributionId = () => createRandomString(8);
      const contingentPaymentData =
        data.Deals?.[ENTRY_DATA_LICENSEE_TABLE]?.[0][
          ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
        ][
          ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
            .contingentInvestmentAndDetailVf
        ][0];

      return {
        [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentVf]: {
          value: [],
          options:
            contingentPaymentData?.[
              ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentVf
            ].options,
          attributionId: createAttributionId(),
        },
        [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentDetailVf]: {
          value: [],
          options:
            contingentPaymentData?.[
              ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentDetailVf
            ].options,
          attributionId: createAttributionId(),
        },
      } as ContingentInvestmentVf;
    },

    [CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerA]: (
      data: ArticleEntryData,
    ) => {
      const createAttributionId = () => createRandomString(8);
      const rightScopeGroupShellData =
        data.Deals?.["Licensee Table"]?.[0]["Rights Scope"]?.[0];

      return {
        Drug: {
          value: [],
          options: rightScopeGroupShellData?.Drug.options,
          attributionId: createAttributionId(),
        },
        Indication: {
          value: [],
          options: rightScopeGroupShellData?.Indication.options,
          attributionId: createAttributionId(),
        },
        Exclusivity: {
          value: [],
          options: rightScopeGroupShellData?.Exclusivity.options,
          attributionId: createAttributionId(),
        },
        Territory: {
          value: [],
          options: rightScopeGroupShellData?.Territory.options,
          attributionId: createAttributionId(),
        },
      } as RightsScope;
    },
    [CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerB]: (
      data: ArticleEntryData,
    ) => {
      const createAttributionId = () => createRandomString(8);
      const rightScopeGroupShellData =
        data.Deals?.["Licensee Table"]?.[0]["Rights Scope"]?.[0];

      return {
        Drug: {
          value: [],
          options: rightScopeGroupShellData?.Drug.options,
          attributionId: createAttributionId(),
        },
        Indication: {
          value: [],
          options: rightScopeGroupShellData?.Indication.options,
          attributionId: createAttributionId(),
        },
        Exclusivity: {
          value: [],
          options: rightScopeGroupShellData?.Exclusivity.options,
          attributionId: createAttributionId(),
        },
        Territory: {
          value: [],
          options: rightScopeGroupShellData?.Territory.options,
          attributionId: createAttributionId(),
        },
      } as RightsScope;
    },

    Milestone: (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const milestoneShellData =
        data.Deals?.["Licensee Table"]?.[0][
          "Strategic Collaboration & License Agreement terms"
        ]["Milestone Payment and Condition"][0];

      return {
        "Milestone Payment": {
          value: [],
          options: milestoneShellData?.["Milestone Payment"].options,
          attributionId: createAttributionId(),
        },
        "Milestone Condition Title": {
          value: [],
          options: milestoneShellData?.["Milestone Condition Title"].options,
          attributionId: createAttributionId(),
        },
      } as MilestonePaymentAndCondition;
    },

    [LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail]: (
      data: ArticleEntryData,
    ) => {
      const createAttributionId = () => createRandomString(8);
      const otherPaymentShellData =
        data.Deals?.[ENTRY_DATA_LICENSEE_TABLE]?.[0][
          ENTRY_DATA_LICENSEE_TABLE_KEYS
            .strategicCollaborationLicenseAgreementTerms
        ][
          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
            .otherPaymentAndDetailScLa
        ][0];

      return {
        [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa]: {
          value: [],
          options:
            otherPaymentShellData?.[
              ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa
            ].options,
          attributionId: createAttributionId(),
        },
        [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa]: {
          value: [],
          options:
            otherPaymentShellData?.[
              ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa
            ].options,
          attributionId: createAttributionId(),
        },
      } as OtherPaymentAndDetail;
    },

    [MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment]: (
      data: ArticleEntryData,
    ) => {
      const createAttributionId = () => createRandomString(8);
      const contingentPaymentData =
        data.Deals?.[ENTRY_DATA_LICENSEE_TABLE]?.[0][
          ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
        ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment][0];

      return {
        [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentMilestoneMa]: {
          value: [],
          options:
            contingentPaymentData?.[
              ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentMilestoneMa
            ].options,
          attributionId: createAttributionId(),
        },
        [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPaymentMa]: {
          value: [],
          options:
            contingentPaymentData?.[
              ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPaymentMa
            ].options,
          attributionId: createAttributionId(),
        },
        [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPricePerShareMa]: {
          value: [],
          options:
            contingentPaymentData?.[
              ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPricePerShareMa
            ].options,
          attributionId: createAttributionId(),
        },
      } as ContingentPayment;
    },
  },
  [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
    [CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm]: (
      data: ArticleEntryData,
    ) => {
      const createAttributionId = () => createRandomString(8);
      const clinicalTrialDesign2Data =
        data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN
        ]?.[0][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2][0];

      return {
        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName]: {
          value: [],
          options:
            clinicalTrialDesign2Data?.[
              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName
            ].options,
          attributionId: createAttributionId(),
        },
        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.patientsNumber]: {
          value: [],
          options:
            clinicalTrialDesign2Data?.[
              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.patientsNumber
            ].options,
          attributionId: createAttributionId(),
        },
        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation]: [
          {
            [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does]: {
              value: [],
              options:
                clinicalTrialDesign2Data?.[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation
                ][0][ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does].options,
              attributionId: createAttributionId(),
            },
            [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName]: {
              value: [],
              options:
                clinicalTrialDesign2Data?.[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation
                ][0][ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName].options,
              attributionId: createAttributionId(),
            },
            [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration]: {
              value: [],
              options:
                clinicalTrialDesign2Data?.[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation
                ][0][ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration].options,
              attributionId: createAttributionId(),
            },
          },
        ],
      } as ArmValue;
    },
  },
};
