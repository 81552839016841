import styled, { css } from "styled-components";

import {
  DividerWrapper,
  PlusBar,
  PlusButton,
} from "@/components/business/CTGFillInTableContainer/shared/styles/dividerPlusBar.style";

export const TableDividerWrapper = styled(DividerWrapper)`
  top: -2.9rem;
  ${({ isLastPosition }) =>
    isLastPosition &&
    css`
      top: calc(100% + 0.4rem);
    `}
`;

export const TableDividerPlusBar = styled(PlusBar)``;

export const TablePlusButton = styled(PlusButton)``;
