import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";

import { similarDataApis } from "./dealsSimilarData.service";

export const getSimilarData = async (pressId: string) => {
  const response = await similarDataApis.get(pressId);
  return response;
};

export const useSimilarDataQuery = (pressId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.similarData, pressId],
    queryFn: () => getSimilarData(pressId),
    select: (data) => data.detail,
    retry: false,
    throwOnError: false,
  });
};
