import { apiClient } from "@api/apiClient";

import {
  GetCTGInfoResponse,
  PutCTGInfoArg,
  PutCTGInfoResponse,
} from "./ctgInfo.interface";

const API_ENDPOINT = {
  get: "/des/draft-article/info",
  update: "/des/draft-article/update",
};

export const ctgInfoApis = {
  get: async (draftArticleId: number) => {
    const { data } = await apiClient.get<GetCTGInfoResponse>(
      `${API_ENDPOINT.get}/${draftArticleId}`,
    );

    return data;
  },
  put: async (ctgInfo: PutCTGInfoArg) => {
    const { data } = await apiClient.put<PutCTGInfoResponse>(
      API_ENDPOINT.update,
      ctgInfo,
    );

    return data;
  },
};
