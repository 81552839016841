/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import * as S from "./DealTermsVentureFormationTableContainer.style";
import { createArrayToCountValue, useCheckWindowSize } from "../../../shared";
import DealTermArrow from "../DealTermArrow/DealTermArrow";
import ParagraphSectionLayout from "../ParagraphSectionLayout/ParagraphSectionLayout";

type RightsScopeValue = {
  drugInfo: {
    drug: React.ReactNode;
    mainUsage: React.ReactNode;
  }[];
  exclusivity: React.ReactNode;
  territory: React.ReactNode;
};

type FinancialTermsValue = {
  initialInvestment: React.ReactNode;
  contingentInvestment: React.ReactNode;
  totalInvestment: React.ReactNode;
  ownershipStructureDetails: React.ReactNode;
};
type Props = {
  sellerName: string;
  buyerName: string;
  isMobileViewer?: boolean;
  licensingInfoData: {
    partnerA: {
      licensingName: React.ReactNode;
      responsibilityAndRights: React.ReactNode;
    };
    partnerB: {
      licensingName: React.ReactNode;
      responsibilityAndRights: React.ReactNode;
    };
  };
  otherFinancialTermsData: {
    otherFinancialTerms: React.ReactNode;
  };
  financialTermsData: {
    buyer: FinancialTermsValue;
  };
  rightScopeData: {
    buyer: RightsScopeValue[];
  };
};

const checkTextEmpty = (text: React.ReactNode) => {
  if (text === undefined) return true;
  if (text === "") return true;
  if ((text as any)?.props?.children?.at(1).props.item.text === "") return true;
  if ((text as any)?.props?.item?.text === "") return true;
  return false;
};
const checkEmptyRowValue = (partnerBValue: React.ReactNode) => {
  if (checkTextEmpty(partnerBValue)) return true;

  return false;
};

const checkEmptyEveryRightsScopeTableValue = (
  rightsScopeTableValueInfo?: RightsScopeValue,
) => {
  if (rightsScopeTableValueInfo === undefined) return true;
  return Object.values(rightsScopeTableValueInfo || {}).every((value) => {
    if (Array.isArray(value) === true) {
      return value.length === 0;
    }

    return (
      (value as any)?.props?.item?.text === "" ||
      value === undefined ||
      (value as any)?.props?.children?.at(1).props.item.text === ""
    );
  });
};

const checkEmptyEveryFinancialTermsTableValue = (
  financialTermsValueInfo?: FinancialTermsValue,
) => {
  if (financialTermsValueInfo === undefined) return true;
  return Object.values(financialTermsValueInfo).every((value) => {
    return (value as any)?.props?.item?.text === "" || value === undefined;
  });
};

const DealTermsVentureFormationTableContainer = ({
  sellerName,
  buyerName,
  licensingInfoData,
  otherFinancialTermsData,
  financialTermsData,
  rightScopeData,
  isMobileViewer = false,
}: Props) => {
  const { isMobileScreen: isMobileSize } = useCheckWindowSize();
  const isMobileScreen = isMobileSize && isMobileViewer;

  const isPartnerAAllRightsScopeTableValueEmpty = true;
  const isPartnerBAllRightsScopeTableValueEmpty = rightScopeData.buyer
    .map((tableData) => checkEmptyEveryRightsScopeTableValue(tableData))
    .every((isEmptyData) => isEmptyData === true);

  const isPartnerAFinancialTermsTableValueEmpty = true;
  const isPartnerBFinancialTermsTableValueEmpty =
    checkEmptyEveryFinancialTermsTableValue(financialTermsData.buyer);

  // const isEmptyRightsScopeAndFinancialTerms =
  //   isPartnerAAllRightsScopeTableValueEmpty &&
  //   isPartnerBAllRightsScopeTableValueEmpty &&
  //   isPartnerAFinancialTermsTableValueEmpty &&
  //   isPartnerBFinancialTermsTableValueEmpty;

  const maxRightsScopeTableLength = rightScopeData.buyer.length;
  const rightsScopeEmptyArray = createArrayToCountValue(
    maxRightsScopeTableLength,
  );
  const calculateDrugInfoMaxData = (
    partnerADrugData: {
      drug: React.ReactNode;
      mainUsage: React.ReactNode;
    }[],
    partnerBDrugData: {
      drug: React.ReactNode;
      mainUsage: React.ReactNode;
    }[],
  ) => {
    return Math.max(partnerADrugData.length, partnerBDrugData.length);
  };

  const checkTableGap = () => {
    if (isMobileScreen) return 16;
    return 32;
  };
  const checkTableWithBackgroundGap = () => {
    if (isMobileScreen) return 16;
    return 64;
  };

  const MOBILE_TITLE_DRUG_INFO = "Asset(s) & Main Usage";

  const EMPTY_TABLE_BY_DIRECTION = {
    left:
      isPartnerAFinancialTermsTableValueEmpty &&
      isPartnerAAllRightsScopeTableValueEmpty,
    right:
      isPartnerBFinancialTermsTableValueEmpty &&
      isPartnerBAllRightsScopeTableValueEmpty,
  };
  const isNoneArrow = !!EMPTY_TABLE_BY_DIRECTION.right;
  return (
    <ParagraphSectionLayout>
      <S.TableWrapper $isMobileViewer={isMobileViewer}>
        <S.Table>
          <S.TableBody>
            <S.TableRow>
              <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                <S.TitleContentsShell $isRadius>
                  {sellerName}
                </S.TitleContentsShell>
              </S.BodyShell>
              <S.BodyShell rowSpan={4} width={`${checkTableGap()}px`} />
              <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                <S.TitleContentsShell $isRadius>
                  {buyerName}
                </S.TitleContentsShell>
              </S.BodyShell>
            </S.TableRow>

            <S.TableRow>
              <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                <S.ValueContentsShell
                  $isBottomBorder={
                    checkEmptyRowValue(
                      licensingInfoData.partnerA.responsibilityAndRights,
                    ) &&
                    checkEmptyRowValue(
                      licensingInfoData.partnerB.responsibilityAndRights,
                    )
                  }
                >
                  {licensingInfoData.partnerA.licensingName}
                </S.ValueContentsShell>
              </S.BodyShell>
              <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                <S.ValueContentsShell
                  $isBottomBorder={
                    checkEmptyRowValue(
                      licensingInfoData.partnerA.responsibilityAndRights,
                    ) &&
                    checkEmptyRowValue(
                      licensingInfoData.partnerB.responsibilityAndRights,
                    )
                  }
                >
                  {licensingInfoData.partnerB.licensingName}
                </S.ValueContentsShell>
              </S.BodyShell>
            </S.TableRow>

            <S.TableRow>
              <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                <S.TitleContentsShell
                  $isNoneDisplay={
                    checkEmptyRowValue(
                      licensingInfoData.partnerA.responsibilityAndRights,
                    ) &&
                    checkEmptyRowValue(
                      licensingInfoData.partnerB.responsibilityAndRights,
                    )
                  }
                >
                  Responsibility & Rights
                </S.TitleContentsShell>
              </S.BodyShell>
              <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                <S.TitleContentsShell
                  $isNoneDisplay={
                    checkEmptyRowValue(
                      licensingInfoData.partnerA.responsibilityAndRights,
                    ) &&
                    checkEmptyRowValue(
                      licensingInfoData.partnerB.responsibilityAndRights,
                    )
                  }
                >
                  Responsibility & Rights
                </S.TitleContentsShell>
              </S.BodyShell>
            </S.TableRow>

            <S.TableRow>
              <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                <S.ValueContentsShell
                  $isBottomBorder
                  $isNoneDisplay={
                    checkEmptyRowValue(
                      licensingInfoData.partnerA.responsibilityAndRights,
                    ) &&
                    checkEmptyRowValue(
                      licensingInfoData.partnerB.responsibilityAndRights,
                    )
                  }
                >
                  {licensingInfoData.partnerA.responsibilityAndRights}
                </S.ValueContentsShell>
              </S.BodyShell>
              <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                <S.ValueContentsShell
                  $isBottomBorder
                  $isNoneDisplay={
                    checkEmptyRowValue(
                      licensingInfoData.partnerA.responsibilityAndRights,
                    ) &&
                    checkEmptyRowValue(
                      licensingInfoData.partnerB.responsibilityAndRights,
                    )
                  }
                >
                  {licensingInfoData.partnerB.responsibilityAndRights}
                </S.ValueContentsShell>
              </S.BodyShell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableWrapper>
      <S.TableGroupWrapper
        $isMobileViewer={isMobileViewer}
        $isNoneDisplay // isEmptyRightsScopeAndFinancialTerms
        $dataInfoByDirection={EMPTY_TABLE_BY_DIRECTION}
      >
        <S.TableWrapper $isMobileViewer={isMobileViewer}>
          <S.ArrowContainer
            $isMobileViewer={isMobileViewer}
            $isNoneDisplay={isNoneArrow}
          >
            <DealTermArrow arrowType="right" />
          </S.ArrowContainer>
          {isPartnerBAllRightsScopeTableValueEmpty === false && (
            <>
              <S.Table>
                <S.TableBody>
                  <S.TableRow>
                    <S.BodyShell
                      width={`calc(50% - ${
                        checkTableWithBackgroundGap() / 2
                      }px)`}
                    >
                      <S.TableSectionTitle
                        $isNoneDisplay={isPartnerAAllRightsScopeTableValueEmpty}
                      >
                        Asset Transfer
                      </S.TableSectionTitle>
                    </S.BodyShell>
                    <S.BodyShell
                      rowSpan={100}
                      width={`${checkTableWithBackgroundGap()}px`}
                    />
                    <S.BodyShell
                      width={`calc(50% - ${
                        checkTableWithBackgroundGap() / 2
                      }px)`}
                    >
                      <S.TableSectionTitle
                        $isNoneDisplay={isPartnerBAllRightsScopeTableValueEmpty}
                      >
                        Asset Transfer
                      </S.TableSectionTitle>
                    </S.BodyShell>
                  </S.TableRow>
                </S.TableBody>
              </S.Table>
              {rightsScopeEmptyArray.map((tableIndex) => {
                const maxDrugInfoRowLength = calculateDrugInfoMaxData(
                  [],
                  rightScopeData.buyer.at(tableIndex)?.drugInfo || [],
                );
                const drugInfoEmptyArray =
                  createArrayToCountValue(maxDrugInfoRowLength);

                const isEmptyPartnerARightsScope = true;
                const isEmptyPartnerBRightsScope =
                  rightScopeData.buyer.at(tableIndex) === undefined ||
                  !!checkEmptyEveryRightsScopeTableValue(
                    rightScopeData.buyer.at(tableIndex),
                  );

                return (
                  <S.Table key={`rights-scope-table-${tableIndex}`}>
                    <S.TableBody>
                      {isMobileScreen && (
                        <S.TableRow>
                          <S.BodyShell
                            width={`calc(50% - ${
                              checkTableWithBackgroundGap() / 2
                            }px)`}
                            colSpan={2}
                          >
                            <S.TitleContentsShell
                              $isRadius
                              $isNoneDisplay={isEmptyPartnerARightsScope}
                            >
                              {MOBILE_TITLE_DRUG_INFO}
                            </S.TitleContentsShell>
                          </S.BodyShell>

                          <S.BodyShell
                            rowSpan={100}
                            width={`${checkTableWithBackgroundGap()}px`}
                          />
                          <S.BodyShell
                            width={`calc(50% - ${
                              checkTableWithBackgroundGap() / 2
                            }px)`}
                            colSpan={2}
                          >
                            <S.TitleContentsShell
                              $isRadius
                              $isNoneDisplay={isEmptyPartnerBRightsScope}
                            >
                              {MOBILE_TITLE_DRUG_INFO}
                            </S.TitleContentsShell>
                          </S.BodyShell>
                        </S.TableRow>
                      )}
                      {!isMobileScreen && (
                        <S.TableRow>
                          <S.BodyShell
                            width={`calc(50% - ${
                              checkTableWithBackgroundGap() / 2
                            }px)`}
                          >
                            <S.TitleContentsShell
                              $isRadius
                              $isNoneDisplay={isEmptyPartnerARightsScope}
                              style={{
                                borderTopRightRadius: 0,
                                borderRight: "0.5px solid #E5E7EB",
                              }}
                            >
                              Asset(s)
                            </S.TitleContentsShell>
                          </S.BodyShell>

                          <S.BodyShell
                            width={`calc(50% - ${
                              checkTableWithBackgroundGap() / 2
                            }px)`}
                          >
                            <S.TitleContentsShell
                              $isRadius
                              $isNoneDisplay={isEmptyPartnerARightsScope}
                              style={{
                                borderTopLeftRadius: 0,
                                borderLeft: "0.5px solid #E5E7EB",
                              }}
                            >
                              Main Usage
                            </S.TitleContentsShell>
                          </S.BodyShell>
                          <S.BodyShell
                            rowSpan={100}
                            width={`${checkTableWithBackgroundGap()}px`}
                          />
                          <S.BodyShell
                            width={`calc(50% - ${
                              checkTableWithBackgroundGap() / 2
                            }px)`}
                          >
                            <S.TitleContentsShell
                              $isRadius
                              $isNoneDisplay={isEmptyPartnerBRightsScope}
                              style={{
                                borderTopRightRadius: 0,
                                borderRight: "0.5px solid #E5E7EB",
                              }}
                            >
                              Asset(s)
                            </S.TitleContentsShell>
                          </S.BodyShell>

                          <S.BodyShell
                            width={`calc(50% - ${
                              checkTableWithBackgroundGap() / 2
                            }px)`}
                          >
                            <S.TitleContentsShell
                              $isRadius
                              $isNoneDisplay={isEmptyPartnerBRightsScope}
                              style={{
                                borderTopLeftRadius: 0,
                                borderLeft: "0.5px solid #E5E7EB",
                              }}
                            >
                              Main Usage
                            </S.TitleContentsShell>
                          </S.BodyShell>
                        </S.TableRow>
                      )}

                      {drugInfoEmptyArray.map((drugInfoIndex) => {
                        const isLastRowOfThisTable =
                          checkEmptyRowValue(
                            rightScopeData.buyer.at(tableIndex)?.territory,
                          ) &&
                          checkEmptyRowValue(
                            rightScopeData.buyer.at(tableIndex)?.exclusivity,
                          ) &&
                          maxDrugInfoRowLength === drugInfoIndex + 1;

                        return (
                          <S.TableRow>
                            <S.BodyShell
                              width={`calc(50% - ${checkTableGap() / 2}px)`}
                              colSpan={isMobileScreen ? 2 : 1}
                              $isNoneDisplay={
                                isEmptyPartnerARightsScope ||
                                (checkEmptyRowValue(
                                  rightScopeData.buyer
                                    .at(tableIndex)
                                    ?.drugInfo.at(drugInfoIndex)?.drug,
                                ) &&
                                  checkEmptyRowValue(
                                    rightScopeData.buyer
                                      .at(tableIndex)
                                      ?.drugInfo.at(drugInfoIndex)?.mainUsage,
                                  ))
                              }
                            >
                              <S.ValueContentsShell
                                $isNoneDisplay={
                                  isEmptyPartnerARightsScope ||
                                  (checkEmptyRowValue(
                                    rightScopeData.buyer
                                      .at(tableIndex)
                                      ?.drugInfo.at(drugInfoIndex)?.drug,
                                  ) &&
                                    checkEmptyRowValue(
                                      rightScopeData.buyer
                                        .at(tableIndex)
                                        ?.drugInfo.at(drugInfoIndex)?.mainUsage,
                                    ))
                                }
                                style={{
                                  borderRight: "0.5px solid #E5E7EB",
                                  borderBottom:
                                    maxDrugInfoRowLength !==
                                      drugInfoIndex + 1 || isLastRowOfThisTable
                                      ? "1px solid #E5E7EB"
                                      : "none",
                                  borderBottomLeftRadius: isLastRowOfThisTable
                                    ? "0.4rem"
                                    : "0",
                                  borderBottomRightRadius: isLastRowOfThisTable
                                    ? isMobileScreen
                                      ? "0.4rem"
                                      : "0"
                                    : "0",
                                }}
                              />
                            </S.BodyShell>
                            {!isMobileScreen && (
                              <S.BodyShell
                                width={`calc(50% - ${checkTableGap() / 2}px)`}
                                $isNoneDisplay={
                                  isEmptyPartnerARightsScope ||
                                  (checkEmptyRowValue(
                                    rightScopeData.buyer
                                      .at(tableIndex)
                                      ?.drugInfo.at(drugInfoIndex)?.drug,
                                  ) &&
                                    checkEmptyRowValue(
                                      rightScopeData.buyer
                                        .at(tableIndex)
                                        ?.drugInfo.at(drugInfoIndex)?.mainUsage,
                                    ))
                                }
                              >
                                <S.ValueContentsShell
                                  $isNoneDisplay={
                                    isEmptyPartnerARightsScope ||
                                    (checkEmptyRowValue(
                                      rightScopeData.buyer
                                        .at(tableIndex)
                                        ?.drugInfo.at(drugInfoIndex)?.drug,
                                    ) &&
                                      checkEmptyRowValue(
                                        rightScopeData.buyer
                                          .at(tableIndex)
                                          ?.drugInfo.at(drugInfoIndex)
                                          ?.mainUsage,
                                      ))
                                  }
                                  style={{
                                    borderLeft: "0.5px solid #E5E7EB",
                                    borderBottom:
                                      maxDrugInfoRowLength !==
                                        drugInfoIndex + 1 ||
                                      isLastRowOfThisTable
                                        ? "1px solid #E5E7EB"
                                        : "none",
                                    borderBottomRightRadius:
                                      isLastRowOfThisTable ? "0.4rem" : "0",
                                    borderBottomLeftRadius: isLastRowOfThisTable
                                      ? isMobileScreen
                                        ? "0.4rem"
                                        : "0"
                                      : "0",
                                  }}
                                />
                              </S.BodyShell>
                            )}
                            <S.BodyShell
                              width={`calc(50% - ${checkTableGap() / 2}px)`}
                              colSpan={isMobileScreen ? 2 : 1}
                              $isNoneDisplay={
                                isEmptyPartnerBRightsScope ||
                                (checkEmptyRowValue(
                                  rightScopeData.buyer
                                    .at(tableIndex)
                                    ?.drugInfo.at(drugInfoIndex)?.drug,
                                ) &&
                                  checkEmptyRowValue(
                                    rightScopeData.buyer
                                      .at(tableIndex)
                                      ?.drugInfo.at(drugInfoIndex)?.mainUsage,
                                  ))
                              }
                            >
                              <S.ValueContentsShell
                                $isNoneDisplay={
                                  isEmptyPartnerBRightsScope ||
                                  (checkEmptyRowValue(
                                    rightScopeData.buyer
                                      .at(tableIndex)
                                      ?.drugInfo.at(drugInfoIndex)?.drug,
                                  ) &&
                                    checkEmptyRowValue(
                                      rightScopeData.buyer
                                        .at(tableIndex)
                                        ?.drugInfo.at(drugInfoIndex)?.mainUsage,
                                    ))
                                }
                                style={{
                                  borderRight: "0.5px solid #E5E7EB",
                                  borderBottom:
                                    maxDrugInfoRowLength !==
                                      drugInfoIndex + 1 || isLastRowOfThisTable
                                      ? "1px solid #E5E7EB"
                                      : "none",
                                  borderBottomLeftRadius: isLastRowOfThisTable
                                    ? "0.4rem"
                                    : "0",
                                  borderBottomRightRadius: isLastRowOfThisTable
                                    ? isMobileScreen
                                      ? "0.4rem"
                                      : "0"
                                    : "0",
                                }}
                              >
                                {!isMobileScreen &&
                                  rightScopeData.buyer
                                    .at(tableIndex)
                                    ?.drugInfo.at(drugInfoIndex)?.drug}
                                {isMobileScreen && (
                                  <>
                                    <S.MobileDrugText>
                                      {
                                        rightScopeData.buyer
                                          .at(tableIndex)
                                          ?.drugInfo.at(drugInfoIndex)?.drug
                                      }
                                    </S.MobileDrugText>
                                    {!checkTextEmpty(
                                      rightScopeData.buyer
                                        .at(tableIndex)
                                        ?.drugInfo.at(drugInfoIndex)?.mainUsage,
                                    ) && (
                                      <S.MobileMainUsageText>
                                        {
                                          rightScopeData.buyer
                                            .at(tableIndex)
                                            ?.drugInfo.at(drugInfoIndex)
                                            ?.mainUsage
                                        }
                                      </S.MobileMainUsageText>
                                    )}
                                  </>
                                )}
                              </S.ValueContentsShell>
                            </S.BodyShell>
                            {!isMobileScreen && (
                              <S.BodyShell
                                width={`calc(50% - ${checkTableGap() / 2}px)`}
                                $isNoneDisplay={
                                  isEmptyPartnerBRightsScope ||
                                  (checkEmptyRowValue(
                                    rightScopeData.buyer
                                      .at(tableIndex)
                                      ?.drugInfo.at(drugInfoIndex)?.drug,
                                  ) &&
                                    checkEmptyRowValue(
                                      rightScopeData.buyer
                                        .at(tableIndex)
                                        ?.drugInfo.at(drugInfoIndex)?.mainUsage,
                                    ))
                                }
                              >
                                <S.ValueContentsShell
                                  $isNoneDisplay={
                                    isEmptyPartnerBRightsScope ||
                                    (checkEmptyRowValue(
                                      rightScopeData.buyer
                                        .at(tableIndex)
                                        ?.drugInfo.at(drugInfoIndex)?.drug,
                                    ) &&
                                      checkEmptyRowValue(
                                        rightScopeData.buyer
                                          .at(tableIndex)
                                          ?.drugInfo.at(drugInfoIndex)
                                          ?.mainUsage,
                                      ))
                                  }
                                  style={{
                                    borderLeft: "0.5px solid #E5E7EB",
                                    borderBottom:
                                      maxDrugInfoRowLength !==
                                        drugInfoIndex + 1 ||
                                      isLastRowOfThisTable
                                        ? "1px solid #E5E7EB"
                                        : "none",
                                    borderBottomRightRadius:
                                      isLastRowOfThisTable ? "0.4rem" : "0",
                                    borderBottomLeftRadius: isLastRowOfThisTable
                                      ? isMobileScreen
                                        ? "0.4rem"
                                        : "0"
                                      : "0",
                                  }}
                                >
                                  {
                                    rightScopeData.buyer
                                      .at(tableIndex)
                                      ?.drugInfo.at(drugInfoIndex)?.mainUsage
                                  }
                                </S.ValueContentsShell>
                              </S.BodyShell>
                            )}
                          </S.TableRow>
                        );
                      })}
                      <S.TableRow>
                        <S.BodyShell
                          width={`calc(50% - ${
                            checkTableWithBackgroundGap() / 2
                          }px)`}
                          colSpan={2}
                          $isNoneDisplay={isEmptyPartnerARightsScope}
                        >
                          <S.TitleContentsShell
                            $isNoneDisplay={
                              isEmptyPartnerARightsScope ||
                              checkEmptyRowValue(
                                rightScopeData.buyer.at(tableIndex)
                                  ?.exclusivity,
                              )
                            }
                          >
                            Exclusivity
                          </S.TitleContentsShell>
                        </S.BodyShell>

                        <S.BodyShell
                          width={`calc(50% - ${
                            checkTableWithBackgroundGap() / 2
                          }px)`}
                          colSpan={2}
                          $isNoneDisplay={
                            isEmptyPartnerBRightsScope ||
                            checkEmptyRowValue(
                              rightScopeData.buyer.at(tableIndex)?.exclusivity,
                            )
                          }
                        >
                          <S.TitleContentsShell
                            $isNoneDisplay={
                              isEmptyPartnerBRightsScope ||
                              checkEmptyRowValue(
                                rightScopeData.buyer.at(tableIndex)
                                  ?.exclusivity,
                              )
                            }
                          >
                            Exclusivity
                          </S.TitleContentsShell>
                        </S.BodyShell>
                      </S.TableRow>

                      <S.TableRow>
                        <S.BodyShell
                          width={`calc(50% - ${checkTableGap() / 2}px)`}
                          colSpan={2}
                          $isNoneDisplay={
                            isEmptyPartnerARightsScope ||
                            checkEmptyRowValue(
                              rightScopeData.buyer.at(tableIndex)?.exclusivity,
                            )
                          }
                        >
                          <S.ValueContentsShell
                            $isNoneDisplay={
                              isEmptyPartnerARightsScope ||
                              checkEmptyRowValue(
                                rightScopeData.buyer.at(tableIndex)
                                  ?.exclusivity,
                              )
                            }
                            $isBottomBorder={checkEmptyRowValue(
                              rightScopeData.buyer.at(tableIndex)?.territory,
                            )}
                          />
                        </S.BodyShell>
                        <S.BodyShell
                          width={`calc(50% - ${checkTableGap() / 2}px)`}
                          colSpan={2}
                          $isNoneDisplay={
                            isEmptyPartnerBRightsScope ||
                            checkEmptyRowValue(
                              rightScopeData.buyer.at(tableIndex)?.exclusivity,
                            )
                          }
                        >
                          <S.ValueContentsShell
                            $isNoneDisplay={
                              isEmptyPartnerBRightsScope ||
                              checkEmptyRowValue(
                                rightScopeData.buyer.at(tableIndex)
                                  ?.exclusivity,
                              )
                            }
                            $isBottomBorder={checkEmptyRowValue(
                              rightScopeData.buyer.at(tableIndex)?.territory,
                            )}
                          >
                            {rightScopeData.buyer.at(tableIndex)?.exclusivity}
                          </S.ValueContentsShell>
                        </S.BodyShell>
                      </S.TableRow>

                      <S.TableRow>
                        <S.BodyShell
                          width={`calc(50% - ${checkTableGap() / 2}px)`}
                          colSpan={2}
                          $isNoneDisplay={
                            isEmptyPartnerARightsScope ||
                            checkEmptyRowValue(
                              rightScopeData.buyer.at(tableIndex)?.territory,
                            )
                          }
                        >
                          <S.TitleContentsShell
                            $isNoneDisplay={
                              isEmptyPartnerARightsScope ||
                              checkEmptyRowValue(
                                rightScopeData.buyer.at(tableIndex)?.territory,
                              )
                            }
                          >
                            Territory
                          </S.TitleContentsShell>
                        </S.BodyShell>
                        <S.BodyShell
                          width={`calc(50% - ${checkTableGap() / 2}px)`}
                          colSpan={2}
                          $isNoneDisplay={
                            isEmptyPartnerBRightsScope ||
                            checkEmptyRowValue(
                              rightScopeData.buyer.at(tableIndex)?.territory,
                            )
                          }
                        >
                          <S.TitleContentsShell
                            $isNoneDisplay={
                              isEmptyPartnerBRightsScope ||
                              checkEmptyRowValue(
                                rightScopeData.buyer.at(tableIndex)?.territory,
                              )
                            }
                          >
                            Territory
                          </S.TitleContentsShell>
                        </S.BodyShell>
                      </S.TableRow>

                      <S.TableRow>
                        <S.BodyShell
                          width={`calc(50% - ${checkTableGap() / 2}px)`}
                          colSpan={2}
                          $isNoneDisplay={
                            isEmptyPartnerARightsScope ||
                            checkEmptyRowValue(
                              rightScopeData.buyer.at(tableIndex)?.territory,
                            )
                          }
                        >
                          <S.ValueContentsShell
                            $isBottomBorder
                            $isNoneDisplay={
                              isEmptyPartnerARightsScope ||
                              checkEmptyRowValue(
                                rightScopeData.buyer.at(tableIndex)?.territory,
                              )
                            }
                          />
                        </S.BodyShell>
                        <S.BodyShell
                          width={`calc(50% - ${checkTableGap() / 2}px)`}
                          colSpan={2}
                          $isNoneDisplay={
                            isEmptyPartnerBRightsScope ||
                            checkEmptyRowValue(
                              rightScopeData.buyer.at(tableIndex)?.territory,
                            )
                          }
                        >
                          <S.ValueContentsShell
                            $isBottomBorder
                            $isNoneDisplay={
                              isEmptyPartnerBRightsScope ||
                              checkEmptyRowValue(
                                rightScopeData.buyer.at(tableIndex)?.territory,
                              )
                            }
                          >
                            {rightScopeData.buyer.at(tableIndex)?.territory}
                          </S.ValueContentsShell>
                        </S.BodyShell>
                      </S.TableRow>
                    </S.TableBody>
                  </S.Table>
                );
              })}
            </>
          )}
        </S.TableWrapper>
        <S.TableWrapper $isMobileViewer={isMobileViewer}>
          <S.Table>
            <S.TableBody>
              <S.TableRow>
                <S.BodyShell
                  width={`calc(50% - ${checkTableWithBackgroundGap() / 2}px)`}
                >
                  <S.TableSectionTitle
                    $isNoneDisplay={isPartnerAFinancialTermsTableValueEmpty}
                  >
                    Investment Terms
                  </S.TableSectionTitle>
                </S.BodyShell>
                <S.BodyShell
                  rowSpan={100}
                  width={`${checkTableWithBackgroundGap()}px`}
                />
                <S.BodyShell
                  width={`calc(50% - ${checkTableWithBackgroundGap() / 2}px)`}
                >
                  <S.TableSectionTitle
                    $isNoneDisplay={isPartnerBFinancialTermsTableValueEmpty}
                  >
                    Investment Terms
                  </S.TableSectionTitle>
                </S.BodyShell>
              </S.TableRow>
            </S.TableBody>
          </S.Table>
          <S.Table>
            <S.TableBody>
              <S.TableRow>
                <S.BodyShell
                  width={`calc(50% - ${checkTableWithBackgroundGap() / 2}px)`}
                >
                  <S.TitleContentsShell
                    $isRadius
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.initialInvestment,
                      ) || isPartnerAFinancialTermsTableValueEmpty
                    }
                  >
                    Initial Investment
                  </S.TitleContentsShell>
                </S.BodyShell>
                <S.BodyShell
                  rowSpan={100}
                  width={`${checkTableWithBackgroundGap()}px`}
                />
                <S.BodyShell
                  width={`calc(50% - ${checkTableWithBackgroundGap() / 2}px)`}
                >
                  <S.TitleContentsShell
                    $isRadius
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.initialInvestment,
                      ) || isPartnerBFinancialTermsTableValueEmpty
                    }
                  >
                    Initial Investment
                  </S.TitleContentsShell>
                </S.BodyShell>
              </S.TableRow>

              <S.TableRow>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.ValueContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.initialInvestment,
                      ) || isPartnerAFinancialTermsTableValueEmpty
                    }
                    $isBottomBorder={
                      checkEmptyRowValue(
                        financialTermsData.buyer.contingentInvestment,
                      ) &&
                      checkEmptyRowValue(
                        financialTermsData.buyer.totalInvestment,
                      ) &&
                      checkEmptyRowValue(
                        financialTermsData.buyer.ownershipStructureDetails,
                      )
                    }
                  />
                </S.BodyShell>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.ValueContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.initialInvestment,
                      ) || isPartnerBFinancialTermsTableValueEmpty
                    }
                    $isBottomBorder={
                      checkEmptyRowValue(
                        financialTermsData.buyer.contingentInvestment,
                      ) &&
                      checkEmptyRowValue(
                        financialTermsData.buyer.totalInvestment,
                      ) &&
                      checkEmptyRowValue(
                        financialTermsData.buyer.ownershipStructureDetails,
                      )
                    }
                  >
                    {financialTermsData.buyer.initialInvestment}
                  </S.ValueContentsShell>
                </S.BodyShell>
              </S.TableRow>

              <S.TableRow>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.TitleContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.contingentInvestment,
                      ) || isPartnerAFinancialTermsTableValueEmpty
                    }
                  >
                    Contingent Investment
                  </S.TitleContentsShell>
                </S.BodyShell>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.TitleContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.contingentInvestment,
                      ) || isPartnerBFinancialTermsTableValueEmpty
                    }
                  >
                    Contingent Investment
                  </S.TitleContentsShell>
                </S.BodyShell>
              </S.TableRow>

              <S.TableRow>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.ValueContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.contingentInvestment,
                      ) || isPartnerAFinancialTermsTableValueEmpty
                    }
                    $isBottomBorder={
                      checkEmptyRowValue(
                        financialTermsData.buyer.totalInvestment,
                      ) &&
                      checkEmptyRowValue(
                        financialTermsData.buyer.ownershipStructureDetails,
                      )
                    }
                  />
                </S.BodyShell>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.ValueContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.contingentInvestment,
                      ) || isPartnerBFinancialTermsTableValueEmpty
                    }
                    $isBottomBorder={
                      checkEmptyRowValue(
                        financialTermsData.buyer.totalInvestment,
                      ) &&
                      checkEmptyRowValue(
                        financialTermsData.buyer.ownershipStructureDetails,
                      )
                    }
                  >
                    {financialTermsData.buyer.contingentInvestment}
                  </S.ValueContentsShell>
                </S.BodyShell>
              </S.TableRow>

              <S.TableRow>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.TitleContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.totalInvestment,
                      ) || isPartnerAFinancialTermsTableValueEmpty
                    }
                  >
                    Total Investment
                  </S.TitleContentsShell>
                </S.BodyShell>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.TitleContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.totalInvestment,
                      ) || isPartnerBFinancialTermsTableValueEmpty
                    }
                  >
                    Total Investment
                  </S.TitleContentsShell>
                </S.BodyShell>
              </S.TableRow>

              <S.TableRow>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.ValueContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.totalInvestment,
                      ) || isPartnerAFinancialTermsTableValueEmpty
                    }
                    $isBottomBorder={checkEmptyRowValue(
                      financialTermsData.buyer.ownershipStructureDetails,
                    )}
                  />
                </S.BodyShell>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.ValueContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.totalInvestment,
                      ) || isPartnerBFinancialTermsTableValueEmpty
                    }
                    $isBottomBorder={checkEmptyRowValue(
                      financialTermsData.buyer.ownershipStructureDetails,
                    )}
                  >
                    {financialTermsData.buyer.totalInvestment}
                  </S.ValueContentsShell>
                </S.BodyShell>
              </S.TableRow>

              <S.TableRow>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.TitleContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.ownershipStructureDetails,
                      ) || isPartnerAFinancialTermsTableValueEmpty
                    }
                  >
                    Ownership Structure Details
                  </S.TitleContentsShell>
                </S.BodyShell>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.TitleContentsShell
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.ownershipStructureDetails,
                      ) || isPartnerBFinancialTermsTableValueEmpty
                    }
                  >
                    Ownership Structure Details
                  </S.TitleContentsShell>
                </S.BodyShell>
              </S.TableRow>

              <S.TableRow>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.ValueContentsShell
                    $isBottomBorder
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.ownershipStructureDetails,
                      ) || isPartnerAFinancialTermsTableValueEmpty
                    }
                  />
                </S.BodyShell>
                <S.BodyShell width={`calc(50% - ${checkTableGap() / 2}px)`}>
                  <S.ValueContentsShell
                    $isBottomBorder
                    $isNoneDisplay={
                      checkEmptyRowValue(
                        financialTermsData.buyer.ownershipStructureDetails,
                      ) || isPartnerBFinancialTermsTableValueEmpty
                    }
                  >
                    {financialTermsData.buyer.ownershipStructureDetails}
                  </S.ValueContentsShell>
                </S.BodyShell>
              </S.TableRow>
            </S.TableBody>
          </S.Table>
        </S.TableWrapper>
      </S.TableGroupWrapper>
      <S.TableWrapper
        $isMobileViewer={isMobileViewer}
        $isNoneDisplay // checkEmptyRowValue(otherFinancialTermsData.otherFinancialTerms)
      >
        <S.Table>
          <S.TableBody>
            <S.TableRow>
              <S.BodyShell width="100%">
                <S.TitleContentsShell $isRadius>
                  Other Financial Terms
                </S.TitleContentsShell>
              </S.BodyShell>
            </S.TableRow>

            <S.TableRow>
              <S.BodyShell width="100%">
                <S.ValueContentsShell $isBottomBorder>
                  {otherFinancialTermsData.otherFinancialTerms}
                </S.ValueContentsShell>
              </S.BodyShell>
            </S.TableRow>
          </S.TableBody>
        </S.Table>
      </S.TableWrapper>
    </ParagraphSectionLayout>
  );
};

export default DealTermsVentureFormationTableContainer;
