import React from "react";

import BottomButtonContainer from "@/components/shared/ui/BottomButtonContainer/BottomButtonContainer";

import CTGBottomButtonContainer from "./components/CTGBottomButtonContainer/CTGBottomButtonContainer";

const FillInTableBottomButtonContainer = () => {
  return (
    <BottomButtonContainer>
      <CTGBottomButtonContainer />
    </BottomButtonContainer>
  );
};

export default FillInTableBottomButtonContainer;
