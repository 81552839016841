import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useCTGEntryDataValue } from "@stores/ctgEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { CTG_SECTION } from "../constants/articleSection.constants";

export type TrialInterventionDrugItem = {
  interventionId: string;
  drugNames: string;
  drugProfileIndex: number;
};

export type TrialInterventionDrugList = TrialInterventionDrugItem[];

export const TrialInterventionDrugListContext = createContext<{
  trialInterventionDrugList: TrialInterventionDrugList;
  changeTrialInterventionDrugList: (
    newStatus: TrialInterventionDrugList,
  ) => void;
}>({
  trialInterventionDrugList: [],
  changeTrialInterventionDrugList: () => [],
});

interface Props {
  children: React.ReactNode;
}

export const TrialInterventionDrugListProvider = ({ children }: Props) => {
  const [trialInterventionDrugList, setTrialInterventionDrugList] =
    useState<TrialInterventionDrugList>([]);

  const entryData = useCTGEntryDataValue(getPressIdFromURL());
  const articleSection = CTG_SECTION;
  const targetEntryData = entryData[articleSection]?.["Drug Profile"];

  const changeTrialInterventionDrugList = (
    updatedList: TrialInterventionDrugList,
  ): void => {
    setTrialInterventionDrugList(updatedList);
  };

  const trialInterventionDrugListWrapper = useMemo(
    () => ({ trialInterventionDrugList, changeTrialInterventionDrugList }),
    [trialInterventionDrugList],
  );

  useEffect(() => {
    if (!targetEntryData) return;
    const drugList = targetEntryData?.map((tableItem, tableIndex) => ({
      interventionId: tableItem?.attributionInfo?.attributionId ?? "",
      drugProfileIndex: tableIndex,
      drugNames:
        [
          tableItem["Brand Name"].value?.at(0)?.text ?? null,
          tableItem["Code Name"].value?.at(0)?.text ?? null,
          tableItem["INN Name"].value?.at(0)?.text ?? null,
        ]
          .filter((item) => !!item)
          .join("__") ?? "",
    }));
    changeTrialInterventionDrugList(drugList);
  }, [targetEntryData]);

  return (
    <TrialInterventionDrugListContext.Provider
      value={trialInterventionDrugListWrapper}
    >
      {children}
    </TrialInterventionDrugListContext.Provider>
  );
};

export const useTrialInterventionDrugList = () => {
  const { trialInterventionDrugList, changeTrialInterventionDrugList } =
    useContext(TrialInterventionDrugListContext);

  return { trialInterventionDrugList, changeTrialInterventionDrugList };
};
