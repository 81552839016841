import { useCallback } from "react";

import {
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_RIGHTS_SCOPE_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS,
  VENTURE_FORMATION_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { DealsSection } from "@/types/deals.types";
import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

interface Props {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  tableKey: string | null;
  tableIndex: number;
}

const useClearVentureFormationGroupShellSection = ({
  groupTableIndex,
  groupTableKey,
  tableIndex,
  tableKey,
}: Props) => {
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();

  const { info, changeInfo } = useTableCreatorPositionInfo();

  const handleClearGroupShellSection = (
    groupShellSectionKey: string | null,
  ) => {
    const clearRightScopeGroupShellSection = (): ArticleEntryData => {
      if (
        info.groupShellSectionKey !==
        VENTURE_FORMATION_GROUP_SHELL_KEYS.rightsScope
      )
        return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          [ENTRY_DATA_LICENSEE_TABLE]: entryData.Deals?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableInfo[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                ].map((groupShellInfo) => {
                  return {
                    ...groupShellInfo,
                    [ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug]: {
                      ...groupShellInfo[ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug],
                      value: [],
                    },
                    [ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity]: {
                      ...groupShellInfo[
                        ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity
                      ],
                      value: [],
                    },
                    [ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication]: {
                      ...groupShellInfo[
                        ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication
                      ],
                      value: [],
                    },
                    [ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory]: {
                      ...groupShellInfo[ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory],
                      value: [],
                    },
                  };
                }),
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearInvestmentTermsGroupShell = (): ArticleEntryData => {
      if (
        info.groupShellSectionKey !==
        VENTURE_FORMATION_GROUP_SHELL_KEYS.investmentTerms
      )
        return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              const targetVFData =
                tableInfo?.[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                ];
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms]: {
                  ...targetVFData,
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentAndDetailVf]:
                    targetVFData[
                      ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                        .contingentInvestmentAndDetailVf
                    ].map((subGroupShellInfo) => {
                      return {
                        ...subGroupShellInfo,
                        [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentInvestmentVf]:
                          {
                            ...subGroupShellInfo[
                              ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                                .contingentInvestmentVf
                            ],
                            value: [],
                          },
                        [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.contingentDetailVf]:
                          {
                            ...subGroupShellInfo?.[
                              ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                                .contingentDetailVf
                            ],
                            value: [],
                          },
                      };
                    }),
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.disclosedPaymentsSumVf]:
                    {
                      ...targetVFData[
                        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                          .disclosedPaymentsSumVf
                      ],
                      value: [],
                    },
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.initialInvestment]: {
                    ...targetVFData[
                      ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.totalInvestmentVf
                    ],
                    value: [],
                  },
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.ownershipStructureDetails]:
                    {
                      ...targetVFData[
                        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                          .ownershipStructureDetails
                      ],
                      value: [],
                    },
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.totalInvestmentVf]: {
                    ...targetVFData[
                      ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                        .ownershipStructureDetails
                    ],
                    value: [],
                  },
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearOtherFinancialTermsGroupShell = (): ArticleEntryData => {
      if (
        info.groupShellSectionKey !==
        VENTURE_FORMATION_GROUP_SHELL_KEYS.otherFinancialTerms
      )
        return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData.Deals,
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              const targetVFData =
                tableInfo?.[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms
                ];
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.ventureFormationTerms]: {
                  ...targetVFData,
                  [ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS.otherFinancialTermsVf]:
                    {
                      ...targetVFData[
                        ENTRY_DATA_VENTURE_FORMATION_TERMS_KEYS
                          .otherFinancialTermsVf
                      ],
                      value: [],
                    },
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    if (
      info.groupShellSectionKey ===
      VENTURE_FORMATION_GROUP_SHELL_KEYS.rightsScope
    ) {
      setEntryData(clearRightScopeGroupShellSection());
      changeCurrentHistory({
        entryData: clearRightScopeGroupShellSection(),
        changedDataInfo: {
          target: "groupShellSection",
          action: "clear",
          position: {
            groupTableIndex,
            groupTableKey,
            tableKey,
            tableIndex,
            groupShellSectionKey,
            groupShellSectionIndex: null,
            groupShellIndex: null,
            groupShellKey: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellSectionKey ===
      VENTURE_FORMATION_GROUP_SHELL_KEYS.investmentTerms
    ) {
      setEntryData(clearInvestmentTermsGroupShell());
      changeCurrentHistory({
        entryData: clearInvestmentTermsGroupShell(),
        changedDataInfo: {
          target: "groupShellSection",
          action: "clear",
          position: {
            groupTableIndex,
            groupTableKey,
            tableKey,
            tableIndex,
            groupShellSectionKey,
            groupShellSectionIndex: null,
            groupShellIndex: null,
            groupShellKey: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellSectionKey ===
      VENTURE_FORMATION_GROUP_SHELL_KEYS.otherFinancialTerms
    ) {
      setEntryData(clearOtherFinancialTermsGroupShell());
      changeCurrentHistory({
        entryData: clearOtherFinancialTermsGroupShell(),
        changedDataInfo: {
          target: "groupShellSection",
          action: "clear",
          position: {
            groupTableIndex,
            groupTableKey,
            tableKey,
            tableIndex,
            groupShellSectionKey,
            groupShellSectionIndex: null,
            groupShellIndex: null,
            groupShellKey: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleClickToClearVentureFormationGroupShellSection = (
    groupShellSectionKey: string | null,
  ) => {
    if (focusStatus.type === "clear") {
      handleClearGroupShellSection(groupShellSectionKey);
    }
  };

  const handleKeyDownToClearVentureFormationGroupShellSection = useCallback(
    (event: KeyboardEvent, groupShellSectionKey: string | null) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearGroupShellSection(groupShellSectionKey);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [focusStatus.type],
  );

  return {
    handleClickToClearVentureFormationGroupShellSection,
    handleKeyDownToClearVentureFormationGroupShellSection,
  };
};

export default useClearVentureFormationGroupShellSection;
