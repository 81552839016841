import styled, { css } from "styled-components";

import { mq } from "@styles/mediaQuery";

export const FillInTableWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 8.4rem);
`;

export const ColumnWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 10.8rem);
  margin-top: 0.8rem;

  ${[mq[1]]} {
    justify-content: left;
    height: calc(100% - 13.2rem);
  }
`;

export const Column = styled.div<{
  columnWidth: number;
  columnWrapperWidth: number;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  min-width: 0rem;
  overflow: hidden;

  &.first-column {
    ${({ columnWidth }) =>
      columnWidth <= 160 &&
      columnWidth > 0 &&
      css`
        display: none;
      `}
    width: ${({ columnWidth }) =>
      columnWidth > 0 ? `${columnWidth / 10}rem` : "33%"};
  }
  &.second-column {
    ${({ columnWidth, columnWrapperWidth }) =>
      columnWidth + 80 >= columnWrapperWidth &&
      css`
        display: none;
      `}
    width: ${({ columnWidth }) =>
      columnWidth > 0 ? `calc(100% - ${columnWidth / 10}rem)` : "67%"};
  }

  &:last-child {
    max-width: calc(100% - 32rem);
  }

  ${[mq[1]]} {
    &:last-child {
      margin-left: 0.8rem;
    }
  }
  ${[mq[3]]} {
    &:last-child {
      margin-left: 1.6rem;
    }
  }
`;

export const ColumnContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const FillInTableContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 1.2rem;
`;

export const LabelInfoViewContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 5.2rem;
  cursor: pointer;
  padding: 0.6rem 6rem 0.6rem 0.8rem;
  border-radius: 0.4rem;

  & > div {
    display: flex;
    justify-content: space-between;
    gap: 0.4rem;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;

    & > h3 > strong {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 145%;
    }

    & > h3 > img {
      opacity: 1;
    }
  }

  .copy-press-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding-top: 0.4rem;
  }
`;

export const ContentsFabContainer = styled.div`
  position: absolute;
  bottom: 2.6rem;
  z-index: 30;
  left: 50%;
  transform: translateX(-50%);

  ${mq[1]} {
    bottom: 4rem;
  }
`;
