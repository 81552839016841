import { useQuery, useSuspenseQuery } from "@tanstack/react-query";

import { ClassificationCode } from "@/types/code.types";
import { QUERY_KEYS } from "@api/queryKeys";

import { pressContentApis } from "./pressContent.service";

export const useGetPressContentQuery = (
  pressId: number,
  options?: { skip: boolean },
) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.pressContent, pressId],
    queryFn: () => pressContentApis.get(String(pressId)),
    retry: false,
    throwOnError: false,
    enabled: !options?.skip,
  });

  return query;
};

export const useGetPressContentBoldKeywordQuery = (
  classificationCode: ClassificationCode,
) => {
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.pressContentBoldKeyword, classificationCode],
    queryFn: async () => {
      if (classificationCode === "") {
        return {
          data: [],
          status: 200,
          message: "",
        };
      }
      return pressContentApis.getBoldKeyword(classificationCode);
    },
  });

  return query;
};

export const useGetPressContentCurrencyKeywordQuery = () => {
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.pressContentCurrencyKeyword],
    queryFn: () => pressContentApis.getCurrencyKeyword(),
  });

  return query;
};
