interface Props {
  width?: number;
  height?: number;
  fill?: string;
}

const IconExternalLink = ({
  width = 16,
  height = 16,
  fill = "#2A2F37",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7097 3.55348C16.6873 3.50109 16.6555 3.45176 16.6143 3.40803C16.6073 3.40052 16.6 3.39323 16.5925 3.38618C16.503 3.30204 16.3825 3.25049 16.25 3.25049H12.0833C11.8072 3.25049 11.5833 3.47435 11.5833 3.75049C11.5833 4.02663 11.8072 4.25049 12.0833 4.25049H15.0429L8.25755 11.0358C8.06229 11.2311 8.06229 11.5477 8.25755 11.7429C8.45282 11.9382 8.7694 11.9382 8.96466 11.7429L15.75 4.95759V7.91715C15.75 8.1933 15.9739 8.41715 16.25 8.41715C16.5261 8.41715 16.75 8.1933 16.75 7.91715V3.75049C16.75 3.68053 16.7356 3.61393 16.7097 3.55348ZM5.13889 6.33382C4.90314 6.33382 4.67705 6.42747 4.51035 6.59417C4.34365 6.76087 4.25 6.98696 4.25 7.22271V14.8616C4.25 15.0973 4.34365 15.3234 4.51035 15.4901C4.67705 15.6568 4.90314 15.7505 5.13889 15.7505H12.7778C13.0135 15.7505 13.2396 15.6568 13.4063 15.4901C13.573 15.3234 13.6667 15.0973 13.6667 14.8616V10.6949C13.6667 10.4188 13.8905 10.1949 14.1667 10.1949C14.4428 10.1949 14.6667 10.4188 14.6667 10.6949V14.8616C14.6667 15.3626 14.4677 15.843 14.1134 16.1972C13.7592 16.5515 13.2787 16.7505 12.7778 16.7505H5.13889C4.63792 16.7505 4.15748 16.5515 3.80324 16.1972C3.44901 15.843 3.25 15.3626 3.25 14.8616V7.22271C3.25 6.72175 3.44901 6.2413 3.80324 5.88706C4.15748 5.53283 4.63792 5.33382 5.13889 5.33382H9.30556C9.5817 5.33382 9.80556 5.55768 9.80556 5.83382C9.80556 6.10996 9.5817 6.33382 9.30556 6.33382H5.13889Z"
        fill={fill}
      />
    </svg>
  );
};

IconExternalLink.displayName = "ExternalLink";

export default IconExternalLink;
