import {
  LICENSE_AGREEMENT_KEY,
  LICENSE_AGREEMENT_GROUP_TABLE_KEY,
  LICENSE_AGREEMENT_TABLE_KEYS,
  LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS,
  LICENSE_AGREEMENT_GROUP_SHELL_KEYS,
  LICENSE_AGREEMENT_SHELL_KEYS,
  LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  MERGE_ACQUISITION_KEY,
  MERGE_ACQUISITION_GROUP_TABLE_KEY,
  MERGE_ACQUISITION_TABLE_KEYS,
  MERGE_ACQUISITION_GROUP_SHELL_KEYS,
  MERGE_ACQUISITION_SHELL_KEYS,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS,
  CROSS_LICENSE_AGREEMENT_KEY,
  CROSS_LICENSE_AGREEMENT_GROUP_TABLE_KEY,
  CROSS_LICENSE_AGREEMENT_TABLE_KEYS,
  CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS,
  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  VENTURE_FORMATION_KEY,
  VENTURE_FORMATION_TABLE_KEYS,
  VENTURE_FORMATION_GROUP_SHELL_KEYS,
  VENTURE_FORMATION_GROUP_TABLE_KEY,
  VENTURE_FORMATION_SHELL_KEYS,
  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import {
  CrossLicenseAgreementLayout,
  DealsTermsLayoutValue,
  MergeAcquisitionLayout,
  VentureFormationLayout,
} from "@FillInTableContainer/shared/types/tableCreator.types";

interface DealTermsLayoutValue {
  "License Agreement": DealsTermsLayoutValue;
  "M&A": MergeAcquisitionLayout;
  "Cross License Agreement": CrossLicenseAgreementLayout;
  "Venture Formation": VentureFormationLayout;
}

export const DEAL_TERMS_LAYOUT: DealTermsLayoutValue = {
  [LICENSE_AGREEMENT_KEY]: {
    groupTableKey: LICENSE_AGREEMENT_GROUP_TABLE_KEY,
    tableList: [
      {
        tableKey: LICENSE_AGREEMENT_TABLE_KEYS.dealPurpose,
        groupShellSectionList: [
          {
            groupShellSectionKey:
              LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS.none,
            groupShellList: [
              {
                groupShellKey: LICENSE_AGREEMENT_GROUP_SHELL_KEYS.dealPurpose,
                subGroupShellList: [
                  {
                    subGroupShellKey: null,
                    shellList: [
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.purposeOfTheDeal,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: LICENSE_AGREEMENT_TABLE_KEYS.licensor,
        groupShellSectionList: [
          {
            groupShellSectionKey:
              LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS.none,
            groupShellList: [
              {
                groupShellKey: LICENSE_AGREEMENT_GROUP_SHELL_KEYS.licensor,
                subGroupShellList: [
                  {
                    subGroupShellKey: null,
                    shellList: [
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.licensorName,
                      },
                      {
                        shellKey:
                          LICENSE_AGREEMENT_SHELL_KEYS.licensorResponsibilityRights,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: LICENSE_AGREEMENT_TABLE_KEYS.licensee,
        groupShellSectionList: [
          {
            groupShellSectionKey:
              LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS.none,
            groupShellList: [
              {
                groupShellKey: LICENSE_AGREEMENT_GROUP_SHELL_KEYS.none,
                subGroupShellList: [
                  {
                    subGroupShellKey:
                      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.none,
                    shellList: [
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.licenseeName,
                      },
                      {
                        shellKey:
                          LICENSE_AGREEMENT_SHELL_KEYS.licenseeResponsibilityRightsScLa,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            groupShellSectionKey:
              LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS.rightsScope,
            groupShellList: [
              {
                groupShellKey: LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScope,
                subGroupShellList: [
                  {
                    subGroupShellKey: null,
                    shellList: [
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.drug,
                      },
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.Indication,
                      },
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.exclusivity,
                      },
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.territory,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            groupShellSectionKey:
              LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS.financialTerms,
            groupShellList: [
              {
                groupShellKey:
                  LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTerms,
                subGroupShellList: [
                  {
                    subGroupShellKey:
                      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.upfront,
                    shellList: [
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.upfrontPayment,
                      },
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.royalty,
                      },
                    ],
                  },
                  {
                    subGroupShellKey:
                      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone,
                    shellList: [
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.milestonePayment,
                      },
                      {
                        shellKey:
                          LICENSE_AGREEMENT_SHELL_KEYS.milestoneConditionTitle,
                      },
                    ],
                  },
                  {
                    subGroupShellKey:
                      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail,
                    shellList: [
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.otherPaymentScLa,
                      },
                      {
                        shellKey:
                          LICENSE_AGREEMENT_SHELL_KEYS.otherPaymentDetailScLa,
                      },
                    ],
                  },
                  {
                    subGroupShellKey:
                      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.total,
                    shellList: [
                      {
                        shellKey: LICENSE_AGREEMENT_SHELL_KEYS.totalPaymentScLa,
                      },
                      {
                        shellKey:
                          LICENSE_AGREEMENT_SHELL_KEYS.disclosedPaymentsSumScLa,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            groupShellSectionKey:
              LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS.otherFinancialTerms,
            groupShellList: [
              {
                groupShellKey:
                  LICENSE_AGREEMENT_GROUP_SHELL_KEYS.otherFinancialTerms,
                subGroupShellList: [
                  {
                    subGroupShellKey:
                      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherFinancialTerms,
                    shellList: [
                      {
                        shellKey:
                          LICENSE_AGREEMENT_SHELL_KEYS.otherFinancialTermsScLa,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: LICENSE_AGREEMENT_TABLE_KEYS.additionalInformation,
        groupShellSectionList: [
          {
            groupShellSectionKey:
              LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS.none,
            groupShellList: [
              {
                groupShellKey:
                  LICENSE_AGREEMENT_GROUP_SHELL_KEYS.additionalInformation,
                subGroupShellList: [
                  {
                    subGroupShellKey:
                      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.none,
                    shellList: [
                      {
                        shellKey:
                          LICENSE_AGREEMENT_SHELL_KEYS.additionalInformation,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  [MERGE_ACQUISITION_KEY]: {
    groupTableKey: MERGE_ACQUISITION_GROUP_TABLE_KEY,
    tableList: [
      {
        tableKey: MERGE_ACQUISITION_TABLE_KEYS.dealPurpose,
        groupShellList: [
          {
            groupShellKey: MERGE_ACQUISITION_GROUP_SHELL_KEYS.dealPurpose,
            subGroupShellList: [
              {
                subGroupShellKey: null,
                shellList: [
                  {
                    shellKey: MERGE_ACQUISITION_SHELL_KEYS.purposeOfTheDeal,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: MERGE_ACQUISITION_TABLE_KEYS.acquiredCompany,
        groupShellList: [
          {
            groupShellKey: MERGE_ACQUISITION_GROUP_SHELL_KEYS.acquiredCompany,
            subGroupShellList: [
              {
                subGroupShellKey: MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.none,
                shellList: [
                  {
                    shellKey: MERGE_ACQUISITION_SHELL_KEYS.licensorName,
                  },
                  {
                    shellKey:
                      MERGE_ACQUISITION_SHELL_KEYS.licensorResponsibilityRights,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: MERGE_ACQUISITION_TABLE_KEYS.acquiringCompany,
        groupShellList: [
          {
            groupShellKey: MERGE_ACQUISITION_GROUP_SHELL_KEYS.none,
            subGroupShellList: [
              {
                subGroupShellKey: MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.none,
                shellList: [
                  {
                    shellKey: MERGE_ACQUISITION_SHELL_KEYS.licenseeName,
                  },
                  {
                    shellKey:
                      MERGE_ACQUISITION_SHELL_KEYS.licenseeResponsibilityAndRightsMa,
                  },
                ],
              },
            ],
          },
          {
            groupShellKey: MERGE_ACQUISITION_GROUP_SHELL_KEYS.rightsScope,
            subGroupShellList: [
              {
                subGroupShellKey: MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.none,
                shellList: [
                  {
                    shellKey: MERGE_ACQUISITION_SHELL_KEYS.drug,
                  },
                  {
                    shellKey: MERGE_ACQUISITION_SHELL_KEYS.indication,
                  },
                ],
              },
            ],
          },
          {
            groupShellKey: MERGE_ACQUISITION_GROUP_SHELL_KEYS.financialTerms,
            subGroupShellList: [
              {
                subGroupShellKey:
                  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.upfrontPayment,
                shellList: [
                  {
                    shellKey: MERGE_ACQUISITION_SHELL_KEYS.upfrontPaymentMa,
                  },
                  {
                    shellKey:
                      MERGE_ACQUISITION_SHELL_KEYS.upfrontPricePerShareMa,
                  },
                ],
              },
              {
                subGroupShellKey:
                  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment,
                shellList: [
                  {
                    shellKey: MERGE_ACQUISITION_SHELL_KEYS.contingentPaymentMa,
                  },
                  {
                    shellKey:
                      MERGE_ACQUISITION_SHELL_KEYS.contingentPricePerShareMa,
                  },
                  {
                    shellKey:
                      MERGE_ACQUISITION_SHELL_KEYS.contingentMilestoneMa,
                  },
                ],
              },
              {
                subGroupShellKey:
                  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.totalPayment,
                shellList: [
                  {
                    shellKey: MERGE_ACQUISITION_SHELL_KEYS.totalPaymentMa,
                  },
                  {
                    shellKey: MERGE_ACQUISITION_SHELL_KEYS.pricePerShare,
                  },
                  {
                    shellKey:
                      MERGE_ACQUISITION_SHELL_KEYS.numberOfAcquiringShares,
                  },
                ],
              },
              {
                subGroupShellKey:
                  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.disclosedPaymentsSum,
                shellList: [
                  {
                    shellKey:
                      MERGE_ACQUISITION_SHELL_KEYS.disclosedPaymentsSumMa,
                  },
                  {
                    shellKey:
                      MERGE_ACQUISITION_SHELL_KEYS.disclosedPaymentsSumPricePerShareMa,
                  },
                ],
              },
              {
                subGroupShellKey:
                  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.mergerFinancialTerms,
                shellList: [
                  {
                    shellKey:
                      MERGE_ACQUISITION_SHELL_KEYS.mergerFinancialTermsMa,
                  },
                ],
              },
              {
                subGroupShellKey:
                  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.otherFinancialTerms,
                shellList: [
                  {
                    shellKey:
                      MERGE_ACQUISITION_SHELL_KEYS.otherFinancialTermsMa,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: MERGE_ACQUISITION_TABLE_KEYS.additionalInformation,
        groupShellList: [
          {
            groupShellKey:
              MERGE_ACQUISITION_GROUP_SHELL_KEYS.additionalInformation,
            subGroupShellList: [
              {
                subGroupShellKey: MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.none,
                shellList: [
                  {
                    shellKey:
                      MERGE_ACQUISITION_SHELL_KEYS.additionalInformation,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  [CROSS_LICENSE_AGREEMENT_KEY]: {
    groupTableKey: CROSS_LICENSE_AGREEMENT_GROUP_TABLE_KEY,
    tableList: [
      {
        tableKey: CROSS_LICENSE_AGREEMENT_TABLE_KEYS.dealPurpose,
        groupShellList: [
          {
            groupShellKey: CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.dealPurpose,
            subGroupShellList: [
              {
                subGroupShellKey: null,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.purposeOfTheDeal,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: CROSS_LICENSE_AGREEMENT_TABLE_KEYS.licensee,
        groupShellList: [
          {
            groupShellKey:
              CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.licensingPartnerA,
            subGroupShellList: [
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.none,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.licenseeName,
                  },
                  {
                    shellKey:
                      LICENSE_AGREEMENT_SHELL_KEYS.licenseeResponsibilityRightsScLa,
                  },
                ],
              },
            ],
          },
          {
            groupShellKey:
              CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.licensingPartnerB,
            subGroupShellList: [
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.none,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.licenseeName,
                  },
                  {
                    shellKey:
                      LICENSE_AGREEMENT_SHELL_KEYS.licenseeResponsibilityRightsScLa,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: CROSS_LICENSE_AGREEMENT_TABLE_KEYS.rightsScope,
        groupShellList: [
          {
            groupShellKey:
              CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScopePartnerA,
            subGroupShellList: [
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerA,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.drug,
                  },
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.Indication,
                  },
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.exclusivity,
                  },
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.territory,
                  },
                ],
              },
            ],
          },
          {
            groupShellKey:
              CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScopePartnerB,
            subGroupShellList: [
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerB,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.drug,
                  },
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.Indication,
                  },
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.exclusivity,
                  },
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.territory,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: CROSS_LICENSE_AGREEMENT_TABLE_KEYS.financialTerms,
        groupShellList: [
          {
            groupShellKey:
              CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTermsPartnerA,
            subGroupShellList: [
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.upfront,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.upfrontPayment,
                  },
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.royalty,
                  },
                ],
              },
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.milestonePayment,
                  },
                  {
                    shellKey:
                      LICENSE_AGREEMENT_SHELL_KEYS.milestoneConditionTitle,
                  },
                ],
              },
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.otherPaymentScLa,
                  },
                  {
                    shellKey:
                      LICENSE_AGREEMENT_SHELL_KEYS.otherPaymentDetailScLa,
                  },
                ],
              },
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.total,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.totalPaymentScLa,
                  },
                  {
                    shellKey:
                      LICENSE_AGREEMENT_SHELL_KEYS.disclosedPaymentsSumScLa,
                  },
                ],
              },
            ],
          },
          {
            groupShellKey:
              CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTermsPartnerB,
            subGroupShellList: [
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.upfront,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.upfrontPayment,
                  },
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.royalty,
                  },
                ],
              },
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.milestonePayment,
                  },
                  {
                    shellKey:
                      LICENSE_AGREEMENT_SHELL_KEYS.milestoneConditionTitle,
                  },
                ],
              },
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.otherPaymentScLa,
                  },
                  {
                    shellKey:
                      LICENSE_AGREEMENT_SHELL_KEYS.otherPaymentDetailScLa,
                  },
                ],
              },
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.total,
                shellList: [
                  {
                    shellKey: LICENSE_AGREEMENT_SHELL_KEYS.totalPaymentScLa,
                  },
                  {
                    shellKey:
                      LICENSE_AGREEMENT_SHELL_KEYS.disclosedPaymentsSumScLa,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: CROSS_LICENSE_AGREEMENT_TABLE_KEYS.otherFinancialTerms,
        groupShellList: [
          {
            groupShellKey:
              CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.otherFinancialTerms,
            subGroupShellList: [
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherFinancialTerms,
                shellList: [
                  {
                    shellKey:
                      LICENSE_AGREEMENT_SHELL_KEYS.otherFinancialTermsScLa,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: CROSS_LICENSE_AGREEMENT_TABLE_KEYS.additionalInformation,
        groupShellList: [
          {
            groupShellKey:
              CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.additionalInformation,
            subGroupShellList: [
              {
                subGroupShellKey:
                  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.none,
                shellList: [
                  {
                    shellKey:
                      LICENSE_AGREEMENT_SHELL_KEYS.additionalInformation,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  [VENTURE_FORMATION_KEY]: {
    groupTableKey: VENTURE_FORMATION_GROUP_TABLE_KEY,
    tableList: [
      {
        tableKey: VENTURE_FORMATION_TABLE_KEYS.dealPurpose,
        groupShellList: [
          {
            groupShellKey: VENTURE_FORMATION_GROUP_SHELL_KEYS.dealPurpose,
            subGroupShellList: [
              {
                subGroupShellKey: null,
                shellList: [
                  {
                    shellKey: VENTURE_FORMATION_SHELL_KEYS.purposeOfTheDeal,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: VENTURE_FORMATION_TABLE_KEYS.newCompany,
        groupShellList: [
          {
            groupShellKey: VENTURE_FORMATION_GROUP_SHELL_KEYS.newCompany,
            subGroupShellList: [
              {
                subGroupShellKey: VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.none,
                shellList: [
                  {
                    shellKey: VENTURE_FORMATION_SHELL_KEYS.licensorName,
                  },
                  {
                    shellKey:
                      VENTURE_FORMATION_SHELL_KEYS.licensorResponsibilityRights,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: VENTURE_FORMATION_TABLE_KEYS.existingCompany,
        groupShellList: [
          {
            groupShellKey: VENTURE_FORMATION_GROUP_SHELL_KEYS.existingCompany,
            subGroupShellList: [
              {
                subGroupShellKey: VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.none,
                shellList: [
                  {
                    shellKey: VENTURE_FORMATION_SHELL_KEYS.licenseeName,
                  },
                  {
                    shellKey:
                      VENTURE_FORMATION_SHELL_KEYS.licenseeResponsibilityAndRightsVf,
                  },
                ],
              },
            ],
          },
          {
            groupShellKey: VENTURE_FORMATION_GROUP_SHELL_KEYS.rightsScope,
            subGroupShellList: [
              {
                subGroupShellKey: VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.none,
                shellList: [
                  {
                    shellKey: VENTURE_FORMATION_SHELL_KEYS.drug,
                  },
                  {
                    shellKey: VENTURE_FORMATION_SHELL_KEYS.indication,
                  },
                  {
                    shellKey: VENTURE_FORMATION_SHELL_KEYS.exclusivity,
                  },
                  {
                    shellKey: VENTURE_FORMATION_SHELL_KEYS.territory,
                  },
                ],
              },
            ],
          },
          {
            groupShellKey: VENTURE_FORMATION_GROUP_SHELL_KEYS.investmentTerms,
            subGroupShellList: [
              {
                subGroupShellKey:
                  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.initialInvestment,
                shellList: [
                  {
                    shellKey: VENTURE_FORMATION_SHELL_KEYS.initialInvestment,
                  },
                ],
              },
              {
                subGroupShellKey:
                  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.contingentInvestment,
                shellList: [
                  {
                    shellKey: VENTURE_FORMATION_SHELL_KEYS.contingentInvestment,
                  },
                  {
                    shellKey: VENTURE_FORMATION_SHELL_KEYS.contingentDetail,
                  },
                ],
              },
              {
                subGroupShellKey:
                  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.totalInvestmentVf,
                shellList: [
                  {
                    shellKey: VENTURE_FORMATION_SHELL_KEYS.totalInvestmentVf,
                  },
                  {
                    shellKey:
                      VENTURE_FORMATION_SHELL_KEYS.disclosedPaymentsSumVf,
                  },
                ],
              },
              {
                subGroupShellKey:
                  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.ownershipStructureDetails,
                shellList: [
                  {
                    shellKey:
                      VENTURE_FORMATION_SHELL_KEYS.ownershipStructureDetails,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: VENTURE_FORMATION_TABLE_KEYS.otherFinancialTerms,
        groupShellList: [
          {
            groupShellKey:
              VENTURE_FORMATION_GROUP_SHELL_KEYS.otherFinancialTerms,
            subGroupShellList: [
              {
                subGroupShellKey:
                  VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.otherFinancialTerms,
                shellList: [
                  {
                    shellKey:
                      VENTURE_FORMATION_SHELL_KEYS.otherFinancialTermsVf,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        tableKey: VENTURE_FORMATION_TABLE_KEYS.additionalInformation,
        groupShellList: [
          {
            groupShellKey:
              VENTURE_FORMATION_GROUP_SHELL_KEYS.additionalInformation,
            subGroupShellList: [
              {
                subGroupShellKey: VENTURE_FORMATION_SUB_GROUP_SHELL_KEYS.none,
                shellList: [
                  {
                    shellKey:
                      VENTURE_FORMATION_SHELL_KEYS.additionalInformation,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
