import { apiClient } from "@api/apiClient";

import { GetInitCTGEntryDataResponse } from "./ctgInitEntryData.interface";

const API_ENDPOINT = {
  get: "/des/article-entry-data/init-data",
};

export const ctgInitEntryDataApis = {
  get: async (id: string) => {
    const { data } = await apiClient.get<GetInitCTGEntryDataResponse>(
      `${API_ENDPOINT.get}?id=${id}`,
    );

    return data.data;
  },
};
