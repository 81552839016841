import React from "react";

import * as S from "./PressPreviewModal.style";
import PreviewLoading from "./PreviewLoading/PreviewLoading";
import { preventPropagation } from "./utils/preventPropagation";

interface Props {
  previewPress: string | "";
  isLoading: boolean;
  isError: boolean;
}

const PressPreviewModal = ({ previewPress, isLoading, isError }: Props) => {
  if (isError || !previewPress || isLoading) {
    return null;
  }

  return (
    <S.PressContentHoverModal
      className="press-hover-modal"
      onClick={preventPropagation}
    >
      <S.PressContents>
        {isLoading ? <PreviewLoading /> : previewPress?.trim() ?? ""}
      </S.PressContents>
    </S.PressContentHoverModal>
  );
};

export default PressPreviewModal;
