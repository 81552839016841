/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";

import TableCreatorListBox from "@/components/business/CTGFillInTableContainer/shared/components/TableCreatorListBox/TableCreatorListBox";
import { CTG_SECTION } from "@/components/business/CTGFillInTableContainer/shared/constants/articleSection.constants";
import { AUTO_COMPANY_SHELL } from "@/components/business/CTGFillInTableContainer/shared/constants/autoCompleteListShell";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@/components/business/CTGFillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import { useTableCreatorFocusStatus } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorPositionInfo } from "@/components/business/CTGFillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useHandleTextFieldInputEvent } from "@/components/business/CTGFillInTableContainer/shared/hooks/useHandleTextFieldInputEvent";
import { EntryDataValueAndOptions } from "@/types/drugProfile.types";
import { useCTGInfoQuery } from "@api/ctg/ctgInfo/ctgInfo";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import CopyButton from "./components/CopyButton/CopyButton";
import DeleteButton from "./components/DeleteButton/DeleteButton";
import ValidationMessage from "./components/ValidationMessage/ValidationMessage";
import * as S from "./TableCreatorTextFieldInput.style";
import { IS_NOT_EDITABLE_SHELL_KEYS } from "../../constants/notEditableShellKey.constants";

import type { GroupTableKeyType } from "@/components/business/CTGFillInTableContainer/shared/types/tableCreator.types";

interface Props {
  placeholder: string;
  shellValue: EntryDataValueAndOptions;
  shellKey: string;
  groupTableKey: GroupTableKeyType;
  groupTableIndex: number;
  groupShellKey: string | null;
  tableKey: string | null;
  tableIndex: number;
  groupShellIndex: number;
  subGroupShellKey: string | null;
  subGroupShellIndex: number | null;
  shellIndex: number;
  valueIndex: number;
  changeTextFieldMarginBottom: (margin: number) => void;
  isMultipleLastValue: boolean;
  isMultipleValue?: boolean;
  openValidationInfo: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  closeValidationInfo: () => void;
}

const TableCreatorTextFieldInput = forwardRef(
  (
    {
      placeholder,
      shellValue,
      shellKey,
      groupTableKey,
      groupTableIndex,
      groupShellKey,
      tableKey,
      tableIndex,
      groupShellIndex,
      subGroupShellKey,
      subGroupShellIndex,
      shellIndex,
      valueIndex,
      changeTextFieldMarginBottom,
      isMultipleLastValue,
      isMultipleValue,
      openValidationInfo,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { info } = useTableCreatorPositionInfo();
    const { focusStatus } = useTableCreatorFocusStatus();

    const positionInfo = {
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellKey,
      groupShellIndex,
      subGroupShellKey,
      subGroupShellIndex,
      shellKey,
      shellIndex,
      valueIndex,
    };

    const isFocusedShell =
      info.tableIndex === tableIndex &&
      info.groupShellKey === groupShellKey &&
      info.groupShellIndex === groupShellIndex &&
      info.shellKey === shellKey &&
      info.shellIndex === shellIndex &&
      info.valueIndex === valueIndex;

    const {
      textFieldTextareaRef,
      targetListIndex,
      selectedValue,
      validationMessage,
      valueInfo,
      aiList,
      dictionaryList,
      dataSetList,
      isDictionaryList,
      isFocusInputField,
      isDataSetList,
      copyStatus,
      copyCurrentTextValue,
      checkIndexOfValueInDefaultAiList,
      changeSelectedValue,
      handleEnterValueKeyboard,
      handleChangedEnterValue,
      handleFocusTextField,
      handleFocusOutTextField,
      handleBlurTextField,
      deleteCurrentTextField,
      handleChangeDataSetValue,
    } = useHandleTextFieldInputEvent(positionInfo, shellValue);

    const draftArticleId = getPressIdFromURL();
    const articleSection = CTG_SECTION;
    const [isTextDragging, setIsTextDragging] = useState(false);

    const { data: articleInfo } = useCTGInfoQuery(Number(draftArticleId));

    const enableGroupTableKeyList = ENABLE_GROUP_TABLE_KEY_LIST[articleSection];

    const isEnableEdit = enableGroupTableKeyList[
      groupTableKey as keyof typeof enableGroupTableKeyList
    ].includes(articleInfo?.draftArticleStatus ?? "");

    const isNotEditable = IS_NOT_EDITABLE_SHELL_KEYS.includes(shellKey);

    useEffect(() => {
      const handleCheckClickOutside = () => {
        if (isFocusInputField && !isTextDragging) {
          handleFocusOutTextField();
        }
      };
      window.addEventListener("click", handleCheckClickOutside);

      return () => window.removeEventListener("click", handleCheckClickOutside);
    }, [handleFocusOutTextField, isFocusInputField, isTextDragging]);

    if (isNotEditable) {
      return (
        <S.TextFieldInputWrapper className="modality-textfield-height">
          <S.TextareaWrapper className="modality-textfield-height">
            <S.TextFieldTextarea
              placeholder={placeholder}
              defaultValue={selectedValue}
              ref={textFieldTextareaRef}
              isModified={valueInfo.is_modified ?? true}
              isMultipleLastValue={isMultipleLastValue}
              disabled
              $isNotEditable={isNotEditable}
              isFocusedShell={false}
              className="modality-textfield-height"
            />
          </S.TextareaWrapper>
        </S.TextFieldInputWrapper>
      );
    }

    return (
      <S.TextFieldInputWrapper className="modality-textfield-height">
        <S.TextareaWrapper className="modality-textfield-height">
          <S.TextFieldTextarea
            onFocus={(e) => {
              if (isNotEditable) return;

              e.stopPropagation();
              e.preventDefault();
              handleFocusTextField(e);
              setIsTextDragging(true);
            }}
            onBlur={() => {
              handleBlurTextField();
              setIsTextDragging(false);
            }}
            onInput={handleChangedEnterValue}
            onKeyDown={handleEnterValueKeyboard}
            onClick={(event) => {
              if (isNotEditable) return;

              event.stopPropagation();
            }}
            placeholder={placeholder}
            defaultValue={selectedValue}
            ref={textFieldTextareaRef}
            contentEditable={isEnableEdit}
            isModified={valueInfo.is_modified ?? true}
            isMultipleLastValue={isMultipleLastValue}
            isFocusedShell={isFocusedShell}
            disabled={!isEnableEdit || isNotEditable}
            $isNotEditable={isNotEditable}
            className="modality-textfield-height"
          />
        </S.TextareaWrapper>
        {valueInfo.text && (
          <CopyButton
            copyStatus={copyStatus}
            copyCurrentTextValue={copyCurrentTextValue}
          />
        )}
        {valueInfo.text && (
          <DeleteButton deleteCurrentTextField={deleteCurrentTextField} />
        )}
        {isFocusInputField &&
          !AUTO_COMPANY_SHELL.includes(shellKey) &&
          focusStatus.container !== "noFocus" && (
            <TableCreatorListBox
              ref={ref}
              aiList={aiList}
              shellKey={shellKey}
              changeSelectedValue={changeSelectedValue}
              changeTextFieldMarginBottom={changeTextFieldMarginBottom}
              targetListIndex={targetListIndex}
              dictionaryList={dictionaryList}
              isDictionaryList={isDictionaryList}
              isDataSetList={isDataSetList}
              dataSetList={dataSetList}
              checkIndexOfValueInDefaultAiList={
                checkIndexOfValueInDefaultAiList
              }
              handleChangeDataSetValue={handleChangeDataSetValue}
            />
          )}
        {validationMessage !== null && !!selectedValue && (
          <ValidationMessage
            messageInfo={validationMessage}
            openValidationInfo={openValidationInfo}
            isMultipleValue={isMultipleValue}
          />
        )}
      </S.TextFieldInputWrapper>
    );
  },
);

export default TableCreatorTextFieldInput;
