import React, { useState, useEffect } from "react";

import BorderBox from "@atoms/BorderBox/BorderBox";
import FilterTag from "@atoms/FilterTag/FilterTag";
import IconCheck from "@images/common/icon-filter-check-white.svg";
import IconXMarkRed from "@images/common/icon-x-mark-red.svg";
import IconReloadDisabled from "@images/pressList/icon-filter-reload-disabled.svg";
import IconReload from "@images/pressList/icon-filter-reload.svg";
import XMarkIcon from "@images/pressList/icon-x-mark-red.svg";
import {
  AI_CLASSIFICATION_LIST,
  ClassificationList,
  MANUAL_CLASSIFICATION_LIST,
} from "@PressListContainer/classificationInfo.constants";
import { usePressListFilterState } from "@stores/pressList";

import * as S from "./ClassificationFilter.style";
import {
  FOR_ARTICLE_CLASSIFICATION_CODE,
  RESET_ALL_CHECKBOX,
  ARTICLE_CLASSIFICATION_CHECKBOX,
} from "./constants/classificationFilter.constants";
import { changeCheckedAllList } from "./utils/changeCheckedAllList";
import { changeForArticleList } from "./utils/changeForArticleList";

interface Props {
  closeModal: () => void;
}

const ClassificationFilter = ({ closeModal }: Props) => {
  const [pressListFilter, setPressListFilter] = usePressListFilterState();

  const { confirmClassificationManual, confirmClassificationAi } =
    pressListFilter;

  const [classificationListAi, setClassificationListAi] = useState<
    ClassificationList[]
  >(AI_CLASSIFICATION_LIST);
  const [classificationListManual, setClassificationListManual] = useState<
    ClassificationList[]
  >(MANUAL_CLASSIFICATION_LIST);
  const [controlledTotal, setControlledTotal] = useState<
    Record<string, number>
  >({
    ai: confirmClassificationAi?.length || 0,
    manual: confirmClassificationManual?.length || 0,
  });
  const [controlledAllCheckbox, setControlledAllCheckbox] =
    useState<Record<string, boolean>>(RESET_ALL_CHECKBOX);

  const [
    controlledArticleClassificationCheckbox,
    setControlledArticleClassificationCheckbox,
  ] = useState<Record<string, boolean>>(ARTICLE_CLASSIFICATION_CHECKBOX);

  const changeClassificationTargetList = (
    event: React.ChangeEvent<HTMLInputElement>,
    list: ClassificationList[],
  ) => {
    const changeList = list.map((classification) =>
      classification.code === event.currentTarget.value
        ? {
            code: classification.code,
            name: classification.name,
            isCheck: !classification.isCheck,
          }
        : classification,
    );
    return changeList;
  };

  const handleClassificationManual = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const targetElement = event.currentTarget;

    if (targetElement.value === "all") {
      setControlledAllCheckbox({
        ...controlledAllCheckbox,
        manual: targetElement.checked,
      });
      setClassificationListManual(
        changeCheckedAllList(event, classificationListManual),
      );
      setControlledTotal({
        ...controlledTotal,
        manual: targetElement.checked ? classificationListManual.length : 0,
      });
    } else if (targetElement.value === "for Article") {
      setClassificationListManual(
        changeForArticleList(event, classificationListManual),
      );
      setControlledArticleClassificationCheckbox({
        ...controlledArticleClassificationCheckbox,
        manual: targetElement.checked,
      });
      setControlledTotal({
        ...controlledTotal,
        manual: targetElement.checked
          ? controlledTotal.manual + FOR_ARTICLE_CLASSIFICATION_CODE.length
          : 0,
      });
    } else {
      setClassificationListManual(
        changeClassificationTargetList(event, classificationListManual),
      );
      setControlledTotal({
        ...controlledTotal,
        manual: targetElement.checked
          ? controlledTotal.manual + 1
          : controlledTotal.manual - 1,
      });
    }
  };

  const handleClassificationAi = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const targetElement = event.currentTarget;
    if (targetElement.value === "all") {
      setControlledAllCheckbox({
        ...controlledAllCheckbox,
        ai: targetElement.checked,
      });
      setClassificationListAi(
        changeCheckedAllList(event, classificationListAi),
      );

      setControlledTotal({
        ...controlledTotal,
        ai: targetElement.checked ? classificationListAi.length : 0,
      });
    } else if (targetElement.value === "for Article") {
      setClassificationListAi(
        changeForArticleList(event, classificationListAi),
      );
      setControlledArticleClassificationCheckbox({
        ...controlledArticleClassificationCheckbox,
        ai: targetElement.checked,
      });
      setControlledTotal({
        ...controlledTotal,
        ai: targetElement.checked
          ? controlledTotal.manual + FOR_ARTICLE_CLASSIFICATION_CODE.length
          : 0,
      });
    } else {
      setClassificationListAi(
        changeClassificationTargetList(event, classificationListAi),
      );
      setControlledTotal({
        ...controlledTotal,
        ai: targetElement.checked
          ? controlledTotal.ai + 1
          : controlledTotal.ai - 1,
      });
    }
  };

  const handleConfirm = () => {
    const selectedClassificationManual = classificationListManual
      .filter((classification) => classification.isCheck)
      .map((classification) => classification.code);
    const selectedClassificationAi = classificationListAi
      .filter((classification) => classification.isCheck)
      .map((classification) => classification.code);

    setPressListFilter({
      ...pressListFilter,
      confirmClassificationManual: selectedClassificationManual,
      confirmClassificationAi: selectedClassificationAi,
      page: 1,
    });
    closeModal();
  };

  const handleReset = () => {
    setControlledTotal({
      ai: 0,
      manual: 0,
    });
    setControlledAllCheckbox(RESET_ALL_CHECKBOX);
    setClassificationListManual(MANUAL_CLASSIFICATION_LIST);
    setClassificationListAi(AI_CLASSIFICATION_LIST);
    setControlledArticleClassificationCheckbox(ARTICLE_CLASSIFICATION_CHECKBOX);
  };

  useEffect(() => {
    setClassificationListAi(() =>
      AI_CLASSIFICATION_LIST.map((classification) => ({
        code: classification.code,
        name: classification.name,
        isCheck: confirmClassificationAi.includes(classification.code),
      })),
    );
  }, [confirmClassificationAi]);

  useEffect(() => {
    setClassificationListManual(() =>
      MANUAL_CLASSIFICATION_LIST.map((classification) => ({
        code: classification.code,
        name: classification.name,
        isCheck: confirmClassificationManual.includes(classification.code),
      })),
    );
  }, [confirmClassificationManual]);

  useEffect(() => {
    const isAllCheckedAi = controlledTotal.ai === classificationListAi.length;
    const isAllCheckedManual =
      controlledTotal.manual === classificationListManual.length;

    setControlledAllCheckbox({
      ai: isAllCheckedAi,
      manual: isAllCheckedManual,
      all: isAllCheckedAi && isAllCheckedManual,
    });
  }, [
    classificationListAi.length,
    classificationListManual.length,
    controlledTotal,
  ]);

  useEffect(() => {
    const isForArticleCheckAi = classificationListAi.every(
      ({ code, isCheck }) => {
        if (FOR_ARTICLE_CLASSIFICATION_CODE.includes(code)) return isCheck;

        return true;
      },
    );

    const isForArticleCheckManual = classificationListManual.every(
      ({ code, isCheck }) => {
        if (FOR_ARTICLE_CLASSIFICATION_CODE.includes(code)) return isCheck;

        return true;
      },
    );

    setControlledArticleClassificationCheckbox({
      ai: isForArticleCheckAi,
      manual: isForArticleCheckManual,
    });
  }, [classificationListAi, classificationListManual]);

  const confirmButtonText =
    controlledTotal.ai + controlledTotal.manual > 1
      ? "Apply Classification Filters"
      : "Apply Classification Filter";

  const disabledReloadIcon =
    !controlledTotal.ai && !controlledTotal.manual
      ? IconReloadDisabled
      : IconReload;

  return (
    <S.ClassificationWrapper>
      <BorderBox padding="16">
        <S.ClassificationContentWrapper>
          <S.ClassificationRow>
            <S.FilterResetButton
              onClick={handleReset}
              disabled={!controlledTotal.ai && !controlledTotal.manual}
            >
              <img src={disabledReloadIcon} alt="filter reload icon" />
              <S.FilterResetButtonText>Filter Reset</S.FilterResetButtonText>
            </S.FilterResetButton>
            <S.ClassificationCloseButton
              type="button"
              onClick={() => closeModal()}
            >
              <S.ClassificationXMark>
                <img src={XMarkIcon} alt="close filter" />
              </S.ClassificationXMark>
              <S.ClassificationCloseText>Close</S.ClassificationCloseText>
            </S.ClassificationCloseButton>
          </S.ClassificationRow>
          <S.ClassificationRow>
            <S.ClassificationContent>
              <S.ClassificationTitleWrapper>
                <S.ClassificationTitle>Ai Classification</S.ClassificationTitle>
                <FilterTag
                  name="classificationAi"
                  value="all"
                  onChange={handleClassificationAi}
                  checked={controlledAllCheckbox.ai}
                  isAll
                >
                  Select All
                </FilterTag>
                <FilterTag
                  name="classificationAi"
                  value="for Article"
                  onChange={handleClassificationAi}
                  checked={controlledArticleClassificationCheckbox.ai}
                  isAll
                >
                  for Article
                </FilterTag>
              </S.ClassificationTitleWrapper>
              <S.ClassificationFilterFieldset>
                {classificationListAi.map((classification) => (
                  <FilterTag
                    key={`classificationAi-${classification.code}`}
                    tagType="checkbox"
                    onChange={handleClassificationAi}
                    name="classificationAi"
                    value={classification.code}
                    checked={classification.isCheck}
                    filterType="pressListClassification"
                    aiSelected
                  >
                    {classification.name}
                  </FilterTag>
                ))}
              </S.ClassificationFilterFieldset>
            </S.ClassificationContent>
          </S.ClassificationRow>
          <S.ClassificationRow>
            <S.ClassificationContent>
              <S.ClassificationTitleWrapper>
                <S.ClassificationTitle>
                  Manual Classification
                </S.ClassificationTitle>
                <FilterTag
                  name="classificationManual"
                  value="all"
                  onChange={handleClassificationManual}
                  checked={controlledAllCheckbox.manual}
                  isAll
                >
                  Select All
                </FilterTag>
                <FilterTag
                  name="classificationManual"
                  value="for Article"
                  onChange={handleClassificationManual}
                  checked={controlledArticleClassificationCheckbox.manual}
                  isAll
                >
                  for Article
                </FilterTag>
              </S.ClassificationTitleWrapper>
              <S.ClassificationFilterFieldset>
                {classificationListManual.map((classification) => (
                  <React.Fragment
                    key={`classificationManual-${classification.code}`}
                  >
                    {classification.code !== "" ? (
                      <FilterTag
                        tagType="checkbox"
                        onChange={handleClassificationManual}
                        name="classificationManual"
                        value={classification.code}
                        checked={classification.isCheck}
                        filterType="pressListClassification"
                        manualSelected
                      >
                        {classification.name}
                      </FilterTag>
                    ) : (
                      <>
                        <FilterTag
                          key={`classificationManual-${classification.code}`}
                          tagType="checkbox"
                          onChange={handleClassificationManual}
                          name="classificationManual"
                          value={classification.code}
                          checked={classification.isCheck}
                          filterType="pressListClassification"
                          manualSelected
                        >
                          {classification.name}
                        </FilterTag>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </S.ClassificationFilterFieldset>
            </S.ClassificationContent>
          </S.ClassificationRow>
          <S.ConfirmButtonWrapper>
            <S.ConfirmButton onClick={handleConfirm}>
              <img src={IconCheck} alt="filter check icon" />
              <S.ConfirmButtonText>{confirmButtonText}</S.ConfirmButtonText>
            </S.ConfirmButton>
          </S.ConfirmButtonWrapper>
        </S.ClassificationContentWrapper>
      </BorderBox>
      <S.CloseButton type="button" onClick={() => closeModal()}>
        <img src={IconXMarkRed} alt="close filter" />
      </S.CloseButton>
    </S.ClassificationWrapper>
  );
};

export default ClassificationFilter;
