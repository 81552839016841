import {
  ArticleDetailDrugProfileTable,
  DISABLED_PHASE_VALUES,
} from "@frontend/common";

import {
  COMMON_EDITABLE_TEXT_STYLE,
  DRUG_NAME_EDITABLE_TEXT_STYLE,
} from "@EditArticleContainer/components/EditArticle/components/ArticleDetailBody/constants/editableText.constants";
import { isExistDrugProfileTableValue } from "@EditArticleContainer/components/EditArticle/components/ArticleDetailBody/utils/isExistDrugProfileTableValue";
import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useDrugProfileModalityIconList } from "@EditArticleContainer/shared/components/useDrugProfileModalityIconList/useDrugProfileModalityIconList";
import { useCreateCompanyLogoComponentList } from "@EditArticleContainer/shared/hooks/useCreateCompanyLogoComponentList";
import {
  REF_CODE_COMPANY_NAME,
  REF_CODE_DEVELOPMENT_STAGE,
  REF_CODE_GENERIC_BIO_SIMILAR,
  REF_CODE_INN_NAME,
  REF_CODE_MODALITY,
  REF_CODE_OTHER_CHARACTERISTICS,
  REF_CODE_REF_PRODUCT_NAME,
  REF_CODE_ROA,
  REF_CODE_TARGET,
  REF_CODE_TARGET_INDICATION,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";
import { EditArticleText } from "@EditArticleContainer/shared/types/editArticle.types";
import { ParagraphContent } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

import * as S from "./DrugProfileTable.style";
import { convertPhaseToDevelopmentStage } from "../../utils/convertPhaseToDevelopmentStage";

type Props = {
  row: ParagraphContent["TC203"]["text"]["Drug profile"][0];
  index: number;
};

const DrugProfileTable = ({ row, index }: Props) => {
  const { createCompanyLogoComponentList } =
    useCreateCompanyLogoComponentList();

  const formattedDrugName = (
    brandNameValue: EditArticleText[],
    codeNameValue: EditArticleText[],
    innNameValue: EditArticleText[],
  ): string[] => {
    const targetBrandName = brandNameValue?.at(0)?.text || "";
    const targetCodeName = codeNameValue?.at(0)?.text || "";
    const targetInnName = innNameValue?.at(0)?.text || "";

    const checkDrugNameType = () => {
      if (targetBrandName && !targetInnName && !targetCodeName) return "type1";
      if (!targetBrandName && targetInnName && !targetCodeName) return "type2";
      if (!targetBrandName && !targetInnName && targetCodeName) return "type3";
      if (targetBrandName && targetInnName && !targetCodeName) return "type4";
      if (targetBrandName && !targetInnName && targetCodeName) return "type5";
      if (!targetBrandName && targetInnName && targetCodeName) return "type6";
      if (targetBrandName && targetInnName && targetCodeName) return "type7";
    };

    if (checkDrugNameType() === "type1") {
      return [targetBrandName];
    }
    if (checkDrugNameType() === "type2") {
      return [targetInnName];
    }
    if (checkDrugNameType() === "type3") {
      return [targetCodeName];
    }
    if (checkDrugNameType() === "type4") {
      return [`${targetBrandName} (${targetInnName})`];
    }
    if (checkDrugNameType() === "type5") {
      return [targetBrandName];
    }
    if (checkDrugNameType() === "type6") {
      return [`${targetCodeName} (${targetInnName})`];
    }
    if (checkDrugNameType() === "type7") {
      return [`${targetBrandName} (${targetInnName})`];
    }
    return [];
  };

  const editedTextStage = row?.["Development Stage"]?.value?.at(0)?.text;
  const stageValueList =
    row?.Phase?.value?.map?.(
      (item) => convertPhaseToDevelopmentStage(item.text ?? "") ?? "",
    ) || [];

  const isHideRowComponent =
    editedTextStage !== undefined
      ? DISABLED_PHASE_VALUES.some((value) =>
          (editedTextStage ?? "").includes(value),
        )
      : stageValueList?.filter?.(
          (item) => !!DISABLED_PHASE_VALUES.includes(item.toLocaleLowerCase()),
        ).length > 0 || !isExistDrugProfileTableValue(row);

  const DRUG_PROFILE_TABLE_VALUE_TEXT = {
    "Drug Name": createMultiLineText({
      textList:
        formattedDrugName(
          row?.["Brand Name"]?.value ?? [],
          row?.["Code Name"]?.value ?? [],
          row?.["INN Name"]?.value ?? [],
        )?.filter((item) => item !== "") ?? [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
    "Originator/Developer": createMultiLineText({
      textList:
        row?.["Originator/Developer"]?.value.map?.((item) => item.text ?? "") ??
        [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
    Modality: createMultiLineText({
      textList:
        row?.Modality.value.map?.(
          (item) => item.text?.replaceAll("(etc)", "") ?? "",
        ) ?? [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
    Target: createMultiLineText({
      textList: [
        row?.Target.value.map((item) => item.text ?? "")?.join(", ") || "",
        row?.MOA.value.map((item) => item.text ?? "")?.join(", ") || "",
      ],
      parseString: (text) => `- ${text?.trim()}`,
    }),
    "Target Indication": createMultiLineText({
      textList:
        row?.["Target Indication"].value.map?.((item) => item.text ?? "") ?? [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
    ROA: row?.ROA.value.at(0)?.text || "",
    Stage: createMultiLineText({
      textList:
        row?.["Development Stage"]?.value.map?.((item) => item.text ?? "") ||
        row?.Phase?.value?.map?.(
          (item) => convertPhaseToDevelopmentStage(item.text ?? "") ?? "",
        ) ||
        [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
    "Other Characteristics": createMultiLineText({
      textList:
        row?.["Other Characteristics"].value.map?.((item) => item.text ?? "") ??
        [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
    "Generic / Biosimilar":
      row["Generic / Biosimilar"]?.value?.at(0)?.text ||
      (row["Generic / Biosimilar"]?.value as unknown as string[])?.at(0) ===
        "true"
        ? "Yes"
        : "",
    "Ref.Product Name": createMultiLineText({
      textList:
        row?.["Ref.Product Name"]?.value.map?.((item) => item.text ?? "") ?? [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
  };

  const { modalityIconList } = useDrugProfileModalityIconList({
    modalityValue: row?.Modality.value ?? [],
  });

  const DRUG_PROFILE_TABLE_DATA = {
    "Drug Name": (
      <EditableTextContainer
        item={{
          text: DRUG_PROFILE_TABLE_VALUE_TEXT["Drug Name"],
          labels: [],
        }}
        refKey={createRefCode(REF_CODE_INN_NAME, index)}
        canTextEdit
        style={DRUG_NAME_EDITABLE_TEXT_STYLE}
      />
    ),
    "Originator/Developer": (
      <EditableTextContainer
        item={{
          text: DRUG_PROFILE_TABLE_VALUE_TEXT["Originator/Developer"],
          labels: [],
        }}
        refKey={createRefCode(REF_CODE_COMPANY_NAME, index)}
        canTextEdit
        isDisabled
        imagePosition="left"
        imageNodeList={createCompanyLogoComponentList(
          row?.["Originator/Developer"]?.value?.flatMap?.(
            (item) => item.text?.replaceAll("- ", "").split(`\n`) ?? "",
          ) ?? [],
        )}
        style={COMMON_EDITABLE_TEXT_STYLE}
      />
    ),
    Modality: (
      <EditableTextContainer
        item={{
          text: DRUG_PROFILE_TABLE_VALUE_TEXT.Modality,
          labels: [],
        }}
        refKey={createRefCode(REF_CODE_MODALITY, index)}
        canTextEdit
        isDisabled
        imagePosition="left"
        imageNodeList={modalityIconList}
        style={COMMON_EDITABLE_TEXT_STYLE}
      />
    ),
    Target: (
      <EditableTextContainer
        item={{
          text: DRUG_PROFILE_TABLE_VALUE_TEXT.Target,
          labels: [],
        }}
        refKey={createRefCode(REF_CODE_TARGET, index)}
        canTextEdit
        style={COMMON_EDITABLE_TEXT_STYLE}
      />
    ),
    "Target Indication": (
      <EditableTextContainer
        item={{
          text: DRUG_PROFILE_TABLE_VALUE_TEXT["Target Indication"],
          labels: [],
        }}
        refKey={createRefCode(REF_CODE_TARGET_INDICATION, index)}
        canTextEdit
        style={COMMON_EDITABLE_TEXT_STYLE}
      />
    ),
    ROA: (
      <EditableTextContainer
        item={{
          text: DRUG_PROFILE_TABLE_VALUE_TEXT.ROA,
          labels: [],
        }}
        refKey={createRefCode(REF_CODE_ROA, index)}
        canTextEdit
        style={COMMON_EDITABLE_TEXT_STYLE}
      />
    ),
    Stage: (
      <EditableTextContainer
        item={{
          text: DRUG_PROFILE_TABLE_VALUE_TEXT.Stage,
          labels: [],
        }}
        refKey={createRefCode(REF_CODE_DEVELOPMENT_STAGE, index)}
        canTextEdit
        style={COMMON_EDITABLE_TEXT_STYLE}
      />
    ),
    "Other Characteristics": (
      <EditableTextContainer
        item={{
          text: DRUG_PROFILE_TABLE_VALUE_TEXT["Other Characteristics"],
          labels: [],
        }}
        refKey={createRefCode(REF_CODE_OTHER_CHARACTERISTICS, index)}
        canTextEdit
        style={COMMON_EDITABLE_TEXT_STYLE}
      />
    ),
    "Generic / Biosimilar": (
      <EditableTextContainer
        item={{
          text: DRUG_PROFILE_TABLE_VALUE_TEXT["Generic / Biosimilar"],
          labels: [],
        }}
        refKey={createRefCode(REF_CODE_GENERIC_BIO_SIMILAR, index)}
        canTextEdit
        style={COMMON_EDITABLE_TEXT_STYLE}
      />
    ),

    "Ref.Product Name": (
      <EditableTextContainer
        item={{
          text: DRUG_PROFILE_TABLE_VALUE_TEXT["Ref.Product Name"],
          labels: [],
        }}
        refKey={createRefCode(REF_CODE_REF_PRODUCT_NAME, index)}
        canTextEdit
        style={COMMON_EDITABLE_TEXT_STYLE}
      />
    ),
  };

  const IS_EXIST_VALUE = {
    "Drug Name": DRUG_PROFILE_TABLE_VALUE_TEXT["Drug Name"] !== "",
    "Originator/Developer":
      DRUG_PROFILE_TABLE_VALUE_TEXT["Originator/Developer"] !== "",
    Modality: DRUG_PROFILE_TABLE_VALUE_TEXT.Modality !== "",
    Target: DRUG_PROFILE_TABLE_VALUE_TEXT.Target !== "",
    "Target Indication":
      DRUG_PROFILE_TABLE_VALUE_TEXT["Target Indication"] !== "",
    ROA: DRUG_PROFILE_TABLE_VALUE_TEXT.ROA !== "",
    Stage: DRUG_PROFILE_TABLE_VALUE_TEXT.Stage !== "",
    "Other Characteristics":
      DRUG_PROFILE_TABLE_VALUE_TEXT["Other Characteristics"] !== "",
    "Generic / Biosimilar":
      DRUG_PROFILE_TABLE_VALUE_TEXT["Generic / Biosimilar"] !== "",
    "Ref.Product Name":
      DRUG_PROFILE_TABLE_VALUE_TEXT["Ref.Product Name"] !== "",
  };

  return (
    <S.TableWrapper
      className={`is-hide-drug-profile-tableData-${isHideRowComponent}`}
    >
      <ArticleDetailDrugProfileTable
        tableData={DRUG_PROFILE_TABLE_DATA}
        existValueInfo={IS_EXIST_VALUE}
        isEditMode
      />
    </S.TableWrapper>
  );
};

export default DrugProfileTable;
