import styled from "styled-components";

import Button from "@atoms/Button/Button";

export const PressListTableFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 2.4rem;
  width: 100%;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: var(--Spacing-24, 2.4rem) 4rem;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;
`;

export const LeftControls = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Spacing-40, 4rem);
  flex-wrap: nowrap;
`;

export const RightControls = styled.div`
  display: flex;
  height: 2.4rem;
  align-items: center;
  gap: 2.4rem;
  flex-wrap: nowrap;
  margin-left: 4rem;
`;

export const FilterButton = styled(Button)`
  flex-shrink: 0;
  gap: 0.2rem;
  font-weight: 600;
  padding: 0 0.95rem;
`;
