import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";

import { PRODUCT_NAME } from "@constants/productDefault.constants";
import { useHandleSignOut } from "@libs/authorization/hooks/useHandleSignOut";
import { ROUTE_PATHS } from "@routes/routePath";

import * as S from "./Header.style";

const TEXT_SIGN_OUT = "Sign Out";

const Header = () => {
  const { handleSignOut } = useHandleSignOut();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const isLeaveConfirmPage = (() => {
    return (
      location.pathname !== ROUTE_PATHS.pressList &&
      location.pathname !== ROUTE_PATHS.ctgList
    );
  })();

  const handleNavigate = (path: string) => {
    const status =
      new URLSearchParams(window.location.search).get("status") || "";

    const isLocal = import.meta.env.VITE_ENV === "local";
    const admin = status === "admin";

    if (isLeaveConfirmPage && !isLocal && !admin) {
      const confirmed = window.confirm("변경사항이 저장되지 않을 수 있습니다.");
      if (confirmed) {
        navigate(path);
      }
    } else {
      navigate(path);
    }
  };

  const isActivePath = (path: string) => {
    if (path === ROUTE_PATHS.ctgList) {
      return pathname.startsWith("/ctg");
    }

    if (path === ROUTE_PATHS.pressList) {
      return !pathname.startsWith("/ctg");
    }

    return pathname.startsWith(`${path}`);
  };

  return (
    <S.HeaderWrapper>
      <S.HeaderCenterBox>
        <S.HeaderColumn>
          <S.HeaderText onClick={() => navigate(ROUTE_PATHS.pressList)}>
            {PRODUCT_NAME}
          </S.HeaderText>
          <S.Divider />
          <S.PressText
            onClick={() => handleNavigate(ROUTE_PATHS.pressList)}
            focus={isActivePath(ROUTE_PATHS.pressList)}
          >
            Press
          </S.PressText>
          <S.CTGText
            onClick={() => handleNavigate(ROUTE_PATHS.ctgList)}
            focus={isActivePath(ROUTE_PATHS.ctgList)}
          >
            CTG
          </S.CTGText>
        </S.HeaderColumn>
        <S.HeaderColumn>
          <S.Date>{dayjs().format("MMMM DD, YYYY")}</S.Date>
          <S.Divider />
          <S.SignOutButton type="button" onClick={handleSignOut}>
            {TEXT_SIGN_OUT}
          </S.SignOutButton>
        </S.HeaderColumn>
      </S.HeaderCenterBox>
    </S.HeaderWrapper>
  );
};

export default Header;
