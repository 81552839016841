import { createAttributionId } from "@/components/business/CTGFillInTableContainer/shared/utils/createAttributionId";
import { CTGEntryData } from "@/types/ctgEntryData.types";
import { DrugProfileTable } from "@/types/drugProfile.types";

import { CTG_SECTION } from "../../../shared/constants/articleSection.constants";

export const CHECK_ATTRIBUTION_ID_IN_ENTRY_DATA: {
  [key in string]: (data: CTGEntryData) => boolean;
} = {
  [CTG_SECTION]: (data: CTGEntryData): boolean => {
    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": data[CTG_SECTION]?.["Drug Profile"],
    };

    const checkDrugProfile = () => {
      return ENTRY_DATA_BASIC_PATH["Drug Profile"]?.every((tableItem) => {
        return (
          tableItem.attributionInfo?.attributionId &&
          tableItem["Brand Name"]?.attributionId &&
          tableItem["INN Name"]?.attributionId &&
          tableItem["Code Name"]?.attributionId &&
          tableItem.MOA?.attributionId &&
          tableItem.Modality?.attributionId &&
          tableItem["Other Characteristics"]?.attributionId &&
          tableItem.ROA?.attributionId &&
          tableItem.Target?.attributionId &&
          tableItem["Target Indication"]?.attributionId &&
          tableItem["Originator/Developer"]?.attributionId &&
          tableItem.Phase?.attributionId
        );
      });
    };

    if (!checkDrugProfile()) {
      return false;
    }

    return true;
  },
};

export const CHECK_ENTRY_DATA_FORMAT: {
  [key in string]: (data: CTGEntryData) => CTGEntryData;
} = {
  [CTG_SECTION]: (data: CTGEntryData): CTGEntryData => {
    const drugProfile = data[CTG_SECTION] as DrugProfileTable;
    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": drugProfile["Drug Profile"],
    };

    return {
      ...data,
      [CTG_SECTION]: {
        ...drugProfile,
        "Drug Profile": ENTRY_DATA_BASIC_PATH["Drug Profile"]?.map(
          (tableItem) => {
            return {
              ...tableItem,
              attributionInfo: {
                attributionId:
                  tableItem.attributionInfo?.attributionId ??
                  createAttributionId(),
              },
              "Brand Name": {
                ...tableItem["Brand Name"],
                attributionId:
                  tableItem["Brand Name"]?.attributionId ??
                  createAttributionId(),
              },
              "INN Name": {
                ...tableItem["INN Name"],
                attributionId:
                  tableItem["INN Name"]?.attributionId ?? createAttributionId(),
              },
              "Code Name": {
                ...tableItem["Code Name"],
                attributionId:
                  tableItem["Code Name"]?.attributionId ??
                  createAttributionId(),
              },
              MOA: {
                ...tableItem.MOA,
                attributionId:
                  tableItem.MOA?.attributionId ?? createAttributionId(),
              },
              Modality: {
                ...tableItem.Modality,
                attributionId:
                  tableItem.Modality?.attributionId ?? createAttributionId(),
              },
              "Other Characteristics": {
                ...tableItem["Other Characteristics"],
                attributionId:
                  tableItem["Other Characteristics"]?.attributionId ??
                  createAttributionId(),
              },
              ROA: {
                ...tableItem.ROA,
                attributionId:
                  tableItem.ROA?.attributionId ?? createAttributionId(),
              },
              Target: {
                ...tableItem.Target,
                attributionId:
                  tableItem.Target?.attributionId ?? createAttributionId(),
              },
              "Target Indication": {
                ...tableItem["Target Indication"],
                attributionId:
                  tableItem["Target Indication"]?.attributionId ??
                  createAttributionId(),
              },
              "Originator/Developer": {
                ...tableItem["Originator/Developer"],
                attributionId:
                  tableItem["Originator/Developer"]?.attributionId ??
                  createAttributionId(),
              },
              Phase: {
                ...tableItem.Phase,
                attributionId:
                  tableItem.Phase?.attributionId ?? createAttributionId(),
              },
            };
          },
        ),
      },
    };
  },
};
