import { useLocation } from "react-router-dom";
import { AtomEffect, atom, useRecoilState, useRecoilValue } from "recoil";

import { CtgListOrderingValue } from "@/components/business/CtgListContainer/ctgList.types";

import { RECOIL_KEYS } from "./recoilKeys";

import type { ProcessStatusCode } from "@/types/code.types";

export interface CtgListSearchFilter {
  allInfo?: string;
  companyName?: string;
  nctId?: string;
  ctgTitle?: string;
  allEditor?: string;
  mcEditorName?: string;
  ftEditorName?: string;
}

export interface CtgListFilter {
  search?: CtgListSearchFilter;
  pageLimit: number;
  page: number;
  ctgListDataTotal: number;
  maxPage: number;
  status: ProcessStatusCode[];
  publishable?: "true" | "false";
  isTranslated?: "true" | "false";

  order: {
    nctId?: CtgListOrderingValue;
    ctgDate?: CtgListOrderingValue;
    sponsorName?: CtgListOrderingValue;
    collaboratorName?: CtgListOrderingValue;
    ctgTitle?: CtgListOrderingValue;
    writerName?: CtgListOrderingValue;
    mcEditorName?: CtgListOrderingValue;
    ftEditorName?: CtgListOrderingValue;
    writeStartDt?: CtgListOrderingValue;
    writeEndDt?: CtgListOrderingValue;
    draftArticleStatusHighest?: CtgListOrderingValue;
    articleRejectReason?: CtgListOrderingValue;
  };
}

const handleSearchQuery =
  (): AtomEffect<CtgListFilter> =>
  ({ setSelf, onSet }) => {
    const newSearchParams = new URLSearchParams();
    const location = useLocation();

    if (location.search) {
      const searchParams = new URLSearchParams(location.search);

      const { status, search } = JSON.parse(
        Object.fromEntries(searchParams).filter,
      );

      setSelf({
        page: 1,
        maxPage: 1,
        pageLimit: 20,
        ctgListDataTotal: 0,
        status: status || [],
        order: {},
        search: search || {},
      });
    }
    onSet((newValue) => {
      if (window.location.pathname.includes("ctg-list")) {
        newSearchParams.set("filter", JSON.stringify(newValue));
        window.history.pushState(
          "",
          "ctg-list",
          `ctg-list?${newSearchParams.toString()}`,
        );
      }
    });
  };

const ctgListFilterState = atom<CtgListFilter>({
  key: RECOIL_KEYS.ctgListFilterState,
  default: {
    page: 1,
    maxPage: 1,
    pageLimit: 20,
    ctgListDataTotal: 0,
    status: [],
    order: {},
    search: {},
  },
  effects: [handleSearchQuery()],
});

export const useCtgListFilterState = () => useRecoilState(ctgListFilterState);

export const useCtgListFilterValue = () => useRecoilValue(ctgListFilterState);
